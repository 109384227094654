import React from 'react'

import LivePreview from './LivePreview.date'
import Editor from './Editor.date'
import CodeCopy from './CodeCopy.date'
import View from './View.date'
import Input from './Input.date'

export default {
  livePreview: args =>  <LivePreview {...args}/>,
  editor: args => <Editor {...args}/>,
  codeCopy: args => <CodeCopy {...args}/>,
  input: args => <Input {...args}/>,

  default: () => ({
    type: "date",
    text: "Date Picker Label",
    pinned: {
      status: false
    },
    placeholder: "Date Picker Placeholder",
    validations: []
  }),
  
  availableValidations: ['required', 'no future date', 'no past date', 'no after session date', 'no before session date'],
  validate: () => false,
  group: "GENERAL",
  icon: "icon-ia-calendar",
  name: "Date Picker",
  view: args => <View {...args} />
}