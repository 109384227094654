import React from 'react'

import TabletPreview from '../../components/TabletPreview'
import Loading from '../../components/Loading'
import supportedElements from '../../formComponents/supportedElements'

const FormCodes = ({state}) => {

    return(
        <TabletPreview>
            {state.loadingForm && <Loading />}
            {!state.loadingForm && state.form &&
                <div 
                    className="wrapper fixed-elements"
                    css={`
                        && {
                            .header,
                            .footer,
                            .fieldset-row,
                            .id-card-extractor,
                            .add-photo { pointer-events: auto !important; }
                        }
                    `} 
                >
                    <header className="header">
                        <div className="logo">
                            <img className="white-logo" src="/alchemistLight/img/Alchemist_Identity_Final-17.png" alt="" />
                        </div>
                    </header>
                    <div className="wrapper-content">
                        <div className="table">
                            <div className="table-cell">
                                <div className="content">
                                    <form className="form">

                                    <div className="fieldset-row fieldset-row-title">
                                        <h1 className="main-title">{state.form.heading}</h1>
                                    </div>

                                    {/* <div className="fieldset-row">
                                        <p className="paragraph">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
                                    </div> */}
                                        
                                        {state.form.elements.map(element => 
                                            <React.Fragment key={element.code}>
                                                {supportedElements[element.type].codeCopy({element})}
                                            </React.Fragment>        
                                        )}

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                          
            }
        </TabletPreview>
    )
}

export default FormCodes