import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText"
import DefaultOfferPhoto from '../../images/photo.svg'

export default ({
    element,
    value = [],
    changeValue,
    errors = [],
    answers_by_code
}) => {

    const onOfferClick = offerId => {

        if(value.includes(offerId)) {
            changeValue(value.filter(id => id !== offerId))
            return
        }
        
        if(element.allowMultipleSelections) {
            changeValue([...value, offerId])
            return
        }

        changeValue([offerId])
    }

    return (
        <div className="fieldset-row">
            {element.title &&
                <p className="form-box-wrap-descrition">{synchronizeText(element.title, answers_by_code)}</p>
            }

            {element.text &&
                <p className="form-box-wrap-descrition">{synchronizeText(element.text, answers_by_code)}</p>
            }

            <div className={`form-row ${(errors.length > 0) ? 'error' : '' }`}>
                <div className="form-grid" style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                    {element.offers.map(offer => (
                        <div key={offer._id} className={`offer-row ${value.includes(offer._id) ? 'selected' : '' }`} onClick={() => onOfferClick(offer._id)}>
                            <div className="upload-image-grid">
                                <div className="upload-image-box"><img src={offer.image || DefaultOfferPhoto} alt="" /></div>

                                {offer.title &&
                                    <p><strong>{offer.title}</strong></p>
                                }

                                {offer.description &&
                                    <p>{offer.description}</p>
                                }
                                <i className="icon-check" />
                            </div>
                        </div>
                    ))}
                </div>
                {renderErrors(errors)}
            </div>
        </div>
    );
};

const renderErrors = (errors) => {
    if (errors.length === 0)
        return null;

    return errors.map(error => {
        if (error === 'required') {
            return <span key={error} className="form-row-error-msg">This field is required</span>;
        }

        return null;
    })
};