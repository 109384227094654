import React from 'react'
import LdeleteConfirm from '../../components/LdeleteConfirm';

export default ({element, remove, editElement, changeElementEditorMode, dragHandleProps = {}, VisibilityAffected, formElements}) => {

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onPinnedChange = e => {
        editElement({
            ...element,
            pinned: {
                status: e.target.checked
            }
        })
    };

    const onSaveClick = () => {
        changeElementEditorMode("DEFAULT")
    };

  return(
    <div className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"}`}>
      <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
      <div className="form-box-wrap-center">
        <div className="form-box-wrap-center-content">
          <p>{element.text}</p>
            <p className="form-row-validation-text">{element.validations.map(v => `[ ${v.validation} ]  `)}{element.pinned && element.pinned.status ? "[ show on top ]" : ""}</p>
        </div>
        <VisibilityAffected element={element} formElements={formElements}/>
        {/* <span className="form-box-wrap-id">{element.code}</span> */}
        <div className="form-box-wrap-options">
          <div className="form-box-wrap-options-buttons">
            <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
            <LdeleteConfirm
              onConfirm={remove}
              okText="Delete"
              cancelText="Cancel"
              title="Are you sure you want to delete this component? All data will be lost. "
            >
              <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
            </LdeleteConfirm>
          </div>
        </div>
      </div>
      <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
      <div className="form-box-wrap-center-edit">
        <div className="form-row">
          <textarea placeholder="Heading Text" value={element.text} onChange={e => editElement({ ...element, text: e.target.value })} />
        </div>
          <div className="frc-box">
              <div className="form-row-checkbox">
                  <input id={`pinned-${element.code}`} type="checkbox"
                         checked={element.pinned && element.pinned.status} onChange={onPinnedChange}/>
                  <label htmlFor={`pinned-${element.code}`}>Show on top in saved response</label>
              </div>
          </div>
        <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
      </div>
    </div>
  )
}