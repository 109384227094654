import React from 'react'

import {getAnswersByCode} from '../../utils/getAnswersByCode'
import LpopUp from '../../components/LpopUp'

const CompletedForm = ({Oppression, state, supportedElements, setHiddenElement}) => {

    const [modal, setModal] = React.useState(true)

    const elements_references = {}
    const form = state.session.formSnapshot.length > 0 ? state.session.formSnapshot[0] : {}
    const formElements = form.elements || []
    const answers = formElements.reduce((acc, {value, code}) => value ? ({...acc, [code]: value}) : acc, {})
    console.log("answers", answers)
    const answers_by_code = state.session ? getAnswersByCode(answers, formElements) : {}

    return (
        <>
            <form className="form" onSubmit={e => e.preventDefault()} css={`
                .header,
                .footer,
                .fieldset-row,
                .id-card-extractor,
                .add-photo { pointer-events: none!important; }
            `}>

                <div className="fieldset-row fieldset-row-title">
                    <h1 className="main-title">{state.form.heading}</h1>
                </div>

                {state.form.elements.map(element => {
                    if(!supportedElements[element.type]) return null

                        const value = formElements.find(({code}) => element.code === code).value

                        return (
                            <Oppression 
                                {...{
                                    answers_by_code,
                                    form: state.form,
                                    setHiddenElement,
                                    hiddenElements: state.hiddenElements,
                                    element
                                }}
                                key={element._id}
                            >
                                <div ref={ref => elements_references[element._id] = ref}>
                                    {supportedElements[element.type].input(
                                        {
                                            element,
                                            value,
                                            changeValue: f=>f,
                                            errors: [],
                                            answers_by_code,
                                            formElements,
                                            form: state.form,
                                        }
                                    )}
                                </div>
                            </Oppression>
                        )
                })}
            </form>
            <LpopUp
                visible={modal}
                okText="ok"
                onConfirm={() => setModal(false)}
                onCancel={() => setModal(false)}
                disableHeader
            >
                <h3>You already competed this form. Thank you for your input.</h3>
            </LpopUp>
        </>
    )
}

export default CompletedForm