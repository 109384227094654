import React from 'react'

import LivePreview from './LivePreview.time'
import Editor from './Editor.time'
import CodeCopy from './CodeCopy.time'
import View from './View.time'
import Input from './Input.time'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <Editor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args} />,

    default: () => ({
        type: "time",
        text: "Time Picker Label",
        placeholder: "Time Picker Placeholder",
        pinned: {
            status: false
        },
        validations: []
    }),

    availableValidations: ['required'],
    validate: () => false,
    group: "GENERAL",
    icon: "icon-clock",
    name: "Time Picker",
    view: args => <View {...args}/>
}