import React from 'react'

export default ({ element }) => {

    return(
        <div className="fieldset-row">
            <div className="id-card-extractor">
                <div className="id-card-extractor-list">
                    <div className="id-card-extractor-cell">
                        <p><span>{`${element.step1} `}</span>{element.uploadTakePictureLabel}</p>
                        <div className="step1-btns">
                            <div 
                                css={`
                                    ::before { content: "${({ uploadButton }) => uploadButton}" !important;}
                                `}    
                                className="upload-image-box upload-camera-img" 
                                uploadButton={element.cameraImageButton}
                            >
                                <input className="upload-image-input" type="file" name="" />
                            </div>
                            <div 
                                css={`
                                    ::before { content: "${({ uploadButton }) => uploadButton}" !important;}
                                    && {::after {content: '\\ee5a';}}
                                `} 
                                className="upload-image-box" 
                                uploadButton={element.uploadImageButton}
                            >
                                <input className="upload-image-input" type="file" name="" />
                            </div>
                        </div>
                        <p>Please take a photo<br/> or upload a photo of your ID card</p>
                        <p>OR</p>
                        <span className="add-block-input mar2b">Complete manually</span>
                    </div>
                    <div className="id-card-extractor-cell">
                        <p><span>Step 2</span>Preview Uploaded Image</p>
                        <div className="id-card-image"><img src="../img/id-card.svg" /></div>
                        <div className="id-card-image-actions">
                            <span className="button button-outline discard-btn">Discard</span>
                            <span className="button button-outline accept-btn">Accept</span>
                        </div>
                    </div>
                    <div className="id-card-extractor-cell">
                        <p><span>Step 3</span>Preview extracted data</p>
                        <fieldset className="form-grid">
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>Name</label>
                                    <input type="text" name="" placeholder="" />
                                </div>
                                <div className="form-row">
                                    <label>Surname</label>
                                    <input type="text" name="" placeholder="" />
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>Email</label>
                                    <input type="text" name="" placeholder="" />
                                </div>
                                <div className="form-row">
                                    <label>Phone</label>
                                    <input type="text" name="" placeholder="" />
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>CNP</label>
                                    <input type="text" name="" placeholder="" />
                                </div>
                                <div className="form-row with-calendar">
                                    <label>Date of birth</label>
                                    <input type="text" name="" value="DD / MM / YYYY" />
                                    <i className="calendar-btn icon-ia-calendar"></i>
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>County</label>
                                    <select />
                                </div>
                                <div className="form-row">
                                    <label>City</label>
                                    <select />
                                </div>
                            </div>
                            <div className="form-grid-row street-grid">
                                <div className="form-row">
                                    <label>Street</label>
                                    <input type="text" name="" placeholder="" />
                                </div>
                                <div className="form-row">
                                    <label>No</label>
                                    <input type="text" name="" placeholder="" />
                                </div>
                            </div>
                            <div className="form-grid-row address-grid">
                                <div className="form-row">
                                    <label>Building</label>
                                    <input type="text" name="" placeholder="" />
                                </div>
                                <div className="form-row">
                                    <label>Entry</label>
                                    <input type="text" name="" placeholder="" />
                                </div>
                                <div className="form-row">
                                    <label>Floor</label>
                                    <input type="text" name="" placeholder="" />
                                </div>
                                <div className="form-row">
                                    <label>Apartment</label>
                                    <input type="text" name="" placeholder="" />
                                </div>
                            </div>
                            <div className="form-grid-row id-grid">
                                <div className="form-row">
                                    <label>CI Series</label>
                                    <input type="text" name="" placeholder="" />
                                </div>
                                <div className="form-row">
                                    <label>CI No</label>
                                    <input type="text" name="" placeholder="" />
                                </div>
                                <div className="form-row">
                                    <label>Issued by</label>
                                    <input type="text" name="" placeholder="" />
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row with-calendar">
                                    <label>Valid from</label>
                                    <input type="text" name="" value="DD / MM / YYYY" />
                                    <i className="calendar-btn icon-ia-calendar"></i>
                                </div>
                                <div className="form-row with-calendar">
                                    <label>Valid until</label>
                                    <input type="text" name="" value="DD / MM / YYYY" />
                                    <i className="calendar-btn icon-ia-calendar"></i>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    )
}