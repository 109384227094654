import React from 'react'
import {useParams} from 'react-router-dom'

import FormProperties from './FormProperties'
import FormDefinition from './FormDefinition'
import FormMapping from './FormMapping'

const EditForm = () => {
    const params = useParams()
    return(
        <>
            {
                {
                    properties: <FormProperties />,
                    definition: <FormDefinition />,
                    mapping: <FormMapping />
                }[params.part]
            }
        </>
    )
}

export default EditForm