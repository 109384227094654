import React from 'react'
import CodeCopy from '../CodeCopy'
import camera from '../../images/camera.svg'

export default ({ 
    element
}) => {

    return (
        <div className="fieldset-row">
            <CodeCopy text={`#${element.code}}
            {%.}
            {/${element.code}`}>
                    <label>{element.text}</label>
                    <img style={{ maxWidth: '100%', height: "auto" }} src={camera}/>
            </CodeCopy>
        </div>
    )
}