import React from 'react'
import uuid from 'uuid/v4'

import LdeleteConfirm from '../../components/LdeleteConfirm'

export default ({element, remove, editElement, changeElementEditorMode, dragHandleProps = {}}) => {

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        changeElementEditorMode("DEFAULT")
    };

    const onPinnedChange = e => {
        editElement({
            ...element,
            pinned: {
                status: e.target.checked
            }
        })
    };

    const onTextChange = key => ({target: {value: text}}) => editElement({...element, [key]: text});

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" ? "view-box-options" : ""} ${element.editorMode === "EDIT" ? "view-box-edit" : ""} ${element.editorMode === "VALIDATIONS" ? "view-box-validation" : ""}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center">
                <div className="form-box-wrap-center-content">
                    <h6 className="supTitle">Customer Satisfaction</h6>
                    <p className="form-box-wrap-descrition">{element.title}</p>
                    <p className="form-box-wrap-descrition">{element.text}</p>
                </div>
                <ul className="feedback-component">
                    <li><i className="icon-feedback-icon-1"/><span>{element.worst}</span></li>
                    <li><i className="icon-feedback-icon-2"/><span>{element.poor}</span></li>
                    <li><i className="icon-feedback-icon-3"/><span>{element.average}</span></li>
                    <li><i className="icon-feedback-icon-4"/><span>{element.good}</span></li>
                    <li><i className="icon-feedback-icon-5"/><span>{element.excellent}</span></li>
                </ul>
                <div className="form-row">
                    <p className="form-box-wrap-descrition">{element.descriptionTitle}</p>
                    <textarea disabled/>
                </div>
                {/* <span className="form-box-wrap-id">#s456te</span> */}
                <p className="form-row-validation-text">{element.validations.map(v => `[ ${v.validation} ]  `)}{element.pinned && element.pinned.status ? "[ show on top ]" : ""}</p>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <LdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </LdeleteConfirm>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                {(id => (
                    <div className="form-row">
                        <input id={id} placeholder="Customer Satisfaction Title" onChange={onTextChange("title")} value={element.title}/>
                    </div>
                ))(uuid())}

                {(id => (
                    <div className="form-row">
                        <input id={id} placeholder="Customer Satisfaction Description" onChange={onTextChange("text")} value={element.text}/>
                    </div>
                ))(uuid())}

                {(id => (
                    <div className="form-row">
                        <input id={id} placeholder="Details" onChange={onTextChange("descriptionTitle")} value={element.descriptionTitle}/>
                    </div>
                ))(uuid())}

                {(id => (
                    <div className="form-row">
                        <input id={id} placeholder="Worst" onChange={onTextChange("worst")} value={element.worst}/>
                    </div>
                ))(uuid())}

                {(id => (
                    <div className="form-row">
                        <input id={id} placeholder="Poor" onChange={onTextChange("poor")} value={element.poor}/>
                    </div>
                ))(uuid())}

                {(id => (
                    <div className="form-row">
                        <input id={id} placeholder="Average" onChange={onTextChange("average")} value={element.average}/>
                    </div>
                ))(uuid())}

                {(id => (
                    <div className="form-row">
                        <input id={id} placeholder="Good" onChange={onTextChange("good")} value={element.good}/>
                    </div>
                ))(uuid())}

                {(id => (
                    <div className="form-row">
                        <input id={id} placeholder="Excellent" onChange={onTextChange("excellent")} value={element.excellent}/>
                    </div>
                ))(uuid())}
                <div className="frc-box">
                    <div className="form-row-checkbox">
                        <input id={`pinned-${element.code}`} type="checkbox"
                               checked={element.pinned && element.pinned.status} onChange={onPinnedChange}/>
                        <label htmlFor={`pinned-${element.code}`}>Show on top in saved response</label>
                    </div>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}
