import React from 'react'
import {notification} from '../../components/Lnotification'

import API from '../../api'
import Loading from '../../components/Loading'

const ChangePassword = ({onCancel=f=>f}) => {
    const [state, setState] = React.useState({
        loading: false,
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        errors: {}
    }, 'profile.password')

    const changePassword = async () => {
        try{
            setState(state => ({...state, loading: true, errors: {}}))
            const errors = {}

            if(state.currentPassword.trim() === '') errors.currentPassword = 'Invalid current password'

            if(state.newPassword.trim() === '') errors.newPassword = 'Invalid new password'
            else if(
                !(/[a-z]/.test(state.newPassword)) ||
                !(/[A-Z]/.test(state.newPassword)) ||
                !(/[0-9]/.test(state.newPassword))
            ) errors.newPassword = 'New password must be min 6 char long and include lowercase, uppercase letter and number'

            if(state.confirmPassword.trim() === '') errors.confirmPassword = 'Invalid confirm password'
            else if(
                !(/[a-z]/.test(state.confirmPassword)) ||
                !(/[A-Z]/.test(state.confirmPassword)) ||
                !(/[0-9]/.test(state.confirmPassword))
            ) errors.confirmPassword = 'Confirm password must be min 6 char long and include lowercase, uppercase letter and number'
            else if(state.newPassword !== state.confirmPassword) errors.confirmPassword = 'Confirm password does not match'

            setState(state => ({...state, errors}))

            if(Object.keys(errors).length > 0) {
                setState(state => ({...state, loading: false}))
                return
            }

            await API.put('users/set/new/password', {password: state.currentPassword, new_password: state.newPassword})
            onCancel()
            setState(state => ({
                ...state,
                loading: false,
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
                errors: {}
            }))
        }catch(err) {
            const errors = {}

            if(err.response && err.response.data && err.response.data.error) {
                const error = err.response.data.error
                if(error === 'password_mismatch') errors.currentPassword = "Wrong current password"
                setState(state => ({...state, errors}))
            } else {
                notification.warning({message: 'Something went wrong'})
            }
            setState(state => ({...state, loading: false}))
            
        }
    }


    return(
        <>
            <div className="change-password">
                <div className="titleDesc">
                    <h3>Change password</h3>
                    <p>Please note that when changing your password, we ask you to set your self a secure password that contains both uppercase and lowercase letters as well as numbers. This is for your own safety.</p>
                </div>
                <form 
                    className={`form ${Object.keys(state.errors).length > 0 ? 'error-anim' : ''}`}
                    onSubmit={e => {
                        e.preventDefault()
                        changePassword()
                    }}
                >
                    {state.loading && <Loading color="#39193B"/>}
                    {!state.loading && 
                        <>
                            <div className="fieldset-row">
                                <div className={`form-row ${state.errors.currentPassword ? 'error' : ''}`}>
                                    <label htmlFor="currentPassword">Current Password</label>
                                    <input 
                                        id="currentPassword" 
                                        type="password" 
                                        placeholder=""
                                        value={state.currentPassword}
                                        onChange={({target:{value}}) => setState(state => ({...state, currentPassword: value}))} 
                                    />
                                </div>
                                <div className={`form-row ${state.errors.newPassword ? 'error' : ''}`}>
                                    <label htmlFor="newPassword">New Password</label>
                                    <input 
                                        id="newPassword"
                                        type="password" 
                                        placeholder=""
                                        value={state.newPassword}
                                        onChange={({target:{value}}) => setState(state => ({...state, newPassword: value}))}  
                                    />
                                </div>
                                <div className={`form-row ${state.errors.confirmPassword ? 'error' : ''}`}>
                                    <label htmlFor="confirmPassword">Confirm New Password</label>
                                    <input 
                                        id="confirmPassword" 
                                        type="password" 
                                        placeholder=""
                                        value={state.confirmPassword}
                                        onChange={({target:{value}}) => setState(state => ({...state, confirmPassword: value}))}  
                                    />
                                </div>
                            </div>
                            {Object.keys(state.errors).length > 0 &&
                                <div className="error-msg">{Object.values(state.errors)[0]}</div>
                            }
                            <div className="form-actions">
                                <button className="button with-icon-right close-box"><i className="icon-ia-checked"></i>Save</button>
                                <span onClick={onCancel} className="button button-link close-box">Cancel</span>
                            </div>
                        </>
                    }
                </form>
            </div>
        </>
    )
}

export default ChangePassword