import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText";

const Heading = ({
    element,
    answers_by_code
}) => {

    return (
        <div className="fieldset-row fieldset-row-title">
            <p className="paragraph">{synchronizeText(element.text, answers_by_code)}</p>
        </div>
    )
};

export default Heading;