import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText";
import {GoogleMap, LoadScript} from '@react-google-maps/api';
import {Marker} from '@react-google-maps/api'
import {css} from 'styled-components'

import axios from 'axios'
import debounce from '../../utils/debounce'

const cssFixes = css`

    .location-box {
        width: 100%;
        height: 30rem;
        position: relative;
    }

    #map {
        position: relative;
        overflow: hidden;
    }

    .location-box #map {
        width: 100%;
        height: 100%;
    }

    .search-location {
        width: auto;
        position: absolute;
        top: .8rem;
        left: .8rem;
        z-index: 1000;
    }

    .search-location:before {
        width: 3.6rem;
        height: 3.6rem;
        position: absolute;
        top: 0;
        left: 0;
        content: '\\e9d8';
        text-align: center;
        font-size: 1.4rem;
        line-height: 3.6rem;
        text-align: center;
        font-family: icons;
    }

    .view-dropdown input {
        box-shadow: var(--shadow)!important;
        outline: none!important;
        border-color: var(--primary-color)!important;
        background: #fff!important;
    }

    .search-location input {
        width: 25rem;
        background: #fff!important;
        padding-left: 3.5rem;
    }

    .view-dropdown .dropdown {
        height: 13rem;
        border: 1px solid #ccc;
        box-shadow: var(--shadow);
    }

    .view-dropdown .dropdown {
        height: 13rem;
        border: 1px solid #ccc;
        box-shadow: var(--shadow);
    }

    .dropdown .scrollbar {
        /* max-height: 16rem; */
        padding: 0!important;
    }




    .dropdown ul {
        width: 100%;
        margin: auto;
        padding: 0;
    }

    .dropdown ul li {
        width: 100%;
        position: relative;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.8rem;
        color: #333;
        border-bottom: 1px solid #ccc;
        padding: .7rem 2rem .7rem 1.2rem;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-transition: color .3s ease-in-out, background .3s ease-in-out;
        transition: color .3s ease-in-out, background .3s ease-in-out;
    }

    .dropdown ul li:after {
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: 50%;
        right: .5rem;
        -webkit-transform: translate(0,-50%) scale(0);
        transform: translate(0,-50%) scale(0);
        content: '\\ee68';
        font-size: .8rem;
        line-height: 1.5rem;
        text-align: center;
        font-family: icons;
        background: #5d2560;
        color: #fff;
        border-radius: 50%;
        -webkit-transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
    }

    .dropdown {
        width: 100%;
        height: 0;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 100;
        background: #fff;
        overflow: hidden;
        margin-top: 1px;
        -webkit-transition: height .3s ease-in-out, border .3s ease-in-out, box-shadow .3s ease-in-out;
        transition: height .3s ease-in-out, border .3s ease-in-out, box-shadow .3s ease-in-out;
        border: 0 solid transparent;
        box-shadow: none;
        border-radius: .3rem;
    }

`

export default ({
    element,
    value = '',
    changeValue,
    errors = [],
    answers_by_code
}) => {

    return (
        <div className="fieldset-row" css={cssFixes}>
            {element.title && <p style={{width: '100%'}}>{synchronizeText(element.title, answers_by_code)}</p>}
            {element.text && <p>{synchronizeText(element.text, answers_by_code)}</p>}
            <div className="location-box">
                <div id="map">
                    <MyComponent changeValue={changeValue}/>
                </div>
            </div>
        </div>
    )
}


const center = {
    lat: 45.9237859,
    lng: 22.7775086
}

const MyComponent = ({changeValue}) => {

    const [map, setMap] = React.useState(null)

    const [state, setState] = React.useState({
      search: '',
      locations: [],
      viewDropdown: false,
      debounceState: null
    }, 'map-component')
  
    const debounceStore = [
      state.debounceState, 
      debounceState => setState(state => ({...state, debounceState}))
    ]
  
    const [merkerRef, setMarkerRef] = React.useState(null)
    
    const onLoad = React.useCallback(function callback(map) {
      setMap(map)
    }, [])
  
    const onUnmount = React.useCallback(function callback(map) {
      setMap(null)
    }, [])
  
    const searchAdressWithNominatim = async location => {
  
      const response = await axios.get('https://nominatim.openstreetmap.org/reverse', {params: {
        lat: location.lat,
        lon: location.lng,
        format: 'json'
      }})

      setState(state => ({...state, search: response.data.display_name}))

      searchPlace(response.data.display_name)

  
      changeValue({
        address: response.data.display_name,
        location:  {lat: response.data.lat, lng: response.data.lon}
      })
  
    }
    
    const changeSearch = search => {
      setState(state => ({...state, search}))
    }
  
    const searchPlace = async search => {
      changeSearch(search)
      debounce(async () => {
  
        const response = await axios.get(`https://nominatim.openstreetmap.org/search`, {params: {
          q: search,
          format: 'json'
        }})
  
        if(response.data && Array.isArray(response.data)) {
          setState(state => ({...state, locations: [...response.data]}))
        }
  
      }, 1000, debounceStore)
  
    }

    function animateMapZoomTo({map, currentZoom, targetZoom, callback=f=>f}) {
        currentZoom = currentZoom || map.getZoom();
        if (currentZoom != targetZoom) {
            window.google.maps.event.addListenerOnce(map, 'zoom_changed', function (event) {
                animateMapZoomTo({
                  map, 
                  targetZoom, 
                  currentZoom: currentZoom + (targetZoom > currentZoom ? 1 : -1), 
                  callback
                });
            });
            setTimeout(function(){ map.setZoom(currentZoom) }, 80);
        } else {
          callback()
        }
    }

    return (
        <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        >
            <GoogleMap
                mapContainerStyle={{
                    height: "100%",
                    width: "100%"
                }}
                zoom={7}
                center={center}
        
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                    mapTypeControl: false
                }}
                onClick={e => {
                        if(merkerRef) {
                            merkerRef.setPosition(e.latLng)
                            map.panTo(e.latLng)
                            searchAdressWithNominatim({lat: e.latLng.lat(), lng: e.latLng.lng()})
                        }
                }}
            >
                <Marker
                    onLoad={setMarkerRef}
                    position={center}
                    draggable
                    onDragEnd={() => {
                        if(merkerRef) {
                            const newPosition = merkerRef.getPosition()
                            map.panTo(newPosition)
                            searchAdressWithNominatim({lat: newPosition.lat(), lng: newPosition.lng()})
                        }
                    }}
                    icon={'alchemistLight/img/pin.png'}
                    onClick={() => {
                        if(merkerRef) {
                            const position = merkerRef.getPosition()
                            searchAdressWithNominatim({lat: position.lat(), lng: position.lng()})
                        }
                    }}
                />


                    <div className={`search-location ${(state.viewDropdown && state.locations.length > 0) ? 'view-dropdown': ''}`}>

                        <input 
                            type="text" 
                            name="" 
                            placeholder="Search location" 
                            onChange={({target:{value}}) => searchPlace(value)}
                            value={state.search}
                            onFocus={() => {
                                setState(state => ({...state, viewDropdown: true}))
                            }}
                            onBlur={() => {
                                setState(state => ({...state, viewDropdown: false}))
                            }}
                        />

                            <div className="dropdown">
                                <div className="scrollbar">
                                    <ul>
                                        {state.locations.map(location => (
                                        <li 
                                            value={location.place_id}
                                            onClick={() => {
                                                changeValue({
                                                    address: location.display_name,
                                                    location: {lat: location.lat, lng: location.lon}
                                                })
                                                merkerRef.setPosition({lat: parseFloat(location.lat), lng: parseFloat(location.lon)})
                                                
                                                map.panTo({lat: parseFloat(location.lat), lng: parseFloat(location.lon)})         
                                                window.google.maps.event.addListenerOnce(map, "idle", function() { 
                                                    animateMapZoomTo({map,targetZoom: 13})
                                                })

                                                setState(state => ({...state, viewDropdown: false, search: location.display_name}))
                                            }}
                                        >{location.display_name}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            
                        </div>


            </GoogleMap>
        </LoadScript>
    )
};