import React from 'react'
import {useParams} from 'react-router-dom'
import {ThemeProvider} from 'styled-components'

import axios from 'axios'
import Loading from '../../components/Loading'
import {Oppression} from '../../components/Oppression'
import supportedElements from '../../formComponents/supportedElements'
import {VALIDATION} from '../../constants/validations'
import {getAnswersByCode} from '../../utils/getAnswersByCode'
import {isValidValues} from '../../utils/isValidValues'
import PageNotFound from '../PageNotFound/PageNotFound';
import StartPage from './StartPage'
import FinishPage from './FinishPage'

import CompletedForm from './CompletedForm'
import LpopUp from "../../components/LpopUp";

import look from './session.look'

const NewEmailSession = () => {

    const [state, setState] = React.useState({
        loadingForm: false,
        showTwoStep: false,
        twostepvalidations: [],
        session: null,
        finished: false,
        form: null,
        answers: {},
        hiddenElements: {},
        validation_errors: {},
        savingSession: false,
        sessionStarted: false,
        sessionFinished: false
    }, 'new email session');

    const params = useParams();

    React.useEffect(() => {
        loadSession()
    }, []);

    const setShowTwoStep = showTwoStep => setState(state => ({...state, showTwoStep}));

    const loadSession = async () => {
        try {
            setState(state => ({...state, loadingForm: true}));
            const {data} = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/sessions/secret/${params.secret}`);
            setState(state => ({
                ...state,
                finished: data.finished,
                form: {
                    ...data._form,
                    elements: data._form.steps.length > 0 ? data._form.steps[0].elements : [],
                    heading: data._form.steps.length > 0 ? data._form.steps[0].heading : "",
                    fontColor: data._form.font_color,
                    backgroundColor: data._form.background_color,
                    fontFace: data._form.font_face
                },
                session: {...data, _form: undefined}
            }));
            setState(state => ({...state, loadingForm: false}))
        } catch (err) {
            setState(state => ({...state, loadingForm: false}))
        }
    };

    const startSession = () => setState(state => ({...state, sessionStarted: true}));
    const cancelSession = () => setState(state => ({...state, sessionStarted: false}));

    const setHiddenElement = element => setState(state => ({
        ...state,
        hiddenElements: {...state.hiddenElements, ...element}
    }));

    const changeValue = ({code, value}) => setState(state => ({
        ...state,
        answers: {
            ...state.answers,
            [code]: value
        }
    }));

    const answers_by_code = state.form ? getAnswersByCode(state.answers, state.form.elements) : {};
    const elements_references = {};

    const getValidationErrors = () => {
        const validation_errors = {};
        const elements = state.form.elements;
        const values = Object.entries(state.answers).map(([code, value]) => ({
            element_id: elements.find(element => element.code === code)._id,
            value
        }));
        isValidValues(elements, values, validation_errors);
        return validation_errors
    };

    const scrollToError = (validation_errors, elements_references) => {
        let y, ref = null;
        const validation_errors_keys = Object.keys(validation_errors);
        const elements_references_keys = Object.keys(elements_references).filter(ref => validation_errors_keys.includes(ref));
        elements_references_keys.forEach(key => {
            if (!y) {
                y = elements_references[key].getBoundingClientRect().y;
                ref = elements_references[key]
            } else {
                if (y > elements_references[key].getBoundingClientRect().y) {
                    y = elements_references[key].getBoundingClientRect().y;
                    ref = elements_references[key]
                }
            }
        });
        setTimeout((ref) => {
            ref.scrollIntoView({block: 'start', behavior: 'smooth'})
        }, 500, ref)
    };
    const saveSession = async () => {
        try {
            setState(state => ({...state, savingSession: true}));

            const validation_errors = getValidationErrors();

            if (Object.keys(validation_errors).length > 0) {
                scrollToError(validation_errors, elements_references);
                setState(state => ({...state, validation_errors, savingSession: false}));
                return
            }

            if (Object.keys(state.validation_errors).length > 0) {
                setState(state => ({...state, validation_errors: {}}));
            }
            const elements = state.form.elements;
            const file_uploads = {};
            let values = {...state.answers};
            Object.keys(values).map(code => {
                const element = elements.find(element => code === element.code);
                if (element && element.type === 'file upload') {
                    file_uploads[element._id] = values[code];
                    delete values[code];
                }
            });
            const twoStepComponent = state.form.elements
                .find(element =>
                    element.validations.map(validation => validation.validation).includes(VALIDATION.PHONE_TWO_STEP_VALIDATION) ||
                    element.validations.map(validation => validation.validation).includes(VALIDATION.EMAIL_TWO_STEP_VALIDATION)
                );

            if (twoStepComponent) {
                const required = twoStepComponent.validations.map(validation => validation.validation).includes(VALIDATION.REQUIRED);
                const value = state.answers[twoStepComponent.code];

                if (required || value) {
                    // check if two step is completed
                    const completedTwoStep = state.twostepvalidations && state.twostepvalidations.find(({communication}) => communication === value);

                    if (!completedTwoStep) {
                        setShowTwoStep(true);
                        setState(state => ({...state, savingSession: false}));
                        return
                    }
                }
            }
            const formData = new FormData();
            if (state.form.elements.length > 0) {
                formData.append(
                    'values',
                    JSON.stringify(Object.entries(values).map(([code, value]) => ({
                        element_id: state.form.elements.find(element => element.code === code)._id,
                        value: value
                    })))
                )
            }
            const file_uploads_element_ids = Object.keys(file_uploads);
            file_uploads_element_ids.forEach(element_id => {
                for (let i = 0; i < file_uploads[element_id].length; i++) {
                    formData.append(element_id, file_uploads[element_id][i])
                }
            });
            if (state.twostepvalidations.length > 0) {
                formData.append('twostepvalidations', JSON.stringify(state.twostepvalidations));
            }
            await axios.put(`${process.env.REACT_APP_API_BASE_URL}/sessions/${params.sessionId}/form/${params.formId}`, formData, {params: {secret: params.secret}});

            setState(state => ({...state, savingSession: false, sessionFinished: true}))
        } catch (err) {
            setState(state => ({...state, savingSession: false}))
        }
    };

    return (
        <>


            <div className={`wrapper ${state.startSession ? "fixed-elements" : "form-preview-page"}`}>

                <header className="header" style={{padding: "0 2rem"}}>
                    <div className="logo">
                        <img className="white-logo" src="/alchemistLight/img/Alchemist_Identity_Final-17.png" alt=""/>
                    </div>
                </header>

                {(state.loadingForm || state.savingSession) && <Loading/>}
                {!state.loadingForm && state.form && !state.finished && (state.form.steps.length === 0 || (state.form.steps[0].elements && state.form.steps[0].elements.length === 0)) &&
                <PageNotFound/>
                }
                {state.showTwoStep &&
                <TwoStep
                    onFinish={() => setShowTwoStep(false)}
                    formId={state.form._id}
                    elementValue={
                        state.answers[state.form.elements
                            .find(element =>
                                element.validations.map(validation => validation.validation).includes(VALIDATION.PHONE_TWO_STEP_VALIDATION) ||
                                element.validations.map(validation => validation.validation).includes(VALIDATION.EMAIL_TWO_STEP_VALIDATION)
                            ).code]
                    }
                    twoStepType={
                        state.form.elements
                            .find(element =>
                                element.validations.map(validation => validation.validation).includes(VALIDATION.PHONE_TWO_STEP_VALIDATION)
                            ) ? VALIDATION.PHONE_TWO_STEP_VALIDATION : VALIDATION.EMAIL_TWO_STEP_VALIDATION
                    }
                    setParentState={setState}
                />
                }
                {!state.loadingForm && !state.savingSession && state.form && ((state.form.steps[0] && state.form.steps[0].elements && state.form.steps[0].elements.length > 0) || state.finished) &&
                    <ThemeProvider theme={{ 
                        mode: 'dark',
                        backgroundColor: state.form.backgroundColor,
                        fontColor: state.form.fontColor,
                        fontFace: state.form.fontFace,
                        defaultLook: {
                            fontColor: "#5D2560",
                            backgroundColor: "#F1F1F1",
                            fontFace: 'Open Sans'
                        }
                    }}>

                        <div className="wrapper-content" css={`
                            ${look}
                        `}>
                            <div className="table">
                                <div className="table-cell">
                                    <div className="content">
                                        {state.finished &&
                                        <CompletedForm {...{Oppression, state, supportedElements, setHiddenElement}} />
                                        }
                                        {!state.finished && !state.sessionStarted &&
                                        <StartPage startSession={startSession} form={state.form}/>}
                                        {!state.finished && state.sessionStarted && state.sessionFinished && <FinishPage/>}

                                        {!state.finished && state.sessionStarted && !state.sessionFinished &&
                                        <form
                                            className="form"
                                            onSubmit={e => {
                                                e.preventDefault();
                                                saveSession()
                                            }}
                                        >

                                            <div className="fieldset-row fieldset-row-title">
                                                <h1 className="main-title">{state.form.heading}</h1>
                                            </div>

                                            <div 
                                                css={`
                                                        display: grid;
                                                        align-content: flex-start;
                                                        grid-gap: 3rem;
                                                        position: relative;
                                                `}
                                            >
                                                {state.form.elements.map(element => {
                                                    if (!supportedElements[element.type]) return null;

                                                    const value = state.answers[element.code];

                                                    return (
                                                        <Oppression
                                                            {...{
                                                                answers_by_code,
                                                                form: state.form,
                                                                setHiddenElement,
                                                                hiddenElements: state.hiddenElements,
                                                                element
                                                            }}
                                                            key={element._id}
                                                        >
                                                            <div ref={ref => elements_references[element._id] = ref}>
                                                                {supportedElements[element.type].input(
                                                                    {
                                                                        element,
                                                                        value,
                                                                        changeValue: value => changeValue({
                                                                            code: element.code,
                                                                            value
                                                                        }),
                                                                        errors: state.validation_errors[element._id],
                                                                        answers_by_code,
                                                                        formElements: state.form.elements,
                                                                        form: state.form,
                                                                        answers: state.answers,
                                                                        setErrors: err => {
                                                                            if(!err) delete state.validation_errors[element._id]
                                                                            setState(state => ({...state, validation_errors: {...state.validation_errors, [element._id]: err}}))
                                                                        },
                                                                    }
                                                                )}
                                                            </div>
                                                        </Oppression>
                                                    )
                                                })}
                                            </div>


                                            <div className="fieldset-row form-actions">
                                                <button className="button button-link" type="button" onClick={cancelSession}>Cancel</button>
                                                <button className="button with-icon-right"><i className="icon-ia-checked"/>Save</button>
                                            </div>

                                        </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ThemeProvider>

                }
            </div>

        </>
    )
};

const TwoStep = ({
    onFinish = f => f,
    formId,
    twoStepType,
    elementValue,
    setParentState
}) => {

    const [state, setState] = React.useState({
        createCode: {
            creating: false,
            codeId: undefined
        },
        createCodeError: null,
        sendingConfirmation: false,
        confirmationError: null,
        code: ''
    }, 'two step');

    React.useEffect(() => {
        createTwoStepCode()
    }, []);

    const createTwoStepCode = async () => {
        try {
            setState(state => ({...state, createCode: {...state.createCode, creating: true}}));
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/sessions/${formId}/twostepcode/`, {
                communication: elementValue,
                step: 1
            });
            setState(state => ({...state, createCode: {creating: false, codeId: response.data}}))
        } catch (err) {
            setState(state => ({...state, createCodeError: true,createCode: {creating: false, codeId: undefined}}));
        }

    };

    const onConfirmClick = async () => {
        console.log("lalalalal")
            try {
                setState(state => ({...state, sendingConfirmation: true}));
                await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/sessions/${formId}/twostepcode/${state.createCode.codeId}`, {
                    step: 1,
                    code: state.code
                });
                setState(state => ({
                    ...state,
                    sendingConfirmation: false
                }));
                setParentState(pState => ({
                    ...pState,
                    twostepvalidations: [{
                        _id: state.createCode.codeId,
                        code: state.code,
                        step: 0,
                        communication: elementValue
                    }]
                }));
                onFinish()
            } catch
                (err) {
                setState(state => ({...state, sendingConfirmation: false, confirmationError: true}));
            }
        }
    ;

    const onResendClick = async () => {
        try {
            setState(state => ({...state, code: '', confirmationError: false, createCode: {creating: true, codeId: undefined}}));
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/sessions/${formId}/twostepcode/${state.createCode.codeId}/reset`, {
                communication: elementValue,
                step: 1
            });
            setState(state => ({...state, createCode: {creating: false, codeId: response.data}}))
        } catch (err) {
            setState(state => ({...state, createCodeError: true, createCode: {creating: false, codeId: undefined}}));
        }
    };

    const onCodeChange = ({target: {value: code}}) => setState(state => ({...state, code}));

    return (
        <>
       
            <LpopUp
                visible={true}
                title={twoStepType === VALIDATION.EMAIL_TWO_STEP_VALIDATION ? "Confirm email" : "Confirm phone number"}
                onCancel={onFinish}
                disableFooter
            >
                {(state.createCode.creating || state.sendingConfirmation) &&
                    <Loading/>
                }
                {!state.createCode.creating && !state.sendingConfirmation && !state.createCodeError &&
                    <div class={`form ${state.createCodeError || state.confirmationError ? "error-anim" : ""}`}>
                        <p>You recieved a code by {twoStepType === VALIDATION.EMAIL_TWO_STEP_VALIDATION ? "Email" : "SMS"}. To continue please enter it in the box below.</p>
                        <div className={`form-row ${state.confirmationError ? 'error' : ''}`}>
                            <input value={state.code} onChange={onCodeChange}/>
                        </div>
                        {state.confirmationError && 
                            <div className="error-msg">Code is incorrect</div>
                        }
                        <div className="overlay-actions">
                            <button className="button with-icon-right close-overlay"
                                    onClick={onConfirmClick}>Confirm
                            </button>
                            <button className="button button-link close-overlay"
                                    onClick={onResendClick}>Resend code
                            </button>
                        </div>
                    </div>
                }
                {state.createCodeError &&
                    <div className="error-msg">Something went wrong!</div>
                }
            </LpopUp>
            
        </>
    )
};

export default NewEmailSession