import React, { useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import styled from 'styled-components'
import LdeleteConfirm from './LdeleteConfirm'
import Ratio from 'react-ratio';

export default ({
    value = null,
    onChange = f=>f,
}) => {
    const sigRef = React.useRef({})


    React.useEffect(() => {
        value && sigRef.current.fromDataURL(value)
    }, [value])

    const clear = () => {
        onChange(null)
        sigRef.current.clear()
    }

    const save = () => {
        onChange(sigRef.current.toDataURL("image/png"))
    }

    return (
      <>
        <Ratio ratio={ 16 / 9 }   css={`
            .clear-signature-pad {
                z-index: 1;
            }
            .signature-pad {
                width: 100%;
                height: 100%;
            }
        `}>
             <LdeleteConfirm
                title="Are you sure you want to delete the signature?"
                onConfirm={clear}
                okText="Delete"
            >
                <i class="icon-refresh-cw clear-signature-pad"></i>
            </LdeleteConfirm>
            <SignatureCanvas
                onEnd={save}
                clearOnResize={false}
                ref={sigRef}
                penColor='black'
                canvasProps={{
                    className: "signature-pad"
                }}
            />
        </Ratio>
      </>
    )
}