import React from 'react'
import styled from 'styled-components'
import {Droppable, Draggable} from "react-beautiful-dnd"

import allElements from '../../formComponents/supportedElements'

const Toolbox = ({onClickElement, elements = allElements}) => {
    const [state, setState] = React.useState({
        collapsed: {
            general: false,
            advanced: true,
            automotive: true,
            financial: true
        }
    });

    const onToggle = group => {
        setState(state => ({
            ...state,
            collapsed: {
                general: true,
                advanced: true,
                automotive: true,
                financial: true,
                [group]: !state.collapsed[group]
            }
        }))
    };

    const onClick = name => {
        onClickElement({...elements[name].default(), code: Date.now().toString()})
    };
    const generalComponents = Object.keys(elements).filter(key => elements[key].group === "GENERAL");
    const specialisedComponents = Object.keys(elements).filter(key => elements[key].group === "ADVANCED");
    const financialComponents = Object.keys(elements).filter(key => elements[key].group === "FINANCIAL");

    return (
        <div className="toolbox toolbox-2">
            <h3 className="tb-header-title">ToolBox</h3>
            <div className="scrollbar">
                <div style={{position: "relative", cursor: "pointer"}} onClick={() => onToggle("general")}>
                    <h4>General</h4>
                    <GroupCollapseIcon className="exp-row-arrow icon-ia-arrow" collapsed={state.collapsed.general}/>
                </div>

                <Droppable droppableId="GENERAL_COMPONENT" isDropDisabled={true}>
                    {(provided, snapshot) => (
                        <List
                            className="components-list"
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                            collapsed={state.collapsed.general}
                        >
                            {generalComponents.map((key, keyIndex) => (
                                <Draggable
                                    key={keyIndex}
                                    draggableId={key}
                                    index={Object.keys(elements).findIndex(name => name === key)}
                                >
                                    {(provided, snapshot) => (
                                        <>
                                            <Element
                                                onClick={() => onClick(key)}
                                                key={key}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                isDragging={snapshot.isDragging}
                                                style={provided.draggableProps.style}
                                            >
                                                <i className={elements[key].icon}/>
                                                <span>{elements[key].name}</span>
                                            </Element>
                                            {snapshot.isDragging && (
                                                <Clone>
                                                    <i className={elements[key].icon}/>
                                                    <span>{elements[key].name}</span>
                                                </Clone>
                                            )}
                                        </>
                                    )}
                                </Draggable>
                            ))}
                        </List>
                    )}
                </Droppable>
                <div style={{position: "relative", cursor: "pointer"}} onClick={() => onToggle("advanced")}>
                    <h4>Advanced</h4>
                    <GroupCollapseIcon className="exp-row-arrow icon-ia-arrow" collapsed={state.collapsed.advanced}/>
                </div>
                <Droppable droppableId="ADVANCED_COMPONENT" isDropDisabled={true}>
                    {(provided, snapshot) => (
                        <List
                            className="components-list"
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                            collapsed={state.collapsed.advanced}
                        >
                            {specialisedComponents.map((key, keyIndex) => (
                                <Draggable
                                    key={keyIndex}
                                    draggableId={key}
                                    index={Object.keys(elements).findIndex(name => name === key)}
                                >
                                    {(provided, snapshot) => (
                                        <>
                                            <Element
                                                onClick={() => onClick(key)}
                                                key={key}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                isDragging={snapshot.isDragging}
                                                style={provided.draggableProps.style}
                                            >
                                                <i className={elements[key].icon}/>
                                                <span>{elements[key].name}</span>
                                            </Element>
                                            {snapshot.isDragging && (
                                                <Clone>
                                                    <i className={elements[key].icon}/>
                                                    <span>{elements[key].name}</span>
                                                </Clone>
                                            )}
                                        </>
                                    )}
                                </Draggable>
                            ))}
                        </List>
                    )}
                </Droppable>

                <div style={{position: "relative", cursor: "pointer"}} onClick={() => onToggle("financial")}>
                    <h4>Financial</h4>
                    <GroupCollapseIcon className="exp-row-arrow icon-ia-arrow" collapsed={state.collapsed.financial}/>
                </div>
                <Droppable droppableId="FINANCIAL_COMPONENT" isDropDisabled={true}>
                    {(provided, snapshot) => (
                        <List
                            className="components-list"
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                            collapsed={state.collapsed.financial}
                        >
                            {financialComponents.map((key, keyIndex) => (
                                <Draggable
                                    key={keyIndex}
                                    draggableId={key}
                                    index={Object.keys(elements).findIndex(name => name === key)}
                                >
                                    {(provided, snapshot) => (
                                        <>
                                            <Element
                                                onClick={() => onClick(key)}
                                                key={key}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                isDragging={snapshot.isDragging}
                                                style={provided.draggableProps.style}
                                            >
                                                <i className={elements[key].icon}/>
                                                <span>{elements[key].name}</span>
                                            </Element>
                                            {snapshot.isDragging && (
                                                <Clone>
                                                    <i className={elements[key].icon}/>
                                                    <span>{elements[key].name}</span>
                                                </Clone>
                                            )}
                                        </>
                                    )}
                                </Draggable>
                            ))}
                        </List>
                    )}
                </Droppable>
            </div>
        </div>
    )

};

export default Toolbox

const List = styled.ul`
	${({collapsed}) => collapsed ? `
		display: none;
	` : ""};
`;

const Element = styled.li`

`;

const Clone = styled(Element)`
	~ li {
        transform: none !important;
    }
`;

const GroupCollapseIcon = styled.span`
	${({collapsed}) => !collapsed ? `
		transform: rotateX(180deg);
	` : ""}
	transition: all 0.3s ease-out;
	position: absolute;
	right: 1.2rem;
	top: 0px;
	line-height: 4rem;
	font-size: 1rem;
`;