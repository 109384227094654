import React from 'react'
import {useLocation, useHistory} from 'react-router-dom'

import LoginComponent from './LoginComponent'
import RegisterComponent from './RegisterComponent'
import ForgotPasswordComponent from './ForgotPasswordComponent'
import ResetPassword from './ResetPassword'

export default () => {

    const params = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const passwordResetToken = params.get('passwordResetToken');
    const [tab, setTab] = React.useState(passwordResetToken ? 'resetPassword' : 'login');
    return (
        <div
            className={`wrapper login-page${tab === 'forgotPassword' ? ' view-forgot-pass' : ''} ${tab === 'resetPassword' ? 'view-change-pass' : ''}`}>
            <div className="table-cell">

                <div className="logo-box"><img src="alchemistLight/img/Alchemist_Identity_Final-17.png" alt=""/></div>

                <div className={`login-box${tab === 'register' ? ' view-tab-2' : ''}`}>

                    <nav className="login-tabs">
                        <div className="login-tabs-content">
                            <span className={`${tab === 'login' ? 'selected' : ''}`}
                                  onClick={() => setTab('login')}>Login</span>
                            <span className={`${tab === 'register' ? 'selected' : ''}`}
                                  onClick={() => setTab('register')}>Register</span>
                            <div className="tab-line"/>
                        </div>
                    </nav>

                    <div className="login-tabs-content">
                        <div className="login-tabs-content-wrapper">

                            <LoginComponent onForgotPassword={() => setTab('forgotPassword')}/>

                            <RegisterComponent/>

                        </div>
                    </div>
                </div>

                {tab === 'forgotPassword' && <ForgotPasswordComponent onLogin={() => setTab("login")}/>}
                {tab === 'resetPassword' && <ResetPassword token={passwordResetToken} onLogin={() => {
                    setTab("login");
                    history.push("/");
                }}/>}

            </div>
        </div>
    )
}