import React from 'react'
import bytes from 'bytes'
import {css} from 'styled-components'

import ImageUploadImage from '../../images/camera.svg'
import getMaxFileSizeFromValidations from '../../utils/getMaxFileSizeFromValidations'
import {synchronizeText} from "../../utils/synchronizeText"
import Loading from '../../components/Loading'
import LdeleteConfirm from '../../components/LdeleteConfirm'

const cssFixes = css`
    .images-wrapper-div {
        margin: 2rem 0 2rem 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;

        >div {
            position: relative;
            display: grid;
            align-content: center;
            align-items: center;
            border: 1px dashed gray;

            >img {
                max-width: 100%;
                height: auto;
            }

            >div {
                position: absolute;
                z-index: 10;
                right: 0;
                top: 0;
                >i {
                    width: 3.6rem;
                    height: 3.6rem;
                    display: inline-block;
                    font-size: 1.8rem;
                    line-height: 3.4rem;
                    border-radius: 0.3rem;
                    border: 1px solid transparent;
                    cursor: pointer;
                    color: var(--primary-color);
                    background: transparent;
                    transition: all 0.3s ease-in-out;
                    text-align: center;
                    :hover {
                        color: #fff;
                        background: black;
                    }
                }
            }
        }
    }

    .image-upload-background {
        cursor: pointer;
    }
`

export default ({
    element,
    value = [],
    errors = [],
    changeValue,
    answers_by_code,
    setErrors
}) => {

    const [state, setState] = React.useState({
        loading: false
    })

    let image_upload

    const setImage = async e => {

        setState(state => ({...state, loading: true}))
        setErrors(undefined)
        const images = [...e.target.files]
        e.target.value = null

        const max_file_size = getMaxFileSizeFromValidations(element)
        if(
            max_file_size &&
            images.reduce((acc, image) => image.size > max_file_size || acc, false)
        ) {
            setErrors(['max file size'])
            setState(state => ({...state, loading: false}))
            return
        }

        const maxNrOfFiles = element.validations.find(validation => validation.validation === 'multiple images')
        if(maxNrOfFiles && maxNrOfFiles.value && images.length + value.length > maxNrOfFiles.value) {
            setErrors(['max number of files'])
            setState(state => ({...state, loading: false}))
            return
        }

        try {
            const imageDataUrls = await Promise.all(
                images.map(image => new Promise((resolve) => {
                    const render = new FileReader()
                    render.readAsDataURL(image)
                    render.onload = () => resolve(render.result)
                }))
            )

            changeValue([...(multiple ? value : []), ...imageDataUrls])
            setState(state => ({...state, loading: false}))
        } catch(err) {
            setState(state => ({...state, loading: false}))
        }

    }

    const removeImage = imageIndex => changeValue(value.filter((_, i) => i !== imageIndex))
    
    const browseFiles = () => image_upload.click()

    const multiple = !!element.validations.find(({validation}) => validation === "multiple images")
    
    return (
        <div className="fieldset-row" css={cssFixes}>
            {element.text &&
                <h3>{synchronizeText(element.text, answers_by_code)}</h3>
            }

            {state.loading && <Loading />}

            {!state.loading && (
                <>
                    {value.length > 0 &&
                        <>
                            <div className="images-wrapper-div">
                                {value.map((image, imageIndex) => (
                                    <div key={imageIndex}>
                                        <LdeleteConfirm
                                            title="Are you sure you want to delete the image?"
                                            onConfirm={() => removeImage(imageIndex)}
                                            okText="DELETE"
                                        >
                                            <div>
                                                <i className="icon-ia-delete"/>
                                            </div>
                                        </LdeleteConfirm>
                                        <img src={image} alt="" />
                                    </div>
                                ))}
                            </div>
                            <span className="add-block-input mar2b" onClick={browseFiles}>{element.title}</span>
                        </>
                    }

                    <input 
                        type="file" 
                        style={{display: 'none'}} 
                        ref={ref => image_upload = ref } 
                        accept="image/*"
                        multiple={multiple}
                        onChange={setImage} 
                    />
                    
                    {value.length < 1 &&
                        <div className="image-upload-background" onClick={browseFiles}>
                            <img style={{ maxWidth: '100%', maxHeight: '300px' }}  src={ImageUploadImage} alt="" />
                        </div>
                    }

                    <div className={`form-row ${(errors.length > 0) ? 'error' : '' }`}>
                        {errors.map((error, i) => 
                            ({
                                required: () => <span key={error} className="form-row-error-msg">This field is required</span>,
                                'max file size': () => {
                                    const maxFileSize = bytes(element.validations.find(({validation}) => validation === 'max file size').value)
                                    return <span key={error} className="form-row-error-msg">The maximum allowed image size is {maxFileSize}bytes</span>
                                },
                                'max number of files': () => {
                                    const maxNrImages = element.validations.find(({validation}) => validation === 'multiple images').value
                                   return <span key={error} className="form-row-error-msg">Maximum number of images is {maxNrImages}</span>
                                }
                            })[error]()
                        )}
                    </div>
                </>
            )}


        </div>
    )
}

export const validate = (validations, value) => {
    let errors
    errors = validations.reduce((errors, validation) => {
        if(validation.validation === 'required') {
            if(value.length < 1) 
                errors.push('required')
        } 
    }, [])
    if(errors) return errors
}