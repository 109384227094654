import React from 'react'

import LivePreview from './LivePreview.Iban'
import Editor from './Editor.Iban'
import CodeCopy from './CodeCopy.Iban'
import View from './View.Iban'
import {elements as ELEMENTS} from '../../constants/elements'
import Input from './Input.iban'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <Editor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,
    
    default: () => ({
        type: ELEMENTS.IBAN,
        text: "IBAN Title",
        pinned: {
            status: false
        },
        placeholder: "IBAN Placeholder",
        validations: []
    }),

    availableValidations: ['required'],
    validate: () => false,
    group: "FINANCIAL",
    icon: "icon-ia-iban",
    name: "IBAN",
    view: args => <View {...args}/>
}

