import React from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {dataURLToBlob} from 'blob-util'
import uuid from 'uuid/v4'
import {notification} from '../../components/Lnotification'

import Navbar from '../../components/Navbar/Navbar'
import API from '../../api'
import Loading from '../../components/Loading'
import FormCodes from './FormMapping.FormCodes'
import DocumentEditor from './FormMapping.DocumentEditor'
import LnavigationPrompt from '../../components/LnavigationPrompt'
import LpopUp from '../../components/LpopUp'
import {css} from 'styled-components'

const FormMapping = () => {
    
    const [state, setState] = React.useState({
        loadingForm: false,
        savingDocument: false,
        form: null,
        unsavedChanges: false,
        docsApiLoaded: false
    }, 'document mapping')

    const params = useParams()
    const history = useHistory()

    React.useEffect(() => { loadForm() }, [])

    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = `${process.env.REACT_APP_DOCUMENT_SERVER_URL}/web-apps/apps/api/documents/api.js`;
        script.onload = () => setState(state => ({...state, docsApiLoaded: true}))
        script.onerror = () => Navbar.warning({message: "Document server not available"})
        const element = document.head.appendChild(script);
        return () => element.remove()
    }, [])

    const loadForm = async () => {
        try {
            setState(state => ({...state, loadingForm: true}))
            const {data:{form}} = await API.get(`forms/${params.id}`)
 
            setState(state => ({
                ...state,
                form: {
                    ...form,
                    elements: form.steps.length > 0 ? form.steps[0].elements : [],
                    heading: form.steps.length > 0 ? form.steps[0].heading : '',
                    logo: form.logo ? URL.createObjectURL(dataURLToBlob(form.logo)) : null,
                    document: form.documents.length > 0 ? {...form.documents[0], key: uuid()} : null
                },
                loadingForm: false
            }))

        } catch(err) {
            setState(state => ({...state, loadingForm: false}))
        }
    }

    const saveDocument = async () => {
        try {
            setState(state => ({...state, savingDocument: true}))
            if(state.unsavedChanges) {
                await API.post(`forms/${params.id}/documents/${state.form.document._id}/saveRequest`, {key: state.form.document.key})
            } 
            setState(state => ({...state, savingDocument: false, unsavedChanges: false}))
        }catch(err) {
            setState(state => ({...state, savingDocument: false}))
            notification.warning({message: "Failed to save changes!"})
        }
    }

    return(
        <div className={`wrapper parameters-page ${state.form && state.form.document ? "view-doc-upload" : ""}`}>
            <Navbar />
            {state.loadingForm  && <Loading />}
            {state.form &&
                <>
                    <div className="content">
                        {state.savingDocument && 
                            <Loading 
								rawCss={css`
									background: #F1F1F1;
									width: 100%;
									height: 100%;
									z-index: 100;
									position: absolute;
								`}
							/>
                        }
                        <div className="content-box">
                            <div className="content-box-header">
                                <h3>Documents and parameters</h3>
                                <p className="supDescription">Here you have the option to include a MS Word Document in .docx format by clicking on the upload button on the right. The form fields on the left can then be associated to fields in the document, by clicking onto a field in the form preview on the left and then pasting at the desired position in the Word document, on the right.</p>
                            </div>
                            <div className="swiper-container pts-top">
                                <div className="tablet-preview">
                                    <img src="/alchemistLight/img/tablet.svg" />
                                    <FormCodes state={state}/>
                                    <i className="icon-ia-edit-bold edit-slide-preview"></i>
                                </div>
                            </div>
                            <DocumentEditor state={state} setState={setState}/>
                            {history.location.state && history.location.state.newForm &&
                                <div className="page-btn" css={`
                                    left: 2rem;
                                    right: unset;
                                    >button {border: none;}
                                `}>
                                    <button className="button button-outline" onClick={async e => {
                                       e.preventDefault()
                                       await saveDocument()
                                       history.push(`/forms/${state.form._id}/edit/definition`, {newForm: true})
                                    }}><i className="icon-ia-arrow-left"></i><span>Back</span></button>
                                </div>
                                
                            }
                            <div className="page-btn">
                                <button className="button" onClick={async e => {
                                    e.preventDefault()
                                    await saveDocument()
                                    history.push('/forms')
                                }}><i className="icon-ia-checked-outline"></i><span>Save</span></button>
                            </div>
                        </div>
                    </div>
                    <LnavigationPrompt when={state.unsavedChanges}>
                        {({onConfirm, onCancel}) => (
                            <LpopUp 
                                visible={true}
                                onCancel={onCancel}
                                onConfirm={onConfirm}
                                disableHeader
                            >
                                <h3>Are you sure you want to leave the page? You have unsaved changes.</h3>
                            </LpopUp>
                        )}
                    </LnavigationPrompt>
                </>
            }
        </div>
    )
}

export default FormMapping