import React from 'react'
import ReactDOM from 'react-dom'

export default ({
    children,
    onConfirm = f=>f,
    okText="Delete",
    cancelText="Cancel",
    title=null,
    onOpen=null,
    body=null,
    header=null
}) => {
    const [open, setOpen] = React.useState(false)

    return(
        <>
            {React.cloneElement(children, { 
                onClick: e => {
                    e.preventDefault()
                    setOpen(true)
                    if (onOpen) onOpen()
                }
            })}
            {ReactDOM.createPortal((
                <>
                    {open &&               
                        <div className="view-overlay-delete-form">
                            <div className="overlay overlay-delete-form" onClick={() => setOpen(false)}>
                                <div className="overlay-content" onClick={e => e.stopPropagation()}>
                                    <div className="overlay-body">
                                        <div className="form">
                                            <h3>{title}</h3>
                                            <div>
                                            {body}
                                            </div>
                                            <div className="overlay-actions">
                                                <button className="button with-icon-right close-overlay" onClick={() => {
                                                    onConfirm()
                                                    setOpen(false)
                                                }}>{okText}</button>
                                                <button className="button button-link close-overlay" onClick={() => setOpen(false)}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                    <i className="icon-x close-button-overlay" onClick={() => setOpen(false)}></i>
                                </div>
                            </div>
                        </div>
                             
                    }
                </>
            ), document.querySelector("#modal"))}
        </>
    )
}
