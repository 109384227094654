import React from 'react'
import isEmail from 'isemail'

const LemailList = ({value={others: [], other: ''}, changeValue=f=>f, error=undefined, placeholder="Add email addresses separated with a comma, semicolon, space or enter."}) => {

    const [state, setState] = React.useState({
        invalid: false
    })

    React.useEffect(() => {
        setState(state => ({
            ...state, 
            invalid: value.other.trim().length === 0 
                ? false 
                : isEmail.validate(value.other)
                    ? false
                    : true
        }))
    }, [value.other])

    const onAddOther = () => {
        if(!isEmail.validate(value.other)) {
            return
        }

        if(value.others.includes(value.other)) {
            changeValue(value => ({...value, other: ''}))
            return
        }

        changeValue(value => ({others: [...value.others, value.other], other: ''}))
    }

    const removeOther = otherIndex => {
        changeValue(value => ({
            ...value,
            others: value.others.filter((_, i) => otherIndex !== i)
        }))
    }

    return(
        <div className={`form-row autocomplete-tags-box ${(error || state.invalid) ? "error" : ""}`}>
            <div className="autocomplete-tags">
                <div className="scrollbar">
                    {value.others.map((other, i) => (
                        <span className="at-tag" key={other} onDoubleClick={() => {
                            changeValue(value => ({
                                other,
                                others: value.others.filter(email => email !== other)
                            }))
                        }}>
                            <i className="icon-ia-close" onClick={() => removeOther(i)}/>
                            {other}
                        </span>
                    ))}
                    <input 
                        required
                        className="at-input" 
                        type="email" 
                        value={value.other}
                        onChange={({target:{value}}) => changeValue(v => ({...v, other: value}))} 
                        placeholder={placeholder}
                        onKeyDown={e => {
                            if(e.keyCode == '13' || e.keyCode == '188' || e.keyCode == '32' || e.keyCode == '186') {
                                e.preventDefault()
                                onAddOther()
                            }   
                        }}
                    />
                </div>
            </div>
            {error && <span className="form-row-error-msg">{error}</span>}
        </div>
    )
}

export default LemailList