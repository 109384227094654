import React from 'react'
const DocumentsPreview = () => null
export default DocumentsPreview
// import API from '../../api'
// import { saveAs } from 'file-saver'
// import { notification } from 'antd'

// import FileSvg from '../../images/file.svg';
// import {synchronizeText} from "../../utils/synchronizeText";

// import {getError} from "../../utils/getError";
// import Loading from "../../components/Loading";

// const DocumentsPreview = ({
//     element,
//     answers_by_code,
//     documents,
//     session_id,
//     secret = null
// }) => {

//     const [state, setState] = React.useState({
//         loading: false,
//         error: null
//     })

//     const downloadFile = async (document_id, filename) => {
//         try {
//             setState(state => ({...state, loading: true}))
//             let response;
//             secret === null
//                 ? response = await API.get(`/sessions/${session_id}/document/${document_id}/pdf`,{responseType: 'blob'})
//                 : response = await API.get(`/sessions/${session_id}/document/${document_id}/secret/${secret}`,{responseType: 'blob'})
//             const pdf_filename = filename.split('.').slice(0, -1).join('.') + '.pdf';
//             saveAs(response.data, session_id + "_" + pdf_filename)
//             setState(state => ({...state, loading: false}))
   
//         } catch(err) {
//             setState(state => ({...state, loading: false, error: getError(err.response)}));
//             notification.error({
//                 message: 'There was a problem downloading the file'
//             })
//         }

//     };
//     if (state.loading) {
//         return <Loading />
//     }
//     return (
//         <div className="fieldset-row">
//             {element.title &&
//                 <p>{synchronizeText(element.title, answers_by_code)}</p>
//             }

//             {element.text &&
//                 <p>{synchronizeText(element.text, answers_by_code)}</p>
//             }

//             <div className="form-grid files-list" style={{gridTemplateColumns: 'repeat(3, 1fr)'}}>
//                 {documents.map(document => (
//                     <div key={document} className="file" style={{cursor: 'pointer'}} onClick={() => downloadFile(document._id, document.filename)}>
//                         <img src={FileSvg} alt="" />
//                         <strong>{document.filename.replace(/\.[^/.]+$/, "")}</strong>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };
