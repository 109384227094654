import React from 'react'
import {useHistory} from 'react-router-dom'

import API from '../../api'
import supportedElements from '../../formComponents/supportedElements'
import {Droppable, Draggable} from "react-beautiful-dnd"
import ConditionalValidation, {VisibilityAffected} from '../../formComponents/ConditionalValidation'
import LdeleteConfirm from '../../components/LdeleteConfirm';
import styled from 'styled-components'
import {notification} from '../../components/Lnotification'

const Editor = React.forwardRef(({state, setState, setForm}, ref) => {
    const history = useHistory();
    // console.log("history", history);

    const formElements = state.form.elements;

    const removeElement = elementIndex => setForm(form => ({
        ...form,
        elements: form.elements.filter((_, i) => i !== elementIndex)
    }));

    const editElementByCode = (code, edited) => setForm(form => ({
        ...form,
        elements: form.elements.map(element => code !== element.code ? element : edited)
    }));

    const clearOppressedElements = oppressorCode => setForm(form => ({
        ...form,
        elements: form.elements.map(potencialyOppressedElement =>
            potencialyOppressedElement.dependency &&
            potencialyOppressedElement.dependency.element_code === oppressorCode
                ? ({...potencialyOppressedElement, dependency: undefined})
                : potencialyOppressedElement
        )
    }));

    const clearOppressedAndShiftAffectedIndexes = (masterCode, optionIndex) => setForm(form => ({
        ...form,
        elements: form.elements.map(potencialyOppressedElement => {

                if (
                    potencialyOppressedElement.dependency &&
                    potencialyOppressedElement.dependency.element_code === masterCode &&
                    potencialyOppressedElement.dependency.option === optionIndex
                ) return ({...potencialyOppressedElement, dependency: undefined});

                if (
                    potencialyOppressedElement.dependency &&
                    potencialyOppressedElement.dependency.element_code === masterCode &&
                    potencialyOppressedElement.dependency.option > optionIndex
                ) return ({
                    ...potencialyOppressedElement,
                    dependency: {
                        ...potencialyOppressedElement.dependency,
                        option: potencialyOppressedElement.dependency.option - 1
                    }
                });

                return potencialyOppressedElement
            }
        )
    }));

    const deleteChain = masterCode => setForm(form => ({
        ...form,
        elements: form.elements.filter(potencialSlave =>
            !(
                potencialSlave.type === "dropdown" &&
                potencialSlave.chained &&
                potencialSlave.chained.role === 'slave' &&
                potencialSlave.chained.masterCode === masterCode
            )
        )
    }));

    const addElementOnPosition = (position, element) => {
        function insert_into_position(orArr, index, element) {
            const arr = [...orArr];
            arr.splice(index, 0, element);
            return arr
        }

        setForm(form => ({
            ...form,
            elements: insert_into_position(form.elements, position, element)
        }))
    };

    const changeElementEditorMode = (elementIndex, mode) => setForm(form => ({
        ...form,
        elements: form.elements.map((el, i) => i !== elementIndex
            ? {...el, editorMode: "DEFAULT"}
            : {...el, editorMode: mode}
        )
    }));

    const changeTitle = heading => setForm(form => ({...form, heading}));

    const save = async callback => {

        if (state.form.elements.find(({editorMode}) => editorMode === "EDIT")) {
            notification.warning({message: <p>Please save the component you are editing</p>});
            return
        }

        try {
            setState(state => ({...state, savingFormDefinition: true}));
            const steps = [{
                heading: state.form.heading,
                elements: state.form.elements
            }];
            await API.put(`forms/${state.form._id}`, {steps});
            setState(state => ({...state, savingFormDefinition: false, unsavedChanges: false}));
            callback()
        } catch (err) {
            setState(state => ({...state, savingFormDefinition: false}))
        }
    };
    return (
        <ContentBox className="content-box">
            <form className="form form-step-2" onSubmit={e => e.preventDefault()}>
                <div className="scrollbar">

                    <div className="form-box-header">
                        <div className="form-row">
                            <input
                                id="form-name"
                                type="text"
                                value={state.form.heading}
                                onChange={({target: {value}}) => changeTitle(value)} placeholder="Screen title"
                            />
                        </div>
                    </div>

                    <Droppable droppableId="editor">
                        {provided => (
                            <div className="form-box-body" ref={el => {
                                provided.innerRef(el);
                                ref(el);
                            }} {...provided.droppableProps}>
                                {formElements.map((element, elementIndex) => {
                                    if (supportedElements[element.type]) {
                                        return <Draggable draggableId={elementIndex.toString()} index={elementIndex}
                                                          key={elementIndex}>
                                            {provided => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >
                                                    {supportedElements[element.type].editor({
                                                        element,
                                                        index: elementIndex,
                                                        editElement: edited => editElementByCode(element.code, edited),
                                                        remove: () => removeElement(elementIndex),
                                                        changeElementEditorMode: mode => changeElementEditorMode(elementIndex, mode),
                                                        dragHandleProps: provided.dragHandleProps,
                                                        form: state.form,
                                                        formElements,
                                                        VisibilityAffected,
                                                        ConditionalValidation,
                                                        editElementByCode,
                                                        clearOppressedElements,
                                                        clearOppressedAndShiftAffectedIndexes,
                                                        addElementOnPosition,
                                                        deleteChain
                                                    })}
                                                </div>
                                            )}
                                        </Draggable>
                                    }
                                    return null
                                })}
                                {provided.placeholder}

                                {state.form.elements.length === 0 &&
                                <div className="form-box-wrap drag-zone-box">
                                    <div>
                                        <i className="icon-ia-drag-out"/>
                                        <span>Drag a Form Component<br/>or a specialised Component</span>
                                    </div>
                                </div>
                                }

                                <div className="form-box-actions">
                                    {history.location.state && history.location.state.newForm &&
                                    <a className="button button-outline" href="#"
                                       onClick={e => {
                                           e.preventDefault();
                                           save(() => {
                                            history.push(`/forms/${state.form._id}/edit/properties`, {newForm: true})
                                           })
                                           
                                       }}
                                    ><i className="icon-ia-arrow-left"/><span>Back</span></a>
                                    }
                                    <LdeleteConfirm
                                        onConfirm={() => {
                                            setState(state => ({
                                                ...state,
                                                form: {
                                                    ...state.form,
                                                    heading: "",
                                                    elements: [],
                                                }
                                            }))
                                        }}
                                        okText="Clear"
                                        cancelText="Cancel"
                                        title="Are you sure you want to clear all data?"
                                    >
                                        <span className="button button-outline"><i className="icon-ia-trash"/><span>Clear All</span></span>
                                    </LdeleteConfirm>
                                    <span/>
                                    <a className="button" href="#" onClick={e => {
                                        e.preventDefault();
                                        save(() => {
                                            if (history.location.state && history.location.state.newForm) {
                                                history.push(`/forms/${state.form._id}/edit/mapping`, {newForm: true})
                                            } else {
                                                history.push('/forms')           
                                            }
                                        })
                                    }}>
                                        <i className="icon-ia-checked-outline"/><span>Save</span>
                                    </a>
                                </div>

                            </div>
                        )}
                    </Droppable>

                </div>
            </form>
        </ContentBox>
    )
});

export default Editor

const ContentBox = styled.div`
	.form .form-box-wrap-center-edit .form-row, .form .form-box-wrap .mco-cell-hidden .form-row {
    margin-bottom: 1rem !important;
}
.form label {
    width: 100%;
    display: block;
    font-size: .8rem;
    line-height: 1rem;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
    font-family: 'Lato', Arial, sans-serif;
    margin-bottom: .7rem;
    color: var(--dark);
    cursor: pointer;
}
.form-box-wrap-id{
    padding: .3rem 1.8rem .3rem 1rem
}

.form-box-wrap-id::after {
    width: 1.2rem;
    height: 1.4rem;
    position: absolute;
    top: 0;
    right: .6rem;
    content: '\\e955';
    font-size: .9rem;
    line-height: 2rem;
    text-align: center;
    color: #666;
    font-family: icons;
}
.frc-box, .ssm-box {
    padding: 1rem 0 0;
    margin-top: 1.6rem;
    border-top: 1px dashed rgba(0,0,0,.1);
}
.form .form-row-checkbox label, .form .form-row-radio label {
    width: 100%;
    display: block;
    position: static;
    z-index: 5;
    cursor: pointer;
    font-size: 1.1rem;
    line-height: 1.8rem;
    padding: .4rem 0 0 3rem;
    text-transform: none;
}
.form-box-wrap .supTitle {
    font-weight: 400;
}
.mco-box .form-box-wrap-center-edit.hck {
    margin-top: 1rem;
}

.copied-component-code {
    padding: .3rem 1.8rem .3rem 1rem;
    width: auto;
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 1.5rem;
    background: #f1f1f1;
    color: #666;
    font-size: 1rem;
    line-height: 1rem;
    transform: translate3d(0,100%,0);
    opacity: 0;
    transition: transform .3s ease-out, opacity .3s ease-in-out;
    user-select: text;
}
.form-box-wrap-center:hover .copied-component-code {
    transform: translate3d(0,0,0);
    opacity: 1;
}
`;