import React from 'react'
import {notification} from '../../components/Lnotification'
import bytes from 'bytes'

import API from '../../api'
import Loading from '../../components/Loading'
import {useAppContext} from "../../context";

const ChangeDetails = () => {

    const {auth: [auth]} = useAppContext();
    const [state, setState] = React.useState({
        loading: false,
        imageLoading: false,
        email: '',
        name: '',
        surname: '',
        lastLogin: '',
        image: null
    },'profile.details')

    React.useEffect(() => {
        getProfileInformation()
    }, [])

    const getProfileInformation = async () => {
        try {
            setState(state => ({ ...state, loading: true }))
            const { data } = await API.get('/users/personal/profile')
            setState(state => ({
                ...state,
                email: data.email,
                image: data.image,
                name: data.name,
                surname: data.surname,
                lastLogin: data.lastLogin,
                loading: false,
            }))
        } catch (err) {
            setState(state => ({ ...state, loading: false }));
            notification.warning({message: 'Something went wrong!'})
        }
    }

    const onImageChange = async e => {
        try {
            if (e.target.files.length === 0) return
            if (e.target.files[0].size > bytes('20MB')) {
                notification.warning({message: 'Max file size 20MB.'})
                e.target.value = null;
                return
            }

            setState(state => ({...state, imageLoading: true}))  
            const dataForm = new FormData()
            dataForm.append('image', e.target.files[0])
            const {data} = await API.put('/users', dataForm)
            setState(state => ({...state, imageLoading: false, image: data.user.image}))
        }catch(err){
            setState(state => ({...state, imageLoading: false}))
            notification.warning({message: 'Something went wrong'})
        }
    }

    return (
        <div className="profile-details">
            {state.loading && <Loading color="#39193B"/>}
            {!state.loading && 
                <>
                    <div className="profile-image">
                        {state.imageLoading && <Loading color="#39193B" style={{position: 'absolute', zIndex: 20}}/>}
                        {!state.imageLoading && 
                            <>
                                {state.image &&
                                    <div className="ils-uploaded-img"><img src={`${(/^https?:\/\//i.test(state.image) || /data:image\/([a-zA-Z]*);base64,([^\"]*)/i.test(state.image)) ? state.image : process.env.REACT_APP_API_BASE_URL +'/images/users/image/' + state.image}`} alt="" /></div>
                                }
                                <input 
                                    className="ils-upload-file"
                                    style={{zIndex: 26}} 
                                    type="file" 
                                    name=""
                                    accept="image/*"
                                    onChange={onImageChange} 
                                />
                                <span>Upload photo</span>
                            </>
                        }
                    </div>
                    <div>
                        <h3>{`${auth.decodedToken.name ? auth.decodedToken.name : ""} ${auth.decodedToken.surname ? auth.decodedToken.surname : ""}`}</h3>
                        <p>{auth.decodedToken.email}</p>
                
                    </div>
                </>
            }
        </div>
    )
}
 
export default ChangeDetails