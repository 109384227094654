import React from 'react';
import {Route, Switch, useHistory, useLocation, Redirect} from 'react-router-dom'
import socketIOClient from "socket.io-client"

import './styles/ant.css';

import Forms from './pages/Forms/Forms'
import Profile from './pages/Profile/Profile'
import Login from './pages/Login/Login'
import Responses from './pages/Responses/Responses'

import ViewResponse from './pages/ViewSession/ViewResponse';
import ViewResponseBySecret from './pages/ViewSession/ViewResponseBySecret';
import NewForm from "./pages/NewForm/NewForm";
import EditForm from './pages/EditForm/EditForm'
import PageNotFound from './pages/PageNotFound/PageNotFound';
import NewEmailSession from './pages/NewEmailSession/NewEmailSession'
import NewPublicSession from './pages/NewPublicSession/NewPublicSession'

import AuthorizationRedirectPage from './pages/AuthorizationRedirectPage'

import {useAppContext} from './context'
import API from './api'
import {notification} from './components/Lnotification'
import SessionMaintainer from './components/SessionMaintainer'

const AuthorisedRoute = props => {
    const {auth: [auth]} = useAppContext()
    const history = useHistory();
    if(!auth || auth.refreshExpirationTime < Date.now()) {
        if(history.location.pathname !== '/') history.push('/');
        return <Login/>
    }
    return <Route {...props} />;
}

const App = () => {

    return (
       <>
            <SessionMaintainer />
            <StorageErrorChecker />
            <Switch>
                <Route path="/login" component={Login}/>
                <AuthorisedRoute path="/profile" component={Profile}/>
                <AuthorisedRoute path="/" exact component={() => <Redirect to="/forms"/>}/>
                <AuthorisedRoute path="/forms" exact component={Forms}/>
                <AuthorisedRoute path="/responses" exact component={Responses}/>

                <AuthorisedRoute path="/forms/new" exact component={NewForm}/>
                <AuthorisedRoute path="/forms/:id/edit/:part" exact component={EditForm}/>
                <AuthorisedRoute path="/responses/:id" component={ViewResponse}/>

                <Route path="/storage/:provider/redirect_uri" component={AuthorizationRedirectPage}/>

                <Route path="/responses/secret/:secret" component={ViewResponseBySecret}/>
                <Route path="/forms/:formId/sessions/:sessionId/public/:secret" exact component={NewEmailSession}/>
                <Route path="/forms/:formId/sessions/public/new" exact component={NewPublicSession}/>
                <Route path="*" component={PageNotFound}/>
            </Switch>
       </>
    )
}

export default App

const StorageErrorChecker = () => {
    const {auth: [auth]} = useAppContext()

    const [state, setState] = React.useState({
        was: auth,
        is: auth
    })

    const history = useHistory()
    const location = useLocation()

    React.useEffect(() => {
        setState(state => ({was: state.is, is: auth}))
    }, [auth])

    React.useEffect(() => {
        if (!state.was && state.is) {
            getStorageErrorInformation()
        }
    }, [state])

    React.useEffect(() => {
        if(location.pathname.includes('public')) return

        const socket = socketIOClient(process.env.REACT_APP_API_BASE_URL)
        socket.on("storage_error", async () => {
            try {
                const {data:{storage}} = await API.get('/users/personal/profile')
                notification.warning({
                    duration: 0, 
                    message: (
                        <>
                           <p>We encountered problems in saving your generated documents to {{
                                gdrive: "Google Drive",
                                onedrive: "Microsoft OneDrive",
                                dropbox: "Dropbox"
                            }[storage.defaultStorage]}. Please reconnect and check your account.</p>
                            <button className="button button-outline" onClick={() => history.push('/profile', {onedrive: true})}>Check</button>
                        </>
                    )
                })
            } catch(err) {
                notification.warning({message: "Failed storage error information"})
            }
        })

    }, [])

    const getStorageErrorInformation = async () => {
        try {
            const {data:{storage, storageFailed}} = await API.get('/users/personal/profile')
            if (storageFailed) {
                notification.warning({
                    duration: 0, 
                    message: (
                        <>
                            <p>We encountered problems in saving your generated documents to {{
                                gdrive: "Google Drive",
                                onedrive: "Microsoft OneDrive",
                                dropbox: "Dropbox"
                            }[storage.defaultStorage]}. Please reconnect and check your account.</p>
                            <button className="button button-outline" onClick={() => history.push('/profile', {onedrive: true})}>Check</button>
                        </>
                    )
                })
            }
        } catch(err) {
            notification.warning({message: "Failed storage error information"})
        }
    }


    return null
}
