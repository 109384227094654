import React from 'react'

import LivePreview from './LivePreview.shortAnswer'
import Editor from './Editor.shortAnswer'
import CodeCopy from './CodeCopy.shortAnswer'
import View from './View.shortAnswer'
import Input from './Input.shortAnswer'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <Editor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,

    default: () => ({
        type: "short answer",
        text: "Text Box Title",
        pinned: {
            status: false
        },
        placeholder: "Text Box Placeholder",
        formula: "",
        validations: []
    }),
    
    availableValidations: ['required', 'phone two step validation', 'phone', 'email two step validation', 'email', 'number', 'positive', 'integer', 'min length', 'min value', 'max length', 'max value'],
    validate: () => false,
    group: "GENERAL",
    icon: "icon-ia-input-box",
    name: "Text Box",
    view: args => <View {...args}/>
}

