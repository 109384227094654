import React from 'react';
import moment from "moment";

const IdCardExtractor = ({
                             value = null
                         }) => {

    const getValue = (name) => {
        if (value === null)
            return '';

        return value[name];
    };

    const getDateValue = (name) => {
        if (value === null) return null;
        if (!value[name] || value[name] === null) return null;
        return moment(value[name], 'YYYY-MM-DD').format('DD/MM/YYYY')
    };

    return (
        <div className="fieldset-row">
            <div className="id-card-extractor">
                <fieldset className="session-form-grid">
                    <div className="session-form-grid-row">
                        <div className="session-form-row">
                            <label>Name</label>
                            <span className="input-box">{getValue('first_name')}</span>
                        </div>
                        <div className="session-form-row">
                            <label>Surname</label>
                            <span className="input-box">{getValue('last_name')}</span>
                        </div>
                    </div>
                    <div className="session-form-grid-row">
                        <div className="form-row">
                            <label>Email</label>
                            <span className="input-box">{getValue('email')}</span>
                        </div>
                        <div className="form-row">
                            <label>Phone</label>
                            <span className="input-box">{getValue('phone')}</span>
                        </div>
                    </div>
                    <div className="session-form-grid-row">
                        <div className="session-form-row">
                            <label>CNP</label>
                            <span className="input-box">{getValue('cnp')}</span>
                        </div>
                        <div className="session-form-row">
                            <label>Date of birth</label>
                            <span className="input-box">{getDateValue('date_of_birth')}</span>
                        </div>
                    </div>
                    <div className="session-form-grid-row">
                        <div className="session-form-row">
                            <label>County</label>
                            <span className="input-box">{getValue('country')}</span>
                        </div>
                        <div className="session-form-row">
                            <label>City</label>
                            <span className="input-box">{getValue('locality')}</span>
                        </div>
                    </div>
                    <div className="session-form-grid-row street-grid">
                        <div className="session-form-row">
                            <label>Street</label>
                            <span className="input-box">{getValue('street')}</span>
                        </div>
                        <div className="session-form-row">
                            <label>No</label>
                            <span className="input-box">{getValue('nr')}</span>
                        </div>
                    </div>
                    <div className="session-form-grid-row address-grid">
                        <div className="session-form-row">
                            <label>Building</label>
                            <span className="input-box">{getValue('block_value')}</span>
                        </div>
                        <div className="session-form-row">
                            <label>Entry</label>
                            <span className="input-box">{getValue('entry')}</span>
                        </div>
                        <div className="session-form-row">
                            <label>Floor</label>
                            <span className="input-box">{getValue('floor')}</span>
                        </div>
                        <div className="session-form-row">
                            <label>Apartment</label>
                            <span className="input-box">{getValue('apartment')}</span>
                        </div>
                    </div>
                    <div className="session-form-grid-row id-grid">
                        <div className="session-form-row">
                            <label>CI Series</label>
                            <span className="input-box">{getValue('series_ci')}</span>
                        </div>
                        <div className="session-form-row">
                            <label>CI No</label>
                            <span className="input-box">{getValue('nr_ci')}</span>
                        </div>
                        <div className="session-form-row">
                            <label>Issued by</label>
                            <span className="input-box">{getValue('emitted_by')}</span>
                        </div>
                    </div>
                    <div className="session-form-grid-row">
                        <div className="session-form-row">
                            <label>Valid from</label>
                            <span className="input-box">{getDateValue('available_from')}</span>
                        </div>
                        <div className="session-form-row with-calendar">
                            <label>Valid until</label>
                            <span className="input-box">{getDateValue('available_to')}</span>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    );
};

export default IdCardExtractor;