import React from 'react'

export default ({ 
    element, 
    onChange = f => f,
    value = {
        first_name: '',
        last_name: '',
        country: '',
        locality: '',
        street: '',
        nr: '',
        block_value: '',
        entry: '',
        floor: '',
        apartment: '',
        postal_code: ''
    } 
}) => {
    const onElementChange = e => onChange({ ...value, [e.target.name]: e.target.value })

    return(
        <div className="fieldset-row">
            <p>{element.text}</p>
            <div className="form-grid">
                <div className="form-grid-row">
                    <div className="form-row">
                        <label>Name</label>
                        <input type="text" value={value.first_name} name="first_name" onChange={onElementChange} />
                    </div>
                    <div className="form-row">
                        <label>Surname</label>
                        <input type="text" value={value.last_name} name="last_name" onChange={onElementChange} />
                    </div>
                </div>
                <div className="form-grid-row">
                    <div className="form-row">
                        <label>County</label>
                        <select value={value.country} name="country" onChange={onElementChange}>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                        </select>
                    </div>
                    <div className="form-row">
                        <label>City</label>
                        <select value={value.locality} name="locality" onChange={onElementChange}>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                        </select>
                    </div>
                </div>
                <div className="form-grid-row street-grid">
                    <div className="form-row">
                        <label>Street</label>
                        <input type="text" value={value.street} name="street" onChange={onElementChange} />
                    </div>
                    <div className="form-row">
                        <label>No</label>
                        <input type="text" value={value.nr} name="nr" onChange={onElementChange} />
                    </div>
                </div>
                <div className="form-grid-row address-grid">
                    <div className="form-row">
                        <label>Building</label>
                        <input type="text" value={value.block_value} name="block_value" onChange={onElementChange} />
                    </div>
                    <div className="form-row">
                        <label>Entry</label>
                        <input type="text" value={value.entry} name="entry" onChange={onElementChange} />
                    </div>
                    <div className="form-row">
                        <label>Floor</label>
                        <input type="text" value={value.floor} name="floor" onChange={onElementChange} />
                    </div>
                    <div className="form-row">
                        <label>Apartment</label>
                        <input type="text" value={value.apartment} name="apartment" onChange={onElementChange} />
                    </div>
                </div>
                <div className="form-grid-row address-grid">
                    <div className="form-row">
                        <label>Postal Code</label>
                        <input type="text" value={value.postal_code} name="postal_code" onChange={onElementChange} />
                    </div>
                </div>
            </div>
        </div>
    )
}