import React from 'react'

import LivePreview from './LivePreview.vehicleEvaluation'
import Editor from './Editor.vehicleEvaluation'
import CodeCopy from './CodeCopy.vehicleEvaluation'
import View from './View.vehicleEvaluation'

export default {
  livePreview: args => <LivePreview {...args}/>,
  editor: args => <Editor {...args}/>,
  codeCopy: args => <CodeCopy {...args}/>,
  view: args => <View {...args}/>,

  default: () => ({
    type: "vehicle evaluation",
    title: "Car Damage Evaluation Sheet",
    text: "",
    pinned: {
      status: false
    },
    validations: []
  }),
  
  availableValidations: [],
  validate: () => false,
  group: "AUTOMOTIVE",
  icon: "icon-ia-car",
  name: "Car Damage Evaluation Sheet"
}