import styled from 'styled-components';

const error_color = '#f10b5d';

export const SignatureImage = styled.img`
  position: relative;
  display: block;
  width: 302px;
  height: 202px;
  margin: 0 0 .5rem;
  border: 1px solid ${props => props.error ? error_color : '#c9ccf0'};
  border-radius: .2rem;
  background-color: ${props => props.error ? 'rgba(242,12,93,.1)' : 'rgb(243, 244, 252)'};
`;

export const SignaturePad = styled.div`
  position: relative;
  display: block;
  width: 302px;
  height: 202px;
  margin: 0 0 .5rem;
  border: 1px solid ${props => props.error ? error_color : '#c9ccf0'};
  border-radius: .2rem;
  background-color: ${props => props.error ? 'rgba(242,12,93,.1)' : 'rgb(243, 244, 252)'};
`;