import React from 'react'
import LdeleteConfirm from '../../components/LdeleteConfirm';
import CodeCopy from '../../components/Editor.fragmentCodeCopy'

export default ({element, remove, editElement, changeElementEditorMode, dragHandleProps = {}}) => {

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        changeElementEditorMode("DEFAULT")
    };

    const onTextChange = e => {
        editElement({
            ...element,
            text: e.target.value
        })
    };

    const onPinnedChange = e => {
        editElement({
            ...element,
            pinned: {
                status: e.target.checked
            }
        })
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center standard-postal-address-box">
                <div className="form-box-wrap-center-content">
                    <h6 className="supTitle">Standard Postal Address</h6>
                    <p className="form-box-wrap-descrition mar2b">{element.text}</p>
                    <fieldset className="form-grid">
                        <div className="form-grid-row">
                            <div className="form-row">
                                <label>Name</label>
                                <CodeCopy code={`${element.code}_first_name`}>
                                    <input type="text" placeholder=""/>
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>Surname</label>
                                <CodeCopy code={`${element.code}_last_name`}>
                                    <input type="text" placeholder=""/>
                                </CodeCopy>
                            </div>
                        </div>
                        <div className="form-grid-row">
                            <div className="form-row">
                                <label>County</label>
                                <CodeCopy code={`${element.code}_country`}>
                                    <select/>
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>City</label>
                                <CodeCopy code={`${element.code}_locality`}>
                                    <select/>
                                </CodeCopy>
                            </div>
                        </div>
                        <div className="form-grid-row street-grid">
                            <div className="form-row">
                                <label>Street</label>
                                <CodeCopy code={`${element.code}_street`}>
                                    <input type="text" placeholder=""/>
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>No</label>
                                <CodeCopy code={`${element.code}_nr`}>
                                    <input type="text" placeholder=""/>
                                </CodeCopy>
                            </div>
                        </div>
                        <div className="form-grid-row address-grid">
                            <div className="form-row">
                                <label>Building</label>
                                <CodeCopy code={`${element.code}_block`}>
                                    <input type="text" placeholder=""/>
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>Entry</label>
                                <CodeCopy code={`${element.code}_entry`}>
                                    <input type="text" placeholder=""/>
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>Floor</label>
                                <CodeCopy code={`${element.code}_floor`}>
                                    <input type="text" placeholder=""/>
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>Apartment</label>
                                <CodeCopy code={`${element.code}_apartment`}>
                                    <input type="text" placeholder=""/>
                                </CodeCopy>
                            </div>
                        </div>
                        <div className="form-grid-row address-grid">
                            <div className="form-row">
                                <label>Postal Code</label>
                                <CodeCopy code={`${element.code}_postal_code`}>
                                    <input type="text" placeholder=""/>
                                </CodeCopy>
                            </div>
                        </div>
                    </fieldset>
                    <p className="form-row-validation-text">{element.validations.map(v => `[ ${v.validation} ]  `)}{element.pinned && element.pinned.status ? "[ show on top ]" : ""}</p>
                    {/* <span className="form-box-wrap-id">{element.code}</span> */}
                </div>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <LdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </LdeleteConfirm>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className="form-row">
                    <input type="text" name="" placeholder="Standard Postal Address Group Label" value={element.text} onChange={onTextChange}/>
                </div>
                <div className="frc-box">
                    <div className="form-row-checkbox">
                        <input id={`pinned-${element.code}`} type="checkbox"
                               checked={element.pinned && element.pinned.status} onChange={onPinnedChange}/>
                        <label htmlFor={`pinned-${element.code}`}>Show on top in saved response</label>
                    </div>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}