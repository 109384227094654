import React from 'react'
import { DatePicker } from 'antd'
import CodeCopy from '../CodeCopy'
import styled from 'styled-components'

export default ({ 
    element, 
}) => {
    return(
        <div className="fieldset-row">
            <div className="form-row">
                <label>{element.text}</label>
                <CodeCopy text={element.code}>
                    <StyledDatePicker
                        style={{ width: "100%" }}
                        mode="date"
                        placeholder={element.placeholder}
                    />
                </CodeCopy>
            </div>
        </div>
    )
}

const StyledDatePicker = styled(DatePicker)`
   > div {
        > input {
            height: 4.2rem !important;
            font-size: 1.2rem !important;
        }

        > i {
            font-size: 2.2rem !important;
            margin-top: -1.1rem;
        }
   }
`