import React from 'react'
import {css} from 'styled-components'
import {useLocation} from 'react-router-dom'

import Loading from '../../components/Loading'
import API from '../../api'
import LpopUp from '../../components/LpopUp'
import {notification} from '../../components/Lnotification'
import {useAppContext} from '../../context'

const formRowRadioStyes = css`
    .form .form-row-radio.error label:after { border-color:#eb2b2b!important; background:rgba(242,12,93,.1)!important; }

    .form .form-row-checkbox,
    .form .form-row-radio { width:100%; /*min-height:3rem;*/ position:relative; margin:0 auto!important; text-align:center; padding:0; border:none; transition:all 0.3s ease-in-out; }
    .form .form-row-checkbox label,
    .form .form-row-radio label { width:100%; display:block; position:static; z-index:5; cursor:pointer; font-size:1.1rem; line-height:1.8rem; padding:.4rem 0 0 3rem; text-transform:none; }
    .form .form-row-checkbox input[type=checkbox],
    .form .form-row-radio input[type=radio] { width:100%; height:100%; position:absolute; top:0; left:0; opacity:0; overflow:hidden; } 
    .form .form-row-checkbox label:after,
    .form .form-row-radio label:after { width:1.8rem; height:1.8rem; padding:0; display:block; position:absolute; top:.2rem; left:0; border-radius:0.2rem; content:""; border:1px solid rgba(0,0,0,.1); background:#f8f8f8; font-family:icons; text-align:center; line-height:1.9rem; font-size:1.4rem; }
    .form .form-row-radio label:after { border-radius:50%; }
    .form .form-row-checkbox input[type=checkbox]:checked + label:after,
    .form .form-row-radio input[type=radio]:checked + label:after,
    .form .form-row-checkbox.error input[type=checkbox]:checked + label:after,
    .form .form-row-radio.error input[type=radio]:checked + label:after { content:"\\e93d"; border-color:var(--primary-color)!important; background:var(--primary-color)!important; color:#fff; box-shadow: var(--shadow); }

    .form .form-row-checkbox.error input[type=checkbox]:checked + label,
    .form .form-row-radio.error input[type=radio]:checked + label { color: var(--dark)!important; }
    .form .form-row-checkbox:hover label:after,
    .form .form-row-radio:hover label:after { border-color: var(--primary-color)!important; box-shadow: var(--shadow); }


    /* ============================================================= */

    .radioCheckbox-list { width:100%; margin:0 auto 4rem; display:grid; grid-template-columns: 1fr; grid-gap:.75rem 1.5rem; }
    .radioCheckbox-list li { /*display:grid; grid-template-columns: auto 1fr; grid-gap:1.5rem;*/ }

    .radioCheckbox-list .form-row-radio {  }
    .form-row-radio-error-msg { display:none; position:relative; color: rgba(235,43,43,1); padding:0 0 .5rem 3rem; border-radius:.3rem; }
    .error .form-row-radio-error-msg { display:block; }
    .form-row-radio-error-msg p { width:auto; display:inline; font-size:1.2rem; font-weight:400; line-height:2rem; margin:0 1.2rem 0 0; }
    .form-row-radio-error-msg .button { height:2rem; line-height:1.8rem; text-transform:none; padding:0 1.5rem; }

    .radioCheckbox-list .error .form-row-radio input[type=radio]:checked + label:after, 
    .radioCheckbox-list .error .form-row-radio input[type=radio]:checked + label:after { content:'\\e9ad'!important; border-color: rgba(235,43,43,1)!important;
        background: rgba(235,43,43,1)!important; box-shadow:none!important; }

    /* ============================================================= */
`;

const storagePopUpStyles = css`
    .ads-steps { width:25rem; position:relative; margin:0 auto 3rem; display:grid; grid-template-columns: repeat(2,auto); }
    .ads-steps:before,
    .ads-steps:after { width:17rem; height:1px; position:absolute; top:50%; left:4rem; transform: translate(0,-50%); background:#e6e6e6; content:''; border:none; }
    .ads-steps:after { width:0; background:#2C2457; -webkit-transition: width .3s ease-in-out; transition: width .3s ease-in-out; }
    .view-next-step .ads-steps:after { width:17rem; }

    .ads-steps li { width:4rem; height:4rem; position:relative; display:inline-block; text-align:center; justify-self: start; }
    .ads-steps li:last-child { justify-self: end; }
    .ads-steps li:first-child:after { width:100%; height:100%; position:absolute; top:50%; left:50%; transform: translate(-50%,-50%) scale(0); background:#2C2457; font-size:1.6rem; line-height:4rem; text-align:center; font-family: icons; content:'\\ee69'; color:#fff; border-radius:50%; -webkit-transition: transform .3s ease-in-out; transition: transform .3s ease-in-out; }
    .view-next-step .ads-steps li:first-child:after { transform: translate(-50%,-50%) scale(1); }

    .ads-steps li span { width:100%; height:100%; display:block; border-radius:50%; border:1px solid #ccc; font-size:1.4rem; line-height:3.8rem; text-align:center; color:#333; -webkit-transition: color .3s ease-in-out .2s, background .3s ease-in-out .2s, border-color .3s ease-in-out .2s; transition: color .3s ease-in-out .2s, background .3s ease-in-out .2s, border-color .3s ease-in-out .2s; }
    .view-next-step .ads-steps li:last-child span { border-color:#2C2457; color:#fff; background:#2C2457; }

    .ads-steps-content { width:100%; margin:0 auto; display:grid; grid-template-columns: repeat(2,20rem); grid-gap:1.5rem; justify-content:center; /*align-items:center;*/ }
    .ads-steps-content li { text-align:center; }
    .ads-steps-content li:last-child { opacity:0; visibility:hidden; -webkit-transition: opacity .3s ease-in-out .2s; transition: opacity .3s ease-in-out .2s; }
    .view-next-step .ads-steps-content li:last-child { opacity:1; visibility:visible; }

    .ads-steps-content h6 { font-size:1.4rem; line-height:2.2rem; font-weight:bold; margin-bottom:1.5rem; text-transform:none; }
    .ads-steps-content p { margin:0 auto; }
    .ads-steps-content .button { width:auto!important; height:3.4rem; line-height:3.2rem; margin:0 auto!important; padding: 0 2rem!important; }

    h3 { text-align:center; margin:0 auto 5rem; }
`

const ConfigureStorage = ({tab}) => {

    const [state, setState] = React.useState({
        loading: false,
        loadingError: null,
        loaded: false,
        config: null,
        modal: false,
        provider: '',
        permission: null,
        folder: null,
        saveConfigurationLoading: false
    },'storageConfig')

    const location = useLocation()

    const {notifications:[notifications]} = useAppContext()

    React.useEffect(() => {
        getConfig()
    }, [])

    React.useEffect(() => {
        if (location.search || location.hash) {
            const script = document.createElement("script");
            script.async = true;
            script.src = 'https://js.live.net/v7.0/OneDrive.js'
            const element = document.head.appendChild(script);
            return () => element.remove()
        }
    }, [])

    const getConfig = async () => {
        try {
            setState(state => ({...state, loading: true}))
            const {data: {storage, storageFailed}} = await API.get('/users/personal/profile')
            setState(state => ({
                ...state, 
                provider: storage.defaultStorage, 
                loading: false, 
                loaded: true,
                config: {
                    storageFailed,
                    storage
                }
            }))
        } catch(err) {
            setState(state => ({...state, loading: false}))
            notification.error({message: "Failed loading storage config"})
        }
    }

    const onStorageClick = async ({target: {value}}) => {
        if(value === state.storage) return
        if(value === 'alchemist') {
            try {
                setState(state => ({...state, loading: true}))
                await API.put('/users', {storage: value})
                setState(state => ({...state, loading: false, provider: 'alchemist'}))
                if(notifications.open) notifications.cancel()
                getConfig()
                
            } catch(err) {
                setState(state => ({...state, loading: false}))
                notification.error({message: "Failed changing storage"})
            }
        } else  {
            requirePrmission()
            setState(state => ({...state, modal: true, provider: value}))
            
            return
        }
    }

    const onPermission = permission => {
        setState(state => ({...state, permission}))
    }

    const requirePrmission = () => {
        const redirect_uri = `${process.env.REACT_APP_DASHBOARD_URL}/storage/onedrive/redirect_uri`
        const scope = 'Files.ReadWrite offline_access openid'
        const client_id = process.env.REACT_APP_STORAGE_ONEDRIVE_CLIENT_ID
        const response_type = 'code'
        const link = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${client_id}&scope=${scope}&response_type=${response_type}&redirect_uri=${redirect_uri}`
        
        const handleCodemessage = event => {
    
            const {code, provider} = event.data
            if(!code || !provider) return
            onPermission({code, provider})
            window.removeEventListener('message', handleCodemessage)
        }
 
        window.open(link, '_blank', 'height=600,width=450')
        window.addEventListener('message', handleCodemessage)

      
    }


    
    const onFolder = folder => setState(state => ({...state, folder}))

    const saveConfiguration = async () => {
        try {
            setState(state => ({...state, loading: true}))
            const {permission: {code}, provider, folder} = state
            await API.post('users/storage/offlineAuthorization', {code, folder, provider})
            setState(state => ({
                ...state,
                modal: false,
                permission: null,
                provider,
                folder: null,
                loading: false
            }))

            if(notifications.open) notifications.cancel()
            getConfig()
            

        } catch (err) {
            setState(state => ({...state, loading: false}))
            notification.error({message: "Failed saving configuration"})
        }
    }

    const reconnect = provider => {
        setState(state => ({...state, modal: true, provider}))
    }

    return (
        <>
            <div 
                css={`
                    padding-left: 5%!important;
                    padding-right: 5%!important;
                    min-height: unset !important;
                    height: 0;
                    border-bottom: 1px solid #ccc;
                    opacity: 0;
                    background: #f8f8f8;
                    transition: opacity .3s ease-in-out, padding .3s ease-in-out, height .3s ease-in-out;
                    overflow: hidden;
                    ${tab === 'storage' 
                        ?   css`
                            height: auto;
                            opacity: 1;
                            padding-top: 5rem;
                            padding-bottom: 5rem;
                        `
                        :   ''
                    }
                    ${formRowRadioStyes}
                `}
                className="form"
            >
                {state.loading && <Loading color="#39193B"/>}
                {!state.loading && state.loaded &&
                    <>
                        <h4>Documents storage</h4>
                        <ul className="radioCheckbox-list cols4">
                            <li>
                                <div className="form-row-radio">
                                    <input 
                                        id="alchemist" 
                                        type="radio" 
                                        name="radio" 
                                        checked={state.provider === 'alchemist'}
                                        value="alchemist"
                                        onChange={onStorageClick}
                                    />
                                    <label htmlFor="alchemist">Alchemist</label>
                                </div>
                            </li>
                            <li className={`${state.config.storageFailed && state.config.storage.defaultStorage === 'onedrive' ? "error" : ""}`}>
                                <div className="form-row-radio give-permission-popup">
                                    <input 
                                        id="onedrive" 
                                        type="radio" 
                                        name="radio" 
                                        checked={state.provider === 'onedrive'}
                                        value="onedrive"
                                        onChange={onStorageClick}
                                    />
                                    <label htmlFor="onedrive">OneDrive</label>
                                </div>
                                <div className="form-row-radio-error-msg">
                                    <p>We encountered problems in saving your generated documents to OneDrive. Please reconnect and check your account.</p>
                                    <span className="button button-outline reconect-button" onClick={() => reconnect('onedrive')}>Reconnect</span>
                                </div>
                            </li>

                        </ul>

                        <LpopUp
                            disableHeader
                            disableFooter={!state.permission || !state.folder}
                            visible={state.modal}
                            onConfirm={saveConfiguration}
                            onCancel={() => setState(state => ({...state, modal: false, permission: null, folder: null, provider: 'alchemist'}))}
                            rawCss={css`
                                .overlay-content {
                                    width: 56rem;
                                }
                            `}
                        >
                            <>
                                <div css={`
                                    ${storagePopUpStyles}
                                    ${state.permission ? css`
                                        .ads-steps:after { width:17rem; }
                                        .ads-steps li:first-child:after { transform: translate(-50%,-50%) scale(1); }
                                        .ads-steps li:last-child span { border-color:#2C2457; color:#fff; background:#2C2457; }
                                        .ads-steps-content li:last-child { opacity:1; visibility:visible; }
                                    ` : ''}
                                `}>
                                    <h3>Documents storage on Microsoft OneDrive</h3>
                                    <ul className="ads-steps">
                                        <li><span>1</span></li>
                                        <li><span>2</span></li>
                                    </ul>
                                    <ul className="ads-steps-content">
                                        <li>
                                            <h6>Give permission</h6>
                                            <p>Your Alchemist generated documents will be stored on your Microsoft Account. You can always change these settings in your profile.</p>
                                        </li>
                                        <li>
                                            <h6>Pick a folder</h6>
                                            {state.permission && !state.folder &&
                                                <OndeDriveFolderPicker onFolder={onFolder}/>
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </>
                        </LpopUp>
                    </>
                }
            </div>
        </>
    )
}

export default ConfigureStorage

const OndeDriveFolderPicker = ({onFolder}) => {

    React.useEffect(() => {
        if (!window.OneDrive) {
            const script = document.createElement("script");
            script.async = true;
            script.src = 'https://js.live.net/v7.0/OneDrive.js'
            const element = document.head.appendChild(script);
            return () => {
                element.remove()
            }
        }
    }, [])

    const load = () => {
        const odOptions = {
            clientId: process.env.REACT_APP_STORAGE_ONEDRIVE_CLIENT_ID,
            action: "query",
            multiSelect: false,
            openInNewWindow: true,
            advanced: {
                filter: "folder"
            },
            success: function (files) {
                const {id} = files.value[0]
                onFolder(id)
            },
            cancel: function () {
            },
            error: function (e) {
            }
        }

        window.OneDrive.save(odOptions);
    }

    return (
        <a onClick={load} type="button" className="button button-outline">Select folder</a>
    )
}