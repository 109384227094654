import React from 'react'
import LdeleteConfirm from '../../components/LdeleteConfirm';
import CardImg from '../../images/id-card.svg'
import CodeCopy from '../../components/Editor.fragmentCodeCopy'
import Validations from "../Validations";
import supportedElements from "../supportedElements";

export default ({element, remove, editElement, changeElementEditorMode, dragHandleProps = {}}) => {

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onValidationsClick = () => {
        changeElementEditorMode("VALIDATIONS")
    };

    const onValidationsChange = newValidations => {
        editElement({
            ...element,
            validations: newValidations
        })
    };

    const onPinnedChange = e => {
        editElement({
            ...element,
            pinned: {
                status: e.target.checked
            }
        })
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        changeElementEditorMode("DEFAULT")
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center id-card-box">
                <div className="id-card-extractor">
                    <div className="id-card-extractor-cell">
                        <p><span>{`${element.step1} `}</span>{element.uploadTakePictureLabel}</p>
                        <div className="step1-icons">
                            <i className="icon-ia-camera"/>
                            <i className="icon-ia-upload"/>
                        </div>
                        {/*<p></p>*/}
                    </div>
                    <div className="id-card-extractor-cell">
                        <p><span>{`${element.step2} `}</span>{element.previewUploadedImage}</p>
                        <div className="id-card-image"><img src={CardImg}/></div>
                    </div>
                    <div className="id-card-extractor-cell">
                        <p><span>{`${element.step3} `}</span>{element.cardInformation}</p>
                        <fieldset className="form-grid">
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>Name</label>
                                    <CodeCopy code={`${element.code}_first_name`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Surname</label>
                                    <CodeCopy code={`${element.code}_last_name`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>Email</label>
                                    <CodeCopy code={`${element.code}_email`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Phone</label>
                                    <CodeCopy code={`${element.code}_phone`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>CNP</label>
                                    <CodeCopy code={`${element.code}_cnp`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row with-calendar">
                                    <label>Date of birth</label>
                                    <CodeCopy code={`${element.code}_date_of_birth`}>
                                        <input type="text" name="" value="DD / MM / YYYY" onChange={f => f}/>
                                    </CodeCopy>
                                    <i className="calendar-btn icon-ia-calendar"/>
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>County</label>
                                    <CodeCopy code={`${element.code}_country`}>
                                        <select/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>City</label>
                                    <CodeCopy code={`${element.code}_locality`}>
                                        <select/>
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row street-grid">
                                <div className="form-row">
                                    <label>Street</label>
                                    <CodeCopy code={`${element.code}_street`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>No</label>
                                    <CodeCopy code={`${element.code}_nr`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row address-grid">
                                <div className="form-row">
                                    <label>Building</label>
                                    <CodeCopy code={`${element.code}_block`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Entry</label>
                                    <CodeCopy code={`${element.code}_entry`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Floor</label>
                                    <CodeCopy code={`${element.code}_floor`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Apartment</label>
                                    <CodeCopy code={`${element.code}_apartment`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row id-grid">
                                <div className="form-row">
                                    <label>CI Series</label>
                                    <CodeCopy code={`${element.code}_series_ci`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>CI No</label>
                                    <CodeCopy code={`${element.code}_nr_ci`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Issued by</label>
                                    <CodeCopy code={`${element.code}_emitted_by`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row with-calendar">
                                    <label>Valid from</label>
                                    <CodeCopy code={`${element.code}_available_from`}>
                                        <input type="text" name="" value="DD / MM / YYYY" onChange={f => f}/>
                                    </CodeCopy>
                                    <i className="calendar-btn icon-ia-calendar"/>
                                </div>
                                <div className="form-row with-calendar">
                                    <label>Valid until</label>
                                    <CodeCopy code={`${element.code}_available_to`}>
                                        <input type="text" name="" value="DD / MM / YYYY" onChange={f => f}/>
                                    </CodeCopy>
                                    <i className="calendar-btn icon-ia-calendar"/>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    {/* <span className="form-box-wrap-id">{element.code}</span> */}
                    <div className="id-card-extractor-cell">
                        <p className="form-row-validation-text">{element.validations.map(v => `[ ${v.validation} ]  `)}{element.pinned && element.pinned.status ? "[ show on top ]" : ""}</p>
                    </div>
                </div>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onValidationsClick}><i className="icon-check trigger-validation-box"/>Validation</span>
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <LdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </LdeleteConfirm>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className="form-row-checkbox">
                    <input id={`pinned-${element.code}`} type="checkbox"
                           checked={element.pinned && element.pinned.status} onChange={onPinnedChange}/>
                    <label htmlFor={`pinned-${element.code}`}>Show on top in saved response</label>
                </div>
                {!element.validations.map((validation) => validation.validation).includes("skip first two steps") &&
                <>
                    <div className="form-row mar2b">
                        <label>Step 1</label>
                        <input type="text" name="" placeholder="None" value={element.step1}
                               onChange={e => editElement({...element, step1: e.target.value})}/>
                    </div>
                    <div className="form-row mar2b">
                        <label>UPLOAD / TAKE PICTURE</label>
                        <input type="text" name="" placeholder="None" value={element.uploadTakePictureLabel}
                               onChange={e => editElement({...element, uploadTakePictureLabel: e.target.value})}/>
                    </div>
                    <div className="form-row mar2b">
                        <label>Upload Image Button</label>
                        <input type="text" name="" placeholder="None" value={element.uploadImageButton}
                               onChange={e => editElement({...element, uploadImageButton: e.target.value})}/>
                    </div>
                    <div className="form-row mar2b">
                        <label>Camera Image Button</label>
                        <input type="text" name="" placeholder="None" value={element.cameraImageButton}
                               onChange={e => editElement({...element, cameraImageButton: e.target.value})}/>
                    </div>
                    <div className="form-row mar2b">
                        <label>Step 2</label>
                        <input type="text" name="" placeholder="None" value={element.step2}
                               onChange={e => editElement({...element, step2: e.target.value})}/>
                    </div>
                    <div className="form-row mar2b">
                        <label>PREVIEW UPLOADED IMAGE</label>
                        <input type="text" name="" placeholder="None" value={element.previewUploadedImage}
                               onChange={e => editElement({...element, previewUploadedImage: e.target.value})}/>
                    </div>
                    <div className="form-row mar2b">
                        <label>Upload Image Again Button</label>
                        <input type="text" name="" placeholder="None" value={element.uploadImageAgainButton}
                               onChange={e => editElement({...element, uploadImageAgainButton: e.target.value})}/>
                    </div>
                    <div className="form-row mar2b">
                        <label>Get ID Information Button</label>
                        <input type="text" name="" placeholder="None" value={element.getIdInformationButton}
                               onChange={e => editElement({...element, getIdInformationButton: e.target.value})}/>
                    </div>
                </>
                }
                <div className="form-row mar2b">
                    <label>Step 3</label>
                    <input type="text" name="" placeholder="None" value={element.step3}
                           onChange={e => editElement({...element, step3: e.target.value})}/>
                </div>
                <div className="form-row mar2b">
                    <label>CARD INFORMATION</label>
                    <input type="text" name="" placeholder="None" value={element.cardInformation}
                           onChange={e => editElement({...element, cardInformation: e.target.value})}/>
                </div>
                <div className="form-row mar2b">
                    <label>Upload Another Image Button</label>
                    <input type="text" name="" placeholder="None" value={element.uploadAnotherImageButton}
                           onChange={e => editElement({...element, uploadAnotherImageButton: e.target.value})}/>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
            <div className="form-box-wrap-center-validation mco-box">
                <div className="mco-cell"><h4>Validations</h4></div>
                <Validations
                    availableValidations={supportedElements[element.type].availableValidations}
                    onChange={onValidationsChange}
                    value={element.validations}
                />
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}