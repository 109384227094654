import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText"

const ClientSignature = ({
    element,
    answers_by_code,
    value = null
}) => {
    return (
        <div class="fieldset-row">
            {element.text && <p>{synchronizeText(element.text, answers_by_code)}</p>}
            <div className="signature-pad" css={`background-image: url(${value});`} />
        </div>
    )
}

export default ClientSignature