import React from 'react'
import AdeleteConfirm from '../../components/LdeleteConfirm';
import mapImg from '../../images/map.svg'
import Validations from '../Validations'
import supportedElements from '../supportedElements'
import CodeCopy from '../../components/Editor.codeCopy'


export default ({element, remove, editElement, changeElementEditorMode, dragHandleProps = {}, VisibilityAffected, formElements, form}) => {

    const [state, setState] = React.useState({
        localElement: {
            ...element
        },
        errors: {}
    })

    const localElement = state.localElement

    const setLocalElement = callback => setState(state => ({
        ...state,
        localElement: {...callback(state.localElement)}
    }))

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        editElement({
            ...state.localElement
        })
        changeElementEditorMode("DEFAULT")
    };

    const onValidationsClick = () => {
        changeElementEditorMode("VALIDATIONS")
    };

    const onPinnedChange = ({target: {checked}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                pinned: {
                    status: checked
                }
            }
        ))
    };

    const onValidationsChange = newValidations => {
        setLocalElement(localElement => (
            {
                ...localElement,
                validations: newValidations
            }
        ))
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center">
                <div className="form-box-wrap-center-content">
                    <h6 className="supTitle">Map</h6>
                    <p className="form-box-wrap-descrition">{localElement.title}</p>
                    <p className="form-box-wrap-descrition">{localElement.text}</p>
                    <div className="form-row-image-cell map-box">
                        <img src={mapImg}/>
                    </div>
                    <VisibilityAffected element={element} formElements={formElements}/>
                    <p className="form-row-validation-text">{element.validations.map(v => `[ ${v.validation} ]  `)}</p>
                </div>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        {/*<span onClick={onValidationsClick}><i className="icon-check trigger-validation-box"/>Validation</span>*/}
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <AdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </AdeleteConfirm>
                    </div>
                </div>
                <CodeCopy code={element.code}>
                    <span className="form-box-wrap-id"/>
                </CodeCopy>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className="form-row">
                    <input type="text" name="" placeholder="Map Title" value={localElement.title}
                           onChange={({target: {value}}) => {
                               setLocalElement(localElement => (
                                   {
                                       ...localElement,
                                       title: value
                                   }
                               ))
                           }}/>
                </div>
                <div className="form-row">
                    <input type="text" name="" placeholder="Map Placeholder" value={localElement.text}
                           onChange={({target: {value}}) => {
                               setLocalElement(localElement => (
                                   {
                                       ...localElement,
                                       text: value
                                   }
                               ))
                           }}/>
                </div>
                <div className="form-row-checkbox">
                    <input id={`pinned-${element.code}`} type="checkbox"
                           checked={localElement.pinned && localElement.pinned.status} onChange={onPinnedChange}/>
                    <label htmlFor={`pinned-${element.code}`}>Show on top in saved session</label>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
            {/*<div className="form-box-wrap-center-validation mco-box">*/}
            {/*    <div className="mco-cell"><h4>Validations</h4></div>*/}
            {/*    <Validations*/}
            {/*        availableValidations={supportedElements[element.type].availableValidations}*/}
            {/*        onChange={onValidationsChange}*/}
            {/*        value={element.validations}*/}
            {/*        form={form}*/}
            {/*    />*/}
            {/*    <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>*/}
            {/*</div>*/}
        </div>
    )
}
