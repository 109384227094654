import React from 'react'
import {css} from 'styled-components'

import TabletPreview from './TabletPreview'

const look = css`

    ${({theme: {backgroundColor, fontColor, defaultLook}}) => (backgroundColor !== defaultLook.backgroundColor || fontColor !== defaultLook.fontColor)
    ? css`
                background-color:  ${backgroundColor};

                color: ${fontColor};

                .form {
                    color: ${fontColor};
                }

                .sup-title,
                .main-title,
                .form .fieldset-row,
                .form .form-grid,
                .form label {
                    color: ${fontColor};
                }

                input, input::placeholder, select, textarea, .add-block-input.mar2b, .add-block-input.mar2b {
                    background-color: ${backgroundColor} !important;
                    color: ${fontColor} !important;
                }

                .button {
                    background-color: ${fontColor} !important;
                    color: ${backgroundColor} !important;
                }

                .ant-calendar-picker > div > i {
                    color: ${fontColor} !important;
                }

                .form-row-radio label:after {
                    color: ${fontColor} !important;
                    background-color: ${backgroundColor} !important;
                }

                .form-row-checkbox label:after {
                    color: ${fontColor} !important;
                    background-color: ${backgroundColor} !important;
                }

                .upload-image-box {
                    background-color: ${backgroundColor} !important;
                }
                
                .upload-image-box:before, .upload-image-box:after {
                    color: ${fontColor} !important;
                }

                .signature-pad {
                    background-color: ${backgroundColor} !important;
                }
            `
    : ""
}


    /* look font */
    ${({theme: {fontFace}}) =>
    css`
            .form {
                font-family: ${fontFace};
            }

            .sup-title,
            .main-title,
            .form .fieldset-row,
            .form .form-grid,
            .form label {
                font-family: ${fontFace};
            }

            input, input::placeholder, select, textarea, .add-block-input.mar2b, .add-block-input.mar2b, pre, .button {
                font-family: ${fontFace} !important;
            }

        `
}


`

const LivePreview = ({name = '', logo = null, description = ''}) => {

    return (
        <TabletPreview>
            <div className="wrapper form-preview-page" css={`
                ${look}
                
                /* fix */
                && {
                    .form-preview  {
                        display: grid;
                        grid-template-rows: min-content auto min-content;
                        header {
                            position: unset;
                            padding: 0;
                        }
                        footer {
                            position: unset;
                        }

                        .button {
                            color: #9C6CFF;
                        }
                        .button:not(.button-outline) {
                            background: #2C2457;
                        }
                        .button:not(.button-link):hover {
                            background: #2C2457;
                            color: #FFF;
                        }
                        * {pointer-events: none!important;}
                    }
                }
            `}>
                <header className="header">
                    <div className="logo">
                        <img className="white-logo" src="/alchemistLight/img/Alchemist_Identity_Final-17.png" alt=""/>
                    </div>
                </header>
                <div className="wrapper-content">
                    <div className="content">

                        <div className="form-preview">
                            <header>
                                <div className="form-logo">
                                    <img src={logo || "/alchemistLight/img/Alchemist_Identity_Final-01.png"}/>
                                </div>
                                <h1 className="main-title">{name}</h1>
                            </header>

                            <div className="content-preview">
                                <div className="table">
                                    <div className="table-cell">
                                        <pre style={{whiteSpace: "pre-wrap"}}>{description}</pre>
                                    </div>
                                </div>
                            </div>

                            <footer><a className="button" href="#" title="">Start</a></footer>
                        </div>

                    </div>
                </div>
                <footer></footer>
            </div>
        </TabletPreview>
    )
}

export default LivePreview