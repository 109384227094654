import { elements as ELEMENTS } from '../constants/elements'

export const getAnswersByCode = (answers = {}, elements = []) => {
    const answers_by_code = {}
    Object.entries(answers).forEach(([code, value]) => {
        const element = elements.find(elm => elm.code === code)
        switch(element.type) {
            case ELEMENTS.CONTRACT_COMMUNICATION_OPTIONS:
                answers_by_code[element.code+'_name'] = value.name;
                answers_by_code[element.code+'_phone'] = value.phone;
                answers_by_code[element.code+'_address'] = value.address;
                answers_by_code[element.code+'_email'] = value.email;
                answers_by_code[element.code+'_monthly_income'] = value.monthly_income;
                answers_by_code[element.code+'_children_no'] = value.children_no;
                break;
            case ELEMENTS.ID_CARD_EXTRACTOR:
                answers_by_code[element.code+'_first_name'] = value.first_name;
                answers_by_code[element.code+'_last_name'] = value.last_name;
                answers_by_code[element.code+'_email'] = value.email;
                answers_by_code[element.code+'_phone'] = value.phone;
                answers_by_code[element.code+'_cnp'] = value.cnp;
                answers_by_code[element.code+'_date_of_birth'] = value.date_of_birth;
                answers_by_code[element.code+'_country'] = value.country;
                answers_by_code[element.code+'_locality'] = value.locality;
                answers_by_code[element.code+'_street'] = value.street;
                answers_by_code[element.code+'_nr'] = value.nr;
                answers_by_code[element.code+'_block'] = value.block_value;
                answers_by_code[element.code+'_entry'] = value.entry;
                answers_by_code[element.code+'_floor'] = value.floor;
                answers_by_code[element.code+'_apartment'] = value.apartment;
                answers_by_code[element.code+'_series_ci'] = value.series_ci;
                answers_by_code[element.code+'_nr_ci'] = value.nr_ci;
                answers_by_code[element.code+'_emitted_by'] = value.emitted_by;
                answers_by_code[element.code+'_available_from'] = value.available_from;
                answers_by_code[element.code+'_available_to'] = value.available_to;
                break;
            case ELEMENTS.PRIVACY_AGREEMENT:
                answers_by_code[element.code+'_first_name'] = value.first_name;
                answers_by_code[element.code+'_last_name'] = value.last_name;
                answers_by_code[element.code+'_signature'] = value.signature;
                break;
            case ELEMENTS.AGENT_SIGNATURE:
                answers_by_code[element.code+'_name'] = value.name;
                answers_by_code[element.code+'_signature'] = value.signature;
                break;
            default:
                answers_by_code[element.code] = value
        }
    })
    return answers_by_code
}