import React from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes, StyleSheetManager } from 'styled-components'

const IpopUp = ({
    children,
    title="",
    visible=false,
    footer=null,
    disableCancel=false,
    onCancel=f=>f,
    okText="Confirm",
    onConfirm = f=>f,
    styles = {
        body: {},
        wrapper: {}
    }
}) => {

    return(
        <>
            {ReactDOM.createPortal((
                <>
                    {visible &&

                        <StyleSheetManager target={document.querySelector("#modal")}>
                            <Container>
                                <Overlay onClick={onCancel} />
                                <Wrapper style={{...styles.wrapper}}>
                                    <Header>
                                        <HeaderTitle>{title}</HeaderTitle>
                                        {disableCancel ? null : <CloseCros className="icon-x" onClick={onCancel} />}
                                    </Header>
                                    <Body style={{...styles.body}}>
                                        {children}
                                    </Body>
                                    {footer !== false && 
                                        <Footer>
                                            {footer === null ? (okText ? <ConfirmButton  onClick={onConfirm}>{okText}</ConfirmButton> : null) : footer}
                                        </Footer>
                                    }
                                </Wrapper>
                            </Container>
                        </StyleSheetManager>

                    }
                </>
            ), document.querySelector("#modal"))}
        </>
    )
}


export const FooterButton = styled.button`
    height: 100%;
    float: right;
    background: white;
    color: #727cf5;
    border: none;
    :hover{
        background: #727cf5;
        color: white;
    }
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 5rem;
    text-align: center;
    text-transform: uppercase;
    padding: 0 2rem;
    border-radius: 0;
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    outline: none;
`


const Container = styled.div`
    display: grid;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
`

const Overlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background: rgba(255,255,255,0.8);
    width: 100%;
    height: 100%;
    animation: ${keyframes`
        0%   { background: rgba(255,255,255,0); }
        100% { background: rgba(255,255,255,0.8) }
    `} 0.5s 1;
`

const Wrapper = styled.div`
    position: absolute;
    justify-self: center;
    align-self: center;
    z-index: 2;
    width: 48rem;
    max-width: 100%;
    box-shadow: 0 0 1rem rgba(114,124,245,.4);
    background: white;

    animation: ${keyframes`
        0%   { transform: scale(0) }
        100% { transform: scale(initial) }
    `} .5s 1;
`

const Header = styled.div`
    height: 5rem;
    width: 100%;
    border-bottom: 1px dashed #c9ccf0;
`

const HeaderTitle = styled.h3`
    float: left;
    line-height: 5rem;
    margin-left: 3rem;
`

const CloseCros = styled.i`
    top: .5rem;
    right: .5rem;

    width: 4rem;
    height: 4rem;
    position: absolute;
    display: block;
    border-radius: 0.3rem;
    font-size: 1.4rem;
    line-height: 4rem;
    text-align: center;
    color: rgba(0,0,0,0.3);
    cursor: pointer;
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    :hover{
        color: #fff;
        background: #727cf5;
    }
`

const Body = styled.div`
    width: 100%;
    padding: 2rem 3rem;
`

const Footer = styled.div`
    height: 5rem;
    width: 100%;
    border-top: 1px dashed #c9ccf0;
`

const ConfirmButton = styled.button`
    height: 100%;
    float: right;
    background: white;
    color: #727cf5;
    border: none;
    :hover{
        background: #727cf5;
        color: white;
    }
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 5rem;
    text-align: center;
    text-transform: uppercase;
    padding: 0 2rem;
    border-radius: 0;
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    outline: none;
`

IpopUp.FooterButton = FooterButton

export default IpopUp