import React from 'react'
import {css} from 'styled-components'
import {useLocation} from 'react-router-dom'

import Loading from './Loading'
import API from '../api'
import LpopUp from './LpopUp'
import {notification} from './Lnotification'
import {useAppContext} from '../context'

const storagePopUpStyles = css`
    .ads-steps { width:25rem; position:relative; margin:0 auto 3rem; display:grid; grid-template-columns: repeat(2,auto); }
    .ads-steps:before,
    .ads-steps:after { width:17rem; height:1px; position:absolute; top:50%; left:4rem; transform: translate(0,-50%); background:#e6e6e6; content:''; border:none; }
    .ads-steps:after { width:0; background:#2C2457; -webkit-transition: width .3s ease-in-out; transition: width .3s ease-in-out; }
    .view-next-step .ads-steps:after { width:17rem; }

    .ads-steps li { width:4rem; height:4rem; position:relative; display:inline-block; text-align:center; justify-self: start; }
    .ads-steps li:last-child { justify-self: end; }
    .ads-steps li:first-child:after { width:100%; height:100%; position:absolute; top:50%; left:50%; transform: translate(-50%,-50%) scale(0); background:#2C2457; font-size:1.6rem; line-height:4rem; text-align:center; font-family: icons; content:'\\ee69'; color:#fff; border-radius:50%; -webkit-transition: transform .3s ease-in-out; transition: transform .3s ease-in-out; }
    .view-next-step .ads-steps li:first-child:after { transform: translate(-50%,-50%) scale(1); }

    .ads-steps li span { width:100%; height:100%; display:block; border-radius:50%; border:1px solid #ccc; font-size:1.4rem; line-height:3.8rem; text-align:center; color:#333; -webkit-transition: color .3s ease-in-out .2s, background .3s ease-in-out .2s, border-color .3s ease-in-out .2s; transition: color .3s ease-in-out .2s, background .3s ease-in-out .2s, border-color .3s ease-in-out .2s; }
    .view-next-step .ads-steps li:last-child span { border-color:#2C2457; color:#fff; background:#2C2457; }

    .ads-steps-content { width:100%; margin:0 auto; display:grid; grid-template-columns: repeat(2,20rem); grid-gap:1.5rem; justify-content:center; /*align-items:center;*/ }
    .ads-steps-content li { text-align:center; }
    .ads-steps-content li:last-child { opacity:0; visibility:hidden; -webkit-transition: opacity .3s ease-in-out .2s; transition: opacity .3s ease-in-out .2s; }
    .view-next-step .ads-steps-content li:last-child { opacity:1; visibility:visible; }

    .ads-steps-content h6 { font-size:1.4rem; line-height:2.2rem; font-weight:bold; margin-bottom:1.5rem; text-transform:none; }
    .ads-steps-content p { margin:0 auto; }
    .ads-steps-content .button { width:auto!important; height:3.4rem; line-height:3.2rem; margin:0 auto!important; padding: 0 2rem!important; }

    h3 { text-align:center; margin:0 auto 5rem; }
`

const ConfigureStorage = () => {

    const [state, setState] = React.useState({
        loading: false,
        loadingError: null,
        loaded: false,
        config: null,
        modal: false,
        permission: null,
        folder: null,
        savingConfiguration: false
    },'storage-config')

    const {
        auth:[auth],
        storageNotifications:[
            storageNotifications,
            setStorageNotifications
        ]
    } = useAppContext()

    const location = useLocation()

    React.useEffect(() => {
        if(
            auth && 
            auth.decodedToken.external && 
            auth.decodedToken.external.system === 'microsoft' &&
            !storageNotifications.configureOedrivePrompedOnce
        ) {
            getConfig()
        }
    }, [auth])

    React.useEffect(() => {
        if (location.search || location.hash) {
            const script = document.createElement("script");
            script.async = true;
            script.src = 'https://js.live.net/v7.0/OneDrive.js'
            const element = document.head.appendChild(script);
            return () => element.remove()
        }
    }, [])

    const getConfig = async () => {
        try {
            setState(state => ({...state, loading: true}))
            const {data: {storage, storageFailed}} = await API.get('/users/personal/profile')

            if(!storage.configuredOnce) {

                setState(state => ({...state, loading: false, loaded: true, modal: true}))
                setStorageNotifications(s => ({...s, configureOedrivePrompedOnce: true}))
                return
            }

            setState(state => ({...state, loading: false, loaded: true}))

        } catch(err) {
            setState(state => ({...state, loading: false}))
            notification.error({message: "Failed loading storage config"})
        }
    }

    const onPermission = permission => {
        setState(state => ({...state, permission}))
    }
    
    const onFolder = folder => setState(state => ({...state, folder}))

    const saveConfiguration = async () => {
        try {
            setState(state => ({...state, savingConfiguration: true}))
            const {permission: {code}, folder} = state
            await API.post('users/storage/offlineAuthorization', {code, folder, provider: 'onedrive'})
            setState(state => ({
                ...state,
                modal: false,
                permission: null,
                folder: null,
                savingConfiguration: false
            }))

        } catch (err) {
            setState(state => ({...state, savingConfiguration: false}))
            notification.error({message: "Failed saving configuration"})
        }
    }

    const getPermission = () => {

        const redirect_uri = `${process.env.REACT_APP_DASHBOARD_URL}/storage/onedrive/redirect_uri`
        const scope = 'Files.ReadWrite offline_access openid'
        const client_id = process.env.REACT_APP_STORAGE_ONEDRIVE_CLIENT_ID
        const response_type = 'code'
        const link = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${client_id}&scope=${scope}&response_type=${response_type}&redirect_uri=${redirect_uri}`
        
            
        const handleCodemessage = event => {
    
            const {code, provider} = event.data
            if(!code || !provider) return
            onPermission({code, provider})
            window.removeEventListener('message', handleCodemessage)
        }
    
        window.open(link, '_blank', 'height=600,width=450')
        window.addEventListener('message', handleCodemessage)
    }

    return (
        <>
            {state.modal &&
                <LpopUp
                    disableHeader
                    disableFooter={!state.permission || !state.folder || state.savingConfiguration}
                    visible={state.modal}
                    onConfirm={saveConfiguration}
                    onCancel={() => setState(state => ({...state, modal: false, permission: null, folder: null}))}
                    rawCss={css`
                        .overlay-content {
                            width: 56rem;
                        }
                    `}
                >
                    {state.savingConfiguration && <Loading />}
                    {!state.loading && state.loaded && !state.savingConfiguration &&
                        <>
                            <div css={`
                                ${storagePopUpStyles}
                                ${state.permission ? css`
                                    .ads-steps:after { width:17rem; }
                                    .ads-steps li:first-child:after { transform: translate(-50%,-50%) scale(1); }
                                    .ads-steps li:last-child span { border-color:#2C2457; color:#fff; background:#2C2457; }
                                    .ads-steps-content li:last-child { opacity:1; visibility:visible; }
                                ` : ''}
                            `}>
                                <h3>Documents storage on Microsoft OneDrive</h3>
                                <ul className="ads-steps">
                                    <li><span>1</span></li>
                                    <li><span>2</span></li>
                                </ul>
                                <ul className="ads-steps-content">
                                    <li>
                                        <h6>Give permission</h6>
                                        <p style={{marginBottom: '1.5rem'}}>Your Alchemist generated documents will be stored on your Microsoft Account. You can always change these settings in your profile.</p>
                                        {!state.permission &&
                                            <a onClick={getPermission} type="button" className="button button-outline">Ok</a>
                                        }
                                    </li>
                                    <li>
                                        <h6>Pick a folder</h6>
                                        {state.permission && !state.folder &&
                                            <OndeDriveFolderPicker onFolder={onFolder}/>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </>
                    }
                </LpopUp>
            }
        </>
    )
}

export default ConfigureStorage

const OndeDriveFolderPicker = ({onFolder}) => {

    React.useEffect(() => {
        if (!window.OneDrive) {
            const script = document.createElement("script");
            script.async = true;
            script.src = 'https://js.live.net/v7.0/OneDrive.js'
            const element = document.head.appendChild(script);
            return () => {
                element.remove()
            }
        }
    }, [])

    const load = () => {
        const odOptions = {
            clientId: process.env.REACT_APP_STORAGE_ONEDRIVE_CLIENT_ID,
            action: "query",
            multiSelect: false,
            openInNewWindow: true,
            advanced: {
                filter: "folder"
            },
            success: function (files) {
                const {id} = files.value[0]
                onFolder(id)
            },
            cancel: function () {
            },
            error: function (e) {
            }
        }

        window.OneDrive.save(odOptions);
    }

    return (
        <a onClick={load} type="button" className="button button-outline">Select folder</a>
    )
}