import React from 'react'

import Portrait from '../../components/Portrait'
import Navbar from '../../components/Navbar/Navbar'
import Ipagination from '../../components/Ipagination'
import Loading from "../../components/Loading"
import API from '../../api'
import Response from './Responses.response'
import {DatePicker} from "antd";
import root from 'react-shadow'
import moment from "moment";
import {saveAs} from "file-saver"
import styled from 'styled-components'
import {notification} from '../../components/Lnotification'
import {useHistory} from 'react-router-dom'

const Responses = (props) => {

    const history = useHistory()
    const page = new URLSearchParams(history.location.search).get('page')

    const [state, setState] = React.useState({
        loading: false,
        data: [],
        page: page || 1,
        filters: false,
        deleting: {},
        pages: 0,
        recipients: [],
        forms: [],
        recipientsList: [],
        formsList: [],
        recipientsDropdown: false,
        formsDropdown: false,
        recipientsFilter: "",
        formsFilter: "",
        searchQuery: false
    }, 'responses');

    React.useEffect(() => {
        getForms();
        getResponses(state);
        getRecipients();
    }, [state.page]);

    const getForms = async () => {
        try {
            setState(state => ({...state, loading: true}));
            const {data} = await API.get('forms/list/all');
            setState(state => ({...state, loading: false, formsList: data}));
        } catch (err) {
            setState(state => ({...state, loading: false}));
            notification.warning({message: "There was an error while retrieving the list of forms"})
        }
    };


    const getRecipients = async () => {
        try {
            setState(state => ({...state, loading: true}));
            const {data} = await API.get('sessions/recipients');
            setState(state => ({...state, loading: false, recipientsList: data}));
        } catch (err) {
            setState(state => ({...state, loading: false}));
            notification.warning({message: "There was an error while retrieving the list of recipients"})
        }
    };

    const getResponses = async (params = {}) => {
        try {
            setState(state => ({...state, loading: true}));
            const {data: {data, pages}} = await API.get('sessions', {
                params: {
                    page: params.page !== undefined ? params.page : state.page,
                    search: params.search !== undefined ? params.search : state.search,
                    date_from: params.date_from !== undefined ? params.date_from : state.date_from,
                    date_to: params.date_to !== undefined ? params.date_to : state.date_to,
                    recipients: params.recipients !== undefined ? params.recipients : state.recipients,
                    forms: params.forms !== undefined ? params.forms : state.forms.map((form) => form._id),
                    sort: params.sort !== undefined ? params.sort : state.sort,
                    order: params.order !== undefined ? params.order : state.order
                }
            });
            let searchQuery = false;
            if ((state.recipients.length > 0 && params.recipients === undefined) || (state.forms.length > 0 && params.forms === undefined) || (state.date_from && params.date_from === undefined) || (state.date_to && params.date_to === undefined) || params.search) {
                searchQuery = true;
            }
            setState(state => ({...state, loading: false, data, pages, searchQuery: searchQuery}));
        } catch (err) {
            setState(state => ({...state, loading: false}));
            notification.warning({message: "There was an error while retrieving the data"})
        }
    };

    const deleteResponse = async (e) => {
        e.preventDefault();
        setState(state => ({...state, loading: true}));
        try {
            await API.delete(`/sessions/${state.deleting.response}`);
            setState(state => ({
                ...state,
                data: state.data.filter(response => response._id !== state.deleting.response),
                deleting: {}
            }))
        } catch (err) {
            notification.warning({message: "There was an error while deleting the response"})
        }
        setState(state => ({...state, loading: false, deleting: {}}));
    };

    const closeDeleteResponseModal = async (e) => {
        e.preventDefault();
        setState(state => ({...state, deleting: {}}));
    };

    const exportSessions = (export_type) => async () => {
        const extension = {
            'pdf': 'pdf',
            'excel': 'xlsx'
        };
        setState(state => ({...state, loading: true}));
        try {
            const {data} = await API.get(`/sessions/list/export/${export_type}`, {
                params: {
                    search: state.search,
                    date_from: state.date_from,
                    date_to: state.date_to,
                    recipients: state.recipients,
                    forms: state.forms.map((form) => form._id),
                    sort: state.sort,
                    order: state.order
                },
                responseType: 'blob'
            });
            saveAs(data, `responses_list.${extension[export_type]}`);
        } catch (err) {
            notification.warning({message: "There was an error while retrieving the data"})
        }
        setState(state => ({...state, loading: false}));
    };

    const onPageChange = async page => {
        history.push({
            pathname: history.location.pathname,
            search: `?page=${page}`
        })
        setState(state => ({...state, page}));
        await getResponses({page});
    };

    const changeSearch = async ({target: {value: search}}) => {
        setState(state => ({
            ...state,
            search
        }));
        await getResponses({search});
    };
    const changeDateFrom = async (date_from) => {
        if (date_from !== null) {
            date_from = date_from.format('DD/MM/YYYY');

            if (state.date_to) {
                if (date_from > state.date_to) {
                    notification.info({message: 'Date from should be older or the same as Date to.'});
                    return;
                }
            }
        }
        setState(state => ({
            ...state,
            date_from
        }));
    };

    const changeDateTo = async (date_to) => {
        if (date_to !== null) {
            date_to = date_to.format('DD/MM/YYYY');

            if (state.date_from) {
                if (date_to < state.date_from) {
                    notification.info({message: 'Date from should be older or the same as Date to.'});
                    return;
                }
            }
        }
        setState(state => ({
            ...state,
            date_to
        }));
    };

    const setForms = async (form) => {
        if (!(state.forms.some((formInList, i) => {
            if (formInList._id === form._id) {
                state.forms.splice(i, 1);
                return true
            }
        }))) {
            state.forms.push(form);
        }
        setState(state => ({
            ...state,
            formsDropdown: false,
            forms: state.forms
        }));
    };

    const setRecipients = async (recipient) => {
        const i = state.recipients.indexOf(recipient);
        if (i >= 0) {
            state.recipients.splice(i, 1);
        } else {
            state.recipients.push(recipient);
        }
        setState(state => ({
            ...state,
            recipients: state.recipients
        }));
    };

    const setColumnSorting = (sort, order) => async () => {
        setState(state => ({
            ...state,
            sort,
            order
        }));

        await getResponses({order, sort});
    };

    const renderSortIcon = (column) => {
        if (column !== state.sort)
            return <span className="cntrl-box"><i
                className="cntrl-down icon-arrow-down" onClick={setColumnSorting(column, 'desc')}/><i
                className="cntrl-up icon-arrow-up" onClick={setColumnSorting(column, 'asc')}/></span>;

        if (state.order === 'desc')
            return <span className="cntrl-box"><i
                className="cntrl-down icon-arrow-down selected" onClick={setColumnSorting(null, null)}/><i
                className="cntrl-up icon-arrow-up" onClick={setColumnSorting(column, 'asc')}/></span>;

        return <span className="cntrl-box"><i
            className="cntrl-down icon-arrow-down" onClick={setColumnSorting(column, 'desc')}/><i
            className="cntrl-up icon-arrow-up selected" onClick={setColumnSorting(null, null)}/></span>;
    };

    const applyFilters = async (e) => {
        e.preventDefault();
        setState(state => ({...state, filters: false}));
        await getResponses();
    };

    const clearFilters = async (e) => {
        e.preventDefault();
        setState(state => ({
            ...state,
            search: "",
            recipients: [],
            forms: [],
            date_from: null,
            date_to: null,
            sort: null,
            order: null
        }));
        await getResponses({
            search: "",
            recipients: [],
            forms: [],
            date_from: null,
            date_to: null,
            sort: null,
            order: null
        });
    };

    return (
        <>
            <div
                className={`wrapper sessions-page ${state.filters && "view-filters"} ${(state.deleting.status ? "view-overlay-delete-form" : "")} `}>
                <Navbar/>
                <div className="content">

                    <div className="content-box">

                        <div className="content-box-header">
                            <h3>Responses</h3>
                            <div className="content-box-options">
                                <div className="content-box-header-search">
                                    <form className="content-search">
                                        <fieldset>
                                            <input type="text" name="search" placeholder="Search" value={state.search}
                                                   onChange={changeSearch}/>
                                            <button className="tbl-btn"/>
                                        </fieldset>
                                    </form>
                                </div>
                                <span className="button button-outline with-icon-right trigger-filters"
                                      onClick={() => setState(state => ({...state, filters: !state.filters}))}><i
                                    className="icon-ia-arrow"/>Filters</span>
                            </div>
                        </div>

                        <div className="content-box-subheader filters" style={{overflow: "visible"}}>
                            <h4>filters</h4>
                            {(state.forms.length > 0 || state.recipients.length > 0 || state.date_from || state.date_to || state.search) &&
                            <root.div>
                                <FiltersList>
                                    <ul className="applied-filters" style={{padding: "0 8rem 1rem 0"}}>
                                        <li style={{position: "inherit"}}>
                                            <span>Filters:</span>
                                        </li>
                                        {state.forms.map((form, i) =>
                                            (
                                                <li key={form._id}>
                                                    <i className="delete-filter" onClick={() => {
                                                        state.forms.splice(i, 1);
                                                        setState(state => ({
                                                            ...state,
                                                            forms: state.forms
                                                        }));
                                                    }}/>
                                                    <span>{form.name}</span>
                                                </li>
                                            )
                                        )
                                        }
                                        {state.recipients.map((recipient, i) =>
                                            (
                                                <li>
                                                    <i className="delete-filter" onClick={() => {
                                                        state.recipients.splice(i, 1);
                                                        setState(state => ({
                                                            ...state,
                                                            recipients: state.recipients
                                                        }));
                                                    }}/>
                                                    <span>{recipient}</span>
                                                </li>
                                            )
                                        )
                                        }
                                        {state.date_from &&
                                        <li>
                                            <i className="delete-filter" onClick={() => {
                                                setState(state => ({
                                                    ...state,
                                                    date_from: null
                                                }));
                                            }}/>
                                            <span>{state.date_from}</span>
                                        </li>
                                        }
                                        {state.date_to &&
                                        <li>
                                            <i className="delete-filter" onClick={() => {
                                                setState(state => ({
                                                    ...state,
                                                    date_to: null
                                                }));
                                            }}/>
                                            <span>{state.date_to}</span>
                                        </li>
                                        }
                                        {state.search &&
                                        <li>
                                            <i className="delete-filter" onClick={() => {
                                                setState(state => ({
                                                    ...state,
                                                    search: ""
                                                }));
                                            }}/>
                                            <span>{state.search}</span>
                                        </li>
                                        }
                                    </ul>
                                </FiltersList>
                            </root.div>
                            }
                            <DropdownContainer className="form" style={{gridTemplateColumns: "1.5fr 1.5fr 1fr 1fr"}}>
                                <div  onBlur={() => {
                                    setState(state => ({
                                        ...state,
                                        formsDropdown: false
                                    }))
                                }} tabIndex="0" className={`form-row ${(state.formsDropdown ? 'view-dropdown' : '')}`}
                                     style={{overflow: "inherit"}}>
                                    <label htmlFor="">Form(s)</label>
                                    <input type="text"
                                           placeholder="Filter forms" value={state.formsFilter} onChange={e => {
                                        const value = e.target.value;
                                        setState(state => ({
                                            ...state,
                                            formsFilter: value
                                        }));
                                    }} onFocus={() => {
                                        setState(state => ({
                                            ...state,
                                            formsDropdown: true
                                        }));
                                    }}/>
                                    <div className="dropdown">
                                        <div className="scrollbar">
                                            <ul>
                                                {state.formsList.map(form => {
                                                    if (form.name.toLowerCase().includes(state.formsFilter.toLowerCase())) {
                                                        return <li
                                                            className={`${(state.forms.some(formInList => formInList._id === form._id) ? "selected" : "")}`}
                                                            key={form._id} onMouseDown={async () => {
                                                            await setForms(form);
                                                            setState(state => ({
                                                                ...state,
                                                                formsDropdown: false
                                                            }));
                                                        }}>{form.name}</li>
                                                    } else {
                                                        return null
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div  onBlur={() => {
                                        setState(state => ({
                                            ...state,
                                            recipientsDropdown: false
                                        }))
                                }} tabIndex="-1" className={`form-row ${(state.recipientsDropdown ? 'view-dropdown' : '')}`}
                                     style={{overflow: "inherit"}}>
                                    <label htmlFor="">Recipient(s)</label>
                                    <input type="text"
                                           placeholder="Filter recipients" value={state.recipientsFilter} onChange={e => {
                                        const value = e.target.value;
                                        setState(state => ({
                                            ...state,
                                            recipientsFilter: value
                                        }));
                                    }} onFocus={() => {
                                        setState(state => ({
                                            ...state,
                                            recipientsDropdown: true
                                        }));
                                    }}/>
                                    <div className="dropdown">
                                        <div className="scrollbar">
                                            <ul>
                                                {state.recipientsList.map(recipient => {
                                                    if (recipient.toLowerCase().includes(state.recipientsFilter.toLowerCase())) {
                                                        return <li
                                                            className={`${(state.recipients.includes(recipient)) ? "selected" : ""}`}
                                                            key={recipient} onMouseDown={async () => {
                                                            await setRecipients(recipient);
                                                            setState(state => ({
                                                                ...state,
                                                                recipientsDropdown: false
                                                            }));
                                                        }}>{recipient}</li>
                                                    }
                                                    else {
                                                        return null
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row with-calendar">
                                    <label htmlFor="">Date from</label>
                                    <StyledDatePicker format={'DD/MM/YYYY'} style={{width: '100%'}}
                                                      value={(state.date_from) ? moment(state.date_from, "DD/MM/YYYY") : null}
                                                      onChange={changeDateFrom}/>
                                </div>
                                <div className="form-row with-calendar">
                                    <label htmlFor="">Date to</label>
                                    <StyledDatePicker format={'DD/MM/YYYY'} style={{width: '100%'}}
                                                      value={(state.date_to) ? moment(state.date_to, "DD/MM/YYYY") : null}
                                                      onChange={changeDateTo}/>
                                </div>
                            </DropdownContainer>
                            <div className="filters-actions">
                                <a className="button" href="#" onClick={applyFilters}>Apply filters</a>
                                <a className="button button-link" href="#" onClick={clearFilters}>Clear filters</a>
                            </div>
                        </div>

                        <div className="content-box-body content-box-body-fixed-elements">
                            <div className="box-body box-body-fixed">

                                <div className="table-fixed-header">
                                    <ul className="table-ul responses-list">
                                        <li className="table-ul-header">
                                            <ul>
                                                <li>
                                                    <strong>Form</strong>
                                                </li>
                                                <li className="with-cntrl-box">
                                                    <strong>Recipients</strong>
                                                    {renderSortIcon('_client')}
                                                </li>
                                                <li className="with-cntrl-box">
                                                    <strong>Date and time</strong>
                                                    {renderSortIcon('updated_at')}
                                                </li>
                                                <li><strong/></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="scrollbar">
                                    {state.loading && <Loading color="#39193B"/>}
                                    {state.data.length === 0 &&
                                    <div style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        fontSize: "1.1rem"
                                    }}>{(!state.searchQuery ? "Your response list is empty" : "No results found")}</div>
                                    }
                                    {state.data.length > 0 &&
                                    <ul className="table-ul responses-list">
                                        {state.data.map((response, index) => <Response setState={setState}
                                                                                       history={props.history}
                                                                                       response={response}
                                                                                       key={response._id}
                                                                                       index={index}/>)}
                                    </ul>
                                    }
                                </div>

                                <div className="box-action table-action-fixed with-pagination">
                                    <span className="erts"/>
                                    <Ipagination
                                        page={state.page}
                                        pages={state.pages}
                                        onChange={onPageChange}
                                    />
                                    <div className="export-options">
                                        <span>Export:</span>
                                        <button className="button button-outline" onClick={exportSessions('pdf')}>PDF
                                        </button>
                                        <button className="button button-outline"
                                                onClick={exportSessions('excel')}>XLS
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <div className="overlay overlay-delete-form">
                    <div className="overlay-content">
                        <div className="overlay-body">
                            <form className="form">
                                <div className="overlay-icon">
                                    <img src="alchemistLight/img/delete-form.svg" alt=""/>
                                </div>
                                <h3>Are you sure you want to delete this response?</h3>
                                <div className="overlay-actions">
                                    <button className="button with-icon-right close-overlay" onClick={deleteResponse}><i
                                        className="icon-ia-delete"/>Delete
                                    </button>
                                    <button className="button button-link close-overlay"
                                            onClick={closeDeleteResponseModal}>Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                        <i className="icon-x close-button-overlay"
                           onClick={closeDeleteResponseModal}/>
                    </div>
                </div>

            </div>
            <Portrait/>
        </>
    )
};

const StyledDatePicker = styled(DatePicker)
    `
    .ant-calendar-picker-input {
        height: 3.6rem;
        font-size: 1.2rem;
    }
    `;

const FiltersList = styled.div
    `
    
.applied-filters { width:100%; position:relative; margin:0; padding:0 8rem 1rem 5rem; margin:-1.5rem auto 3rem; border-bottom:1px dashed rgba(0,0,0,.1); text-align:left; font-size:0; line-height:0; user-select:none; }
.applied-filters li { width:auto; display:inline-block; height:2.4rem; margin:0 .5rem .5rem 0; padding-right:3rem; position:relative; color:#666; background:#F1F1F1; transition: color .3s ease-in-out, background .3s ease-in-out; border-radius:.3rem; }
.applied-filters li:hover { background:var(--primary-color); color:#fff; }
.applied-filters li:first-child { position:absolute; top:0; left:0; background:transparent!important; }
.applied-filters li:hover:first-child { color:#666; }

.applied-filters li.applied-filters-actions { position:absolute; top:-.3rem; right:0; background:transparent!important; padding:0; text-align:right; }
.applied-filters li.applied-filters-actions .tbl-btn { opacity:.5!important; float:right; margin-left:.5rem; }
.applied-filters li.applied-filters-actions .tbl-btn:hover { opacity:1!important; }
/*.applied-filters li.applied-filters-actions .button { height:2.6rem; font-size:1rem; line-height:2.4rem; padding-left:1.1rem; padding-right:1.5rem; }
.applied-filters li.applied-filters-actions .button i { line-height:2.4rem; }*/

.applied-filters span,
.applied-filters em { width:100%; height:100%; display:block; font-size:1.1rem; line-height:2.4rem; font-style:normal; padding:0 0 0 1rem; }
.applied-filters em { width:auto; text-transform:uppercase; color:#666; padding:0; }
.applied-filters li:first-of-type span,
.applied-filters li:first-of-type em { padding:0; }

.applied-filters i.delete-filter { width:2.4rem; height:2.4rem; display:block; position:absolute; top:0; right:0; color:#fff; opacity:0; border-left:1px solid rgba(255,255,255,.1); }
.applied-filters i.delete-filter:after { width:100%; height:100%; display:block; position:absolute; top:0; right:0; font-size:.8rem; line-height:2.4rem; content:'\\ee6a'; font-family:'icons'; font-style:normal; text-align:center; transition: opacity .3s ease-in-out; cursor:pointer; }
.applied-filters li:hover i.delete-filter { opacity:1; border-right-color:rgba(255,255,255,.2); }
    `;

const DropdownContainer = styled.div
    `
.dropdown { width:100%; height:0; position:absolute; top:100%; left:0; z-index:100; background: #fff;  overflow:hidden; margin-top:1px; -webkit-transition: height .3s ease-in-out, border .3s ease-in-out, box-shadow .3s ease-in-out; transition: height .3s ease-in-out, border .3s ease-in-out, box-shadow .3s ease-in-out; border:0 solid transparent; box-shadow:none; border-radius: .3rem; }
.view-dropdown .dropdown { height:13rem; border:1px solid #ccc; box-shadow: var(--shadow); }

.dropdown .scrollbar { /*max-height:16rem;*/ padding:0!important;  }
.dropdown ul { width:100%; margin:auto; padding:0; }
.dropdown ul li { width:100%; position:relative; font-size:1.2rem; font-weight:400; line-height:1.8rem; color:#333; border-bottom:1px solid #ccc; padding:.7rem 2rem .7rem 1.2rem; cursor:pointer; overflow:hidden; white-space: nowrap; text-overflow:ellipsis; -webkit-transition: color .3s ease-in-out, background .3s ease-in-out; transition: color .3s ease-in-out, background .3s ease-in-out; }
.dropdown ul li:hover { color:#000; background:#f8f8f8; }
.dropdown ul li.selected { color:#5D2560; }
.dropdown ul li:last-child { border:none; }

.dropdown ul li:after { width:1.5rem; height:1.5rem; position:absolute; top:50%; right:.5rem; -webkit-transform: translate(0,-50%) scale(0); transform: translate(0,-50%) scale(0); content:'\\ee68'; font-size:.8rem; line-height:1.5rem; text-align:center; font-family:icons; background:#5D2560; color:#fff; border-radius:50%; -webkit-transition: transform .3s ease-in-out; transition: transform .3s ease-in-out; }
.dropdown ul li.selected:after { -webkit-transform: translate(0,-50%) scale(1); transform: translate(0,-50%) scale(1); }

.view-dropdown input { box-shadow: var(--shadow)!important; outline: none!important; border-color: var(--primary-color)!important; background: #fff!important; }
    `;

export default Responses