const VALIDATION = {
    REQUIRED: 'required',
    NOT_REQUIRED: 'not required',
    HIDDEN: 'hidden',
    VISIBLE: 'visible',
    MAX_FILE_SIZE: 'max file size',
    MIN_NUMBER_OF_FILES: 'min number of files',
    MAX_NUMBER_OF_FILES: 'max number of files',
    SKIP_FIRST_TWO_STEPS: 'skip first two steps',
    PHONE: 'phone',
    PHONE_TWO_STEP_VALIDATION: 'phone two step validation',
    EMAIL: 'email',
    EMAIL_TWO_STEP_VALIDATION: 'email two step validation',
    NUMBER: 'number',
    INTEGER: 'integer',
    POSITIVE: 'positive',
    MIN_LENGTH: 'min length',
    MAX_LENGTH: 'max length',
    MIN_VALUE: 'min value',
    MAX_VALUE: 'max value',
    NO_FUTURE_DATE: 'no future date',
    NO_PAST_DATE: 'no past date',
    NO_BEFORE_SESSION_DATE: 'no before session date',
    NO_AFTER_SESSION_DATE: 'no after session date'
}

export {VALIDATION}