import React from 'react'

import LivePreview from './LivePreview.standardAddress'
import Editor from './Editor.standardAddress'
import CodeCopy from './CodeCopy.standardAddress'
import View from './View.standardAddress'
import Input from './Input.standardAddress'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <Editor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,
    default: () => ({
        type: "standard address",
        text: "Standard Postal Address Group Label",
        pinned: {
            status: false
        },
        validations: []
    }),

    validate: () => false,
    group: "ADVANCED",
    icon: "icon-ia-mail",
    name: "Standard Postal Address",
    view: args => <View {...args}/>
}