import React from 'react'
import LoadAfter from '../../components/LoadAfter'
import root from 'react-shadow'

export default () => {

    return (
        <root.div style={{height: "100%"}}>
            <LoadAfter links={['/alchemistLight/css/global.css']}>
                <div className="wrapper page-404">
                    <div className="content">
                        <div className="content-404">
                            <div className="table">
                                <div className="table-cell">
                                    <h6>404</h6>
                                    <h2>This page was not found....</h2>
                                </div>
                            </div>
                            <div className="content-404-icon"></div>
                        </div>
                    </div>
                </div>
            </LoadAfter>
        </root.div>
    )
}

