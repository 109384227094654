import React from 'react'

import Portrait from '../../components/Portrait'
import Navbar from '../../components/Navbar/Navbar'
import Loading from "../../components/Loading"
import moment from "moment";
import SupportedElements from '../../formComponents/supportedElements'
import {saveAs} from "file-saver";
import API from "../../api";
import {elements as ELEMENTS} from "../../constants/elements";
import {getAnswersByCode} from "../../utils/getAnswersByCode";
import {notification} from "../../components/Lnotification";
import styled from "styled-components";

const ViewResponse = (props) => {

    const [state, setState] = React.useState({
        response: null
    }, 'viewresponse');

    React.useEffect(() => {
        document.getElementById("client-css").removeAttribute('disabled');
        getResponse();
        return () => document.getElementById("client-css").setAttribute('disabled', true)
    }, []);


    const downloadGeneratedDocumentPdf = async ({_id: document_id, filename, provider}) => {
        try {
            setState(state => ({...state, loading: true}));
            const {data: pdf} = await API.get(`/sessions/${state.response._id}/document/${document_id}/pdf`, {responseType: 'blob'});
            const pdf_filename = filename.split('.').slice(0, -1).join('.') + '.pdf';
            saveAs(pdf, state.response._id + "_" + pdf_filename);
            setState(state => ({...state, loading: false}))
        } catch (err) {
            let message = 'Something went wrong with downloading the file';
            if (err.response && err.response.status === 404) {
                message = "The file was not found";
            } else if (err.response && err.response.data && err.response.data.message) {
                if (err.response.data.message === "reauthorize_storage") {
                    message = "There was an error when connecting to the selected default storage, please try again authorizing it."
                } else if (err.response.data.message === "different_storage") {
                    message = `Access denied. The document is stored on an inaccessible storage platform or account. Please connect directly to your ${{
                        gdrive: "Google Drive",
                        onedrive: "Microsoft OneDrive",
                        dropbox: "Dropbox"
                    }[provider.name]} account to retrieve the file. File name: ${state.response._id}_${filename}`
                } else if (err.response.data.message === "different_account") {
                    message = `The account of the storage provider for this document is different from the one authorized now`
                } else if (err.response.data.message === "different_folder") {
                    message = `The folder of the storage provider for this document is different from the one selected now`
                }
            }
            if (err.response && err.response.status === 404) {
                message = "The file was not found";
            }
            notification.warning({message: message, duration: 6000});
            setState(state => ({...state, loading: false}));
        }
    };

    const getResponse = async () => {
        try {
            setState(state => ({...state, loading: true}));
            const {data: response} = await API.get('/sessions/' + props.match.params.id);
            setState(state => ({...state, response, loading: false}))
        } catch (err) {
            setState(state => ({...state, loading: false, error: {code: err.response.status}}))
        }
    };

    const answers_by_code = state.response
        ? getAnswersByCode(
            state.response.formSnapshot[0].elements.reduce((acc, {code, value}) => value ? ({
                ...acc,
                [code]: value
            }) : acc, {}),
            state.response.formSnapshot[0].elements
        )
        : {};

    let pinnedItems = state.response ? state.response.formSnapshot[0].elements.filter(element => element.pinned && element.pinned.status) : [];
    pinnedItems.sort((elementA, elementB) => {
        if (elementA.pinned.updatedAt > elementB.pinned.updatedAt) {
            return 1;
        } else if (elementA.pinned.updatedAt < elementB.pinned.updatedAt) {
            return -1;
        } else
            return 0;
    });

    return (
        <>
            <div className={`wrapper session-page`}>
                <Navbar/>
                <div className="content">

                    <div className="content-box session-content-box">
                        <div className="scrollbar">
                            {state.loading && <Loading color="#39193B"/>}
                            {state.response &&
                            <>
                                <div className="content-box-header">
                                    <h1 style={{width: "auto", display: "inline-block"}}>{state.response._form.name}</h1>
                                    <span style={{float: "right", textTransform: "lowercase", fontSize: "1.5rem", lineHeight: "2rem", textAlign: "center"}}>{(state.response._client ? state.response._client: "Unknown")}<br />{moment(state.response.updated_at).format('d.MM.YYYY - H:mm')}</span>
                                </div>

                                <div className="content-box-body">
                                    <SessionForm>
                                        {pinnedItems.map((element) => {
                                                if (SupportedElements[element.type]) {
                                                    let value;

                                                    if (element.value !== undefined) {
                                                        value = element.value;
                                                    }

                                                    if (element.type === ELEMENTS.FILE_UPLOAD) {
                                                        return <div key={element._id}>
                                                            {SupportedElements[element.type].view({
                                                                element,
                                                                answers_by_code,
                                                                value,
                                                                session_id: props.match.params.id,
                                                            })}
                                                        </div>
                                                    }

                                                    if (element.type === ELEMENTS.DOCUMENTS_PREVIEW) {
                                                        return null;
                                                    }

                                                    return <div key={element._id}>
                                                        {SupportedElements[element.type].view({
                                                            element,
                                                            answers_by_code,
                                                            value
                                                        })}
                                                    </div>
                                                } else {
                                                    return null;
                                                }
                                            }
                                        )}
                                        {pinnedItems.length > 0 &&
                                        <div className="step-divider"/>
                                        }
                                        {state.response.formSnapshot.map((step) => {
                                                return step.elements.map(element => {
                                                        if (SupportedElements[element.type]) {
                                                            if (!pinnedItems.find(item => item._id === element._id)) {

                                                                let value;

                                                                if (element.value !== undefined) {
                                                                    value = element.value;
                                                                }

                                                                if (element.type === ELEMENTS.FILE_UPLOAD) {
                                                                    return SupportedElements[element.type].view({
                                                                        element,
                                                                        answers_by_code,
                                                                        value,
                                                                        session_id: props.match.params.id,
                                                                    })
                                                                }

                                                                if (element.type === ELEMENTS.DOCUMENTS_PREVIEW) {
                                                                    return null;
                                                                }

                                                                return SupportedElements[element.type].view({
                                                                    element,
                                                                    answers_by_code,
                                                                    value
                                                                })
                                                            } else {
                                                                return "";
                                                            }
                                                        } else {
                                                            return null;
                                                        }
                                                    }
                                                )
                                            }
                                        )}
                                        {(!state.response.finished || state.response.documents.length > 0) &&
                                        <div className="fieldset-row fieldset-row-end">
                                            {!state.response.finished &&
                                            <h2>Waiting for recipient</h2>
                                            }
                                            {state.response.documents.length > 0 &&
                                            <div style={state.response.finished ? {borderTop: "none"} : {}}>
                                                <h3 className="session-form-box-wrap-title">Generated Documents</h3>
                                                <div className="session-form-grid files-list mar1b"
                                                     style={{gridTemplateColumns: "repeat(3, 1fr)"}}>
                                                    {state.response.documents.map(file => (
                                                        <div key={file._id} style={{cursor: 'pointer'}}
                                                             className="file"
                                                             onClick={() => downloadGeneratedDocumentPdf(file)}>
                                                            <div>
                                                                <img src="alchemistLight/img/file.svg" alt=""/>
                                                                <strong>{file.filename.replace(/\.[^/.]+$/, "")}</strong>
                                                            </div>
                                                            <div className="button button-outline">Download
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        }
                                    </SessionForm>
                                </div>
                            </>
                            }
                        </div>
                    </div>

                </div>
            </div>
            <Portrait/>
        </>
    )
};

const SessionForm = styled.div.attrs({
    className: "session-form"
})`
  > div:last-child .session-form-row:last-child .input-box {
    margin-bottom: 3rem;
  }
`;
export default ViewResponse