import React from 'react'

export default ({ 
    element, 
    onChange = f => f, 
    value = "", 
}) => {
    return(

        <div className="fieldset-row">
            <div className="form-row with-calendar">
                <label>{element.text}</label>
                <input type="text" value={value} onChange={onChange} placeholder={element.placeholder} />
                <i className="calendar-btn icon-clock"/>
            </div>
        </div>
    )
}