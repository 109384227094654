import React from 'react'
import {notification} from '../../components/Lnotification'
import {useAppContext} from '../../context'
import isEmail from 'isemail'

import API from '../../api'
import Loading from '../../components/Loading'

const ChangeEmail = ({onCancel=f=>f}) => {
    const [state, setState] = React.useState({
        loading: false,
        newEmail: '',
        newName: '',
        newSurname: '',
        errors: {}
    }, 'profile.changeEmail');

    const {auth:[auth, setAuth]} = useAppContext();
    React.useEffect(() => {
        setState(state => ({...state, newEmail: auth.decodedToken.email, newName: auth.decodedToken.name, newSurname: auth.decodedToken.surname}))
    }, []);
    

    const changeEmail = async () => {
        try{
            setState(state => ({...state, loading: true}));
            const errors = {};

            if(!isEmail.validate(state.newEmail)) errors.newEmail = "Invalid email";
            if(!state.newName) errors.newName = "Name can not be empty";
            if(!state.newSurname) errors.newSurname = "Surname can not be empty";
            setState(state => ({...state, errors}));

            if(Object.keys(errors).length > 0) {
                setState(state => ({...state, loading: false}));
                return
            }

            await API.put('users', {email: state.newEmail, name: state.newName, surname: state.newSurname});
            setState(state => ({...state, loading: false}));
            setAuth(auth => ({
                ...auth,
                decodedToken: {
                    ...auth.decodedToken,
                    email: state.newEmail,
                    name: state.newName,
                    surname: state.newSurname
                }
            }));
            onCancel()
        }catch(err){
            const errors = {};
            if(err.response && err.response.data && err.response.data.error) {
                const error = err.response.data.error;
                if(error === 'google_account_cant_modify_email') errors.api = "Accounts registered with google cannot modify email!";
                else if(error === 'email_taken') errors.api = "This email is not available"
            }

            if(Object.keys(errors).length > 0) {
                setState(state => ({...state, errors}))
            }  else {
                notification.warning({message: 'Something went wrong'})
            }

            setState(state => ({...state, loading: false}))
        }
    };

    return(
        <div className="edit-details">
            <div className="titleDesc">
                <h3>Edit details</h3>
                <p>The new email address, if changed, will be used for future logins</p>
            </div>
            <form 
                className={`form ${Object.keys(state.errors).length > 0 ? 'error-anim' : ''}`}
                onSubmit={async e => {
                    e.preventDefault();
                    await changeEmail()
                }}
            >
                {state.loading && <Loading color="#39193B"/>}
                {!state.loading && 
                    <>
                        <div className="fieldset-row">
                            <div className={`form-row ${state.errors.newName ? 'error' : ''}`}>
                                <label htmlFor="changeName">Name</label>
                                <input 
                                    id="changeName"
                                    type="text"
                                    placeholder="Your name"
                                    value={state.newName}
                                    onChange={({target:{value}}) => setState(state => ({...state, newName: value}))}
                                />
                            </div>
                            <div className={`form-row ${state.errors.newSurname ? 'error' : ''}`}>
                                <label htmlFor="changeSurname">Surname</label>
                                <input
                                    id="changeSurname"
                                    type="text"
                                    placeholder="Your surname"
                                    value={state.newSurname}
                                    onChange={({target:{value}}) => setState(state => ({...state, newSurname: value}))}
                                />
                            </div>
                            <div className={`form-row ${state.errors.newEmail ? 'error' : ''}`}>
                                <label htmlFor="changeEmail">Email</label>
                                <input
                                    id="changeEmail"
                                    type="email"
                                    placeholder="Your email address"
                                    value={state.newEmail}
                                    onChange={({target:{value}}) => setState(state => ({...state, newEmail: value}))}
                                />
                            </div>
                        </div>
                        {Object.keys(state.errors).length > 0 &&
                            <div className="error-msg">{Object.values(state.errors)[0]}</div>
                        }
                        <div className="form-actions">
                            <button className="button with-icon-right close-box"><i className="icon-ia-checked"/>Save</button>
                            <span onClick={onCancel} className="button button-link close-box">Cancel</span>
                        </div>
                    </>
                }
            </form>
        </div>
    )
};

export default ChangeEmail