import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText"

export default ({
    element,
    answers_by_code
}) => {

    return (
        <div className="fieldset-row fieldset-row-title">
            <h1 className="paragraph">{synchronizeText(element.text, answers_by_code)}</h1>
        </div>
    )
}