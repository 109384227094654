import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText"
import {Parser} from 'hot-formula-parser'

export default ({
                    element,
                    value = '',
                    changeValue,
                    errors = [],
                    answers_by_code
                }) => {

    //If the element is a formula component, it should set the value beforehand since it's precalculated
    if (element.formula) {
        const parser = new Parser();
        const formulaValue = parser.parse(synchronizeText(element.formula, answers_by_code, true));
        value = formulaValue.result ? formulaValue.result : 0;
        if (answers_by_code[element.code] !== value.toString()) {
            changeValue(value.toString());
        }
    }
    return (
        <div className="fieldset-row">
            <div className={`form-row ${(errors.length > 0) ? 'error' : ''}`}>
                {element.text && <label>{synchronizeText(element.text, answers_by_code)}</label>}
                {!element.formula &&
                <input placeholder={element.placeholder} value={value} onChange={e => changeValue(e.target.value)}/>
                }
                {element.formula &&
                <input style={{cursor: "pointer"}} title="Read only formula" readOnly={true}
                       placeholder={element.placeholder} value={value}/>
                }
                {renderErrors(errors)}
            </div>
        </div>
    )
}

const renderErrors = (errors) => {
    if (errors.length === 0)
        return null;

    return errors.map(error => {
        const error_array = error.split('|');
        switch (error_array[0]) {
            case 'required':
                return 'This field is required';
            case 'phone':
                return 'Invalid phone number';
            case 'email':
                return 'Invalid email';
            case 'number':
                return 'The field should be a number';
            case 'integer':
                return 'The field should be an integer';
            case 'positive':
                return 'The field should be a positive number';
            case 'min length':
                return `Minimum length is ${error_array[1]}`;
            case 'max length':
                return `Maximum length is ${error_array[1]}`;
            case 'min value':
                return `Minimum values is ${error_array[1]}`;
            case 'max value':
                return `Maximum value is ${error_array[1]}`;
        }
    }).map(error => <span key={error} className="form-row-error-msg">{error}</span>)
};