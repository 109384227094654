import React from 'react'

import LivePreview from './LivePreview.ItemsSelectListWithImages'
import Editor from './Editor.ItemsSelectListWithImages'
import CodeCopy from './CodeCopy.ItemsSelectListWithImages'
import View from './View.ItemsSelectListWithImages'
import Input from './Input.ItemsSelectListWithImages'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <Editor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,

    default: () => ({
        type: "items select list with images",
        title: "Items Select List With Images Title",
        text: "Placeholder Title Description",
        pinned: {
            status: false
        },
        offers: [
            {
                image: null,
                title: "Item Title",
                description: "Item Description"
            }
        ],
        validations: [],
        allowMultipleSelections: false
    }),

    availableValidations: ['required'],
    validate: () => false,
    group: "ADVANCED",
    icon: "icon-ia-offers",
    name: "Items select list with images",
    view: args => <View {...args}/>
}