import React from 'react'
import LdeleteConfirm from '../../components/LdeleteConfirm'

export default ({element, remove, editElement, changeElementEditorMode, dragHandleProps = {}}) => {

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        changeElementEditorMode("DEFAULT")
    };

    const onTitleChange = ({target: {value}}) => {
        editElement({...element, title: value})
    };

    const onDescriptionChange = ({target: {value}}) => {
        editElement({...element, text: value})
    };

    const onPinnedChange = e => {
        editElement({
            ...element,
            pinned: {
                status: e.target.checked
            }
        })
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" ? "view-box-options" : ""} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center">
                <div className="form-box-wrap-center-content">
                    {/* <h6 className="supTitle">Raport evaluare achizitie autovehicul</h6> */}
                    <h3 className="form-box-wrap-descrition">{element.title}</h3>
                    <p className="form-box-wrap-descrition">{element.text}</p>
                </div>
                <div className="car-insurance-component">
                    <nav className="car-insurance-menu">
                        <a href="#outside-car-row"><i className="icon-ia-exterior"/><span>Exterior</span></a>
                        <a href="#inside-car-row"><i className="icon-ia-interior"/><span>Interior</span></a>
                        <a href="#engine-row"><i className="icon-ia-engine"/><span>Pornire Motor</span></a>
                        <a href="#accessory-car-row"><i className="icon-ia-accessories"/><span>Accesorii</span></a>
                    </nav>
                    <img className="cars-component-img" src="img/cars-component-img.svg"/>

                </div>
                {/* <span className="form-box-wrap-id">#s456te</span> */}
                <p className="form-row-validation-text">{element.validations.map(v => `[ ${v.validation} ]  `)}{element.pinned && element.pinned.status ? "[ show on top ]" : ""}</p>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <LdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </LdeleteConfirm>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className="form-row-checkbox">
                    <input id={`pinned-${element.code}`} type="checkbox"
                           checked={element.pinned && element.pinned.status} onChange={onPinnedChange}/>
                    <label htmlFor={`pinned-${element.code}`}>Show on top in saved response</label>
                </div>
                <div className="form-row">
                    <label for="tbl-e">Title</label>
                    <input id="tbl-e" type="text" value={element.title} onChange={onTitleChange}/>
                </div>
                <div className="form-row">
                    <label for="tbl-e">Description</label>
                    <input id="tbl-e" type="text" value={element.text} onChange={onDescriptionChange}/>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}