import React, {useState, useEffect} from 'react'
import uuid from 'uuid/v4'
import moment from 'moment'
import {notification} from '../components/Lnotification'
import AdatePicker from '../components/AdatePicker/AdatePicker'

const Validations = ({availableValidations, onChange, value = [], formElements}) => {

    const [objectVersion, setObjectVersion] = useState({});
    useEffect(() => {
        setObjectVersion(
            value.reduce((acc, v) => !!v.dependent_on ? acc : ({...acc, [v.validation]: v}), {})
        )
    }, [value]);

    return (
        <>
            {availableValidations.map((av, avIndex) => {
                switch (av) {
                    case 'required':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input
                                                    id={id}
                                                    type="checkbox"
                                                    checked={!!objectVersion['required']}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            onChange(
                                                                Object.values({
                                                                    ...objectVersion,
                                                                    ["required"]: {validation: 'required'}
                                                                })
                                                            )
                                                        } else {
                                                            let {'required': _, ...rest} = objectVersion;
                                                            onChange(
                                                                Object.values({...rest})
                                                            )
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={id}>Required</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                </div>
                            </div>
                        );
                    case 'skip first two steps':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input
                                                    id={id}
                                                    type="checkbox"
                                                    checked={!!objectVersion['skip first two steps']}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            onChange(
                                                                Object.values({
                                                                    ...objectVersion,
                                                                    ["skip first two steps"]: {validation: 'skip first two steps'}
                                                                })
                                                            )
                                                        } else {
                                                            let {'skip first two steps': _, ...rest} = objectVersion;
                                                            onChange(
                                                                Object.values({...rest})
                                                            )
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={id}>Skip first two steps</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                </div>
                            </div>
                        );
                    case 'hidden':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input
                                                    id={id}
                                                    type="checkbox"
                                                    checked={!!objectVersion['hidden']}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            onChange(
                                                                Object.values({
                                                                    ...objectVersion,
                                                                    ["hidden"]: {validation: 'hidden'}
                                                                })
                                                            )
                                                        } else {
                                                            let {'hidden': _, ...rest} = objectVersion;
                                                            onChange(
                                                                Object.values({...rest})
                                                            )
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={id}>Hidden</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                </div>
                            </div>
                        );
                    case 'integer':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input
                                                    id={id}
                                                    type="checkbox"
                                                    checked={!!objectVersion['integer']}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            if (objectVersion["email"]) {
                                                                notification.warning({
                                                                    message: 'Email doesn\'t support this validation.'
                                                                });
                                                            } else {
                                                                onChange(
                                                                    Object.values({
                                                                        ...objectVersion,
                                                                        ["integer"]: {validation: 'integer'}
                                                                    })
                                                                )
                                                            }
                                                        } else {
                                                            let {'integer': _, ...rest} = objectVersion;
                                                            onChange(
                                                                Object.values({...rest})
                                                            )
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={id}>Integer</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                </div>
                            </div>
                        );


                    case 'email two step validation':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input
                                                    id={id}
                                                    type="checkbox"
                                                    checked={!!objectVersion['email two step validation']}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            let currentScreenValidations = formElements.map(element => element.validations.map(validation => validation.validation)).reduce((accumulator, currentValue) => accumulator.concat(currentValue));
                                                            if (currentScreenValidations.includes('email two step validation') || currentScreenValidations.includes('phone two step validation')) {
                                                                e.target.checked = false;
                                                                notification.warning({
                                                                    message: 'You already chose a two step validation.'
                                                                });
                                                            }
                                                            else if (objectVersion["number"] || objectVersion["positive"] || objectVersion["integer"] || objectVersion["min value"] || objectVersion["max value"]) {
                                                                notification.warning({
                                                                    message: 'Email doesn\'t support this validation.'
                                                                });
                                                            }
                                                            else {
                                                                onChange(
                                                                    Object.values({
                                                                        ...objectVersion,
                                                                        ["email two step validation"]: {validation: 'email two step validation'},
                                                                        ["email"]: {validation: 'email'}
                                                                    })
                                                                )
                                                            }
                                                        } else {
                                                            let {'email two step validation': _, 'email': __, ...rest} = objectVersion;
                                                            onChange(
                                                                Object.values({...rest})
                                                            )
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={id}>Email address two step validation</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                </div>
                            </div>
                        );


                    case 'email':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input
                                                    id={id}
                                                    type="checkbox"
                                                    checked={!!objectVersion['email']}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            if (objectVersion["phone"]) {
                                                                notification.warning({
                                                                    message: 'You already checked phone validation.'
                                                                });
                                                            } else if (objectVersion["number"] || objectVersion["positive"] || objectVersion["integer"] || objectVersion["min value"] || objectVersion["max value"]) {
                                                                notification.warning({
                                                                    message: 'Email doesn\'t support this validation.'
                                                                });
                                                            } else {
                                                                onChange(
                                                                    Object.values({
                                                                        ...objectVersion,
                                                                        ["email"]: {validation: 'email'}
                                                                    })
                                                                )
                                                            }
                                                        } else {
                                                            if (objectVersion["email two step validation"]) {
                                                                notification.warning({
                                                                    message: 'The email format validation is mandatory if you have selected the email two step authentication validation.'
                                                                });
                                                            } else {
                                                                let {'email': _, ...rest} = objectVersion;
                                                                onChange(
                                                                    Object.values({...rest})
                                                                )
                                                            }
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={id}>Email address format validation</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                </div>
                            </div>
                        );


                    case 'phone two step validation':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input
                                                    id={id}
                                                    type="checkbox"
                                                    checked={!!objectVersion['phone two step validation']}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            let currentScreenValidations = formElements.map(element => element.validations.map(validation => validation.validation)).reduce((accumulator, currentValue) => accumulator.concat(currentValue));
                                                            if (currentScreenValidations.includes('email two step validation') || currentScreenValidations.includes('phone two step validation')) {
                                                                e.target.checked = false;
                                                                notification.warning({
                                                                    message: 'You already chose a two step validation.'
                                                                });
                                                            } else {
                                                                onChange(
                                                                    Object.values({
                                                                        ...objectVersion,
                                                                        ["phone two step validation"]: {validation: 'phone two step validation'},
                                                                        ["phone"]: {validation: 'phone'}
                                                                    })
                                                                )
                                                            }
                                                        } else {
                                                            let {'phone two step validation': _, 'phone': __, ...rest} = objectVersion;
                                                            onChange(
                                                                Object.values({...rest})
                                                            )
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={id}>Phone number two step validation</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                </div>
                            </div>
                        );


                    case 'phone':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input
                                                    id={id}
                                                    type="checkbox"
                                                    checked={!!objectVersion['phone']}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            if (objectVersion["email"]) {
                                                                notification.warning({
                                                                    message: 'You already checked email validation.'
                                                                });
                                                            } else {
                                                                onChange(
                                                                    Object.values({
                                                                        ...objectVersion,
                                                                        ["phone"]: {validation: 'phone'}
                                                                    })
                                                                )
                                                            }
                                                        } else {
                                                            if (objectVersion["phone two step validation"]) {
                                                                notification.warning({
                                                                    message: 'The phone format validation is mandatory if you have selected the phone two step authentication validation.'
                                                                });
                                                            } else {
                                                                let {'phone': _, ...rest} = objectVersion;
                                                                onChange(
                                                                    Object.values({...rest})
                                                                )
                                                            }
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={id}>Phone number format validation</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                </div>
                            </div>
                        );
                    case 'number':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input
                                                    id={id} type="checkbox"
                                                    checked={!!objectVersion['number']}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            if (objectVersion["email"]) {
                                                                notification.warning({
                                                                    message: 'Email doesn\'t support this validation.'
                                                                });
                                                            } else {
                                                                onChange(
                                                                    Object.values({
                                                                        ...objectVersion,
                                                                        ["number"]: {validation: 'number'}
                                                                    })
                                                                )
                                                            }
                                                        } else {
                                                            let {'number': _, ...rest} = objectVersion;
                                                            onChange(
                                                                Object.values({...rest})
                                                            )
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={id}>Number</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                </div>
                            </div>
                        );
                    case 'positive':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input
                                                    id={id} type="checkbox"
                                                    checked={!!objectVersion['positive']}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            if (objectVersion["email"]) {
                                                                notification.warning({
                                                                    message: 'Email doesn\'t support this validation.'
                                                                });
                                                            } else {
                                                                onChange(
                                                                    Object.values({
                                                                        ...objectVersion,
                                                                        ["positive"]: {validation: 'positive'}
                                                                    })
                                                                )
                                                            }
                                                        } else {
                                                            let {'positive': _, ...rest} = objectVersion;
                                                            onChange(
                                                                Object.values({...rest})
                                                            )
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={id}>Positive number</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                </div>
                            </div>
                        );
                    case 'string':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input
                                                    id={id}
                                                    type="checkbox"
                                                    checked={!!objectVersion['string']}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            onChange(
                                                                Object.values({
                                                                    ...objectVersion,
                                                                    ["string"]: {validation: 'string'}
                                                                })
                                                            )
                                                        } else {
                                                            let {'string': _, ...rest} = objectVersion
                                                            onChange(
                                                                Object.values({...rest})
                                                            )
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={id}>String</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                </div>
                            </div>
                        )
                    case 'min length':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input
                                                    id={id} type="checkbox"
                                                    checked={!!objectVersion['min length']}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            onChange(
                                                                Object.values({
                                                                    ...objectVersion,
                                                                    ["min length"]: {
                                                                        validation: 'min length',
                                                                        value: ""
                                                                    }
                                                                })
                                                            )
                                                        } else {
                                                            let {'min length': _, ...rest} = objectVersion
                                                            onChange(
                                                                Object.values({...rest})
                                                            )
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={id}>Min length</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                    <div className="mco-cell">
                                        {objectVersion['min length'] &&
                                        <input type="text" value={objectVersion['min length'].value} onChange={e => {
                                            onChange(
                                                Object.values({
                                                    ...objectVersion,
                                                    ['min length']: {validation: 'min length', value: e.target.value}
                                                })
                                            )
                                        }}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    case 'max length':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input id={id}
                                                       type="checkbox"
                                                       checked={!!objectVersion['max length']}
                                                       onChange={e => {
                                                           if (e.target.checked) {
                                                               onChange(
                                                                   Object.values({
                                                                       ...objectVersion,
                                                                       ["max length"]: {
                                                                           validation: 'max length',
                                                                           value: ""
                                                                       }
                                                                   })
                                                               )
                                                           } else {
                                                               let {'max length': _, ...rest} = objectVersion
                                                               onChange(
                                                                   Object.values({...rest})
                                                               )
                                                           }
                                                       }}
                                                />
                                                <label htmlFor={id}>Max length</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                    <div className="mco-cell">
                                        {!!objectVersion['max length'] &&
                                        <input type="text" value={objectVersion['max length'].value} onChange={e => {
                                            onChange(
                                                Object.values({
                                                    ...objectVersion,
                                                    ['max length']: {validation: 'max length', value: e.target.value}
                                                })
                                            )
                                        }}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    case 'min value':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input id={id}
                                                       type="checkbox"
                                                       checked={!!objectVersion['min value']}
                                                       onChange={e => {
                                                           if (e.target.checked) {
                                                               if (objectVersion["email"]) {
                                                                   notification.warning({
                                                                       message: 'Email doesn\'t support this validation.'
                                                                   });
                                                               } else {
                                                                   onChange(
                                                                       Object.values({
                                                                           ...objectVersion,
                                                                           ["min value"]: {
                                                                               validation: 'min value',
                                                                               value: ""
                                                                           }
                                                                       })
                                                                   )
                                                               }
                                                           } else {
                                                               let {'min value': _, ...rest} = objectVersion
                                                               onChange(
                                                                   Object.values({...rest})
                                                               )
                                                           }
                                                       }}
                                                />
                                                <label htmlFor={id}>Min value</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                    <div className="mco-cell">
                                        {!!objectVersion['min value'] &&
                                        <input type="text" value={objectVersion['min value'].value} onChange={e => {
                                            onChange(
                                                Object.values({
                                                    ...objectVersion,
                                                    ['min value']: {validation: 'min value', value: e.target.value}
                                                })
                                            )
                                        }}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    case 'max value':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input id={id}
                                                       type="checkbox"
                                                       checked={!!objectVersion['max value']}
                                                       onChange={e => {
                                                           if (e.target.checked) {
                                                               if (objectVersion["email"]) {
                                                                   notification.warning({
                                                                       message: 'Email doesn\'t support this validation.'
                                                                   });
                                                               }
                                                               else {
                                                                   onChange(
                                                                       Object.values({
                                                                           ...objectVersion,
                                                                           ["max value"]: {
                                                                               validation: 'max value',
                                                                               value: ""
                                                                           }
                                                                       })
                                                                   )
                                                               }
                                                           } else {
                                                               let {'max value': _, ...rest} = objectVersion
                                                               onChange(
                                                                   Object.values({...rest})
                                                               )
                                                           }
                                                       }}
                                                />
                                                <label htmlFor={id}>Max Value</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                    <div className="mco-cell">
                                        {!!objectVersion['max value'] &&
                                        <input type="text" value={objectVersion['max value'].value} onChange={e => {
                                            onChange(
                                                Object.values({
                                                    ...objectVersion,
                                                    ['max value']: {validation: 'max value', value: e.target.value}
                                                })
                                            )
                                        }}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    case 'max file size':
                        return <MaxFileSize 
                            objectVersion={objectVersion}
                            avIndex={avIndex}
                            onChange={onChange}
                        />
                    case 'min number of files':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input id={id}
                                                       type="checkbox"
                                                       checked={!!objectVersion['min number of files']}
                                                       onChange={e => {
                                                           if (e.target.checked) {
                                                               onChange(
                                                                   Object.values({
                                                                       ...objectVersion,
                                                                       ["min number of files"]: {
                                                                           validation: 'min number of files',
                                                                           value: ""
                                                                       }
                                                                   })
                                                               )
                                                           } else {
                                                               let {'min number of files': _, ...rest} = objectVersion
                                                               onChange(
                                                                   Object.values({...rest})
                                                               )
                                                           }
                                                       }}
                                                />
                                                <label htmlFor={id}>Min number of files</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                    <div className="mco-cell">
                                        {!!objectVersion['min number of files'] &&
                                        <input type="text" value={objectVersion['min number of files'].value}
                                               onChange={e => {
                                                   onChange(
                                                       Object.values({
                                                           ...objectVersion,
                                                           ['min number of files']: {
                                                               validation: 'min number of files',
                                                               value: e.target.value
                                                           }
                                                       })
                                                   )
                                               }}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    case 'max number of files':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input id={id}
                                                       type="checkbox"
                                                       checked={!!objectVersion['max number of files']}
                                                       onChange={e => {
                                                           if (e.target.checked) {
                                                               onChange(
                                                                   Object.values({
                                                                       ...objectVersion,
                                                                       ["max number of files"]: {
                                                                           validation: 'max number of files',
                                                                           value: ""
                                                                       }
                                                                   })
                                                               )
                                                           } else {
                                                               let {'max number of files': _, ...rest} = objectVersion
                                                               onChange(
                                                                   Object.values({...rest})
                                                               )
                                                           }
                                                       }}
                                                />
                                                <label htmlFor={id}>Max number of files</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                    <div className="mco-cell">
                                        {!!objectVersion['max number of files'] &&
                                        <input type="text" value={objectVersion['max number of files'].value}
                                               onChange={e => {
                                                   onChange(
                                                       Object.values({
                                                           ...objectVersion,
                                                           ['max number of files']: {
                                                               validation: 'max number of files',
                                                               value: e.target.value
                                                           }
                                                       })
                                                   )
                                               }}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    case 'no future date':
                        return <NoFutureDate 
                            avIndex={avIndex}
                            objectVersion={objectVersion}
                            onChange={onChange}  
                        />

                    case 'no past date':
                        return <NoPastDate 
                            avIndex={avIndex}
                            objectVersion={objectVersion}
                            onChange={onChange}
                        />
                        
                    case 'no before session date':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input id={id}
                                                       type="checkbox"
                                                       checked={!!objectVersion['no before session date']}
                                                       onChange={e => {
                                                           if (e.target.checked) {
                                                               onChange(
                                                                   Object.values({
                                                                       ...objectVersion,
                                                                       ["no before session date"]: {validation: 'no before session date'}
                                                                   })
                                                               )
                                                           } else {
                                                               let {'no before session date': _, ...rest} = objectVersion
                                                               onChange(
                                                                   Object.values({...rest})
                                                               )
                                                           }
                                                       }}
                                                />
                                                <label htmlFor={id}>No before session date</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                </div>
                            </div>
                        )
                    case 'no after session date':
                        return (
                            <div className="mco-cell" key={avIndex}>
                                <div className="mco-cell-header">
                                    <div className="form-row-checkbox">
                                        {(id => (
                                            <>
                                                <input id={id}
                                                       type="checkbox"
                                                       checked={!!objectVersion['no after session date']}
                                                       onChange={e => {
                                                           if (e.target.checked) {
                                                               onChange(
                                                                   Object.values({
                                                                       ...objectVersion,
                                                                       ["no after session date"]: {validation: 'no after session date'}
                                                                   })
                                                               )
                                                           } else {
                                                               let {'no after session date': _, ...rest} = objectVersion
                                                               onChange(
                                                                   Object.values({...rest})
                                                               )
                                                           }
                                                       }}
                                                />
                                                <label htmlFor={id}>No after session date</label>
                                            </>
                                        ))(uuid())}
                                    </div>
                                </div>
                            </div>
                        )
                    case 'multiple images':
                        return (() => {

                            const onToggle = ({target: {checked}}) => {
                                if (checked) {
                                    onChange(
                                        Object.values({
                                            ...objectVersion,
                                            ["multiple images"]: {validation: 'multiple images', value: undefined}
                                        })
                                    )
                                } else {
                                    let {'multiple images': _, ...rest} = objectVersion;
                                    onChange(
                                        Object.values({...rest})
                                    )
                                }
                            }

                            const onValueChange = ({target: {value, validity: {valid}}}) => {
                                if (valid) {
                                    onChange(
                                        Object.values({
                                            ...objectVersion,
                                            'multiple images': {
                                                validation: 'multiple images',
                                                value
                                            }
                                        })
                                    )
                                }
                            }

                            return (
                                <div className="mco-cell" key={avIndex}>
                                    <div className="mco-cell-header">
                                        <div className="form-row-checkbox">
                                            {(id => (
                                                <>
                                                    <input
                                                        id={id}
                                                        type="checkbox"
                                                        checked={!!objectVersion['multiple images']}
                                                        onChange={onToggle}
                                                    />
                                                    <label htmlFor={id}>Multiple images</label>
                                                </>
                                            ))(uuid())}
                                        </div>
                                        {!!objectVersion['multiple images'] &&
                                        (
                                            <div className="mco-cell">
                                                {(id => (
                                                    <>
                                                        <label htmlFor={id}>Max no. of images</label>
                                                        <input type="text" pattern="[1-9]*"
                                                               value={objectVersion['multiple images']['value']}
                                                               onChange={onValueChange}/>
                                                    </>
                                                ))(uuid())}
                                            </div>
                                        )
                                        }
                                    </div>
                                </div>
                            )
                        })()
                    default:
                        return null
                }
            })}
        </>
    )
}

export default Validations

const MaxFileSize = ({objectVersion, avIndex, onChange}) => {
    
    const id = uuid()

    let matches, value = "", unit;
    if (objectVersion['max file size'] && objectVersion['max file size'].value) {
        matches = objectVersion['max file size'].value.match(/^(\d+(?:\.\d+)?)(.*)$/);
        if (matches) {
            value = matches[1];
            unit = matches[2];
        } else {
            unit = objectVersion['max file size'].value;
        }
    }

    const changeUnitValue = unitValue => {
        onChange(
            Object.values({
                ...objectVersion,
                ['max file size']: {
                    validation: 'max file size',
                    value: `${unitValue}${unit}`
                }
            })
        )
    }

    const changeUnit = unit => {
        onChange(
            Object.values({
                ...objectVersion,
                ['max file size']: {
                    validation: 'max file size',
                    value: `${value}${unit}`
                }
            })
        )
    }

    const toggleValidation = active => {
        if (active) {
            onChange(
                Object.values({
                    ...objectVersion,
                    ["max file size"]: {
                        validation: 'max file size',
                        value: "B"
                    }
                })
            )
        } else {
            let {'max file size': _, ...rest} = objectVersion
            onChange(
                Object.values({...rest})
            )
        }
    }

    return (
        <div className="mco-cell" key={avIndex}>
            <div className="mco-cell-header" style={{gridTemplateColumns: "repeat(3,1fr)"}}>
                <div className="form-row-checkbox">
         
                        <input id={id}
                                type="checkbox"
                                checked={!!objectVersion['max file size']}
                                onChange={({target:{checked}}) => toggleValidation(checked)}
                            
                        />
                        <label htmlFor={id}>Max file size</label>
                   
                </div>
                <div className="mco-cell">
                    {!!objectVersion['max file size'] &&
                        <input type="text" 
                            value={value} 
                            onChange={({target:{value}}) => changeUnitValue(value)}
                        />
                    }
                </div>
                <div className="mco-cell">
                    {!!objectVersion['max file size'] &&
                        <select 
                            onChange={({target:{value}}) => changeUnit(value)}
                            value={unit}
                        >
                            <option selected={unit === "B"} value="B">B</option>
                            <option selected={unit === "KB"} value="KB">KB</option>
                            <option selected={unit === "MB"} value="MB">MB</option>
                        </select>
                    }
                </div>
            </div>
        </div>
    )
}

const NoFutureDate = ({objectVersion, onChange, avIndex}) => {

    const id = uuid()

    const toggleValidation = active => {
        if (active) {
            onChange(
                Object.values({
                    ...objectVersion,
                    ["no future date"]: {
                        validation: 'no future date',
                        value: moment().format('DD/MM/YYYY')
                    }
                })
            )
        } else {
            let {'no future date': _, ...rest} = objectVersion
            onChange(
                Object.values({...rest})
            )
        }
    }

    const setNoFutureDate = date => {
        onChange(Object.values({
            ...objectVersion,
            ['no future date']: {validation: 'no future date', value: date}
        }))
    }

    return (
        <div className="mco-cell" key={avIndex}>
            <div className="mco-cell-header">
                <div className="form-row-checkbox">
                    <input id={id}
                        type="checkbox"
                        checked={!!objectVersion['no future date']}
                        onChange={({target:{checked}}) => toggleValidation(checked)}
                    />
                    <label htmlFor={id}>No future date</label>
                </div>
                <div className="mco-cell">
                    {!!objectVersion['no future date'] &&
                        <div className="form-row with-calendar">
                            <AdatePicker
                                placeholder="Select Date"
                                value={objectVersion['no future date'].value ? moment(objectVersion['no future date'].value, 'DD/MM/YYYY').toDate() : null}
                                onChange={date => setNoFutureDate(date ? moment(date).format('DD/MM/YYYY') : date)}
                                dateFormat={"dd/MM/yyyy"}
                            />
                            <i className="calendar-btn icon-ia-calendar" style={{top: '-0.9rem', cursor: 'default'}}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

const NoPastDate = ({objectVersion, avIndex, onChange}) => {

    const id = uuid()

    const toggleValidation = active => {
        if (active) {
            onChange(
                Object.values({
                    ...objectVersion,
                    ["no past date"]: {
                        validation: 'no past date',
                        value: moment().format('DD/MM/YYYY')
                    }
                })
            )
        } else {
            let {'no past date': _, ...rest} = objectVersion
            onChange(
                Object.values({...rest})
            )
        }
    }

    const setNoPastDate = date => {
        onChange(Object.values({
            ...objectVersion,
            ['no past date']: {validation: 'no past date', value: date}
        }))
    }

    return (
        <div className="mco-cell" key={avIndex}>
            <div className="mco-cell-header">
                <div className="form-row-checkbox">
                      
                    <input id={id}
                        type="checkbox"
                        checked={!!objectVersion['no past date']}
                        onChange={({target:{checked}}) => toggleValidation(checked)}
                    />
                    <label htmlFor={id}>No past date</label>
                    
                </div>
                <div className="mco-cell">
                    {!!objectVersion['no past date'] &&
                        <div className="form-row with-calendar">
                            <AdatePicker
                                placeholder="Select Date"
                                value={objectVersion['no past date'].value ? moment(objectVersion['no past date'].value, 'DD/MM/YYYY').toDate() : null}
                                onChange={date => setNoPastDate(date ? moment(date).format('DD/MM/YYYY') : date)}
                                dateFormat={"dd/MM/yyyy"}
                            />
                            <i className="calendar-btn icon-ia-calendar" style={{top: '-0.9rem', cursor: 'default'}}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}