import React from 'react'
import LdeleteConfirm from '../../components/LdeleteConfirm';
import supportedElements from '../supportedElements'
import Validations from '../Validations'
import {VALIDATION} from '../../constants/validations'
import CodeCopy from '../../components/Editor.codeCopy'
import Formula from './Formula'
import {notification} from "../../components/Lnotification";

export default ({element, remove, editElement, changeElementEditorMode, dragHandleProps = {}, form, formElements, VisibilityAffected}) => {
    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        const formulaMatch = element.formula && element.formula.match(/^([{]{2}?(\d+)[}]{2}|(\d+))([-+*\/])([{]{2}?(\d+)[}]{2}|(\d+))(([-+*\/])([{]{2}?(\d+)[}]{2}|(\d+)))*$/) && element.formula.match(/{{[\d]+}}/g);
        const formulaIDs = formulaMatch ? [...new Set(element.formula.match(/{{[\d]+}}/g).map(formulaId => formulaId.replace(/{{|}}/g, "")))] : null;
        let disallowedElement = null;
        if (formulaIDs) {
                for (let j = 0; j < form.elements.length; j++) {
                    if (formulaIDs.includes(form.elements[j].code)) {
                        const validations = form.elements[j].validations.map((validation) => validation.validation);
                        if (!validations.includes(VALIDATION.POSITIVE)
                            && !validations.includes(VALIDATION.NUMBER)
                            && !validations.includes(VALIDATION.INTEGER)) {
                            disallowedElement = form.elements[j].code;
                        }
                        formulaIDs.splice(formulaIDs.indexOf(form.elements[j].code), 1);
                    }
                }
        }
        if (!element.formula || (formulaMatch && formulaIDs.length === 0 && !disallowedElement)) {
            if (element.validations.length > 0 && element.formula) {
                editElement({
                    ...element,
                    validations: []
                })
            }
            // document.getElementsByClassName('formula')[0].classList.remove("error");
            changeElementEditorMode("DEFAULT")
        } else {
            if (!formulaMatch) {
                notification.warning({message: `Formula has an incorrect format`});
            } else if (disallowedElement) {
                notification.warning({message: `Element ${disallowedElement} is not a valid formula element`});
            } else if (formulaIDs.length > 0) {
                notification.warning({message: `Element ${formulaIDs[0]} does not exist in the form`});
            }
            // document.getElementsByClassName('formula')[0].classList.add("error");
            // document.getElementsByClassName('formula')[0].scrollIntoView({
            //     behavior: "smooth",
            //     block: "start"
            // });
        }
    };

    const onValidationsClick = () => {
        changeElementEditorMode("VALIDATIONS")
    };

    const onPlaceholderChange = e => {
        editElement({
            ...element,
            placeholder: e.target.value
        })
    };

    const onPinnedChange = e => {
        editElement({
            ...element,
            pinned: {
                status: e.target.checked
            }
        })
    };

    const onFormulaChange = value => {
        // editElement({
        //     ...element,
        //     formula: e.target.value
        // })
        editElement({
            ...element,
            formula: value
        })
    };

    const onValidationsChange = newValidations => {
        editElement({
            ...element,
            validations: newValidations
        })
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center">
                <div className="form-box-wrap-center-content">
                    <div className="form-row">
                        <label htmlFor="tbl">{element.text}</label>
                        <input id="tbl" type="text" placeholder={element.placeholder} value="" onChange={f => f} disabled/>
                    </div>
                    <VisibilityAffected element={element} formElements={formElements}/>
                    <p className="form-row-validation-text">{element.validations.map(v => `[ ${v.validation} ]  `)}{element.formula ? `[ formula ]  ` : ""}{element.pinned && element.pinned.status ? "[ show on top ]" : ""}</p>
                    <CodeCopy code={element.code}>
                        <span className="form-box-wrap-id"/>
                    </CodeCopy>

                </div>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onValidationsClick}><i className="icon-check trigger-validation-box"/>Validation</span>
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <LdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </LdeleteConfirm>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className="form-row">
                    <input id="tbl-e" type="text" value={element.text}
                           onChange={e => editElement({...element, text: e.target.value})} placeholder="Text Box Title"/>
                </div>
                <div className="form-row">
                    <input type="text" value={element.placeholder} onChange={onPlaceholderChange} placeholder="Text Box Placeholder"/>
                </div>
                {/* <div className="form-row formula">
                    <label>Formula</label>
                    <input type="text" value={element.formula} onChange={onFormulaChange}/>
                </div> */}
                <Formula changeValue={onFormulaChange} value={element.formula}/>
                {element.validations.length > 0 && element.formula &&
                <LdeleteConfirm
                    onConfirm={onSaveClick}
                    okText="Save and delete validations"
                    title="Formula doesn’t permit validations to this component"
                >
                    <a className="form-box-wrap-button">Save</a>
                </LdeleteConfirm>
                }
                <div className="frc-box">
                    <div className="form-row-checkbox">
                        <input id={`pinned-${element.code}`} type="checkbox"
                               checked={element.pinned && element.pinned.status} onChange={onPinnedChange}/>
                        <label htmlFor={`pinned-${element.code}`}>Show on top in saved response</label>
                    </div>
                </div>
                {(element.validations.length === 0 || !element.formula) &&
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
                }
            </div>
            <div className="form-box-wrap-center-validation mco-box">
                <div className="mco-cell"><h4>Validations</h4></div>
                <Validations
                    availableValidations={supportedElements[element.type].availableValidations}
                    onChange={onValidationsChange}
                    value={element.validations}
                    formElements={formElements}
                />
                {element.validations.length > 0 && element.formula &&
                <LdeleteConfirm
                    onConfirm={onSaveClick}
                    okText="Save and delete validations"
                    title="Formula doesn’t permit validations to this component"
                >
                    <a className="form-box-wrap-button">Save</a>
                </LdeleteConfirm>
                }
                {(element.validations.length === 0 || !element.formula) &&
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
                }
            </div>
        </div>
    )
}