import { saveAs } from 'file-saver';
import API from '../../api';

export const downloadFile = async (session_id, file_id, secret, filename, error_callback) => {
    try {
        let response;
        !secret
            ? response = await API.get(`/sessions/${session_id}/file/${file_id}`, {responseType: 'blob'})
            : response = await API.get(`/sessions/${session_id}/file/${file_id}/secret/${secret}`, {responseType: 'blob'});

        saveAs(response.data, session_id + "_" + filename);
    } catch (err) {
        error_callback();
    }
};