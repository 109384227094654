import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText";

const VIN = ({
                         element,
                         answers_by_code,
                         value = ''
                     }) => {

    return (
        <div className="fieldset-row">
            <div className="session-form-row">
                {element.text &&
                <label>{synchronizeText(element.text, answers_by_code)}</label>
                }
                <span className="input-box">{value.toUpperCase()}</span>
            </div>
        </div>
    );
};

export default VIN;