import React from 'react'

import LivePreview from './LivePreview.heading'
import Editor from './Editor.heading'
import CodeCopy from './CodeCopy.heading'
import View from './View.heading'
import Input from './Input.heading'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <Editor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,

    default: () => ({
        type: "heading",
        text: "Heading Text",
        pinned: {
            status: false
        },
        validations: []
    }),

    group: "GENERAL",
    icon: "icon-ia-text",
    name: "Heading",
    view: args => <View {...args}/>
}