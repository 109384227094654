import React from 'react'
import styled from 'styled-components'

import {synchronizeText} from "../../utils/synchronizeText"
import {Table, Checkbox, Input, Row} from 'antd'

export default ({
    element,
    answers_by_code,
    value=null
}) => {


    if(!value) return null

    return (
        <div className="fieldset-row">
            <div className="session-form-row">
                {/* {element.title && <label>{synchronizeText(element.title, answers_by_code)}</label>} */}
                <Exterior value={value.exterior}/>
                <Interior value={value.interior}/>
                <Motor value={value.pornireMotor}/>
                <Accesori value={value.accesorii}/>
            </div>
        </div>
    )
}

const Accesori = ({value}) => {
    const {
        bareTransversale,
        bullBar,
        cameraVideoParbiz,
        carligTractare,
        pachetSiguranta,
        portBagajSuplimentar,
        praguriInox,
        suportBiciclete,
        suportSchiuri,
        alteAcessorii
    } = value
    return(
        <SectionContainer>
            <label style={{marginBottom: "30px"}}>Accesorii</label>
            <Row>
                <Checkbox onChange={f=>f} checked={bareTransversale}>Bare transversale ( inclusiv cheie )</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={bullBar}>Bull bar</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={cameraVideoParbiz}>Camera video parbriz</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={carligTractare}>Carlig tractare</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={pachetSiguranta}>Pachet siguranta ( extinctor, trusa medicala )</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={portBagajSuplimentar}>Portbagaj suplimentar ( inclusiv cheie )</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={praguriInox}>Praguri inox</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={suportBiciclete}>Suport biciclete</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={suportSchiuri}>Suport schiuri</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={alteAcessorii.checked}>Alte accesorii importante</Checkbox>
            </Row>
            {alteAcessorii.checked &&
                <p>{alteAcessorii.text}</p>
            }
        </SectionContainer>
    )
}

const Motor = ({value}) => {
    const {
        martoriImportanti,
        alteAlerteActive,
        zgomoteMotor,
        zgomoteTransmisie,
        observatii
    } = value
    return(
        <SectionContainer>
            <label style={{marginBottom: "30px"}}>Pornire Motor</label>
            <Row>
                <Checkbox onChange={f=>f} checked={martoriImportanti}>Martori importanti aprinsi</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={alteAlerteActive}>Alte alerte active</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={zgomoteMotor}>Probleme / Zgomote motor</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={zgomoteTransmisie}>Probleme / Zgomote transmisie ( cutie de viteze si diferential )</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={observatii.checked}>Alte avarii / Observatii</Checkbox>
            </Row>
            {observatii.checked &&
                <p>{observatii.text}</p>
            }
        </SectionContainer>
    )
}

const Interior = ({value}) => {

    const {
        mirosNeplacut,
        podea,
        tapiserie,
        observatii,
        ...others
    } = value

    others.pedale.name = "Pedale"
    others.volan.name = "Volan"
    others.schimbatorViteze.name = "Schimbator viteze"
    others.butoanePlansaBord.name = "Butoane plansa bord"
    others.butoaneMultimedia.name = "Butoane multimedia"

    others.butoanePortiere.name = "Butoane portiere / geamuri"
    others.cotiera.name = "Cotiera"
    others.plansaBord.name = "Plansa bord ( incl .torpedou )"
    others.plafon.name = "Plafon"
    others.scaunSofer.name = "Scaun sofer"

    others.scaunPasager.name = "Scaun pasager"
    others.banchetaSpate.name = "Bancheta spate"
    others.centuriSiguranta.name = "Centuri siguranta"
    others.capitonajeUsi.name = "Capitonaje usi"
    others.covorase.name = "Covorase"

    return(
        <SectionContainer>
            <label style={{marginBottom: "30px"}}>Interior</label>
            <Table dataSource={Object.values(others)} pagination={false}>
                <Table.Column title="Avariat" dataIndex="name"/>
                <Table.Column title="Zgariat" render={({zgariat}) => <span>{zgariat ? "✓": ""}</span>} />
                <Table.Column title="Patat" render={({patat}) => <span>{patat ? "✓": ""}</span>} />
                <Table.Column title="Rupt" render={({rupt}) => <span>{rupt ? "✓": ""}</span>} />
                <Table.Column title="Foarte Uzat" render={({foarte}) => <span>{foarte ? "✓": ""}</span>} />
                <Table.Column title="Cost EUR" dataIndex="cost"/>
            </Table>
       
            <Row>
                <Checkbox onChange={f=>f} checked={mirosNeplacut}>Miros neplacut</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={podea}>Podea / Tapiserie cu urme de umezeala</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={tapiserie.checked}>Stare generala tapiserie</Checkbox>
            </Row>
            {tapiserie.checked &&
                <p>{tapiserie.text}</p>
            }
            <Row>
                <Checkbox onChange={f=>f} checked={observatii.checked}>Alte avarii / Observatii</Checkbox>
            </Row>
            {observatii.checked &&
                <p>{observatii.text}</p>
            }
        </SectionContainer>
    )
};

const Exterior = ({ value }) => {

    const {
        stalpi,
        lonjeroane,
        observatii,
        ...others
    } = value;

    others.baraFata.name = "Bara fata"
    others.grilaFata.name = "Grila fata"
    others.capotaFata.name = "Capota fata"
    others.farDreapta.name = "Far dreapta"
    others.farStanga.name = "Far stanga"

    others.parbriz.name = "Parbriz"
    others.aripaStangaFata.name = "Aripa stanga fata"
    others.jantaStangaFata.name = "Janta stanga fata"
    others.oglindaStangaFata.name = "Oglinda stanga fata"
    others.portieraStangaFata.name = "Portiera stanga fata"

    others.portieraStangaSpate.name = "Portiera stanga spate"
    others.pragaStanga.name = "Prag stanga"
    others.jantaStangaSpate.name = "Janta stanga spate"
    others.aripaStangaSpate.name = "Aripa stanga spate"
    others.baraSpate.name = "Bara spate"

    others.capotaSpate.name = "Capota spate"
    others.luneta.name = "Luneta"
    others.stopStanga.name = "Stop stanga"
    others.stopDreapta.name = "Stop dreapta"
    others.aripaDreaptaSpate.name = "Aripa dreapta spate"

    others.jantaDreaptaSpate.name = "Janta dreapta spate"
    others.portieraDreapta.name = "Portiera dreapta spate"
    others.portieraDreaptaFata.name = "Portiera dreapta fata"
    others.pragDreapta.name = "Prag dreapta"
    others.plafon.name = "Plafon"

    others.oglindaDreapta.name = "Oglinda dreapta"
    others.jantaDreaptaFata.name = "Janta dreapta fata"
    others.aripaDreaptaFata.name = "Aripa dreapta fata"

    return (
        <SectionContainer>
            <label style={{marginBottom: "30px"}}>Exterior</label>
            <Table dataSource={Object.values(others)} pagination={false}>
                <Table.Column title="Avariat" dataIndex="name"/>
                <Table.Column title="Zgariat" render={({zgariat}) => <span>{zgariat ? "✓": ""}</span>} />
                <Table.Column title="Crapat" render={({crapat}) => <span>{crapat ? "✓": ""}</span>} />
                <Table.Column title="Indoit" render={({indoit}) => <span>{indoit ? "✓": ""}</span>} />
                <Table.Column title="Ruginit" render={({ruginit}) => <span>{ruginit ? "✓": ""}</span>} />
                <Table.Column title="Cost EUR" dataIndex="cost"/>
            </Table>

            <Row>
                <Checkbox onChange={f=>f} checked={stalpi}>Stalpi / Montanti</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={lonjeroane}>Lonjeroane</Checkbox>
            </Row>
            <Row>
                <Checkbox onChange={f=>f} checked={observatii.checked}>Alte avarii / Observatii</Checkbox>
            </Row>
            {observatii.checked &&
                <p>{observatii.text}</p>
            }
        </SectionContainer>
    )
}

const SectionContainer = styled.div`
    border-bottom: 1px dashed black;
    padding: 50px 0 50px 0;

    .ant-checkbox-wrapper {
        padding: 5px;

        span {
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
            font-variant: tabular-nums;
            line-height: 1.5;
            list-style: none;
            font-feature-settings: 'tnum', "tnum";

            text-transform: none;
        }
    }
`