import React from "react"
import { GoogleMap, Marker } from "@react-google-maps/api"

import GoogleMapsLoader from "../../components/GoogleMapsLoader"
import Loading from "../../components/Loading"

const defaultLocation = {
	lat: 45.9237859,
	lng: 22.7775086,
}

const MapComponent = () => {
	return (
		<GoogleMapsLoader
			googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
			loader={<Loading />}
		>
			<GoogleMap
				mapContainerStyle={{
					height: "100%",
					width: "100%",
				}}
				zoom={12}
				center={defaultLocation}
				options={{
					mapTypeControl: false,
				}}
			>
				<Marker
					position={defaultLocation}
					icon={"alchemistLight/img/pin.png"}
				></Marker>
			</GoogleMap>
		</GoogleMapsLoader>
	)
}

export default ({ element }) => {
	return (
		<div className="fieldset-row">
			<p className="form-box-wrap-descrition">{element.title}</p>
			<p className="form-box-wrap-descrition">{element.text}</p>
			<div className="location-box" style={{ height: "15rem" }}>
				<MapComponent />
				<div className="search-location">
					<input type="text" name="" placeholder="Search location" />
				</div>
			</div>
		</div>
	)
}
