import React from 'react'
import ReactDOM from 'react-dom'

import root from 'react-shadow'
import LoadAfter from './LoadAfter'

const LpopUp = ({
    children,
    title="",
    visible=false,
    footer=null,
    disableFooter=false,
    disableHeader=false,
    disableCancel=false,
    onCancel=f=>f,
    okText="Confirm",
    onConfirm = f=>f,
    rawCss=``
}) => {

    return(
        <>
            {ReactDOM.createPortal((
                <>
                    {visible &&
                        <root.div>
                            <LoadAfter links={[
                                'alchemistLight/css/global.css',
                                'alchemistLight/css/icons.css',
                                'fixes.css'
                            ]}>
                                <div className="view-overlay-delete-form" css={rawCss}>
                                    <div className="overlay overlay-delete-form" onClick={onCancel}>
                                        <div className={`overlay-content ${!disableHeader ? "with-header" : ""}`} onClick={e => e.stopPropagation()}>

                                            {!disableHeader && 
                                                <div className="overlay-header">
                                                    <h3>{title}</h3>
                                                </div>
                                            }

                                            <div className="overlay-body">
                                          
                                                    
                                                    {children}
                                                    
                                                    {!disableFooter &&                                                   
                                                        <div className="overlay-actions">
                                                            <button className="button with-icon-right close-overlay" onClick={() => {onConfirm()}}>{okText}</button>
                                                            {/* <button className="button button-link close-overlay" onClick={onCancel}>Cancel</button> */}
                                                        </div>
                                                    }
                                               
                                            </div>
                                            <i className="icon-x close-button-overlay" onClick={onCancel}></i>
                                        </div>
                                    </div>
                                </div>
                            </LoadAfter>
                        </root.div>
                    }
                </>
            ), document.querySelector("#modal"))}
        </>
    )
}

export default LpopUp