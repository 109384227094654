import React from 'react'
import {useParams} from 'react-router-dom'
import {notification} from '../../components/Lnotification'

import API from '../../api'
import {useAppContext} from '../../context'
import LdeleteConfirm from '../../components/LdeleteConfirm'
import uuid from 'uuid/v4'


const DocumentEditor = ({state, setState}) => {

    const {auth:[{access_token}]} = useAppContext()
    const params = useParams()

    const setDocumentChanged = () => setState(state => ({...state, unsavedChanges: true}))

    const onFilesChange = async e => {
        try {
            setState(state => ({...state, uploadingDocument: true}))
            const form = new FormData()
            const file = e.target.files[0]
            e.target.value = ""
            form.append('docs', file)
            await API.post(`forms/${params.id}/documents`, form)
            const {data:{form:{documents:[document]}}} = await API.get(`forms/${params.id}`)
            setState(state => ({...state, uploadingDocument: false, form: {...state.form, document: {...document, key: uuid()} }}))
        } catch(err) {
            setState(state => ({...state, uploadingDocument: false}))
            notification.warning({message: "Failed to add the document!"})
        }
    }

    const onDocumentRemove = async () => {
        try {
            setState(state => ({...state, deletingDocument: true}))
            await API.delete(`forms/${params.id}/documents/${state.form.document._id}`)
            setState(state => ({...state, deletingDocument: false, form: {...state.form, document: null}}))
        } catch(err) {
            setState(state => ({...state, deletingDocument: false}))
            notification.warning({message: 'Failed to delete the document!'})
        }
    }

    return(
        <div className="docs-list">

            <div className="doc-upload">
                <div className="table">
                    <div className="table-cell">
                        <i className="icon-ia-files-upload-a"></i>
                        <h3>Drag &amp; drop your file</h3>
                        <p>or browse for the file</p>

                        <div className="upload-file-btn button button-outline">
                            Browse
                            <input type="file" name="" accept=".docx" onChange={onFilesChange}/>
                        </div>
                    </div>
                </div>
            </div>

            {state.form.document && 
                <div className="scrollbar">
                    <div className="table">
                        <div className="table-cell">

                            <div className="dl-cell active uploaded-doc">
                                <div className="dl-cell-header">
                                    <h3>{state.form.document.filename}</h3>
                                    <ul>
                                        <li>
                                            <LdeleteConfirm
                                                onConfirm={onDocumentRemove}
                                                title="Are you sure you want to delete this document?"
                                                okText="Delete"
                                            >
                                                <a className="tbl-btn trigger-overlay-delete-form" href="#" title="">
                                                    <i className="icon-ia-trash-bold"></i>
                                                </a>
                                            </LdeleteConfirm>
                                        </li>
                                    </ul>
                                </div>

                                <div className="dl-cell-body">
                                    <DocumentEdit 
                                        setDocumentChanged={setDocumentChanged}
                                        document={state.form.document}
                                        formId={params.id}
                                        access_token={access_token}
                                        docsApiLoaded={state.docsApiLoaded}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }

        </div>
      
    )
}

export default DocumentEditor

const DocumentEdit = ({document, formId, access_token, setDocumentChanged, docsApiLoaded = false}) => {

    React.useEffect(() => {
        if(docsApiLoaded) {
            const url = `${process.env.REACT_APP_DOCUMENT_SERVER}/forms/${formId}/documents/${document._id}?token=${access_token}`;
            new window.DocsAPI.DocEditor(document._id, {
                height: "100%",
                documentType: "text",
                document: {
                    url,
                    title: document.name,
                    key: document.key
                },
                editorConfig: {
                    mode: "edit",
                    callbackUrl: url,
                    customization: {
                        forcesave: true,
						"autosave": true,
                    },
                },
                events: {
                    onDocumentStateChange: (e) => {
                        if (e.data) {
                            setDocumentChanged()
                        }
                    }
                }
            })
        }

    }, [docsApiLoaded])

    return (
        <div
            css={`
                > iframe {
                    width: 100%;
                    height: 80vh;
                    border: 0;
                }
            `}
        >
            <div id={document._id} />
        </div>
    )
}