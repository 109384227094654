import React from 'react'

import LivePreview from './LivePreview.fileUpload'
import Editor from './Editor.fileUpload'
import CodeCopy from './CodeCopy.fileUpload'
import View from './View.fileUpload'
import Input from './Input.fileUplopad'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <Editor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,

    default: () => ({
        type: "file upload",
        text: "File Upload Label",
        pinned: {
            status: false
        },
        uploadButton: "Upload File Button",
        fileName: "File Name",
        addFileButton: "Add File Button",
        validations: []
    }),

    availableValidations: ['required', 'max file size', 'min number of files', 'max number of files'],
    validate: () => false,
    group: "ADVANCED",
    icon: "icon-ia-file-upload",
    name: "File Upload",
    view: args => <View {...args}/>
}