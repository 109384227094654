import React from 'react'
import jwt_decode from 'jwt-decode'
import {useHistory} from 'react-router-dom'
import Loading from '../../components/Loading'
import {useAppContext} from '../../context'
import API from '../../api'
import { GoogleLogin } from 'react-google-login';
import MicrosoftLoginButton from '../../components/MicrosoftLoginButton'

const LoginComponent = props => {

    const [state, setState] = React.useState({
        loading: false,
        errors: {},
        username: '',
        password: ''
    }, "LoginForm");

    const {auth: [auth, setAuth], setDefaultState} = useAppContext()

    const history = useHistory();

    React.useEffect(() => {
        if(auth)
            if(history.location.pathname)
                 history.push("/")
    }, [auth]);

    const login = async () => {
        try {
            setState(state => ({...state, loading: true}));
            const errors = {};
            if(state.username === '') errors.username = "Invalid username";
            if(state.password === '') errors.password = "Invalid password";
            setState(state => ({...state, errors}));
            if(Object.keys(errors).length > 0) {
                setState(state => ({...state, loading: false}));
                return
            }

            const { data } = await API.post('/token', { grant_type: 'password', username: state.username, password: state.password }, {
                auth: {username: process.env.REACT_APP_API_KEY, password: process.env.REACT_APP_API_SECRET }
            });
            const { access_token, refresh_token } = data;
            const expirationTime = Date.now() + data.expires_in * 1000;
            const refreshExpirationTime = Date.now() + data.refresh_token_expires_in  * 1000;
            const decodedToken = jwt_decode(access_token);
            setState(state => ({...state, loading: false}));
            setDefaultState()
            setAuth({
                access_token,
                expirationTime,
                refreshExpirationTime,
                refresh_token,
                decodedToken
            })
        } catch(err) {
            const {error} = (err.response && err.response.data) || {response:{data:{}}};
            setState(state => ({...state, loading: false, errors: {
                api: {
                    incorrect_credentials: 'Wrong username or password',
                    access_denied: 'Access Denied',
                    invalid_request: 'Username and Password are required'
                }[error] || 'Something went wrong' 
            }}))

        }
    };

    const responseGoogle = async (response) => {
        try {
            setState(state => ({...state, loading: true}));
            const { data } = await API.post('/token', { grant_type: 'google', idToken:response.tokenId }, {
                auth: {username: process.env.REACT_APP_API_KEY, password: process.env.REACT_APP_API_SECRET }
            });
            const { access_token, refresh_token } = data;
            const expirationTime = Date.now() + data.expires_in * 1000;
            const refreshExpirationTime = Date.now() + data.refresh_token_expires_in  * 1000;
            const decodedToken = jwt_decode(access_token);
            setState(state => ({...state, loading: false}));
            setDefaultState()
            setAuth({
                access_token,
                expirationTime,
                refreshExpirationTime,
                refresh_token,
                decodedToken
            })
        } catch(err) {
            setState(state => ({...state, loading: false, errors: {
                api: (err.response && err.response.status === 409) && 'Wrong username or password' || 'Something went wrong'
            }}))
        }
    }

    const responseError = () => {
        setState(state => ({...state, loading: false, errors: {
            api: "Something went wrong"
        }}))
    }

    
    const microsoftResponseHandler = async (err, data) => {
        try {
            setState(state => ({...state, loading: true, errors: {}}))

            const response = await API.post('/token', 
                { 
                    grant_type: 'microsoft', 
                    code: data.code
                }, 
                {auth: {username: process.env.REACT_APP_API_KEY, password: process.env.REACT_APP_API_SECRET }}
            )

            const { access_token, refresh_token } = response.data
            const expirationTime = Date.now() + response.data.expires_in * 1000
            const refreshExpirationTime = Date.now() + response.data.refresh_token_expires_in  * 1000
            const decodedToken = jwt_decode(access_token)
            setState(state => ({...state, loading: false}))
            setDefaultState()
            setAuth({
                access_token,
                expirationTime,
                refreshExpirationTime,
                refresh_token,
                decodedToken
            })

        } catch(err) {
            setState(state => ({...state, loading: false}))
            const errors = {}
            errors.api = (err.response && err.response.status) && 'Wrong username or password' || 'Something went wrong'
            setState(state => ({...state, errors}))
        }
    }
    
    return(
        <div className="login-tab-content">
            <div className="scrollbar">
                <div className="table">
                    <div className="table-cell">
                        <form className={`form login-form ${Object.keys(state.errors).length > 0 ? 'error-anim' : ''}`} onSubmit={e => {
                            e.preventDefault();
                            login()
                        }}>
                            {state.loading && <Loading color="#39193B"/>}
                            <div className="form-row">
                                <GoogleLogin
                                    clientId={`${process.env.REACT_APP_STORAGE_GOOGLEDRIVE_CLIENT_ID}`}
                                    render={renderProps => (
                                        <button onClick={renderProps.onClick} disabled={renderProps.disabled} type="button" className="button button-outline with-icon-left"><i className="icon-google-round"></i>Use your Google Account</button>
                                            )}
                                    buttonText="Login"
                                    onSuccess={responseGoogle}
                                    onFailure={responseError}
                                />
                            </div>
                            <div className="form-row">
                                <MicrosoftLoginButton authCallBack={microsoftResponseHandler}/>
                            </div>
                            <span className="or">- or -</span>
                            <div className={`form-row ${state.errors.username ? 'error' : ''}`}>
                                <input 
                                    type="email" 
                                    placeholder="Email address"
                                    value={state.username}
                                    onChange={({target:{value:username}}) => setState(state => ({...state, username}))} 
                                />
                            </div>
                            <div className={`form-row ${state.errors.password ? 'error' : ''}`}>
                                <input 
                                    type="password" 
                                    placeholder="Password"
                                    value={state.password}
                                    onChange={({target:{value:password}}) => setState(state => ({...state, password}))}  
                                />
                            </div>
                            <p className="forgot-pass-link">
                                <i className="icon-lock"></i>
                                <a 
                                    href="#" 
                                    onClick={e => {
                                        e.preventDefault();
                                        props.onForgotPassword()
                                    }}
                                >Forgot password</a>
                            </p>
                            {(state.errors.username || state.errors.password) &&
                                <div className="error-msg">Invalid username or password</div>
                            }
                            {(state.errors.api) &&
                                <div className="error-msg">{state.errors.api}</div>
                            }
                            <div className="form-row last">
                                <button className="button with-icon-right" 
                                    onClick={e => {
                                        e.preventDefault();
                                        login()
                                    }}
                                ><i className="icon-ia-arrow-right"></i>Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LoginComponent