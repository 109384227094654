import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import ReactDOM from 'react-dom'
import { dataURLToBlob } from 'blob-util'


export default ({
    onCapture=f=>f,
    children
}) => {
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [size, setSize] = useState({ height: 0, width: 0 });
    

    const getMedia = async () => {
        let constraints = { video: { width: { ideal: 1150 }, height: { ideal: 720 } } }; 

        navigator.mediaDevices.getUserMedia(constraints)
        .then(function(mediaStream) {
            setOpen(true);
            let video = document.querySelector('#video');
            video.srcObject = mediaStream;
            setSize({
                height: mediaStream.getVideoTracks()[0].getSettings().height,
                width: mediaStream.getVideoTracks()[0].getSettings().width
            });
            video.onloadedmetadata = function(e) {
                video.play();
            };
        })
        .catch(function(err) { console.log(err.name + ": " + err.message); });
    };

    const takePicture = () => {
        const canvas = document.getElementById('canvas');
        const context = canvas.getContext('2d');
        console.log(canvas);
        context.drawImage(document.getElementById('video'), 0, 0, canvas.width, canvas.height);
        setImage(canvas.toDataURL())

    };

    const close = () => {
        document.getElementById('video').srcObject.getVideoTracks().forEach(track => track.stop());
        setImage(null);
        setOpen(false)
    };

    const onSave = () => {
        onCapture(
            dataURLToBlob(image)
        );
        
        document.getElementById('video').srcObject.getVideoTracks().forEach(track => track.stop());
        setImage(null);
        setOpen(false)
    };

    const onClear = () => {
        setImage(null)
    };

    return(
        <>
            {React.cloneElement(children, { onClick: getMedia })}
            {ReactDOM.createPortal((
                <>
                    {open &&
                        <Container>
                            <Overlay />
                            <Wrapper>
                                <Header>
                                    <CloseCros className="icon-x" onClick={close} />
                                </Header>
                                <Body>
                                    <Screen id="video" autoplay display={!image} />
                                    <Canvas id="canvas" width={size.width} height={size.height} display={!!image} />
                                </Body>
                                <Footer>
                                    {!!image
                                        ?   <>
                                                <ConfirmButton type="button" onClick={onSave}>Save</ConfirmButton>
                                                <ClearButton type="button" onClick={onClear}>Clear</ClearButton>
                                            </>
                                        :   <ConfirmButton type="button" onClick={takePicture}>Take picture</ConfirmButton>
                                    }
                                </Footer>
                            </Wrapper>
                        </Container>
                    }
                </>
            ), document.querySelector("#modal"))}
        </>
    )
}

const Container = styled.div`
    display: grid;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
`;

const Overlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background: rgba(255,255,255,0.8);
    width: 100%;
    height: 100%;
    animation: ${keyframes`
        0%   { background: rgba(255,255,255,0); }
        100% { background: rgba(255,255,255,0.8) }
    `} 0.5s 1;
`;

const Wrapper = styled.div`
    position: absolute;
    justify-self: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    z-index: 2;
    width: 48rem;
    max-width: 100%;
    box-shadow: 0 0 1rem rgba(0,0,0,.3);
    background: white;
    -webkit-animation: bQJOXK .5s 1;
    animation: bQJOXK .5s 1;
`;

const Body = styled.div`
    width: 100%;
`;

const Footer = styled.div`
    height: 5rem;
    width: 100%;
    margin: 1rem;
`;

const Header = styled.div`
    height: 5rem;
    width: 100%;
`;

const CloseCros = styled.i`
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: .6rem;
    right: .6rem;
    display: block;
    border-radius: 0.3rem;
    font-size: 1.4rem;
    line-height: 4rem;
    text-align: center;
    color: rgba(0,0,0,0.3);
    cursor: pointer;
    -webkit-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    :hover{
        color: #fff;
        background: black;
    }
`;

const ConfirmButton = styled.button`
    min-width: 11rem;
    margin-right: 1.5rem;
    padding-right: 2.5rem;
    height: 4rem;
    display: inline-block;
    font-size: 1.1rem;
    line-height: 3.8rem;
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'Lato', Arial, sans-serif;
    border-radius: 0.3rem;
    border: 1px solid transparent;
    padding: 0 2rem;
    cursor: pointer;
    color: #fff;
    background: #5D2560;
    border-radius: 2rem;
    box-shadow: 0 0.1rem 0.2rem rgba(114,124,245,0);
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    :hover{
        color: #fff;
        background: #39193b;
        box-shadow: 0 0.1rem 0.2rem rgba(0,0,0,0.2);
        text-decoration: none;
    }
`;

const ClearButton = styled(ConfirmButton)`
    :hover{
        text-decoration: underline;
        color: #39193b;
        background: transparent !important;
        border-color: transparent !important;
        padding: 0 !important;
        box-shadow: none;
    }
    color: #39193b;
    background: transparent !important;
    border-color: transparent !important;
    padding: 0 !important;
    box-shadow: none;
   
`;

const Canvas = styled.canvas`
    width: 100%;
    height: auto;
    display: ${({ display }) => display ? "block" : "none"};
    z-index: 3;
`;
const Screen = styled.video`
    display: ${({ display }) => display ? "block" : "none"};
    height: auto;
    width: 100%;
    z-index: 2;
    transform: rotateY(180deg);
`;

