import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText";
import styled from 'styled-components'
import {DatePicker} from "antd";

const ShortAnswer = ({
                         element,
                         answers_by_code,
                         value = ''
                     }) => {

    return (
        <div className="fieldset-row">
            <div className="session-form-row">
                {element.text &&
                <label>{synchronizeText(element.text, answers_by_code)}</label>
                }
                <span className="input-box">{value}
                    {(element.validations.find(validation => validation.validation === 'email two step validation' || validation.validation === 'phone two step validation')) && value &&
                    <Verified/>
                    }
                </span>
            </div>
        </div>
    );
};


const Verified = styled.i.attrs(
    {className: "verified icon-check"}
)`
   margin-left: 2rem;
    vertical-align: middle;
    font-size: 1.5rem;
    `;

export default ShortAnswer;