import React from 'react'
import {useHistory} from 'react-router-dom'
import moment from "moment"
import {notification} from '../../components/Lnotification'
import LdeleteConfirm from '../../components/LdeleteConfirm'
import SendWithEmail from './Form.sendWithEmail'
import SentForms from './Form.sentForms'
import CompletedForms from './Forms.completedForms'
import LcopyToClipboard from '../../components/LcopyToClipboard'

const Form = props => {
    const [state, setState] = React.useState({
        options: false,
        sendWithEmail: false,
        sentUsersModal: false,
        completedFormsModal: false
    }, "form");

    const history = useHistory()

    return (
        <>
            <SendWithEmail
                formId={props.form._id}
                close={() => setState(state => ({...state, sendWithEmail: false}))}
                visible={state.sendWithEmail}
                onSuccess={() => {
                    props.getForms()
                }}
            />
            <SentForms
                form={props.form}
                visible={state.sentUsersModal}
                close={() => setState(state => ({...state, sentUsersModal: false}))}
                onSendSuccess={() => {
                    props.getForms()
                }}
            />
            <CompletedForms
                form={props.form}
                visible={state.completedFormsModal}
                close={() => setState(state => ({...state, completedFormsModal: false}))}
                onSendSuccess={() => {
                    props.getForms()
                }}
            />
            <li>
                <ul>
                    <li><strong>{props.form.name}</strong></li>
                    <li>
                        <strong>{props.form.no_sessions}<span>{Math.round((props.form.no_sessions || 0) * (100 / ((props.form.no_sessions + props.form.sent - props.form.finished) || 1)))}% conversion rate</span></strong>
                    </li>
                    <li>
                        <strong>
                            <span className="link trigger-overlay-users-sent"
                                  onClick={() => setState(state => ({...state, sentUsersModal: true}))}>
                                {props.form.sent}<i className="icon-users"/>
                            </span>
                        </strong>
                    </li>
                    <li>
                        <strong>
                            <span className="link trigger-overlay-users-completed"
                                  onClick={() => setState(state => ({...state, completedFormsModal: true}))}>
                                {props.form.finished}<i className="icon-user-check"/>
                            </span>
                        </strong>
                    </li>
                    <li>
                        <strong>{props.form.last_session ? moment(props.form.last_session).format('D MMM, h:mm') : "-"}<span>({props.form.last_session ? moment(props.form.last_session).format('YYYY') : "-"})</span></strong>
                    </li>
                    <li className={`${(state.options ? "view-row-options" : "")}`}>
                        <div className="table-actions">
                            <div className="tbl-btn trigger-overlay-send-emails" onClick={() => {
                                props.form.empty
                                    ? notification.info({message: "Form is empty, please fill it before sharing with a user"})
                                    : setState(state => ({...state, sendWithEmail: true}))
                            }}><i className="icon-mail"/>
                                <div
                                    className={`tooltip ${(props.index === 0 ? "tooltip-bottom" : "tooltip-top")}`}>Send
                                    emails
                                </div>
                            </div>
                            {!props.form.empty &&
                            <LcopyToClipboard
                                text={`${process.env.REACT_APP_DASHBOARD_URL}/forms/${props.form._id}/sessions/public/new`}
                                feedback={(
                                    <div className="tbl-btn"><i className="icon-ia-checked"/>
                                        <div
                                            className={`tooltip ${(props.index === 0 ? "tooltip-bottom" : "tooltip-top")}`}>Copied!
                                        </div>
                                    </div>
                                )}
                            >
                                <div className="tbl-btn"><i className="icon-link-2"/>
                                    <div
                                        className={`tooltip ${(props.index === 0 ? "tooltip-bottom" : "tooltip-top")}`}>Copy
                                        URL
                                    </div>
                                </div>
                            </LcopyToClipboard>
                            }
                            {props.form.empty &&
                            <div className="tbl-btn"
                                 onClick={() => notification.info({message: "Form is empty, please fill it before sharing with a user"})}>
                                <i className="icon-link-2"/>
                                <div
                                    className={`tooltip ${(props.index === 0 ? "tooltip-bottom" : "tooltip-top")}`}>Copy
                                    URL
                                </div>
                            </div>
                            }
                            <div
                                className="tbl-btn trigger-row-options"
                                onClick={() => setState({...state, options: !state.options})}
                                onBlur={() => setTimeout(() => setState({...state, options: false}), 100)}
                                tabIndex="-1"
                            ><i className="icon-more-horizontal"/>
                                <div
                                    className={`tooltip ${(props.index === 0 ? "tooltip-bottom" : "tooltip-top")}`}>More
                                </div>
                                <ul className="row-options">
                                    <li onClick={() => history.push(`/forms/${props.form._id}/edit/properties`)}><i
                                        className="icon-ia-document"/>Form properties
                                    </li>
                                    <li onClick={() => history.push(`/forms/${props.form._id}/edit/definition`)}><i
                                        className="icon-ia-document"/>Form definition
                                    </li>
                                    <li onClick={() => history.push(`/forms/${props.form._id}/edit/mapping`)}><i
                                        className="icon-ia-document"/>Document mapping
                                    </li>
                                    <LdeleteConfirm
                                        onConfirm={props.deleteForm}
                                        title="Are you sure you want to delete this form?"
                                    >
                                        <li className="trigger-overlay-delete-form"><i className="icon-ia-delete"/>Delete
                                            Form
                                        </li>
                                    </LdeleteConfirm>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </li>
        </>
    )
};

export default Form