import React from 'react'

export const Oppression = ({form, children, element, answers_by_code, setHiddenElement, hiddenElements}) => {
    const calculateVisibility = () => {
        if(!element.dependency) return true;

        if(!element.dependency.visibility) {

            // Oppressor is selected
            if(answers_by_code[element.dependency.element_code]) {
                const oppressor = form.steps
                    .reduce((acc, step) => [...acc, ...step.elements],[])
                    .find(({code}) => element.dependency.element_code === code);

                if(oppressor.type === 'checkbox group') {
                    const optionValue = oppressor.items[element.dependency.option];
                    if(answers_by_code[element.dependency.element_code].includes(optionValue)) {
                        return false
                    } else return true
                }

                else {
                    if(
                        form.steps
                            .reduce((acc, step) => [...acc, ...step.elements],[])
                            .find(({code}) => element.dependency.element_code === code).items
                            .indexOf(answers_by_code[element.dependency.element_code]) === element.dependency.option
                    ) {
                        return false
                    } else return true
                }
            }

            else return true

        }

        else if(element.dependency.visibility) {

            // Oppressor is selected
            if(answers_by_code[element.dependency.element_code]) {
                const oppressor = form.steps
                    .reduce((acc, step) => [...acc, ...step.elements],[])
                    .find(({code}) => element.dependency.element_code === code);

                if(oppressor.type === 'checkbox group') {
                    const optionValue = oppressor.items[element.dependency.option];
                    if(answers_by_code[element.dependency.element_code].includes(optionValue)) {
                        return true
                    } else return false
                }

                else {
                    if(
                        answers_by_code[oppressor.code] === oppressor.items[element.dependency.option]
                    ) {
                        return true
                    } else return false
                }
            }

            else return false
        }

    };

    const visibility = calculateVisibility();

    React.useEffect(() => {
        if(visibility && hiddenElements[element.code]) {
            setHiddenElement({[element.code]:  undefined})
        } else if(!visibility && !hiddenElements[element.code]) {
            setHiddenElement({[element.code]: true})
        }
    }, [answers_by_code]);


    return(
        <>
            {visibility ? children : null}
        </>
    )
};


