import React from 'react'

const StartPage = ({startSession, form}) => {

    return(
        <div className="form-preview">
            <header>
                <div className="form-logo">
                    <img src={form.logo || "alchemistLight/img/Alchemist_Identity_Final-01.png"} />
                </div>
                <h1 className="main-title">{form.name}</h1>
            </header>

            <div className="content-preview">
                <div className="table">
                    <div className="table-cell">
                        <p style={{whiteSpace: 'pre'}}>{form.description}</p>
                    </div>
                </div>
            </div>

            <footer><a className="button" href="#" title="" onClick={e => {
                e.preventDefault()
                startSession()
            }}>Start</a></footer>
        </div>
    )
}

export default StartPage