import React from 'react'
import uuid from 'uuid/v4'
import {notification} from "../../components/Lnotification"

import LdeleteConfirm from '../../components/LdeleteConfirm'
import LpopConfirm from '../../components/LpopConfirm'
import supportedElements from '../supportedElements'
import Validations from '../Validations'
import CodeCopy from '../../components/Editor.codeCopy'
import {read, utils} from 'xlsx'
import Loading from "../../components/Loading"
import Iscroll from '../../components/Iscroll'
import dropdown from '../../formComponents/DropDown/DropDown'

export default ({
    element, 
    remove, 
    editElement, 
    changeElementEditorMode, 
    dragHandleProps = {}, 
    index,
    formElements, 
    VisibilityAffected, 
    ConditionalValidation,
    editElementByCode,
    clearOppressedElements,
    clearOppressedAndShiftAffectedIndexes,
    addElementOnPosition,
    deleteChain
}) => {

    const [state, setState] = React.useState({
        keepDependency: false,
        merge: false,
        duplicates: {}
    });

    const [loading, setLoading] = React.useState(false);

    let excelRef = React.useRef();
    if(!element.items || !(element.items instanceof Array)){
        element.items = [];
    }
    React.useEffect(() => {
        if (!element.chained) {
            const duplicates = {}
            element.items.forEach((item, itemIndex) => {
                if(element.items.includes(item, itemIndex + 1))
                duplicates[item] = true
            })
            setState(state => ({...state, duplicates}))
        }
    }, [element.items])

    const removeItem = itemIndex => {
        clearOppressedAndShiftAffectedIndexes(element.code, itemIndex)
        editElement({...element, items: element.items.filter((_, i) => i !== itemIndex)})
    };

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onPinnedChange = e => {
        editElement({
            ...element,
            pinned: {
                status: e.target.checked
            }
        })
    };

    const onValidationsClick = () => {
        changeElementEditorMode("VALIDATIONS")
    };


    const onSaveClick = () => {
        if(Object.keys(state.duplicates).length > 0) {
            console.log('duplicates', state.duplicates)
            notification.error({
                message: 'Some of the dropdown data is duplicated'
            })
        } else {
            changeElementEditorMode("DEFAULT")
        }
    };

    const onAddOptionClick = () => {
        editElement({...element, items: [...element.items, "Option"]})
    };

    const onEditOption = (index, newValue) => {
        editElement({
            ...element,
            items: element.items.map((item, itemIndex) => itemIndex !== index ? item : newValue)
        })
    };

    const onValidationsChange = newValidations => {
        editElement({
            ...element,
            validations: newValidations
        })
    };

    const createChainedDropdowns = ({names, masterCode}) => {
        names.forEach((name, nameIndex) => {
            addElementOnPosition(index + nameIndex + 1, {
                ...dropdown.default(), 
                code: Date.now().toString(), 
                text: name, 
                items: [],
                chained: {
                    role: 'slave',
                    masterCode,
                    chainIndex: nameIndex
                } 
            })
        })
    }

    const getDropdownData = async input => {
        const merge = state.merge;
        setLoading(true);

        try {
            if(input.target.files[0].size > 1024 * 1024) {
                notification.error({
                    message: 'No files more than 1MB are allowed'
                });
                return setLoading(false);
            }
            // var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
            // let excel = utils.sheet_to_json(first_worksheet, {header:1})

            clearOppressedElements(element.code)

            let files = input.target.files, f = files[0];
            let reader = new FileReader();
            reader.onload = function(e) {
                let data = new Uint8Array(e.target.result);
                let workbook = read(data, {type: 'array'});
                let items = [];
                let first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
                let excel = utils.sheet_to_json(first_worksheet, {header:1, blankrows: false});
                if (excel[0].length > 10) {
                    notification.error({
                        message: 'No more than 10 columns allowed'
                    });
                    return setLoading(false);
                }

                if (!state.keepDependency || excel[0].length === 1) {
                    for (let i = 0; i < excel.length; i++) {
                        items.push(excel[i][0]);
                    }
                    editElement({...element, items: [...element.items, ...items], data: excel});
                } else {
                    //The function that deals with multi column imports
                    let dropdownValues = excel.reduce((r, a, i) => {
                        let last = a.pop();
                        if (merge) {
                            a.pop();
                        }
                        if (i === 1) r = {};
                        a.reduce((items, k, i) => {
                            if (merge && i % 2 === 0) {
                                return items;
                            }
                            let temp = items[k];
                            if (!temp) temp = items[k] = a.length === (i + 1) ? [] : {};
                            return temp;
                        }, r).push(last);
                        return r;
                    });
                    let names = Array((merge ? excel[0].length / 2 : excel[0].length)).fill(element.text);

                    editElement({
                        ...element, 
                        items: Object.keys(dropdownValues), 
                        data: excel, 
                        chained: {
                            role: 'master',
                            structure: dropdownValues
                        }
                    });

                    // ============== 
                    createChainedDropdowns({
                        names: names.slice(1, names.length).map((_, i) => `Dropdown Label ${i + 1}`),
                        masterCode: element.code
                    })
                    // ==============
                }
                setState(state => ({
                    ...state,
                    excelFile: true,
                    items: items
                }));

                /* DO SOMETHING WITH workbook HERE */
            };
            reader.readAsArrayBuffer(f);
            input.target.value = ""
        } catch (err) {
            notification.error({
                message: 'There was an error while importing the provided spreadsheet file'
            });
        }
        setLoading(false)
    };

    const clearUploadedData = () => {
        setLoading(true);

        if(element.chained && element.chained.role === 'master') {
            deleteChain(element.code)
        }

        editElement({
            ...element, 
            items: [],
            data: undefined,
            chained: undefined
        })

        setLoading(false)
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center">
                <div className="form-box-wrap-center-content">
                    <div className="form-row">
                        <label>{element.text}</label>
                        <select disabled>
                            <option/>
                        </select>
                    </div>
                    <VisibilityAffected element={element} formElements={formElements}/>
                    <p className="form-row-validation-text">{element.validations.map(v => `[ ${v.validation} ]  `)}{element.pinned && element.pinned.status ? "[ show on top ]" : ""}</p>
                    <CodeCopy code={element.code}>
                        <span className="form-box-wrap-id"/>
                    </CodeCopy>

                </div>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onValidationsClick}><i className="icon-check trigger-validation-box"/>Validation</span>
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        {(!element.chained || element.chained.role === 'master') &&
                            <LdeleteConfirm
                                onConfirm={() => {
                                    clearOppressedElements(element.code)
                                    element.chained && element.chained.role === 'master' && deleteChain(element.code)
                                    remove()
                                }}
                                okText="Delete"
                                cancelText="Cancel"
                                title="Are you sure you want to delete this component? All data will be lost. "
                            >
                                <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                            </LdeleteConfirm>
                        }
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className="form-row">
                    <input
                        type="text"
                        placeholder="Dropdown Label"
                        value={element.text}
                        onChange={({target:{value:text}}) => editElement({...element, text})}
                    />
                </div>

                <div className="form-row">
                    {loading && <Loading/>}
                    <label>Options list</label>
                    <div className="scrollbar" style={{maxHeight: '65rem'}}>
                        {!element.chained && 

                            <Iscroll allItems={element.items} limit={20}>
                                {({items}) => (
                                    <>
                                        {
                                            items.map((item, itemIndex) => (
                                                <div 
                                                    key={itemIndex} 
                                                    className={`mbi-row with-delete-btn ${state.duplicates[item] ? "error" : ""}`}
                                                >
                                                    <input type="text" name="" value={item} onChange={e => onEditOption(itemIndex, e.target.value)}/>
                                                    <ConditionalValidation oppressorItemIndex={itemIndex} formElements={formElements} oppressorElement={element} editElementByCode={editElementByCode} />
                                                    <LdeleteConfirm
                                                        onConfirm={() => removeItem(itemIndex)}
                                                        okText="Delete"
                                                        cancelText="Cancel"
                                                        title="Are you sure you want to delete this option? All data will be lost. "
                                                    >
                                                        <i className="icon-ia-trash-bold delete-form-row-btn" title="Delete option"/>
                                                    </LdeleteConfirm>
                                                </div>
                                            ))
                                        }
                                    </>
                                )}
                            </Iscroll>
                        }
                    </div>
                    
                    {!element.chained &&
                        <span className="add-block-input" onClick={onAddOptionClick}>Add option</span>
                    }
                    {!element.chained && !element.data && //!state.excelFile &&
                    (
                        <>
                            <br/>
                            <LpopConfirm
                                onConfirm={() => {
                                    excelRef.current.click()
                                }}

                                okText="Import"
                                title={state.keepDependency ? "All edited data will be lost after importing new data!" : ""}
                                body={
                                    <div className="form mco-cell">
                                        {(uniqueName => (
                                            <> 
                                                <div className="form-row-radio">
                                                    {(id => (
                                                        <>
                                                            <input type="radio" id={id} checked={!state.keepDependency && !state.merge}
                                                                onChange={() => setState(state => ({
                                                                    ...state,
                                                                    keepDependency: false,
                                                                    merge: false
                                                                }))}
                                                                name={uniqueName}
                                                            />
                                                            <label htmlFor={id} style={{fontSize: '1.2rem'}}>Import first column's data</label>&nbsp;
                                                            </>
                                                    ))(uuid())}
                                                </div>                                                                             
                                                <div className="form-row-radio">
                                                    {(id => (
                                                        <>
                                                            <input type="radio" id={id} checked={state.keepDependency && !state.merge}
                                                                onChange={() => setState(state => ({
                                                                    ...state,
                                                                    keepDependency: true,
                                                                    merge: false
                                                                }))}
                                                                name={uniqueName}
                                                            />
                                                            <label htmlFor={id} style={{fontSize: '1.2rem'}}>Keep dependency between columns</label>&nbsp;
                                                        </>
                                                    ))(uuid())}
                                                </div>
                                                <div className="form-row-radio">
                                                    {(id => (
                                                        <>
                                                            <input type="radio" id={id} checked={state.merge && state.keepDependency}
                                                                onChange={() => setState(state => ({
                                                                    ...state,
                                                                    merge: true,
                                                                    keepDependency: true
                                                                }))}
                                                                name={uniqueName}
                                                            />
                                                            <label htmlFor={id} style={{fontSize: '1.2rem'}}>Keep dependency and merge columns</label>&nbsp;
                                                        </>
                                                    ))(uuid())}
                                                </div>                
                                            </>
                                        ))(uuid())}
                                    </div>
                                }
                            >
                                <label>
                                    <span className="import-buttons add-block-input">Import options from Excel</span>
                                </label>
                            </LpopConfirm>
                            <input
                                className="upload-image-input"
                                type="file"
                                onChange={getDropdownData}
                                ref={excelRef}
                                style={{display: 'none'}}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
    
                        </>
                    )
                    }
                    {element.data && element.data.length > 0 &&
                        <>
                            <br/>
                            <LdeleteConfirm
                                onConfirm={clearUploadedData}
                                okText="Remove"
                                cancelText="Cancel"
                                title="Are you sure you want to remove the imported data?"
                            >
                                <label>
                                    <span className="import-buttons add-block-input">Clear dropdown</span>
                                </label>
                            </LdeleteConfirm>
                        </>
                    }
                </div>
                <div className="frc-box">
                    <div className="form-row-checkbox">
                        <input id={`pinned-${element.code}`} type="checkbox"
                               checked={element.pinned && element.pinned.status} onChange={onPinnedChange}/>
                        <label htmlFor={`pinned-${element.code}`}>Show on top in saved response</label>
                    </div>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
            <div className="form-box-wrap-center-validation mco-box">
                <div className="mco-cell"><h4>Validations</h4></div>
                <Validations
                    availableValidations={supportedElements[element.type].availableValidations}
                    onChange={onValidationsChange}
                    value={element.validations}
                />
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}