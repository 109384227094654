import React from 'react'

const MicrosoftLoginButton = ({authCallBack}) => {

    const redirect_uri = `${process.env.REACT_APP_DASHBOARD_URL}/storage/onedrive/redirect_uri`
    const scope = 'offline_access openid'
    const client_id = process.env.REACT_APP_STORAGE_ONEDRIVE_CLIENT_ID
    const response_type = 'code'
    const link = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${client_id}&scope=${scope}&response_type=${response_type}&redirect_uri=${redirect_uri}`

    const onButtonClick = () => {
        window.open(link, '_blank', 'height=600,width=450')
        window.addEventListener('message', handleCodemessage)
    }

    const handleCodemessage = event => {
        console.log("event ...", event)
        const {code} = event.data
        if(!code) return
        authCallBack(null, {code})
        window.removeEventListener('message', handleCodemessage)
    }

    return(
        <MicrosoftButton onClick={onButtonClick} />
    )
}


export default MicrosoftLoginButton

const MicrosoftButton = args => {
    return(
        <button {...args} type="button" className="button button-outline with-icon-left" css={`
            svg {
                width: 1.5rem;
                height: 1.5rem;
                > .square-path{fill: #666666;}
                > .big-square-path {fill: transparent;}
            }
            :hover { svg > .square-path {fill: #9C6CFF;}}
            :hover { svg > .big-square-path {fill: #2C2457;}}
            > i{:before {content: '';}}
        `}>
            <i className="icon-google-round">
                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
                    <path className="big-square-path" d="M0 0h23v23H0z"/>
                    <path className="square-path" fill="#f35325" d="M1 1h10v10H1z"/>
                    <path className="square-path" fill="#81bc06" d="M12 1h10v10H12z"/>
                    <path className="square-path" fill="#05a6f0" d="M1 12h10v10H1z"/>
                    <path className="square-path" fill="#ffba08" d="M12 12h10v10H12z"/>
                </svg>
            </i>
           
            Use your Microsoft Account
        </button>
    )
}