import React from 'react'
import {css} from 'styled-components'

import { notification } from '../../components/Lnotification'
import LpopUp from '../../components/LpopUp'
import API from '../../api'
import Loading from '../../components/Loading'

const CompletedForms = ({form, visible=false, close, onSendSuccess=f=>f}) => {

    const [state, setState] = React.useState({
        loadCompletedUsers: false,
        completedUsers: [],
        sendingForm: false,
    });

    React.useEffect(() => {
        if(visible && !state.loadingSentUsers) {
            getFormSentUsers()
        }
    }, [visible]);

    const getFormSentUsers = async () => {
        try {
            setState(state => ({...state, loadCompletedUsers: true}));
            const {data} = await API.get(`/forms/${form._id}/recipients`);
            setState(state => ({...state, completedUsers: data.filter(user => user.finished), loadCompletedUsers: false}))
        }catch(err) {
            setState(state => ({...state, loadCompletedUsers: false}));
            notification.warning({message: "There was an error while getting the list of users"})
        }
    };

    const reSend = async client => {
        try {
            setState(state => ({...state, sendingForm: true}));
            await API.post(`/sessions/form/${form._id}/sendToClient`, {clients: [client]});
            setState(state => ({...state, sendingForm: false}));
            onSendSuccess()
        }catch(err) {
            setState(state => ({...state, sendingForm: false}));
            notification.warning({message: "There was an error while sending the emails to the recipient"})
        }
    };

    return(
        <>
            <LpopUp 
                title={`${state.completedUsers.filter(user => user.finished).length} users completed the sent form`}
                visible={visible}
                onCancel={close}
                disableFooter
                rawCss={css`
                    && {
                        .overlay-body {
                            padding: 0;
                        }
                    }
                `}
            >
                {state.loadCompletedUsers || state.sendingForm && <Loading />}
                {!state.loadCompletedUsers && !state.sendingForm && 
                    <div className="scrollbar emails-list-content">
                        <ul className="table-ul simple-emails-list">
                            {state.completedUsers.map((user, index) =>
                                <li key={index}>
                                    <ul>
                                        <li><strong><i className="icon-user"/></strong></li>
                                        <li><strong>{user._client}</strong></li>
                                        <li>
                                            <div className="table-actions">
                                                <div className="tbl-btn trigger-overlay-send-emails" onClick={() => reSend(user._client)}>
                                                    <i className="icon-mail"/>
                                                    <div className={`tooltip ${(index === 0 ? "tooltip-bottom" : "tooltip-top")}`}>Resend form</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            )}
                        </ul>
                    </div>
                }
            </LpopUp>
        </>
    )
};

export default CompletedForms