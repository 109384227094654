import React from 'react'

export default ({
                    element
                }) => {

    return (
        <div className="fieldset-row">
            <p className="paragraph">{element.title}</p>
            <p className="paragraph">{element.text}</p>
            <div className="mco-cell">
                <div className="mco-cell-header">
                    <div className="form-row-radio">
                        <input type="radio" name="" checked={true}/>
                        <label style={{fontWeight: "300"}}>By mail</label>
                    </div>
                </div>
                <fieldset className="form-grid">
                    <div className="form-grid-row">
                        <div className="form-row">
                            <label>Name</label>
                            <input type="text" name="" placeholder=""/>
                        </div>
                        <div className="form-row">
                            <label>Surname</label>
                            <input type="text" name="" placeholder=""/>
                        </div>
                    </div>
                    <div className="form-grid-row">
                        <div className="form-row">
                            <label>County</label>
                            <select id="">
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                            </select>
                        </div>
                        <div className="form-row">
                            <label>City</label>
                            <select id="">
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-grid-row street-grid">
                        <div className="form-row">
                            <label>Street</label>
                            <input type="text" name="" placeholder=""/>
                        </div>
                        <div className="form-row">
                            <label>No</label>
                            <input type="text" name="" placeholder=""/>
                        </div>
                    </div>
                    <div className="form-grid-row address-grid">
                        <div className="form-row">
                            <label>Building</label>
                            <input type="text" name="" placeholder=""/>
                        </div>
                        <div className="form-row">
                            <label>Entry</label>
                            <input type="text" name="" placeholder=""/>
                        </div>
                        <div className="form-row">
                            <label>Floor</label>
                            <input type="text" name="" placeholder=""/>
                        </div>
                        <div className="form-row">
                            <label>Apartment</label>
                            <input type="text" name="" placeholder=""/>
                        </div>
                    </div>
                    <div className="form-grid-row address-grid">
                        <div className="form-row">
                            <label>Postal Code</label>
                            <input type="text" name="" placeholder=""/>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div className="mco-cell">
                <div className="mco-cell-header">
                    <div className="form-row-radio">
                        <input type="radio" name=""/>
                        <label style={{fontWeight: "300"}}>By email</label>
                    </div>
                    <input style={{display: "none"}} type="text" placeholder="Your email.."/>
                </div>
            </div>
            <div className="mco-cell last">
                <div className="mco-cell-header">
                    <div className="form-row-radio">
                        <input type="radio" name=""/>
                        <label style={{fontWeight: "300"}}>By text message</label>
                    </div>
                    <input style={{display: "none"}} type="text" placeholder=""/>
                </div>
            </div>
        </div>
    )
}