import React from 'react'
import styled, {css} from 'styled-components'
import IpopUp from '../components/IpopUp'
import LpopUp from '../components/LpopUp'
import RichTextViewer from '../components/RichTextViewer'

import supportedElements from './supportedElements'
import {elements} from '../constants/elements'

export default ({
    oppressorElement, 
    oppressorItemIndex,
    formElements = [],
    editElementByCode
}) => {

    const [state, setState] = React.useState({
        show: false
    })
    
    const showEditor = () => setState(state => ({...state, show: true}))

    const hideEditor = () => setState(state => ({...state, show: false}))

    const not_affected_components_filter = element =>
    (
        !supportedElements[element.type] || supportedElements[element.type].group === "GENERAL" ||
        element.type === elements.VIN || element.type === elements.REGISTRATION_NUMBER || element.type === elements.IBAN
    ) &&
    element.code !== oppressorElement.code && // not me
    !element.dependency || // unoppressed ones
    (element.dependency && element.dependency.element_code === oppressorElement.code && element.dependency.option === oppressorItemIndex) // oppresed by me
    
    const subjectElements = formElements.filter(not_affected_components_filter)

    return(
      <>
        <EditButton className="icon-ia-eye-view" onClick={showEditor} title="Show/hide components based on answer"/>
        <LpopUp
            rawCss={css`
                && {
                    .overlay-body {
                        padding: 0;
                    }
                    .overlay-content{
                        width: 45rem;
                    }
                    .overlay-actions {
                        padding: 0 2rem;
                        margin: 0;
                        height: 6rem;
                        display: grid;
                        align-content: center;
                        border-top: 1px dashed rgba(0,0,0,.1);
                    }
                }
            `}
            visible={state.show}
            onCancel={hideEditor}
            styles={{body:{padding: 0}}}
            onConfirm={hideEditor}
            okText="Save"
        >
            <div className="scrollbar" style={{maxHeight: '60vh', padding: '3rem 2rem'}}>
                {subjectElements.map(element => (
                    <SubjectElement
                        key={element.code} 
                        editElement={edited => editElementByCode(element.code, edited)}
                        element={element}
                        oppressorElement={oppressorElement}
                        oppressorItemIndex={oppressorItemIndex}
                    />
                ))}
                {subjectElements.length === 0 && <p>You don’t have any components available in the form for this setting. Please add some extra components. </p>}
            </div>
        </LpopUp>
      </>
    )
}

const SubjectElement = ({editElement=f=>f, element, oppressorElement, oppressorItemIndex}) => {

    const status = !element.dependency ? 0 : element.dependency.visibility ? 1 : 2

    const setRuleShow = () => editElement({
        ...element,
        dependency: {
            visibility: true,
            element_code: oppressorElement.code,
            option: oppressorItemIndex
        } 
    })

    const setRuleHide = () => editElement({
        ...element,
        dependency: {
            visibility: false,
            element_code: oppressorElement.code,
            option: oppressorItemIndex
        } 
    })

    const setRuleNoAction = () => editElement({
        ...element,
        dependency: undefined 
    })

    const activeStyle = css`
        && {
            background: var(--primary-color); 
            color:#fff; 
            box-shadow:0 0.1rem 0.2rem rgba(114,124,245,0);
            >i {
                color: #fff;
            }
        }
    `

    return(
        <>
            <div className="form-box-wrap">
                <div className="form-box-wrap-center" css={`
                    display: grid;
                    grid-template-columns: auto min-content;
                    padding: 0;
                `}>
                    <div className="form-box-wrap-center-content scrollbar" css={`
                        padding: 1rem;
                        overflow-x: auto;
                        ::-webkit-scrollbar {
                            height: 0.4rem;
                        }
                    `}>
                        <RichTextViewer css={`
                            display: grid;
                            align-items: center;
                        `}>
                            {element.text}
                        </RichTextViewer>
                    </div>

                    <>
                        <div className="form-box-wrap-options-buttons" css={`    
                            display: grid;
                            grid-template-columns: min-content min-content min-content;
                            position: inherit;
                        `}>
                            <span onClick={setRuleShow} className="txt2rows" css={status === 1 && activeStyle}><i className="icon-ia-eye-view"></i>Show<br />Components</span>
                            <span onClick={setRuleHide} className="txt2rows" css={status === 2 && activeStyle}><i className="icon-ia-eye-hide"></i>Hide<br />Components</span>
                            <span onClick={setRuleNoAction} css={status === 0 && activeStyle}><i className="icon-ia-close trigger-close-box"></i>No action</span>
                        </div>
                    </>

                </div>
            </div>
        </>
    )
}


export const VisibilityAffected = ({formElements, element}) => {
    return (
        <>
            {element.dependency && 
                <i 
                    className="icon-ia-eye-view" 
                    css={`position: absolute; right: 2.5rem; top: 1rem; font-size: 2.1rem; cursor: pointer;`}
                    title={`Visibility affected by rules set on: ${formElements.find(e => e.code === element.dependency.element_code).text}`}
                />
            }
        </>
    )
}



const EditButton = styled.i`
  position: absolute;
  right: 3.6rem;
  top: 50%;
  transform: translate(0,-50%);
  margin-top: 0;

  width: 3.6rem;
  height: 3.6rem;
  display: inline-block;
  font-size: 2rem;
  line-height: 3.4rem;
  border-radius: 0.3rem;
  border: 1px solid transparent;
  cursor: pointer;
  color: var(--primary-color);
  background: transparent;
  transition: all 0.3s ease-in-out;
  text-align: center;

  :hover {
    color: #fff;
    background: var(--primary-color);
    box-shadow: 0 0.1rem 0.2rem rgba(114,124,245,0.2);
    text-shadow: rgba(1,12,118,.3);
    text-decoration: none;
  }
`