import React from 'react'

import LivePreview from './LivePreview.paragraph'
import Editor from './Editor.paragraph'
import CodeCopy from './CodeCopy.paragraph'
import View from './View.paragraph'
import Input from './Input.paragraph'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <Editor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,

    default: () => ({
        type: "paragraph",
        text: "<p style='font-size: 1.2rem;'>This is a paragraph</p>",
        pinned: {
            status: false
        },
        validations: []
    }),
    
    group: "GENERAL",
    icon: "icon-ia-text",
    name: "Paragraph",
    view: args => <View {...args}/>
}