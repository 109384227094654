import React from 'react'
import {useParams, useLocation} from 'react-router-dom'

const AuthorizationRedirectPage = () => {
    const provider = useParams().provider
    const params = new URLSearchParams(useLocation().search)
    const code = params.get('code')

    React.useEffect(() => {
        window.opener.postMessage({code, provider})
        window.close()
    }, [])
    return null
}

export default AuthorizationRedirectPage