import React from 'react'
import TabletPreview from '../../components/TabletPreview'
import supportedElements from '../../formComponents/supportedElements'
import Loading from '../../components/Loading'

const Sidebar = ({state}) => {

    return(

        <div className="sidebar">

            <div className="sidebar-title">
                <h3 className="sidebar-title sidebar-title-fixed">Preview</h3>
            </div>

            <div className="swiper-container pts-top">
                <div className="tablet-preview">
                    <img src="./alchemistLight/img/tablet.svg" />
                    <TabletPreview>
                        {state.loadingForm && <Loading />}
                        {!state.loadingForm && state.form &&
                             <div className="wrapper fixed-elements">
                                <header className="header">
                                    <div className="logo">
                                        <img className="white-logo" src="/alchemistLight/img/Alchemist_Identity_Final-17.png" alt="" />
                                    </div>
                                </header>
                                <div className="wrapper-content">
                                    <div className="table">
                                        <div className="table-cell">
                                            <div className="content">
                                                <form className="form">
                                                
                                                {state.form.heading &&                                                
                                                    <div className="fieldset-row fieldset-row-title">
                                                        <h1 className="main-title">{state.form.heading}</h1>
                                                    </div>
                                                }

                                                {/* <div className="fieldset-row">
                                                    <p className="paragraph">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
                                                </div> */}
                                                    
                                                    {state.form.elements.map(element => 
                                                        <React.Fragment key={element.code}>
                                                            {supportedElements[element.type].livePreview({element})}
                                                        </React.Fragment>        
                                                    )}
    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                          
                        }
                    </TabletPreview>
                </div>
            </div>

        </div>
    )
}

export default Sidebar