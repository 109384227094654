import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({ 
    element
}) => {
    return(
        <div className="fieldset-row">
            <p>{element.text}</p>
            <div className="form-grid">

                <div className="form-grid-row">
                    <div className="form-row">
                        <label>Name</label>
                        <CodeCopy text={`${element.code}_first_name`}>
                            <input type="text" value="" name="first_name" onChange={f=>f} />
                        </CodeCopy>
                    </div>
                    <div className="form-row">
                        <label>Surname</label>
                        <CodeCopy text={`${element.code}_last_name`}>
                            <input type="text" value="" name="last_name" onChange={f=>f} />
                        </CodeCopy>
                    </div>
                </div>

                <div className="form-grid-row">
                    <div className="form-row">
                        <label>County</label>
                        <CodeCopy text={`${element.code}_country`}>
                            <select value="" name="country" onChange={f=>f}>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                            </select>
                        </CodeCopy>
                    </div>
                    <div className="form-row">
                        <label>City</label>
                        <CodeCopy text={`${element.code}_locality`}>
                            <select value="" name="locality" onChange={f=>f}>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                                <option value="">Select Option</option>
                            </select>
                        </CodeCopy>
                    </div>
                </div>
                <div className="form-grid-row street-grid">
                    <div className="form-row">
                        <label>Street</label>
                        <CodeCopy text={`${element.code}_street`}>
                            <input type="text" value="" name="street" onChange={f=>f} />
                        </CodeCopy>
                    </div>
                    <div className="form-row">
                        <label>No</label>
                        <CodeCopy text={`${element.code}_nr`}>
                            <input type="text" value="" name="nr" onChange={f=>f} />
                        </CodeCopy>
                    </div>
                </div>
                <div className="form-grid-row address-grid">
                    <div className="form-row">
                        <label>Building</label>
                        <CodeCopy text={`${element.code}_block_value`}>
                            <input type="text" value="" name="block_value" onChange={f=>f} />
                        </CodeCopy>
                    </div>
                    <div className="form-row">
                        <label>Entry</label>
                        <CodeCopy text={`${element.code}_entry`}>
                            <input type="text" value="" name="entry" onChange={f=>f} />
                        </CodeCopy>
                    </div>

                    <div className="form-row">
                        <label>Floor</label>
                        <CodeCopy text={`${element.code}_floor`}>
                            <input type="text" value="" name="floor" onChange={f=>f} />
                        </CodeCopy>
                    </div>

                    <div className="form-row">
                        <label>Apartment</label>
                        <CodeCopy text={`${element.code}_apartment`}>
                            <input type="text" value="" name="apartment" onChange={f=>f} />
                        </CodeCopy>
                    </div>
                </div>
                <div className="form-grid-row address-grid">
                    <div className="form-row">
                        <label>Postal Code</label>
                        <CodeCopy text={`${element.code}_postal_code`}>
                            <input type="text" value="" name="postal_code" onChange={f=>f} />
                        </CodeCopy>
                    </div>
                </div>
            </div>
        </div>
    )
}