import React from 'react'
import camera from '../../images/camera.svg'

export default ({
    element
}) => {
    return (
        <div className="fieldset-row">
            <label>{element.text}</label>
            <img style={{ maxWidth: '100%', height: "auto" }} src={camera}/>
        </div>
    )
}