import React from 'react'

const Portrait = () => (
    <div className="portrait">
        <div className="table">
            <div className="table-cell">
                <div className="logo">
                    <img src="alchemistLight/img/Alchemist_Identity_Final-17.png" />
                </div>
                <p>Please turn your device<br />to landscape</p>
            </div>
        </div>
    </div>
)

export default Portrait