import React from 'react'
import { VALIDATION } from '../../constants/validations'
import styled from 'styled-components'
import bytes from 'bytes'
import LdeleteConfirm from "../../components/LdeleteConfirm"
import {synchronizeText} from "../../utils/synchronizeText"

export default ({
    element,
    value = [],
    changeValue,
    answers_by_code,
    errors = [],
    setErrors
}) => {

    let max_no_files = 10;
    let max_file_size = bytes('70MB');

    if (element.validations) {
        const validation_values = getFileUploadValidations(element.validations, answers_by_code);

        if (validation_values.max_no_files) {
            max_no_files = validation_values.max_no_files;
        }

        if (validation_values.max_file_size) {
            max_file_size = validation_values.max_file_size;
        }
    }

    let uploader;

    const setValue = (value) => {
        changeValue(value)
    };

    const clickUpload = () => {
        uploader.click();
    };

    const uploadFiles = e => {
        setErrors(undefined)
        if (value.length + e.target.files.length > max_no_files) {
            setErrors(['max number of files'])
            e.target.value = null;
            return;
        }

        const current_filenames = value.map(file => file.name);

        for (let i = 0; i < e.target.files.length; i++) {
            if (current_filenames.includes(e.target.files[i].name)) {
                setErrors(['duplicated name'])
                e.target.value = null;
                return;
            }

            if (e.target.files[i].size > max_file_size) {
                setErrors(['max file size'])
                e.target.value = null;
                return;
            }
        }

        const file_list = [...value];

        for(let i = 0; i < e.target.files.length; i++) {
            file_list.push(e.target.files[i]);
        }

        setValue(file_list);
        e.target.value = null;
    };

    const uploadFile = (file_index) => (e) => {
        setErrors(undefined)
        const current_filenames = value.filter((_, index) => index !== file_index).map(file => file.name);

        for (let i = 0; i < e.target.files.length; i++) {
            if (current_filenames.includes(e.target.files[i].name)) {
                setErrors(['duplicated name'])
                e.target.value = null;
                return;
            }

            if (e.target.files[i].size > max_file_size) {
                setErrors(['max file size'])
                e.target.value = null;
                return;
            }
        }

        const file_list = [...value];

        file_list[file_index] = e.target.files[0];

        setValue(file_list);
        e.target.value = null;
    };

    const changeFileName = (e) => {
        setValue(value.map((file, index) => {
            if (parseInt(e.target.name) === index) {
                return new File([file], e.target.value, {type: file.type});
            }

            return file;
        }));
    };

    const removeFile = (index) => () => {
        const file_list = value.filter((file, i) => i !== index);

        setValue(file_list);
    };

    return (
        <div className="fieldset-row form-grid">
            <div className="multiple-files-upload">
                {element.text &&  <label>{synchronizeText(element.text, answers_by_code)}</label>}
                {value.map((file, index) => (
                    <div key={index} className="upload-image-grid">
                        <UploadButton className="upload-image-box doc-upload" uploadButton={element.uploadButton}>
                            <input className="upload-image-input" type="file" onChange={uploadFile(index)} />
                        </UploadButton>
                        <div className="form-row with-delete-btn">
                            <label htmlFor="img-caption">{element.fileName}</label>
                            <input name={`${index}`} value={file.name} onChange={changeFileName} />
                            <LdeleteConfirm
                                title="Are you sure you want to delete the file?"
                                onConfirm={removeFile(index)}
                                okText="Delete"
                            >
                                <i className="icon-ia-trash-bold delete-form-row-btn"/>
                            </LdeleteConfirm>
                            <span className="file-size">size: {bytes(file.size)}</span>
                        </div>
                    </div>
                ))}
            </div>

            <span className="add-block-input mar2b" onClick={clickUpload}>{element.addFileButton}</span>

            <input style={{display: 'none'}} ref={ref => uploader = ref} type="file" onChange={uploadFiles} multiple />

            {errors.length > 0 &&
                <div className={`form-row ${(errors.length > 0) ? 'error' : '' }`}>
                    {errors.map((error, i) => 
                        ({
                            required: () => <span key={error} className="form-row-error-msg">This field is required</span>,
                            'max file size': () => {
                                const maxFileSize = bytes(element.validations.find(({validation}) => validation === 'max file size').value)
                                return <span key={error} className="form-row-error-msg">File exceeds {maxFileSize}bytes</span>
                            },
                            'max number of files': () => {
                                const maxNrFiles = element.validations.find(({validation}) => validation === 'max number of files').value
                                return <span key={error} className="form-row-error-msg">You can not add more than {maxNrFiles} files</span>
                            },
                            'min number of files': () => {
                                const minNrFiles = element.validations.find(({validation}) => validation === 'min number of files').value
                                return <span key={error} className="form-row-error-msg">Minimum {minNrFiles} {minNrFiles > 1 ? 'files' : 'file' }</span>
                            },
                            'duplicated name': () => {
                                return  <span key={error} className="form-row-error-msg">The document name already exists</span>
                            }
                        })[error]()
                    )}
                </div>
            }
        </div>
    );
}

export const validate = (validations, value) => {
    let errors = validations.reduce((acc, validation) => {
        if(validation.validation === 'required') {
            if(value.length < 1) acc.push('required')
        }
        else if(validation.validation === 'min number of files') {
            if(value.length > 0 && value.length < parseInt(validation.value)) {
                acc.push('min number of files')
            }
        }
        return acc
    }, [])
    if(errors.length > 0) return errors
}

const getFileUploadValidations = (validations, answers_by_code) => {
    const result = {};

    validations.forEach(validation => {
        addValidation(validation, result)
    });

    return result;
};

const addValidation = (validation, result) => {
    if (validation.validation === VALIDATION.MAX_FILE_SIZE) {
        result.max_file_size = bytes(validation.value);
    } else if (validation.validation === VALIDATION.MAX_NUMBER_OF_FILES) {
        result.max_no_files = parseInt(validation.value);
    }
};

const renderErrors = (errors) => {
    if (errors.length === 0)
        return null;

    return errors.map(error => {
        const error_array = error.split('|');
        switch(error_array[0]) {
            case 'required':
                return 'Required';
            case 'min number of files':
                return `Minimum ${error_array[1]} ${error_array[1] > 1 ? 'files' : 'file' }`;
            case 'max number of files':
                return `You can not add more than ${error_array[1]} files`;
            case 'max file size':
                return `File exceeds  ${error_array[1]}`;
        }
    }).map(error => <span key={error} className="form-row-error-msg">{error}</span>)
};

const UploadButton = styled.div`
    ::before {
        content: "${({ uploadButton }) => uploadButton}" !important;
    }
`