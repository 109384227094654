import React from 'react'

import Frame from './Frame'
import LoadAfter from './LoadAfter'
import Loading from './Loading'

const TabletPreview = ({children}) => {
    return (
        <Frame
            head={
                <>
                    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                    <meta name="viewport" content="user-scalable=no, initial-scale=1.0, maximum-scale=1.0, width=device-width" />
                    <meta name="robots" content="index, follow" />
                    <meta name="language" content="ro" />
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="mobile-web-app-capable" content="yes" />
                    <title>New Form Step 1</title>
                </>
            }
        >
            <LoadAfter 
                    links={[
                        'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i',
                        '/alchemistLight/css/icons.css',
                        '/alchemistLight/formular/css/swiper.min.css',
                        '/alchemistLight/formular/css/global.css',
                        '/alchemistLight/formular/css/preview.css'
                    ]}
                    loader={<Loading/>}
                >
                    {children}
             </LoadAfter>
        </Frame>
    )   
}

export default TabletPreview