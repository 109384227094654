import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({
    element
}) => {
    return (
        <div className="fieldset-row">
            <CodeCopy text={element.code}>
                <p className="paragraph">{element.text}</p>

                <p className="paragraph">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
                  
                <ul className="feedback-component">
                    <li><i className="icon-feedback-icon-1"/><span>Worst</span></li>
                    <li><i className="icon-feedback-icon-2"/><span>Poor</span></li>
                    <li><i className="icon-feedback-icon-3"/><span>Average</span></li>
                    <li className="active"><i className="icon-feedback-icon-4"/><span>Good</span></li>
                    <li><i className="icon-feedback-icon-5"/><span>Excellent</span></li>
                </ul>
                <div className="form-row">
                    <p className="form-box-wrap-descrition">Mentions</p>
                    <textarea />
                </div>
            </CodeCopy>
        </div>     
    )
}

