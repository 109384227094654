import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({ 
    element
}) => {

    return (
        <div className="fieldset-row">
            <p>{element.text}</p>
            <CodeCopy text={`#${element.code}}{item}{/${element.code}`}>
                <div className="form-grid" style={{ gridTemplateColumns: `repeat(${element.grid_columns}, 1fr)` }}>
                    {element.items.map((item, itemIndex) => (
                        <div className="form-row-checkbox" key={itemIndex}>
                            <input  type="checkbox" checked={false} onChange={f=>f} />
                            <label>{item}</label>
                        </div>
                    ))}
                </div>
            </CodeCopy>
        </div>
    )
}