import React from 'react'

import Loading from '../../components/Loading'
import API from '../../api'
import { GoogleLogin } from 'react-google-login';
import jwt_decode from "jwt-decode";
import AppContext from "../../context";
import MicrosoftLoginButton from '../../components/MicrosoftLoginButton'

const RegisterComponent = () => {
    const [state, setState] = React.useState({
        surname: '',
        name: '',
        email: '',
        password: '',
        loading: false,
        errors: {}
    }, "Register");

    const {auth: [, setAuth], setDefaultState} = React.useContext(AppContext);

    const onRegisterClick = async () => {
        try {
            setState(state => ({...state, loading: true}));

            const errors = {};
            if(state.surname === '') errors.surname = "Invalid surname";
            if(state.name === '') errors.name = "Invalid name";
            if(state.email === '') errors.email = "Invalid email";
            if(state.password === '') errors.password = "Invalid password";

            setState(state => ({...state, errors}));

            if(Object.keys(errors).length > 0) {
                setState(state => ({...state, loading: false}));
                return
            }

            await API.post(
                '/users', 
                { surname: state.surname, name: state.name, email: state.email, password: state.password }, 
                { auth: {username: process.env.REACT_APP_API_KEY, password: process.env.REACT_APP_API_SECRET }}
            )

            const { data } = await API.post('/token', { grant_type: 'password', username: state.email, password: state.password }, {
                auth: {username: process.env.REACT_APP_API_KEY, password: process.env.REACT_APP_API_SECRET }
            })
            const { access_token, refresh_token } = data;
            const expirationTime = Date.now() + data.expires_in * 1000;
            const decodedToken = jwt_decode(access_token);
            setDefaultState()
            setAuth({
                access_token,
                expirationTime,
                refresh_token,
                decodedToken
            })
        } catch(err) {
            const {error} = err.response.data || {error: undefined};
            const errors = {};

            if(error === 'email_taken') errors.email = 'Email not available';
            if(!error) errors.api = 'Something went wrong';

            setState(state => ({...state, loading: false, errors}))
        }
    };

    const responseGoogle = async (response) => {
        try {
            setState(state => ({...state, loading: true}));
            const { data } = await API.post('/token', { grant_type: 'google', idToken:response.tokenId }, {
                auth: {username: process.env.REACT_APP_API_KEY, password: process.env.REACT_APP_API_SECRET }
            });
            const { access_token, refresh_token } = data;
            const expirationTime = Date.now() + data.expires_in * 1000;
            const refreshExpirationTime = Date.now() + data.refresh_token_expires_in  * 1000;
            const decodedToken = jwt_decode(access_token);
            setState(state => ({...state, loading: false}));
            setDefaultState()
            setAuth({
                access_token,
                expirationTime,
                refreshExpirationTime,
                refresh_token,
                decodedToken
            })
        } catch(err) {
            setState(state => ({...state, loading: false, errors: {
                api: (err.response && err.response.status === 409) ? 'Wrong username or password' : 'Something went wrong'
            }}))
        }
    }

    const responseError = () => {
        setState(state => ({...state, loading: false, errors: {
            api: "Something went wrong"
        }}))
    }

    const microsoftResponseHandler = async (err, data) => {
        try {
            setState(state => ({...state, loading: true, errors: {}}))

            const response = await API.post('/token', 
                { 
                    grant_type: 'microsoft', 
                    code: data.code
                }, 
                {auth: {username: process.env.REACT_APP_API_KEY, password: process.env.REACT_APP_API_SECRET }}
            )

            const { access_token, refresh_token } = response.data
            const expirationTime = Date.now() + response.data.expires_in * 1000
            const refreshExpirationTime = Date.now() + response.data.refresh_token_expires_in  * 1000
            const decodedToken = jwt_decode(access_token)
            setState(state => ({...state, loading: false}))
            setDefaultState()
            setAuth({
                access_token,
                expirationTime,
                refreshExpirationTime,
                refresh_token,
                decodedToken
            })

        } catch(err) {
            setState(state => ({...state, loading: false}))
            const errors = {}
            errors.api = (err.response && err.response.status) && 'Wrong username or password' || 'Something went wrong'
            setState(state => ({...state, errors}))
        }
    }

    return(
        <div className="login-tab-content">
            <div className="scrollbar">
                <div className="table">
                    <div className="table-cell">
                        <form className={`form login-form ${Object.keys(state.errors).length > 0 ? 'error-anim' : ''}`} 
                            onSubmit={e => {
                                e.preventDefault();
                                onRegisterClick()
                            }}
                        >
                            {state.loading && <Loading color="#39193B"/>}
                            <div className="form-row">
                                <GoogleLogin
                                    clientId={`${process.env.REACT_APP_STORAGE_GOOGLEDRIVE_CLIENT_ID}`}
                                    render={renderProps => (
                                        <button onClick={renderProps.onClick} disabled={renderProps.disabled} type="button" className="button button-outline with-icon-left"><i className="icon-google-round"></i>Use your Google Account</button>
                                    )}
                                    buttonText="Login"
                                    onSuccess={responseGoogle}
                                    onFailure={responseError}
                                />
                            </div>
                            <div className="form-row">
                                <MicrosoftLoginButton authCallBack={microsoftResponseHandler}/>
                            </div>
                            <span className="or">- or -</span>
                            <div className={`form-row ${state.errors.name ? 'error' : ''}`}>
                                <input 
                                    type="text" 
                                    placeholder="Name"
                                    value={state.name}
                                    onChange={({target:{value}}) => setState(state => ({...state, name: value}))}  
                                />
                            </div>
                            <div className={`form-row ${state.errors.surname ? 'error' : ''}`}>
                                <input 
                                    type="text" 
                                    placeholder="Surname"
                                    value={state.surname}
                                    onChange={({target:{value}}) => setState(state => ({...state, surname: value}))} 
                                />
                            </div>
                            <div className={`form-row ${state.errors.email ? 'error' : ''}`}>
                                <input 
                                    type="text" 
                                    placeholder="Email address"
                                    value={state.email}
                                    onChange={({target:{value}}) => setState(state => ({...state, email: value}))} 
                                />
                            </div>
                            <div className={`form-row ${state.errors.password ? 'error' : ''}`}>
                                <input
                                    type="password" 
                                    placeholder="Password" 
                                    value={state.password}
                                    onChange={({target:{value}}) => setState(state => ({...state, password: value}))} 
                                />
                            </div>
                            {Object.keys(state.errors).length > 0 &&
                                <div className="error-msg">{Object.values(state.errors)[0]}</div>
                            }
                            <div className="form-row last">
                                <button className="button with-icon-right"><i className="icon-ia-arrow-right"></i>Register</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RegisterComponent