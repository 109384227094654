import React from 'react'
import LdeleteConfirm from '../../components/LdeleteConfirm';
import supportedElements from '../supportedElements'
import Validations from '../Validations'
import CodeCopy from '../../components/Editor.codeCopy'
import styled from 'styled-components';

export default ({element, remove, editElement, changeElementEditorMode, dragHandleProps = {}}) => {

    const removeOffer = index => {
        editElement({...element, offers: element.offers.filter((_, i) => i !== index)})
    };

    const addOffer = () => {
        const newOffer = {
            image: null,
            title: "Item Title",
            description: "Item Description"
        };
        editElement({...element, offers: [...element.offers, newOffer]})
    };

    const onOfferImageChange = (offerIndex, e) => {
        if (e.target.files.length === 0) return;
        let reader = new FileReader();
        reader.addEventListener('load', () => {

            editElement({
                ...element,
                offers: element.offers.map((offer, index) => index !== offerIndex
                    ? offer
                    : ({...offer, image: reader.result})
                )
            })

        });
        reader.readAsDataURL(e.target.files[0])
    };

    const onOfferTitleChange = (offerIndex, e) => {
        editElement({
            ...element,
            offers: element.offers.map((offer, index) => index !== offerIndex
                ? offer
                : ({...offer, title: e.target.value})
            )
        })
    };

    const onOfferDescriptionChange = (offerIndex, e) => {
        editElement({
            ...element,
            offers: element.offers.map((offer, index) => index !== offerIndex
                ? offer
                : ({...offer, description: e.target.value})
            )
        })
    };

    const onEditTitle = e => {
        editElement({
            ...element,
            title: e.target.value
        })
    };

    const onEditText = e => {
        editElement({...element, text: e.target.value})
    };

    const onPinnedChange = e => {
        editElement({
            ...element,
            pinned: {
                status: e.target.checked
            }
        })
    };

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onValidationsClick = () => {
        changeElementEditorMode("VALIDATIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        changeElementEditorMode("DEFAULT")
    };

    const onValidationsChange = newValidations => {
        editElement({
            ...element,
            validations: newValidations
        })
    };

    const onAllowMultipleSelectionChange = ({target: {value: allowMultipleSelections}}) => {
        editElement({
            ...element,
            allowMultipleSelections: allowMultipleSelections !== "false"
        })
    };

    return (
        <div
            className={`form-box-wrap offers-box ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center">
                <div className="form-box-wrap-center-content">
                    <h6 className="supTitle">Items List</h6>
                    <p className="form-box-wrap-descrition">{element.title}</p>
                    <p className="form-box-wrap-descrition">{element.text}</p>
                    <div className="form-grid" style={{gridTemplateColumns: "repeat(2, 1fr)"}}>
                        {element.offers.map((offer, offerIndex) => (
                            <div className="offer-row" key={offerIndex}>
                                <div className="upload-image-grid">
                                    <div className="upload-image-box" style={offer.image ? {backgroundImage: "none"} : {}}><img src={offer.image} alt=""/></div>
                                    <p>{offer.title}</p>
                                    <p>{offer.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <p className="form-row-validation-text">{element.validations.map(v => `[ ${v.validation} ]  `)}{element.pinned && element.pinned.status ? "[ show on top ]" : ""}</p>
                    <CodeCopy code={element.code}>
                        <span className="form-box-wrap-id"/>
                    </CodeCopy>
                </div>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onValidationsClick}><i className="icon-check trigger-validation-box"/>Validation</span>
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <LdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </LdeleteConfirm>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                {/*<div className="form-row-checkbox">*/}
                {/*    <input*/}
                {/*        id={`multiple-selection-${element.code}`}*/}
                {/*        type="checkbox"*/}
                {/*        checked={element.allowMultipleSelections}*/}
                {/*        onChange={onAllowMultipleSelectionChange}*/}
                {/*    />*/}
                {/*    <label htmlFor={`multiple-selection-${element.code}`}>Multiple selection</label>*/}
                {/*</div>*/}
                <div className="form-row">
                    <input type="text" placeholder="Items Select List With Images Title" onChange={onEditTitle} value={element.title}/>
                </div>
                <div className="form-row">
                    <input type="text" placeholder="Placeholder Title Description" onChange={onEditText} value={element.text}/>
                </div>
                {element.offers.map((offer, offerIndex) => (
                    <EditOfferRow className="edit-offer-row" key={offerIndex}>
                        <div className="upload-image-grid">
                            <div className={`upload-image-box ${(offer.image ? 'with-image' : '')}`}>
                                <img src={offer.image}/>
                                <input
                                    className="upload-image-input"
                                    type="file"
                                    accept="image/*"
                                    onChange={e => onOfferImageChange(offerIndex, e)}
                                />
                            </div>
                            <div className="form-row">
                                <input type="text" placeholder="Item Title" value={offer.title}
                                       onChange={e => onOfferTitleChange(offerIndex, e)}/>
                            </div>
                            <div className="form-row">
                                <input type="text" placeholder="Item Description" value={offer.description}
                                       onChange={e => onOfferDescriptionChange(offerIndex, e)}/>
                            </div>
                        </div>
                        <LdeleteConfirm
                            title="Are you sure you want to delete the offer? All data will be lost."
                            onConfirm={() => removeOffer(offerIndex)}
                            okText="Delete"
                            cancelText="Cancel"
                        >
                            <i className="delete-offer-row icon-ia-trash-bold"/>
                        </LdeleteConfirm>
                    </EditOfferRow>
                ))}
                {element.offers.length < 30 &&
                <span className="add-block-input mar2b" onClick={addOffer}>Add Item</span>
                }
                <div className="frc-box">
                    <div className="form-row-checkbox">
                        <input id={`pinned-${element.code}`} type="checkbox"
                               checked={element.pinned && element.pinned.status} onChange={onPinnedChange}/>
                        <label htmlFor={`pinned-${element.code}`}>Show on top in saved response</label>
                    </div>
                </div>
                <div className="form-row">
                    <select onChange={onAllowMultipleSelectionChange} value={element.allowMultipleSelections}>
                        <option value="false">
                            Single selection
                        </option>
                        <option value="true">
                            Multiple selection
                        </option>
                    </select>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
            <div className="form-box-wrap-center-validation mco-box">
                <div className="mco-cell"><h4>Validations</h4></div>
                <Validations
                    availableValidations={supportedElements[element.type].availableValidations}
                    onChange={onValidationsChange}
                    value={element.validations}
                />
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}

const EditOfferRow = styled.div`
.upload-image-box.with-image::before, .upload-image-box.with-image::after {
    content: unset
    }
`;