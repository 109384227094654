import React from 'react'
import LdeleteConfirm from '../../components/LdeleteConfirm';
import CodeCopy from '../../components/Editor.fragmentCodeCopy'
import uuid from "uuid/v4";
import Validations from '../Validations'
import supportedElements from "../supportedElements";

export default ({element, remove, editElement, changeElementEditorMode, dragHandleProps = {}}) => {

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onValidationsClick = () => {
        changeElementEditorMode("VALIDATIONS")
    };

    const onPinnedChange = e => {
        editElement({
            ...element,
            pinned: {
                status: e.target.checked
            }
        })
    };

    const onSaveClick = () => {
        changeElementEditorMode("DEFAULT")
    };

    const onTitleChange = e => {
        editElement({
            ...element,
            title: e.target.value
        })
    };

    const onTextChange = e => {
        editElement({
            ...element,
            text: e.target.value
        })
    };

    const onValidationsChange = newValidations => {
        editElement({
            ...element,
            validations: newValidations
        })
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center standard-postal-address-box">
                <div className="mco-cell">
                    <h6 className="supTitle">Contract Communication Options</h6>
                    <p className="form-box-wrap-descrition">{element.text}</p>
                </div>
                <div className="mco-cell">
                    <div className="mco-cell-header">
                        <div className="form-row-radio">
                            {(id => (
                                <>
                                    <input type="radio" id={id} checked={true} onChange={f => f}/>
                                    <label style={{fontWeight: "300"}} htmlFor={id}>By mail</label>
                                </>
                            ))(uuid())}
                        </div>
                    </div>
                    <fieldset className="form-grid">
                        <div className="form-grid-row">
                            <div className="form-row">
                                <label>Name</label>
                                <CodeCopy code={`${element.code}_first_name`}>
                                    <input type="text" value="" onChange={f => f}/>
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>Surname</label>
                                <CodeCopy code={`${element.code}_last_name`}>
                                    <input type="text" value="" onChange={f => f}/>
                                </CodeCopy>
                            </div>
                        </div>
                        <div className="form-grid-row">
                            <div className="form-row">
                                <label>County</label>
                                <CodeCopy code={`${element.code}_country`}>
                                    <select/>
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>City</label>
                                <CodeCopy code={`${element.code}_locality`}>
                                    <select/>
                                </CodeCopy>
                            </div>
                        </div>
                        <div className="form-grid-row street-grid">
                            <div className="form-row">
                                <label>Street</label>
                                <CodeCopy code={`${element.code}_street`}>
                                    <input type="text" value="" onChange={f => f}/>
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>No</label>
                                <CodeCopy code={`${element.code}_nr`}>
                                    <input type="text" value="" onChange={f => f}/>
                                </CodeCopy>
                            </div>
                        </div>
                        <div className="form-grid-row address-grid">
                            <div className="form-row">
                                <label>Building</label>
                                <CodeCopy code={`${element.code}_block`}>
                                    <input type="text" value="" onChange={f => f}/>
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>Entry</label>
                                <CodeCopy code={`${element.code}_entry`}>
                                    <input type="text" value="" onChange={f => f}/>
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>Floor</label>
                                <CodeCopy code={`${element.code}_floor`}>
                                    <input type="text" value="" onChange={f => f}/>
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>Apartment</label>
                                <CodeCopy code={`${element.code}_apartment`}>
                                    <input type="text" value="" onChange={f => f}/>
                                </CodeCopy>
                            </div>
                        </div>
                        <div className="form-grid-row address-grid">
                            <div className="form-row">
                                <label>Postal Code</label>
                                <CodeCopy code={`${element.code}_postal_code`}>
                                    <input type="text" value="" onChange={f => f}/>
                                </CodeCopy>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="mco-cell">
                    <div className="mco-cell-header">
                        <div className="form-row-radio">
                            {(id => (
                                <>
                                    <input id={id} type="radio" checked={true} onChange={f => f}/>
                                    <label style={{fontWeight: "300"}} htmlFor={id}>By email</label>
                                </>
                            ))(uuid())}
                        </div>
                        <CodeCopy code={`${element.code}_email`}>
                            <input type="text" value="" onChange={f => f} placeholder={element.emailPlaceholder}/>
                        </CodeCopy>
                    </div>
                </div>
                <div className="mco-cell last">
                    <div className="mco-cell-header">
                        <div className="form-row-radio">
                            {(id => (
                                <>
                                    <input id={id} type="radio" checked={true} onChange={f => f}/>
                                    <label style={{fontWeight: "300"}} htmlFor={id}>By text message</label>
                                </>
                            ))(uuid())}
                        </div>
                        <CodeCopy code={`${element.code}_sms_number`}>
                            <input type="text" value="" onChange={f => f}
                                   placeholder={element.phoneNumberPlaceholderSMS}/>
                        </CodeCopy>
                    </div>
                    {/* <span className="form-box-wrap-id">{element.code}</span> */}
                </div>
                <p className="form-row-validation-text">{element.validations.map(v => `[ ${v.validation} ]  `)}{element.pinned && element.pinned.status ? "[ show on top ]" : ""}</p>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onValidationsClick}><i className="icon-check trigger-validation-box"></i>Validation</span>
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <LdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </LdeleteConfirm>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className="form-row-checkbox">
                    <input id={`pinned-${element.code}`} type="checkbox" checked={element.pinned && element.pinned.status} onChange={onPinnedChange}/>
                    <label htmlFor={`pinned-${element.code}`}>Show on top in saved response</label>
                </div>
                <div className="form-row">
                    <label>Contract Communication Options Title</label>
                    <input type="text" value={element.title} onChange={onTitleChange}/>
                </div>
                <div className="form-row">
                    <label>Contract Communication Options Placeholder Text</label>
                    <input type="text" value={element.text} onChange={onTextChange}/>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
            <div className="form-box-wrap-center-validation mco-box">
                <div className="mco-cell"><h4>Validations</h4></div>
                <Validations
                    availableValidations={supportedElements[element.type].availableValidations}
                    onChange={onValidationsChange}
                    value={element.validations}
                />
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}