import React from 'react'
import {useHistory} from 'react-router-dom'
import {notification} from '../../components/Lnotification'
import isEmail from 'isemail'
import {isEmpty} from 'validator'
import {ThemeProvider} from 'styled-components'
import {BlockPicker} from 'react-color'

import Navbar from '../../components/Navbar/Navbar'
import PaletteList from '../../components/PaletteList'
import NotifyOthersList from './NotifyOthersList'
import LivePreview from '../../components/LivePreview'
import {readObjectUrl} from '../../utils/readObjectUrl'
import API from '../../api'
import LdeleteConfirm from "../../components/LdeleteConfirm";

const defaultLookAndFeel = {
    formLogo: null,
    fontColor: "#5D2560",
    backgroundColor: "#F1F1F1",
    fontFace: 'Open Sans'
}

const NewForm = () => {

    const [state, setState] = React.useState({
        name: '',
        description: '',
        notifyMe: false,
        sendFormToRecipient: false,
        notifyOthers: {
            others: [],
            other: ''
        },
        fontColor: "#5D2560",
        backgroundColor: "#F1F1F1",
        fontFace: 'Open Sans',
        formLogo: null,
        saving: false,
        errors: {}
    }, 'new form');

    const history = useHistory();

    const onLogoChange = event => {
        const image = event.target.files[0];
        event.target.value = "";
        setState(state => ({...state, formLogo: URL.createObjectURL(image)}))
    };

    const validate = () => {
        const errors = {};

        if (isEmpty(state.name)) errors.name = "Name is required";

        if (isEmpty(state.description)) errors.description = "this field is required";
        else if (state.description.length > 5000) errors.description = "Form description is too long.";

        if (isEmpty(state.fontColor)) errors.font_color = "Font color is required";

        if (isEmpty(state.backgroundColor)) errors.background_color = "Background color is required";

        if (isEmpty(state.fontFace)) errors.font_face = "Font face is required";

        if (state.notifyOthers.other.trim().length > 0 && !isEmail.validate(state.notifyOthers.other)) errors.notifyOthers = "Invalid Email"


        setState(state => ({...state, errors}));
        return Object.keys(errors).length === 0
    };

    const save = async () => {
        try {
            setState(state => ({...state, saving: true}));
            if (!validate()) {
                setState(state => ({...state, saving: false}));
                return
            }
            const {data:{id}} = await API.post('forms', {
                name: state.name,
                description: state.description,
                font_color: state.fontColor,
                font_face: state.fontFace,
                background_color: state.backgroundColor,
                logo: state.formLogo ? await readObjectUrl(state.formLogo) : null,
                notification_recipients: {
                    self: state.notifyMe,
                    client: state.sendFormToRecipient,
                    others: [...state.notifyOthers.others, ...(state.notifyOthers.other.trim().length > 0) ? [state.notifyOthers.other] : []]
                }
            });
            
            setState(state => ({...state, saving: false}))
            history.push(`/forms/${id}/edit/definition`, {newForm: true})
        } catch (err) {
            notification.warning({message: "Something went wrong"});
            setState(state => ({...state, saving: false}))
        }

    };

    const reset = () => setState(state => ({...state, ...defaultLookAndFeel}))
    

    return (
        <>
            <div className="wrapper new-form-page">
                <Navbar/>
                <div className="content">
                    <div className="toolbox toolbox-1">
                        <h3 className="tb-header-title">Look and feel</h3>
                        <div className="scrollbar">
                            <h6>Form logo</h6>
                            <div className="tb-upload-logo">
                                <div className="tb-uploaded-img"><img
                                    src={state.formLogo || "/alchemistLight/img/Alchemist_Identity_Final-01.png"} alt=""/>
                                </div>
                                <input className="tb-upload-file" type="file" onChange={onLogoChange} accept="image/*"/>
                            </div>
                            <h6>Font color</h6>
                            <div css={`margin: .5rem 0 1rem; >.block-picker>div:nth-child(2){border: 2px solid white;}`}>
                                <BlockPicker
                                    triangle="hide"
                                    width="100%"  
                                    colors={[
                                        '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#000000',
                                        '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#7f8c8d',
                                        '#f1c40f', '#e67e22', '#fafbfe', '#fea540', '#95a5a6'
                                    ]}
                                    color={state.fontColor}
                                    onChange={color => setState(state => ({...state, fontColor: color.hex}))}
                                />
                            </div>
                            <h6>Background color</h6>
                            <div css={`margin: .5rem 0 1rem; >.block-picker>div:nth-child(2){border: 2px solid white;}`}>
                                <BlockPicker
                                    triangle="hide"
                                    width="100%" 
                                    colors={[
                                        '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#000000',
                                        '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#7f8c8d',
                                        '#f1c40f', '#e67e22', '#fafbfe', '#fea540', '#95a5a6'
                                    ]}
                                    color={state.backgroundColor}
                                    onChange={color => setState(state => ({...state, backgroundColor: color.hex}))}
                                    
                                />
                            </div>
                            <h6>Font face</h6>
                            <div className="choose-font">
                                <select
                                    value={state.fontFace}
                                    onChange={({target: {value}}) => setState(state => ({...state, fontFace: value}))}
                                >
                                    <option value="Open Sans">Open Sans</option>
                                    <option value="Sans Serif">Sans Serif</option>
                                    <option value="Calibri">Calibri</option>
                                </select>
                            </div>
                            <span className="button button-outline" onClick={reset}>Reset</span>
                        </div>
                    </div>

                    <div className={`content-box ${Object.keys(state.errors).length !== 0 ? "error-anim" : ""}`}>
                        <form className="form form-step-1"
                              onSubmit={e => {
                                  e.preventDefault();
                                  save()
                              }}
                        >
                            <div className="scrollbar">
                                <div className="form-box-header">
                                    <h6 className="supTitle">New form</h6>
                                    <div className={`form-row ${state.errors.name ? "error" : ""}`}>
                                        <input
                                            type="text"
                                            placeholder="Form name"
                                            value={state.name}
                                            onChange={({target: {value}}) => setState(state => ({
                                                ...state,
                                                name: value
                                            }))}
                                        />
                                        {state.errors.name &&
                                        <span className="form-row-error-msg">{state.errors.name}</span>}
                                    </div>
                                </div>
                                <div className="form-box-body">

                                    <fieldset>
                                        <div className={`form-row ${state.errors.description ? "error" : ""}`}>
                                            <label htmlFor="form-description">Form description</label>
                                            <textarea
                                                value={state.description}
                                                onChange={({target: {value}}) => setState(state => ({
                                                    ...state,
                                                    description: value
                                                }))}
                                            />
                                            {state.errors.description &&
                                            <span className="form-row-error-msg">{state.errors.description}</span>}
                                        </div>
                                        <h4>Notification options</h4>
                                        <div className="notif-recipients-box">
                                            <div className="form-row-checkbox">
                                                <input
                                                    id="form-nr-agent"
                                                    type="checkbox"
                                                    checked={state.notifyMe}
                                                    onChange={({target: {checked}}) => setState(state => ({
                                                        ...state,
                                                        notifyMe: checked
                                                    }))}
                                                />
                                                <label htmlFor="form-nr-agent">Notify me when a form is
                                                    filled-in</label>
                                            </div>
                                            <div className="form-row-checkbox">
                                                <input
                                                    id="form-nr-others"
                                                    type="checkbox"
                                                    checked={state.sendFormToRecipient}
                                                    onChange={({target: {checked}}) => setState(state => ({
                                                        ...state,
                                                        sendFormToRecipient: checked
                                                    }))}
                                                />
                                                <label htmlFor="form-nr-others">Send completed form to others:</label>
                                            </div>
                                            <NotifyOthersList 
                                                value={state.notifyOthers}
                                                changeValue={callback => setState(state => ({
                                                    ...state,
                                                    notifyOthers: callback(state.notifyOthers)
                                                }))}
                                                error={state.errors.notifyOthers}
                                            />
                                        </div>

                                    </fieldset>

                                    <div className="form-box-actions">
                                        <LdeleteConfirm
                                            onConfirm={() => {
                                                setState(state => ({
                                                    ...state, name: '',
                                                    description: '',
                                                    notifyMe: false,
                                                    sendFormToRecipient: false,
                                                    notifyOthers: [],
                                                    fontColor: "#8F75DB",
                                                    backgroundColor: "#fafbfe",
                                                    fontFace: 'Open Sans',
                                                    formLogo: null
                                                }))
                                            }}
                                            okText="Clear"
                                            cancelText="Cancel"
                                            title="Are you sure you want to clear all data?"
                                        >
                                            <span className="button button-outline"><i className="icon-ia-trash"></i><span>Clear All</span></span>
                                        </LdeleteConfirm>
                                        <span/>
                                        <a className="button" href="#" onClick={e => {
                                            e.preventDefault();
                                            save()
                                        }}><i className="icon-ia-checked-outline"/><span>Save</span></a>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>

                <div className="sidebar sidebar-preview-tablet">
                    <div className="sidebar-title">
                        <h3 className="sidebar-title sidebar-title-fixed">Preview</h3>
                    </div>
                    <div className="pts-top">

                        <div className="tablet-preview">
                            <img src="alchemistLight/img/tablet.svg"/>
                                <ThemeProvider theme={{ 
                                    mode: 'dark',
                                    backgroundColor: state.backgroundColor,
                                    fontColor: state.fontColor,
                                    fontFace: state.fontFace,
                                    defaultLook: {
                                        fontColor: "#5D2560",
                                        backgroundColor: "#F1F1F1",
                                        fontFace: 'Open Sans'
                                    }
                                }}>
                                    <LivePreview
                                        name={state.name}
                                        logo={state.formLogo}
                                        description={state.description}
                                    />
                                </ThemeProvider>
                            <i className="icon-ia-edit-bold edit-slide-preview"></i>
                        </div>

                    </div>
                </div>


            </div>
        </>
    )
};

export default NewForm