import React from 'react'
import isEmail from 'isemail'

import LpopUp from '../../components/LpopUp'
import LemailList from '../../components/LemailList'
import API from '../../api'
import Loading from '../../components/Loading'
import {notification} from '../../components/Lnotification'

const SendWithEmail = ({formId, visible, close, onSuccess}) => {
    const [state, setState] = React.useState({
        emailList: {
            other: '',
            others: []
        },
        error: undefined,
        sending: false,
    }, 'sendForm')

    const send = async () => {
        try {
            setState(state => ({...state, sending: true, error: false}))

            let error
            if(state.emailList.others.length === 0 && !state.emailList.other) error = "Email is required"
            else if(state.emailList.other && !isEmail.validate(state.emailList.other)) error = "Invalid email"

            if (error) {
                setState(state => ({...state, sending: false, error}))
                return
            }
            await API.post(`/sessions/form/${formId}/sendToClient`, {
                clients: [...state.emailList.others, ...(state.emailList.other.trim().length > 0) ? [state.emailList.other] : []]
            })
            setState(state => ({...state, sending: false, emailList: {other: '', others: []}}))
            onSuccess()
            close()
        } catch(err) {
            setState(state => ({...state, sending: false}))
            notification.warning({message: "Something went wrong."})
        }
    }

    return(
        <>
            <LpopUp 
                visible={visible}
                disableFooter
                disableHeader
                onCancel={() => close()}
            >
                {state.sending && <Loading />}
                {!state.sending && 
                    <form className="form" onSubmit={e => e.preventDefault()}>
                        <div className="overlay-icon">
                            <img src="alchemistLight/img/send-mail.svg" alt=""/>
                        </div>
                        <h3>Send emails</h3>
                        <p>separated with a comma, semicolon, space or enter.</p>
                        <LemailList 
                            placeholder="Email..."
                            value={state.emailList}
                            changeValue={callback => setState(state => ({
                                ...state,
                                emailList: callback(state.emailList)
                            }))}
                            error={state.error}
                        />
                        <div className="overlay-actions">
                            <button type="button" className="button with-icon-right close-overlay" onClick={e => {
                                e.preventDefault()
                                send()
                            }}>
                                <i className="icon-mail"/>Send
                            </button>
                            <button type="button" className="button button-link close-overlay" onClick={close}>Cancel</button>
                        </div>
                    </form>
                }
            </LpopUp>
        </>
    )
}

export default SendWithEmail