import React from 'react';
import dot from 'dot-object'


export default props => 
    props.element.chained && props.element.chained.role === 'slave' 
        ? <SlaveAdapter {...props}/> 
        : <SimpleDropDown {...props}/>
      

const SlaveAdapter = props => {
    const [items, setItems] = React.useState([])

    React.useEffect(() => {
        const items = calculateItemsFromMaster()
        if(items.length === 0 && props.value) props.changeValue('')
        setItems(items)
    }, [props.answers_by_code])
    
    const calculateItemsFromMaster = () => {
        const chainedValues = [
            props.answers_by_code[props.element.chained.masterCode] || null,
            ...(
                props.formElements
                .filter(element => element.type === 'dropdown' && element.chained && element.chained.role === 'slave' && element.chained.masterCode === props.element.chained.masterCode && element.chained.chainIndex < props.element.chained.chainIndex)
                .sort((a, b) => a.chained.chainIndex - b.chained.chainIndex)
                .map(element => props.answers_by_code[element.code] || null)
            )
        ]

        if(chainedValues.includes(null)) {
            return []
        } else {
            const {chained:{structure}} = props.formElements.find(element => element.code == props.element.chained.masterCode)
            const myLevelStructure = dot.pick(chainedValues.join("."), structure)
            return Array.isArray(myLevelStructure) ? myLevelStructure : Object.keys(myLevelStructure)
        }
    }

    return <SimpleDropDown {...{...props, element: {...props.element, items}}}/>
}


const SimpleDropDown = ({
    element,
    value = "",
    changeValue,
    errors = []
}) => {

    return (
        <>
            <div className="fieldset-row">
                <div className={`form-row ${errors.length !== 0 ? 'error' : ''}`}>
                    <label>{element.text}</label>
                    <select value={value} onChange={({target: {value: newValue}}) => changeValue(newValue)}>
                        <option value=""/>
                        {element.items.map((item, itemIndex) => (
                            <option key={itemIndex} value={item}>{item}</option>
                        ))}
                    </select>
                    {renderErrors(errors)}
                </div>
            </div>
        </>
    )
}

const renderErrors = (errors) => {
    if (errors.length === 0)
        return null;

    return errors.map((error, i) => {
        if (error === 'required') {
            return <span className="form-row-error-msg">This field is required</span>
        }
    })
}