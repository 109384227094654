import React from 'react'
import { TimePicker } from 'antd'
import CodeCopy from '../CodeCopy'
import styled from 'styled-components'

export default ({ 
    element, 
}) => {
    return(
        <div className="fieldset-row">
            <div className="form-row">
                <label>{element.text}</label>
                <CodeCopy text={element.code}>
                    <StyledTimePicker
                        style={{ width: "100%" }}
                        format="HH:mm" 
                        value={null}
                        placeholder={element.placeholder} 
                    />
                </CodeCopy>
            </div>
        </div>
    )
}

const StyledTimePicker = styled(TimePicker)`
    > input {
        height: 4.2rem !important;
        font-size: 1.2rem !important;
    }
    > span {
        font-size: 2.2rem !important;
        margin-top: -1.1rem;
    }
`