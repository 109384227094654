import React from 'react'
import CopyToClipboard from "react-copy-to-clipboard"

const LcopyToClipboard = ({children, feedback=null, ...rest}) => {

    const [copied, setCopied] = React.useState(false)

    return (
        <>
            {copied 
                ?   React.cloneElement(feedback, { onMouseLeave: () =>  setCopied(false), style:{cursor: 'default'}})
                :   <CopyToClipboard {...rest} onCopy={() => setCopied(true)}>{children}</CopyToClipboard>
            }
        </>
    )
}

export default LcopyToClipboard