
import React from 'react'
import LdeleteConfirm from '../../components/LdeleteConfirm';
import fileImg from '../../images/file.svg'

export default ({ element, remove, editElement, changeElementEditorMode, dragHandleProps = {} }) => {

  const onOptionClick = () => {
    changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
  }

  const onEditClick = () => {
    changeElementEditorMode("EDIT")
  }

  const onValidationsClick = () => {
    changeElementEditorMode("VALIDATIONS")
  };

  const onSaveClick = () => {
    changeElementEditorMode("DEFAULT")
  };

  return(
    <div className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"}`}>
      <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
      <div className="form-box-wrap-center">
        <div className="form-box-wrap-center-content">
          <h6 className="supTitle">Documents Preview</h6>
          <p className="form-box-wrap-descrition">{element.title}</p>
          <p className="form-box-wrap-descrition">{element.text}</p>
          <div className="form-grid files-list" style={{ gridTemplateColumns: "repeat(3, 1fr)" }}>
            <div className="file">
              <img src={fileImg} />
              <strong>File Name</strong>
            </div>
            <div className="file">
              <img src={fileImg} />
              <strong>File Name</strong>
            </div>
            <div className="file">
              <img src={fileImg} />
              <strong>File Name</strong>
            </div>
          </div>
          <p className="form-row-validation-text">{element.validations.map(v => `[ ${v.validation} ]  `)}</p>
          {/* <span className="form-box-wrap-id">{element.code}</span> */}
        </div>
        <div className="form-box-wrap-options">
          <div className="form-box-wrap-options-buttons">
            <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
            <LdeleteConfirm
                onConfirm={remove}
                okText="Delete"
                cancelText="Cancel"
                title="Are you sure you want to delete this component? All data will be lost. "
            >
              <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
            </LdeleteConfirm>
          </div>
        </div>
      </div>
      <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
      <div className="form-box-wrap-center-edit">
        <div className="form-row">
          <input type="text" value={element.title} placeholder="Documents Preview Title" onChange={e => editElement({ ...element, title: e.target.value })} />
        </div>
        <div className="form-row">
          <input type="text" value={element.text} placeholder="Documents Preview Text" onChange={e => editElement({ ...element, text: e.target.value })} />
        </div>
        <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
      </div>
    </div>
  )
}
