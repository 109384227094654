import React from 'react'
import moment from "moment";
import {notification} from "../../components/Lnotification"
import LcopyToClipboard from '../../components/LcopyToClipboard'

const Response = (props) => {
    const [state, setState] = React.useState({
        options: false
    }, "form");

    const viewResponse = async function (e) {
        e.preventDefault();
        props.history.push('/responses/' + props.response._id)
    };

    const showDeleteResponseModal = async function () {
        props.setState(state => ({...state, deleting: {status: true, response: props.response._id}}));
    };
    return (
        <li>
            <ul>
                <li><strong>{props.response.form_name}</strong></li>
                <li><strong>{props.response._client}</strong></li>
                <li>
                    <strong>{moment(props.response.updated_at).format('D MMM, h:mm')}<span>{moment(props.response.updated_at).format('YYYY')}</span></strong>
                </li>
                <li className={`${(state.options ? "view-row-options" : "")}`}>
                    <div className="table-actions">
                        <div/>
                        <LcopyToClipboard
                            text={`${process.env.REACT_APP_DASHBOARD_URL}/responses/${props.response._id}`}
                            feedback={(
                                <div className="tbl-btn"><i className="icon-ia-checked"/>
                                    <div
                                        className={`tooltip ${(props.index === 0 ? "tooltip-bottom" : "tooltip-top")}`}>Copied!
                                    </div>
                                </div>
                            )}
                        >
                            <div className="tbl-btn"><i className="icon-link-2"/>
                                <div
                                    className={`tooltip ${(props.index === 0 ? "tooltip-bottom" : "tooltip-top")}`}>Copy
                                    URL
                                </div>
                            </div>
                        </LcopyToClipboard>
                        <div className="tbl-btn trigger-row-options"
                             onClick={() => (setState({...state, options: !state.options}))}
                             onBlur={() => setTimeout(() => setState({...state, options: false}), 100)}
                             tabIndex="-1"
                        ><i
                            className="icon-more-horizontal"/>
                            <div className={`tooltip ${(props.index === 0 ? "tooltip-bottom" : "tooltip-top")}`}>More
                            </div>
                            <ul className="row-options">
                                <li onClick={viewResponse}><i className="icon-ia-document"/>View response</li>
                                <li className="trigger-overlay-delete-response" onClick={showDeleteResponseModal}><i
                                    className="icon-ia-delete"/>Delete Response
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </li>
    )
};

export default Response