import React from 'react'
import { TimePicker } from 'antd'
import moment from 'moment'
import {synchronizeText} from "../../utils/synchronizeText"

export default ({
    element,
    value = null,
    changeValue,
    errors = [],
    answers_by_code
}) => {

    const setValue = (_, time) => {
        if (time === '')
            time = null

        changeValue(time)
    }

    return (
        <div className="fieldset-row">
            <div className={`form-row ${(errors.length > 0) ? 'error' : '' }`}>
                {element.text &&
                    <label>{synchronizeText(element.text, answers_by_code)}</label>
                }

                <TimePicker 
                    onChange={setValue} 
                    format="HH:mm" 
                    value={(value !== null) 
                        ? moment(value, 'HH:mm') 
                        : null }
                    placeholder={element.placeholder} 
                />
                {renderErrors(errors)}
            </div>
        </div>
    )
}

const renderErrors = (errors) => {
    if (errors.length === 0)
        return null

    return errors.map(error => {
        if (error === 'required') {
            return <span key={error} className="form-row-error-msg">This field is required</span>
        }

        return null
    })
}