import React from 'react'

import {synchronizeText} from "../../utils/synchronizeText"

export default ({
    element,
    value = {
        rating: null,
        description: ''
    },
    changeValue,
    errors = [],
    answers_by_code
}) => {

    const onDescriptionChange = ({target:{value:newDescription}}) => {
        changeValue({
            ...value,
            description: newDescription
        })
    }

    const onRateClick = newRate => {
        if(newRate !== value.rating) {
            changeValue({
                rating: newRate,
                description: element[['worst', 'poor', 'average', 'good', 'excellent'][newRate]]
            })

        }
    }

    return (
        <>
            <div className="fieldset-row fieldset-row-title">
                <h3>{synchronizeText(element.title, answers_by_code)}</h3>
            </div>

            <div className="fieldset-row">
                <p className="paragraph">{synchronizeText(element.text, answers_by_code)}</p>
            </div>

            <ul className="feedback-component">
                <li className={value.rating === 0 ? "active" : ""} onClick={() => onRateClick(0)}><i className="icon-feedback-icon-1"></i><span>{element.worst}</span></li>
                <li className={value.rating === 1 ? "active" : ""} onClick={() => onRateClick(1)}><i className="icon-feedback-icon-2"></i><span>{element.poor}</span></li>
                <li className={value.rating === 2 ? "active" : ""} onClick={() => onRateClick(2)}><i className="icon-feedback-icon-3"></i><span>{element.average}</span></li>
                <li className={value.rating === 3 ? "active" : ""} onClick={() => onRateClick(3)}><i className="icon-feedback-icon-4"></i><span>{element.good}</span></li>
                <li className={value.rating === 4 ? "active" : ""} onClick={() => onRateClick(4)}><i className="icon-feedback-icon-5"></i><span>{element.excellent}</span></li>
            </ul>

            <div className="fieldset-row">
                <div className="form-row">
                    <h3 className="form-box-wrap-title">{element.descriptionTitle}</h3>
                    <textarea value={value.description} onChange={onDescriptionChange}/>
                </div>
            </div>
        </>
    )
}