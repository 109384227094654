import React, { useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import CodeCopy from '../CodeCopy'


export default ({
    element, 
    onChange = f => f, 
    value = {
        first_name: '',
        last_name: '',
        signature: ''
    }, 
    error = null
}) => {
    let sigPad = useRef(null)

    const clearSignature = () => {
        sigPad.clear()
    }

    const saveSignature = () => {
        onChange({
            ...value,
            signature: sigPad.toDataURL('image/png')
        })
    }

    return(
        <div className="fieldset-row">
            <p>{element.title}</p>
            <p>{element.text}</p>
            <div className="form-grid-row">
                <div className="form-row">
                    <label>Name</label>
                    <input type="text" value={value.first_name} onChange={e => onChange({ ...value, first_name: e.target.value })} />
                </div>
                <div className="form-row">
                    <label>Surname</label>
                    <input type="text" value={value.last_name} onChange={e => onChange({ ...value, first_name: e.target.last_name })}/>
                </div>
            </div>
            <div className="signature-pad"></div>
        </div>
    )
}