import React from 'react'
import {notification} from '../components/Lnotification'

import LcopyToClipboard from '../components/LcopyToClipboard'

export default ({children, code}) => {

    return (

        <LcopyToClipboard
            text={`{{${code}}}`}
            feedback={(
                <span className="copied-component-code"><i className="icon-ia-checked"/>
                    Copied!
                </span>
            )}
        >
            {React.cloneElement(
                children,
                {children: code}
            )}

        </LcopyToClipboard>
    )

}

// export default ({ children, code}) => {
//     let delimitedCode = `{{${code}}}`
//     return(
{/*        <>*/
}
{/*            {React.cloneElement(*/
}
{/*                children,*/
}
{/*                {*/
}
{/*                    children: code,*/
}
{/*                    onClick: () => {*/
}
{/*                        navigator.clipboard.writeText(delimitedCode)*/
}
{/*                        notification.success({message: `${delimitedCode} Copied`})*/
}
{/*                    },*/
}
{/*                    style: { cursor: "copy" }*/
}
{/*                }*/
}
{/*            )}*/
}
{/*//         </>*/
}
//     )
// }