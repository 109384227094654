import React from 'react'
import list from './countryCityList.json'
import uuid from 'uuid/v4'

export { list }

export const Country = ({ 
    placeholder = "", 
    value,
    onChange=f=>f,
    name="",
    ...rest 
}) => {
    const id = uuid()
    return(
        <>
            <input {...rest} value={value} onChange={onChange} name={name} list={id}/>
            <datalist id={id}>
                <option value="" disabled hidden>{placeholder}</option>
                {Object.keys(list).sort().map((country, countryIndex) => (
                    <option value={country} key={countryIndex}>{country}</option>
                ))}
            </datalist>
        </>
    )
}

export const City = ({ 
    selectedCountry = null, 
    placeholder = "",
    name="",
    value,
    onChange=f=>f, 
    ...rest
}) => {
    const id = uuid()
    return(
        <>
            <input {...rest} value={value} onChange={onChange} name={name} list={id}/>
            <datalist id={id}>
                <option value="" disabled hidden>{placeholder}</option>
                {!!selectedCountry && list[selectedCountry]
                    && list[selectedCountry].sort().map((city, cityIndex) => (
                        <option value={city} key={cityIndex}>{city}</option>
                    ))
                }
            </datalist>
        </>
    )
}