import {elements} from '../constants/elements';
import {VALIDATION} from '../constants/validations';
import Isemail from 'isemail';
import moment from 'moment';
import base64FileSize from '../utils/base64FileSize';
import {phoneValidator} from "./phoneValidator";
import {validateVin} from "./vinValidator";
import {postalCodeValidator} from "./postalCodeValidator";
import {ibanValidator} from "./ibanValidator";
import {plateValidator} from "./plateValidator";
import bytes from "bytes";

import {validate as imagUploadValidate} from '../formComponents/ImageUpload/Input.imageUpload'
import {validate as fileUploadValidate} from '../formComponents/FileUpload/Input.fileUplopad'

const addValidationError = (validation_errors, element_id, error) => {
    if (validation_errors[element_id] === undefined) {
        validation_errors[element_id] = [];
    }

    validation_errors[element_id].push(error);
};

export const validValues = (validation_errors, element, value_obj) => {
    const to_validate = {};

    for (let i = 0; i < element.validations.length; i++) {
        const validation = element.validations[i];

        addValidation(validation, to_validate)
    }

    const validation_keys = Object.keys(to_validate);

    if (validation_keys.length) {
        for (let a = 0; a < validation_keys.length; a++) {
            switch (validation_keys[a]) {
                case VALIDATION.REQUIRED:
                    if (element.type === elements.CONTRACT_COMMUNICATION_OPTIONS) {
                        if (value_obj.value.communication === 'post'
                            && !value_obj.value.first_name
                            && !value_obj.value.last_name
                            && !value_obj.value.country
                            && !value_obj.value.locality
                            && !value_obj.value.street
                            && !value_obj.value.nr
                            && !value_obj.value.block_value
                            && !value_obj.value.entry
                            && !value_obj.value.floor
                            && !value_obj.value.apartment
                        ) {
                            addValidationError(validation_errors, element._id, 'post_data_required');
                        } else if (value_obj.value.communication === 'sms' && !value_obj.value.sms_number) {
                            addValidationError(validation_errors, element._id, 'sms_number_required');
                        } else if (value_obj.value.communication === 'email' && !value_obj.value.email) {
                            addValidationError(validation_errors, element._id, 'email_required');
                        }
                    } else if (value_obj.value === null || value_obj.value === '' || (Array.isArray(value_obj.value) && value_obj.value.length === 0)) {
                        addValidationError(validation_errors, element._id, 'required');
                    }
                    break;
                case VALIDATION.MIN_LENGTH:
                    if (value_obj.value && value_obj.value.length < to_validate[VALIDATION.MIN_LENGTH]) {
                        addValidationError(validation_errors, element._id, `min length|${to_validate[VALIDATION.MIN_LENGTH]}`);
                    }
                    break;
                case VALIDATION.MAX_LENGTH:
                    if (value_obj.value && value_obj.value.length > to_validate[VALIDATION.MAX_LENGTH]) {
                        addValidationError(validation_errors, element._id, `max length|${to_validate[VALIDATION.MAX_LENGTH]}`);
                    }
                    break;
                case VALIDATION.MIN_VALUE:
                    if (value_obj.value && parseFloat(value_obj.value) < parseFloat(to_validate[VALIDATION.MIN_VALUE])) {
                        addValidationError(validation_errors, element._id, `min value|${to_validate[VALIDATION.MIN_VALUE]}`);
                    }
                    break;
                case VALIDATION.MAX_VALUE:
                    if (value_obj.value && parseFloat(value_obj.value) > parseFloat(to_validate[VALIDATION.MAX_VALUE])) {
                        addValidationError(validation_errors, element._id, `max value|${to_validate[VALIDATION.MAX_VALUE]}`);
                    }
                    break;
                case VALIDATION.NUMBER:
                    if (value_obj.value && !(!isNaN(parseFloat(value_obj.value)) && isFinite(value_obj.value))) {
                        addValidationError(validation_errors, element._id, 'number');
                    }
                    break;
                case VALIDATION.POSITIVE:
                    if (value_obj.value && Math.sign(value_obj.value) !== 1) {
                        addValidationError(validation_errors, element._id, 'positive');
                    }
                    break;
                case VALIDATION.INTEGER:
                    if (value_obj.value && !isInt(value_obj.value)) {
                        addValidationError(validation_errors, element._id, 'integer');
                    }
                    break;
                case VALIDATION.PHONE:
                    if (value_obj.value && phoneValidator(value_obj.value) === false) {
                        addValidationError(validation_errors, element._id, 'phone');
                    }
                    break;
                case VALIDATION.EMAIL:
                    if (value_obj.value && !Isemail.validate(value_obj.value)) {
                        addValidationError(validation_errors, element._id, 'email');
                    }
                    break;
                case VALIDATION.MAX_NUMBER_OF_FILES:
                    if (value_obj.value.length > parseInt(to_validate[VALIDATION.MAX_NUMBER_OF_FILES])) {
                        addValidationError(validation_errors, element._id, `max number of files|${to_validate[VALIDATION.MAX_NUMBER_OF_FILES]}`);
                    }
                    break;

                case 'multiple images':
                    if (to_validate['multiple images'] && value_obj.value.length > parseInt(to_validate['multiple images'])) {
                        addValidationError(validation_errors, element._id, `max number of files|${to_validate['multiple images']}`);
                    }
                    break;
                case VALIDATION.MIN_NUMBER_OF_FILES:
                    if (value_obj.value.length !== 0 && value_obj.value.length < parseInt(to_validate[VALIDATION.MIN_NUMBER_OF_FILES])) {
                        addValidationError(validation_errors, element._id, `min number of files|${to_validate[VALIDATION.MIN_NUMBER_OF_FILES]}`);
                    }
                    break;

                case VALIDATION.MAX_FILE_SIZE:
            

                    if (element.type === elements.FILE_UPLOAD) {
                        for (let i = 0; i < value_obj.value.length; i++) {
                            if (value_obj.value[i].size > to_validate[VALIDATION.MAX_FILE_SIZE]) {
                                addValidationError(validation_errors, element._id, `max file size|${to_validate[VALIDATION.MAX_FILE_SIZE]}`);
                                break;
                            }
                        }
                    }
                    break;

                case VALIDATION.NO_PAST_DATE:
                    if (value_obj.value && moment(value_obj.value, "DD/MM/YYYY").endOf('day') < moment(to_validate[VALIDATION.NO_PAST_DATE] ? to_validate[VALIDATION.NO_PAST_DATE] : "", "DD/MM/YYYY").startOf('day')) {
                        addValidationError(validation_errors, element._id, VALIDATION.NO_PAST_DATE)
                    }
                    break;
                case VALIDATION.NO_FUTURE_DATE:
                    if (value_obj.value && moment(value_obj.value, "DD/MM/YYYY").startOf('day') > moment(to_validate[VALIDATION.NO_FUTURE_DATE] ? to_validate[VALIDATION.NO_FUTURE_DATE] : "", "DD/MM/YYYY").endOf('day')) {
                        addValidationError(validation_errors, element._id, VALIDATION.NO_FUTURE_DATE)
                    }
                    break;
                case VALIDATION.NO_AFTER_SESSION_DATE:
                    if (value_obj.value && moment(value_obj.value, "DD/MM/YYYY").startOf('day') > moment().endOf('day')) {
                        addValidationError(validation_errors, element._id, VALIDATION.NO_AFTER_SESSION_DATE)
                    }
                    break;
                case VALIDATION.NO_BEFORE_SESSION_DATE:
                    if (value_obj.value && moment(value_obj.value, "DD/MM/YYYY").endOf('day') < moment().startOf('day')) {
                        addValidationError(validation_errors, element._id, VALIDATION.NO_BEFORE_SESSION_DATE)
                    }
                    break;
            }
        }
    }

    return true;
};

export const addValidation = (validation, to_validate) => {
    if (validation.validation === VALIDATION.REQUIRED) {
        to_validate.required = true;
    } else if (validation.validation === VALIDATION.NOT_REQUIRED) {
        to_validate.required = false;
    } else if (validation.validation === VALIDATION.HIDDEN) {
        to_validate.hidden = true;
    } else if (validation.validation === VALIDATION.VISIBLE) {
        to_validate.hidden = false;
    } else if (validation.validation === VALIDATION.PHONE) {
        to_validate.phone = true;
    } else if (validation.validation === VALIDATION.NUMBER) {
        to_validate.number = true;
    } else if (validation.validation === VALIDATION.EMAIL) {
        to_validate.email = true;
    } else if (validation.validation === VALIDATION.INTEGER) {
        to_validate.integer = true;
    } else if (validation.validation === VALIDATION.POSITIVE) {
        to_validate.positive = true;
    } else if (validation.validation === VALIDATION.MIN_LENGTH) {
        to_validate[VALIDATION.MIN_LENGTH] = validation.value;
    } else if (validation.validation === VALIDATION.MAX_LENGTH) {
        to_validate[VALIDATION.MAX_LENGTH] = validation.value;
    } else if (validation.validation === 'multiple images') {
        to_validate['multiple images'] = validation.value;
    } else if (validation.validation === VALIDATION.MIN_VALUE) {
        to_validate[VALIDATION.MIN_VALUE] = validation.value;
    } else if (validation.validation === VALIDATION.MAX_VALUE) {
        to_validate[VALIDATION.MAX_VALUE] = validation.value;
    } else if (validation.validation === VALIDATION.MIN_NUMBER_OF_FILES) {
        to_validate[VALIDATION.MIN_NUMBER_OF_FILES] = validation.value;
    } else if (validation.validation === VALIDATION.MAX_NUMBER_OF_FILES) {
        to_validate[VALIDATION.MAX_NUMBER_OF_FILES] = validation.value;
    } else if (validation.validation === VALIDATION.MAX_FILE_SIZE) {
        to_validate[VALIDATION.MAX_FILE_SIZE] = bytes(validation.value);
    } else if (validation.validation === VALIDATION.NO_PAST_DATE) {
        to_validate[VALIDATION.NO_PAST_DATE] = validation.value
    } else if (validation.validation === VALIDATION.NO_FUTURE_DATE) {
        to_validate[VALIDATION.NO_FUTURE_DATE] = validation.value
    } else if (validation.validation === VALIDATION.NO_AFTER_SESSION_DATE) {
        to_validate[VALIDATION.NO_AFTER_SESSION_DATE] = true
    } else if (validation.validation === VALIDATION.NO_BEFORE_SESSION_DATE) {
        to_validate[VALIDATION.NO_BEFORE_SESSION_DATE] = true
    }
};

export const isValidValues = (form_elements, values, validation_errors) => {
    const answered_elements = [];

    for (let i = 0; i < values.length; i++) {

        const value_obj = values[i];

        const element = form_elements.find(elm => elm._id === value_obj.element_id);

        let current_value_keys;

        switch (element.type) {
            case elements.CONTRACT_COMMUNICATION_OPTIONS:
                current_value_keys = Object.keys(value_obj.value);

                for (let i = 0; i < current_value_keys.length; i++) {
                    switch (current_value_keys[i]) {
                        case 'email':
                            if (value_obj.value[current_value_keys[i]] !== '' && !Isemail.validate(value_obj.value[current_value_keys[i]])) {
                                addValidationError(validation_errors, element._id, 'not_valid_email');
                            }
                            break;
                        case 'sms_number':
                            if (value_obj.value[current_value_keys[i]] !== '' && phoneValidator(value_obj.value[current_value_keys[i]]) === false) {
                                addValidationError(validation_errors, element._id, 'not_valid_sms_number');
                            }
                            break;
                        case 'postal_code':
                            if (value_obj.value[current_value_keys[i]] !== "" && !postalCodeValidator(value_obj.value[current_value_keys[i]])) {
                                addValidationError(validation_errors, element._id, 'not_valid_postal_code');
                            }
                            break;
                    }
                }

                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }

                break;
            case elements.RADIO_GROUP:
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }

                break;
            case elements.CHECKBOX_GROUP:
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }

                break;
            case elements.DROPDOWN:
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }

                break;
            case elements.SHORT_ANSWER:
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }

                break;
            case elements.VIN:
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }
                if (value_obj.value !== '' && !validateVin(value_obj.value)) {
                    addValidationError(validation_errors, element._id, 'not_valid_vin');
                }
                break;
            case elements.IBAN:
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }
                if (value_obj.value !== '' && !ibanValidator(value_obj.value)) {
                    addValidationError(validation_errors, element._id, 'iban');
                }
                break;
            case elements.REGISTRATION_NUMBER:
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }
                if (value_obj.value !== '' && !plateValidator(value_obj.value)) {
                    addValidationError(validation_errors, element._id, 'not_valid_registration_number');
                }
                break;
            case elements.LONG_ANSWER:
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }

                break;
            case elements.SIGNATURE:
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }

                break;
            case elements.DATE:
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }

                break;
            case elements.TIME:
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }

                break;
            case elements.STANDARD_ADDRESS:
                current_value_keys = Object.keys(value_obj.value);

                for (let i = 0; i < current_value_keys.length; i++) {
                    if (current_value_keys[i] === 'postal_code') {
                        if (!postalCodeValidator(value_obj.value[current_value_keys[i]])) {
                            addValidationError(validation_errors, element._id, 'not_valid_postal_code');
                        }
                    }
                }
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }
                break;

            case elements.ID_CARD_EXTRACTOR:
                current_value_keys = Object.keys(value_obj.value);

                for (let i = 0; i < current_value_keys.length; i++) {
                    switch (current_value_keys[i]) {
                        case 'email':
                            if (value_obj.value[current_value_keys[i]] !== '' && !Isemail.validate(value_obj.value[current_value_keys[i]])) {
                                addValidationError(validation_errors, element._id, 'not_valid_email');
                            }
                            break;
                        case 'phone':
                            if (value_obj.value[current_value_keys[i]] !== '' && phoneValidator(value_obj.value[current_value_keys[i]]) === false) {
                                addValidationError(validation_errors, element._id, 'not_valid_phone_number');
                            }
                            break;
                        case 'series_ci':
                            if (value_obj.value[current_value_keys[i]] !== '' && value_obj.value[current_value_keys[i]].length !== 2) {
                                addValidationError(validation_errors, element._id, 'not_valid_serie_ci')
                            }
                            break;
                        case 'nr_ci':
                            if (value_obj.value[current_value_keys[i]] !== '' && !/^[0-9]{6}$/.test(value_obj.value[current_value_keys[i]])) {
                                addValidationError(validation_errors, element._id, 'not_valid_nr_ci')
                            }
                            break;
                        case 'date_of_birth':
                            if (value_obj.value[current_value_keys[i]] !== '' && moment(value_obj.value[current_value_keys[i]], 'YYYY-MM-DD') > moment().endOf("day")) {
                                addValidationError(validation_errors, element._id, 'not_after_session_date_of_birth')
                            }
                            break;
                        case 'available_from':
                            if (value_obj.value[current_value_keys[i]] !== '' && moment(value_obj.value[current_value_keys[i]], 'YYYY-MM-DD') > moment().endOf("day")) {
                                addValidationError(validation_errors, element._id, 'not_after_session_date_available_from')
                            }
                            break;
                        case 'available_to':
                            if (value_obj.value[current_value_keys[i]] !== '' && moment(value_obj.value[current_value_keys[i]], 'YYYY-MM-DD').endOf('day') < moment().startOf('day')) {
                                addValidationError(validation_errors, element._id, 'not_before_session_date_available_to')
                            }
                            break;
                    }
                }
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }

                break;
            case elements.COMMUNICATION_OPTIONS:
                current_value_keys = Object.keys(value_obj.value);

                for (let i = 0; i < current_value_keys.length; i++) {
                    switch (current_value_keys[i]) {
                        case 'email':
                            if (value_obj.value.with_email && value_obj.value[current_value_keys[i]] === '') {
                                addValidationError(validation_errors, element._id, 'email_required');
                            }

                            if (value_obj.value[current_value_keys[i]] !== '' && !Isemail.validate(value_obj.value[current_value_keys[i]])) {
                                addValidationError(validation_errors, element._id, 'not_valid_email');
                            }
                            break;
                        case 'phone_number':
                            if (value_obj.value.with_phone && value_obj.value[current_value_keys[i]] === '') {
                                addValidationError(validation_errors, element._id, 'phone_number_required');
                            }

                            if (value_obj.value[current_value_keys[i]] !== '' && phoneValidator(value_obj.value[current_value_keys[i]]) === false) {
                                addValidationError(validation_errors, element._id, 'not_valid_phone_number');
                            }
                            break;
                        case 'sms_number':
                            if (value_obj.value.with_sms && value_obj.value[current_value_keys[i]] === '') {
                                addValidationError(validation_errors, element._id, 'sms_number_required');
                            }

                            if (value_obj.value[current_value_keys[i]] !== '' && phoneValidator(value_obj.value[current_value_keys[i]]) === false) {
                                addValidationError(validation_errors, element._id, 'not_valid_sms_number');
                            }
                            break;
                        case 'postal_code':
                            if (!postalCodeValidator(value_obj.value[current_value_keys[i]])) {
                                addValidationError(validation_errors, element._id, 'not_valid_postal_code');
                            }
                            break;
                    }
                }

                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }

                break;
            case elements.PRIVACY_AGREEMENT:
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }

                break;
            case elements.DOCUMENTS_PREVIEW:
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }

                break;

            case elements.FILE_UPLOAD:
                if (element.validations) {
                    // 
                    const errors = fileUploadValidate(element.validations, value_obj.value)
                    if(errors) validation_errors[element._id] = errors
                }

                break;

            case elements.IMAGE_UPLOAD:
                if (element.validations) {
                    // 
                    const errors = imagUploadValidate(element.validations, value_obj.value)
                    if(errors) validation_errors[element._id] = errors
                }

                break;
            case elements.OFFERS_LIST:
                if (element.validations) {
                    validValues(validation_errors, element, value_obj);
                }

                break;
        }

        answered_elements.push(value_obj.element_id);
    }

    const not_answered_elements = form_elements.filter(fe => !answered_elements.includes(fe._id));

    // Check if one of the non answered elements needs an answer
    for (let i = 0; i < not_answered_elements.length; i++) {
        const element = not_answered_elements[i];
        const to_validate = {};

        const {validations} = element;

        if (validations) {
            for (let j = 0; j < validations.length; j++) {
                const validation = validations[j];

                addValidationsForRequired(validation, to_validate);
            }
        }

        if (!to_validate.hidden && to_validate.required) {
            addValidationError(validation_errors, element._id, 'required');
        }

    }

    return true;
};

export const addValidationsForRequired = (validation, to_validate) => {
    if (validation.validation === VALIDATION.REQUIRED) {
        to_validate.required = true;
    } else if (validation.validation === VALIDATION.NOT_REQUIRED) {
        to_validate.required = false;
    } else if (validation.validation === VALIDATION.HIDDEN) {
        to_validate.hidden = true;
    } else if (validation.validation === VALIDATION.VISIBLE) {
        to_validate.hidden = false;
    }
};

const isInt = (x) => {
    const y = parseInt(x, 10);
    return !isNaN(y) && x == y && x.toString() == y.toString();
};