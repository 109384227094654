import React from 'react'

const FinishPage = () => {

    return(
        <div className="form-preview">


            <div className="content-preview">
                <div className="table">
                    <div className="table-cell">
                        <h1>Thank you!</h1>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FinishPage