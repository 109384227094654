import React from 'react'

import Navbar from '../../components/Navbar/Navbar'
import Loading from "../../components/Loading"
import API from '../../api'
import Form from './Forms.Form'
import Pagination from '../../components/Ipagination'
import Portrait from '../../components/Portrait'
import {notification} from "../../components/Lnotification"
import {saveAs} from "file-saver";
import {useHistory} from 'react-router-dom'

import ConfigureStorage from '../../components/ConfigureStorage'

const Forms = props => {

    const history = useHistory()
    const page = new URLSearchParams(history.location.search).get('page')

    const [state, setState] = React.useState({
        data: [],
        pages: 0,
        page: page || 1,
        loading: false,
        deleting: {},
        sentUsers: [],
        completedUsersModal: false,
        sentUsersModal: false,
    }, "forms");

    React.useEffect(() => {
        getForms(state)
    }, [state.page]);

    const getForms = async (params = {}) => {
        try {
            setState(state => ({...state, loading: true}));
            const {data} = await API.get('forms', {
                params: {
                    page: params.page !== undefined ? params.page : state.page,
                    search: params.search !== undefined ? params.search : state.search,
                    sort: params.sort !== undefined ? params.sort : state.sort,
                    order: params.order !== undefined ? params.order : state.order
                }
            });
            setState(state => ({...state, loading: false, data: data.data, pages: data.pages}))
        } catch (err) {
            setState(state => ({...state, loading: false}));
            notification.warning({message: "There was an error while retrieving the list of forms"})
        }
    };

    const deleteForm = async id => {
        setState(state => ({...state, loading: true}));
        try {
            await API.delete(`/forms/${id}`)
            setState(state => ({
                ...state,
                data: state.data.filter(form => form._id !== id)
            }))
        } catch (err) {
            notification.warning({message: "There was an error while deleting the form"})
        }
        setState(state => ({...state, loading: false}));
    };

    const exportForms = (export_type) => async () => {
        const extension = {
            'pdf': 'pdf',
            'excel': 'xlsx'
        };
        setState(state => ({...state, loading: true}));
        try {
            const {data} = await API.get(`/forms/list/export/${export_type}`, {
                params: {
                    search: state.search,
                    sort: state.sort,
                    order: state.order
                },
                responseType: 'blob'
            });
            saveAs(data, `forms_list.${extension[export_type]}`);
        } catch (err) {
            notification.warning({message: "There was an error while retrieving the data"})
        }
        setState(state => ({...state, loading: false}));
    };

    const changeSearch = async ({target: {value: search}}) => {
        setState(state => ({
            ...state,
            search
        }));
        await getForms({search});
    };

    const onPageChange = page => {
        history.push({
            pathname: history.location.pathname,
            search: `?page=${page}`
        })
        setState(state => ({...state, page}))
    }

    const setColumnSorting = (sort, order) => async () => {
        setState(state => ({
            ...state,
            sort,
            order
        }));

        await getForms({order, sort});
    };

    const renderSortIcon = (column) => {
        if (column !== state.sort)
            return <span className="cntrl-box"><i
                className="cntrl-down icon-arrow-down" onClick={setColumnSorting(column, 'desc')}/><i
                className="cntrl-up icon-arrow-up" onClick={setColumnSorting(column, 'asc')}/></span>;

        if (state.order === 'desc')
            return <span className="cntrl-box"><i
                className="cntrl-down icon-arrow-down selected" onClick={setColumnSorting(null, null)}/><i
                className="cntrl-up icon-arrow-up" onClick={setColumnSorting(column, 'asc')}/></span>;

        return <span className="cntrl-box"><i
            className="cntrl-down icon-arrow-down" onClick={setColumnSorting(column, 'desc')}/><i
            className="cntrl-up icon-arrow-up selected" onClick={setColumnSorting(null, null)}/></span>;
    }

    return (
        <>
            <ConfigureStorage />
            <div
                className={`wrapper forms-page ${(state.deleting.status ? "view-overlay-delete-form" : "")} ${(state.completedUsersModal ? "view-overlay-users-completed" : "")} ${(state.sentUsersModal ? " view-overlay-users-sent" : "")}`}>
                <Navbar/>
                <div className="content">
                    <div className="content-box">
                        <div className="content-box-header">
                            <h3>My forms</h3>
                            <div className="content-box-options">
                                <div className="content-box-header-search">
                                    <form className="content-search">
                                        <fieldset>
                                            <input type="text" name="" placeholder="Search" value={state.search}
                                                   onChange={changeSearch}/>
                                            <button className="tbl-btn"/>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="content-box-body content-box-body-fixed-elements">
                            <div className="box-body box-body-fixed">

                                <div className="table-fixed-header">
                                    <ul className="table-ul forms-list">
                                        <li className="table-ul-header">
                                            <ul>
                                                <li><strong>Name</strong></li>
                                                <li className="with-cntrl-box">
                                                    <strong>No. of responses</strong>
                                                    {renderSortIcon('no_sessions')}
                                                </li>
                                                <li className="with-cntrl-box">
                                                    <strong>Sent</strong>
                                                    {renderSortIcon('sent')}
                                                </li>
                                                <li className="with-cntrl-box">
                                                    <strong>Completed</strong>
                                                    {renderSortIcon('finished')}
                                                </li>
                                                <li className="with-cntrl-box">
                                                    <strong>Last response</strong>
                                                    {renderSortIcon('last_session')}
                                                </li>
                                                <li><strong/></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="scrollbar">
                                    {state.loading && <Loading color="#39193B"/>}
                                    {state.data.length === 0 &&
                                    <div style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        fontSize: "1.1rem"
                                    }}>Your form list is empty</div>
                                    }
                                    {state.data.length > 0 &&
                                    <ul className="table-ul forms-list">
                                        {state.data.map((form, index) =>
                                            <Form
                                                form={form}
                                                key={form._id}
                                                index={index}
                                                setState={setState} state={state}
                                                deleteForm={() => deleteForm(form._id)}
                                                getForms={getForms}
                                            />
                                        )}
                                    </ul>
                                    }
                                </div>

                                <div className="box-action table-action-fixed with-pagination">
                                    <a
                                        className="button with-icon-right"
                                        href="#"
                                        title="New Form"
                                        onClick={e => {
                                            e.preventDefault();
                                            props.history.push('/forms/new')
                                        }}
                                    ><i className="icon-plus"/>Add new</a>

                                    <Pagination
                                        pages={state.pages}
                                        page={state.page}
                                        onChange={onPageChange}
                                    />

                                    <div className="export-options">
                                        <span>Export:</span>
                                        <button className="button button-outline" onClick={exportForms('pdf')}>PDF
                                        </button>
                                        <button className="button button-outline" onClick={exportForms('excel')}>XLS
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Portrait/>
        </>
    )
}

export default Forms