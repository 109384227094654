import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText";

const ContractCommunicationOptions = ({
                                          element,
                                          answers_by_code,
                                          value = {
                                              communication: 'post',

                                              first_name: '',
                                              last_name: '',
                                              country: '',
                                              locality: '',
                                              street: '',
                                              nr: '',
                                              block_value: '',
                                              entry: '',
                                              floor: '',
                                              apartment: '',
                                              postal_code: '',

                                              email: '',
                                          },
                                      }) => {

    return (
        <div className="fieldset-row">
            {element.title &&
            <p style={{width: '100%'}}>{synchronizeText(element.title, answers_by_code)}</p>
            }

            {element.text &&
            <p style={{width: '100%'}}>{synchronizeText(element.text, answers_by_code)}</p>
            }
            <div className="mco-cell-header">
                <div className="session-form-row-radio">
                    <input type="radio" checked={value.communication === 'post'} value='post'/>
                    <label style={{fontWeight: "300"}}>By mail</label>
                </div>
            </div>
            {value.communication === 'post' &&
            <fieldset className="session-form-grid">
                <div className="session-form-grid-row">
                    <div className="session-form-row">
                        <label>Name</label>
                        <span className="input-box">{value.first_name}</span>
                    </div>
                    <div className="session-form-row">
                        <label>Surname</label>
                        <span className="input-box">{value.last_name}</span>
                    </div>
                </div>

                <div className="session-form-grid-row">
                    <div className="session-form-row">
                        <label>County</label>
                        <span className="input-box">{value.country}</span>
                    </div>

                    <div className="session-form-row">
                        <label>City</label>
                        <span className="input-box">{value.locality}</span>
                    </div>
                </div>

                <div className="session-form-grid-row street-grid">
                    <div className="session-form-row">
                        <label>Street</label>
                        <span className="input-box">{value.street}</span>
                    </div>

                    <div className="session-form-row">
                        <label>No</label>
                        <span className="input-box">{value.nr}</span>
                    </div>
                </div>

                <div className="session-form-grid-row address-grid">
                    <div className="session-form-row">
                        <label>Building</label>
                        <span className="input-box">{value.block_value}</span>
                    </div>

                    <div className="session-form-row">
                        <label>Entry</label>
                        <span className="input-box">{value.entry}</span>
                    </div>

                    <div className="session-form-row">
                        <label>Floor</label>
                        <span className="input-box">{value.floor}</span>
                    </div>

                    <div className="session-form-row">
                        <label>Apartment</label>
                        <span className="input-box">{value.apartment}</span>
                    </div>
                </div>

                <div className="session-form-grid-row address-grid">
                    <div className="session-form-row">
                        <label>Postal Code</label>
                        <span className="input-box">{value.postal_code}</span>
                    </div>
                </div>
            </fieldset>
            }
            <div className="mco-cell-header">
                <div className="session-form-row-radio">
                    <input type="radio" checked={value.communication === 'email'} value='email'/>
                    <label style={{fontWeight: "300"}}>By email</label>
                </div>

                {value.communication === 'email' &&
                <div className="session-form-row">
                    <span className="input-box">{value.email}</span>
                </div>
                }
            </div>
            <div className="mco-cell-header">
                <div className="session-form-row-radio">
                    <input type="radio" checked={value.communication === 'sms'} value='sms'/>
                    <label style={{fontWeight: "300"}}>By text message</label>
                </div>

                {value.communication === 'sms' &&
                <div className="session-form-row">
                    <span className="input-box">{value.sms_number}</span>
                </div>
                }
            </div>
        </div>
    );
};

export default ContractCommunicationOptions;