import React from 'react'
import styled from 'styled-components'

import {synchronizeText} from "../../utils/synchronizeText"
import LfullscreenPopUp from '../../components/LfullscreenPopUp'
import Loading from '../../components/Loading'
import API from '../../api'

export default ({ 
    element,  
    answers_by_code, 
    answers,
    form
}) => {

    const documents = form.documents
    const form_id = form._id

    const [state, setState] = React.useState({ previews: {} })

    const setPreview = (id, data) => setState(state => ({ previews: { ...state.previews, [id]: { ...state.previews[id], ...data} } }))

    const previewDocument = documentId => {

        setPreview(documentId, { open: true, loading: true  })

        const values = Object.entries(answers).map(([code, value]) => ({
            element_id: form.elements.find(element => element.code === code)._id,
            value: value
        }))

        const data = JSON.stringify(values)

        API.post(`/forms/documents/form/${form_id}/id/${documentId}`, { data })
        
        .then(({ data }) => {
            setPreview(documentId, { loading: false, url: data.preview_url })
        })

    }

    const closePreview = documentId => {
        setPreview(documentId, { open: false, url: null })
    }

    React.useEffect(() => {
        const script = document.createElement("script")
        script.async = true
        script.src = `${process.env.REACT_APP_DOCUMENT_SERVER_URL}/web-apps/apps/api/documents/api.js`
        const element = document.head.appendChild(script)
        return () => element.remove()
    }, [])

    React.useEffect(() => {
        setState({ previews: documents.reduce((acc, val) => ({ ...acc, [val._id]: {} }), {}) })
    }, [])

    
    return (
        <div className="fieldset-row">
            {element.title && <h3>{synchronizeText(element.title, answers_by_code)}</h3>}
            {element.text && <p>{synchronizeText(element.text, answers_by_code)}</p>}

            <div className="form-grid files-list" style={{gridTemplateColumns: 'repeat(3, 1fr)'}}>
                {documents.map(document => (
                    <React.Fragment key={document._id}>
                        <div className="file" onClick={() => {
                            previewDocument(document._id)
                        }}>
                            <img src='alchemistLight/img/file.svg' alt="" style={{ cursor: "zoom-in" }}/>
                            <strong>{document.filename.replace(/\.[^/.]+$/, "")}</strong>
                        </div>
                    </React.Fragment>
                ))}

                {Object.keys(state.previews).map(key => (
                    <LfullscreenPopUp visible={state.previews[key].open} closeFn={() => closePreview(key)} key={key}>
                        <>
                        {state.previews[key].loading && <Loading />}
                        {state.previews[key].url &&
                            <IframeWrapper>
                                <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${state.previews[key].url}`} frameBorder="no" />
                            </IframeWrapper>
                        }
                        </>
                    </LfullscreenPopUp>
                ))}
            </div>
        </div>
    )

}

const IframeWrapper = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    > iframe {
        width: 100%;
        height: 100vh;
        border: 0;
    }
`