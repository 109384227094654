import React from 'react'

import LivePreview from './LivePreview.map'
import Editor from './Editor.map'
import CodeCopy from './CodeCopy.map'
import View from './View.map'
import Input from './Input.map'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <Editor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,

    default: () => ({
        type: "map element",
        title: "Map Title",
        text: "Map Placeholder",
        pinned: {
            status: false
        },
        validations: []
    }),

    availableValidations: ['required', 'min length', 'max length'],
    validate: () => false,
    group: "GENERAL",
    icon: "icon-ia-pin-map",
    name: "Map",
    view: args => <View {...args}/>
}