import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({ 
    element
}) => {
    return (
        <div className="fieldset-row">
            <p>{element.text}</p>
            <CodeCopy text={element.code}>
                <div className="form-grid" style={{ gridTemplateColumns: `repeat(${element.grid_columns}, 1fr)` }}>
                    {element.items.map((item, itemIndex) => (
                        <div className="form-row-radio" key={itemIndex}>
                            <input type="radio" checked={false} onChange={f=>f}/>
                            <label>{item}</label>
                        </div>
                    ))}
                </div>
            </CodeCopy>
        </div>
    )
}