import React from 'react'

import LivePreview from './LivePreview.customerSatisfaction'
import Editor from './Editor.customerSatisfaction'
import CodeCopy from './CodeCopy.customerSatisfaction'
import View from './View.customerSatisfaction'
import Input from './Input.customerSatisfaction'

export default {
  livePreview: args => <LivePreview {...args}/>,
  editor: args => <Editor {...args}/>,
  codeCopy: args => <CodeCopy {...args}/>,
  input: args => <Input {...args}/>,

  default:() => ({
    type: "customer satisfaction",
    title: "Customer Satisfaction Title",
    text: "Customer Satisfaction Description",
    descriptionTitle: "Details",
    pinned: {
      status: false
    },

    worst: "Worst",
    poor: "Poor",
    average: "Average",
    good: "Good",
    excellent: "Excellent",

    validations: [],
    placeholder: "Customer satisfaction Placeholder"
  }),

  availableValidations: ['required'],
  validate: () => false,
  group: "ADVANCED",
  icon: "icon-ia-satisfaction",
  name: "Customer Satisfaction",
  view: args => <View {...args}/>
}