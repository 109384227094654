import React from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  /*Added Franci*/
  /* position: absolute;
top: 0;
z-index: 10000;
background: #F1F1F1; */

/* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%); */
`;

const Loading = ({color = "#39193B", rawCss=``, ...other}) => (
    <LoadingContainer {...other} css={`${rawCss}`}>
        <Loader
            type="Bars"
            color={color}
            height="100"
            width="100"
        />
    </LoadingContainer>
)

export default Loading;