import React, {useEffect} from 'react'
import {DatePicker} from 'antd'
import moment from 'moment';
import styled from 'styled-components'
import levenshtein from 'js-levenshtein'
import {
    BrowserView,
    MobileView
} from "react-device-detect"

import API from '../../api'
import {VALIDATION} from '../../constants/validations'
import {getError} from "../../utils/getError"
import Loading from "../../components/Loading"
import {Country, City, list} from '../../components/CountryCity'
import LdeleteConfirm from '../../components/LdeleteConfirm'
import TakePicture from '../../components/TakePicture'


const findClosest = (pattern, list) => {
    return list.reduce((acc, item) => {
        let {closest, diference} = acc;
        let thisDiference = levenshtein(pattern, item);
        if (thisDiference < diference) {
            closest = item;
            diference = thisDiference;
        }
        return {closest, diference}
    }, {closest: undefined, diference: 999})
        .closest
};

export default ({
                    element,
                    value = {},
                    changeValue = f => f,
                    errors = []
                }) => {

    const [state, setState] = React.useState({
        loading: false,
        error: null,
        step: 1,
        image: null
    });
    const skipFirstTwoSteps = element.validations.map((validation) => validation.validation).includes(VALIDATION.SKIP_FIRST_TWO_STEPS);
    const setStep = step => {
        setState(state => ({...state, step}))
    };

    const uploadImage = image => {
        setState(state => ({
            ...state,
            step: 2,
            image
        }))
    };

    const getIdCardInformation = async (data, callback) => {
        try {
            setState(state => ({...state, loading: true}));
            const response = await API.post('/services/extract-id-card-information', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            callback(response.data);
            setState(state => ({...state, loading: false}))
        } catch (err) {
            setState(state => ({
                ...state,
                loading: false,
                error: getError(err)
            }))
        }
    };


    let previewImg;

    useEffect(() => {
        if (skipFirstTwoSteps) {
            setStep(3);
        }
    }, [skipFirstTwoSteps]);
    //
    // useEffect(() => {
    //     if (Object.keys(value).length === 0 && !skipFirstTwoSteps)
    //         setStep(1);
    // }, [value, skipFirstTwoSteps]);

    useEffect(() => {
        if (state.image !== null) {
            const reader = new FileReader();

            reader.onload = function (e) {
                this.setAttribute('src', e.target.result);
            }.bind(previewImg);
            reader.readAsDataURL(state.image);
        }
    }, [state.image, previewImg]);

    const setUploadImage = (e) => {
        const file = e.target.files[0];
        uploadImage(file);
        e.target.value = null;
    };

    const getCardInformation = () => {
        const data = new FormData();

        data.append('doc', state.image);

        getIdCardInformation(data, (card_information) => {
            changeValue(card_information);
            setStep(3);
        });
    };

    const resetData = () => {
        changeValue({});
        setState(state => ({
            ...state,
            step: 1,
            image: null
        }))
    };

    const getValue = (name) => {
        if (Object.keys(value).length === 0) return '';
        return value[name]
    };

    const getDateValue = (name) => {
        if (Object.keys(value).length === 0) return null;
        if (!value[name] || value[name] === null) return null;
        return moment(value[name], 'YYYY-MM-DD')
    };

    const setValue = (e) => {
        changeValue({
            ...value,

            [e.target.name]: e.target.value
        })
    };

    const onCountryChange = (e, fromUser = false) => {
        if (fromUser) {
            changeValue({
                ...value,
                [e.target.name]: e.target.value,
                locality: ""
            })
        } else {
            changeValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    };

    const setDateValue = (field) => (_, stringDate) => {
        let dateValue = stringDate !== '' ? moment(stringDate, 'DD/MM/YYYY') : ''
        changeValue({
            ...value,
            [field]: dateValue
        })
    };

    const errorsContain = (...args) => {
        for (let i = 0; i < args.length; i++) {
            if (errors.includes(args[i])) {
                return true;
            }
        }
        return false;
    };


    return (
        <div className="fieldset-row">
            <div
                className={`id-card-extractor ${(state.step >= 2) ? 'view-ice-step2' : ''} ${state.step === 3 ? 'view-ice-step3' : ''}`}>
                {(!skipFirstTwoSteps && (state.step < 2 || state.image)) &&
                <div
                    className={`id-card-extractor-list`}>

                    <div className="id-card-extractor-cell">
                        <h3><span>{`${element.step1} `}</span>{element.uploadTakePictureLabel}</h3>
                        <div className="step1-btns">
                            <BrowserView>
                                <TakePicture
                                    onCapture={file => uploadImage(file)}
                                >
                                    <div
                                        css={`::before {content: "${({uploadButton}) => uploadButton}" !important;}`} 
                                        className="upload-image-box upload-camera-img"
                                        uploadButton={element.cameraImageButton}
                                    />
                                </TakePicture>
                            </BrowserView>
                            <MobileView>
                                <div 
                                    className="upload-image-box upload-camera-img"
                                    uploadButton={element.cameraImageButton}
                                    css={`::before {content: "${({uploadButton}) => uploadButton}" !important;}`} 
                                >
                                    <input className="upload-image-input" type="file" name="image" accept="image/*" capture="camera" onChange={setUploadImage}/>
                                </div>
                            </MobileView>
                            <div 
                                css={`
                                    ::before {content: "${({uploadButton}) => uploadButton}" !important;}
                                    && {::after {content: '\\ee5a';}}
                                `} 
                                className="upload-image-box" 
                                uploadButton={element.uploadImageButton}
                            >
                                <input className="upload-image-input" type="file" name="image" accept="image/*" onChange={setUploadImage}/>
                            </div>
                        </div>
                        <p>Please take a photo<br/> or upload a photo of your ID card</p>
                        <p>OR</p>
                        <span className="add-block-input mar2b" onClick={() => setState(state => ({
                            ...state,
                            step: 3,
                            image: null
                        }))}>Complete manually</span>
                    </div>
                    {state.image &&
                    <div className="id-card-extractor-cell">
                        <h3><span>{`${element.step2} `}</span>{element.previewUploadedImage}</h3>
                        <div className="id-card-image">
                            <img ref={ref => previewImg = ref} alt="" style={{maxWidth: "100%"}}/>
                            {state.loading &&
                            <Loading/>
                            }
                        </div>

                        {!state.loading &&
                        <div className="id-card-image-actions">
                                <span className="button button-outline discard-btn"
                                      onClick={() => setState(state => ({
                                          ...state,
                                          step: 1,
                                          image: null
                                      }))}>{element.uploadImageAgainButton}</span>
                            <span className="button button-outline accept-btn"
                                  onClick={getCardInformation}>{element.getIdInformationButton}</span>
                        </div>
                        }
                    </div>
                    }
                </div>
                }
                <div className="id-card-extractor-cell id-card-extractor-cell-step-3">
                    <h3><span>{`${element.step3} `}</span>{element.cardInformation}</h3>
                    <fieldset className="form-grid">
                        <div className="form-grid-row">
                            <div className="form-row">
                                <label>Name</label>
                                <input value={getValue('first_name')} name="first_name" onChange={setValue}/>
                            </div>
                            <div className="form-row">
                                <label>Surname</label>
                                <input value={getValue('last_name')} name="last_name" onChange={setValue}/>
                            </div>
                        </div>
                        <div className="form-grid-row">
                            <div
                                className={`form-row ${errorsContain('not_valid_email', 'email_required') ? 'error' : ''}`}>
                                <label>Email</label>
                                <input value={getValue('email')} name="email" onChange={setValue}/>
                                {errorsContain('not_valid_email') &&
                                <span className="form-row-error-msg">Invalid email</span>
                                }
                            </div>
                            <div
                                className={`form-row ${errorsContain('not_valid_phone_number', 'phone_number_required') ? 'error' : ''}`}>
                                <label>Phone</label>
                                <input value={getValue('phone')} name="phone" onChange={setValue}/>
                                {errorsContain('not_valid_phone_number') &&
                                <span className="form-row-error-msg">Invalid phone</span>
                                }
                            </div>
                        </div>
                        <div className="form-grid-row">
                            <div className="form-row">
                                <label>CNP</label>
                                <input value={getValue('cnp')} name="cnp" onChange={setValue}/>
                            </div>
                            <div
                                className={`form-row with-calendar ${errorsContain('not_after_session_date_of_birth') ? 'error' : ''}`}>
                                <label>Date of birth</label>
                                <StyledDatePicker
                                    format={'DD/MM/YYYY'}
                                    onChange={setDateValue('date_of_birth')}
                                    value={getDateValue('date_of_birth')}
                                />
                                {errorsContain('not_after_session_date_of_birth') &&
                                <span className="form-row-error-msg">The date cannot be in the future</span>
                                }
                            </div>
                        </div>
                        <div className="form-grid-row">
                            <div className="form-row">
                                <label>County</label>
                                {((receivedValue, onChange) => {
                                    let value = receivedValue;
                                    if (value !== "" && value !== undefined && !list[value]) {
                                        value = findClosest(value, Object.keys(list));
                                        onChange({target: {name: "country", value}})
                                    }
                                    return (
                                        <Country value={value} onChange={e => onChange(e, true)} name="country"
                                                 placeholder="Choose"/>
                                    )
                                })(getValue('country'), onCountryChange)}

                            </div>
                            <div className="form-row">
                                <label>City</label>
                                {((receivedValue, onChange, selectedCountry) => {
                                    let value = receivedValue;
                                    if (value !== "" && !!selectedCountry && list[selectedCountry] && !list[selectedCountry].includes(value)) {
                                        value = findClosest(value, list[selectedCountry]);
                                        onChange({target: {name: "locality", value}})
                                    }
                                    return (
                                        <City value={value} selectedCountry={selectedCountry} onChange={onChange}
                                              name="locality" placeholder="Choose"/>
                                    )
                                })(getValue('locality'), setValue, getValue('country'))}

                            </div>
                        </div>
                        <div className="form-grid-row street-grid">
                            <div className="form-row">
                                <label>Street</label>
                                <input value={getValue('street')} name="street" onChange={setValue}/>
                            </div>
                            <div className="form-row">
                                <label htmlFor="">No</label>
                                <input value={getValue('nr')} name="nr" onChange={setValue}/>
                            </div>
                        </div>
                        <div className="form-grid-row address-grid">
                            <div className="form-row">
                                <label>Building</label>
                                <input value={getValue('block_value')} name="block_value" onChange={setValue}/>
                            </div>
                            <div className="form-row">
                                <label>Entry</label>
                                <input value={getValue('entry')} name="entry" onChange={setValue}/>
                            </div>
                            <div className="form-row">
                                <label>Floor</label>
                                <input value={getValue('floor')} name="floor" onChange={setValue}/>
                            </div>
                            <div className="form-row">
                                <label>Apartment</label>
                                <input value={getValue('apartment')} name="apartment" onChange={setValue}/>
                            </div>
                        </div>
                        <div className="form-grid-row id-grid">
                            <div className={`form-row ${errorsContain('not_valid_serie_ci') ? 'error' : ''}`}>
                                <label>CI Series</label>
                                <input value={getValue('series_ci')} name="series_ci" onChange={setValue}/>
                                {errorsContain('not_valid_serie_ci') &&
                                <span className="form-row-error-msg">CI series invalid</span>
                                }
                            </div>
                            <div className={`form-row ${errorsContain('not_valid_nr_ci') ? 'error' : ''}`}>
                                <label>CI No</label>
                                <input value={getValue('nr_ci')} name="nr_ci" onChange={setValue}/>
                                {errorsContain('not_valid_nr_ci') &&
                                <span className="form-row-error-msg">CI number invalid</span>
                                }
                            </div>
                            <div className="form-row">
                                <label>Emitted by</label>
                                <input value={getValue('emitted_by')} name="emitted_by" onChange={setValue}/>
                            </div>
                        </div>
                        <div className="form-grid-row">
                            <div
                                className={`form-row with-calendar ${errorsContain('not_after_session_date_available_from') ? 'error' : ''}`}>
                                <label>Valid from</label>
                                <StyledDatePicker
                                    format={'DD/MM/YYYY'}
                                    onChange={setDateValue('available_from')}
                                    value={getDateValue('available_from')}
                                />
                                {errorsContain('not_after_session_date_available_from') &&
                                <span className="form-row-error-msg">The date cannot be in the future</span>
                                }
                            </div>
                            <div
                                className={`form-row with-calendar ${errorsContain('not_before_session_date_available_to') ? 'error' : ''}`}>

                                <label>Valid until</label>
                                <StyledDatePicker
                                    format={'DD/MM/YYYY'}
                                    onChange={setDateValue('available_to')}
                                    value={getDateValue('available_to')}
                                />
                                {errorsContain('not_before_session_date_available_to') &&
                                <span className="form-row-error-msg">The date cannot be in the past</span>
                                }
                            </div>
                        </div>
                    </fieldset>
                    {!skipFirstTwoSteps &&
                    <LdeleteConfirm
                        title="Are you sure you want to upload another image?"
                        onConfirm={resetData}
                        okText="Ok"
                    >
                        <span className="add-block-input mar2b">{element.uploadAnotherImageButton}</span>
                    </LdeleteConfirm>
                    }
                </div>
            </div>
        </div>
    );
};

const UploadButton = styled.div`
    ::before {
        content: "${({uploadButton}) => uploadButton}" !important;
    }
`;
const StyledDatePicker = styled(DatePicker)`
    width: 100%;
    .ant-calendar-picker-input{
    height: 3.6rem;
}
    `;