import React from "react"
import {css} from 'styled-components'
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api"

import GoogleMapsLoader from "../../components/GoogleMapsLoader"
import { synchronizeText } from "../../utils/synchronizeText"
import Loading from '../../components/Loading'

const cssFixes = css`

    .location-box {
        width: 100%;
        height: 30rem;
        position: relative;
    }

    #map {
        position: relative;
        overflow: hidden;
    }

    .location-box #map {
        width: 100%;
        height: 100%;
    }

    .search-location {
        width: auto;
        position: absolute;
        top: .8rem;
        left: .8rem;
        z-index: 1000;
    }

    .search-location:before {
        width: 3.6rem;
        height: 3.6rem;
        position: absolute;
        top: 0;
        left: 0;
        content: '\\e9d8';
        text-align: center;
        font-size: 1.4rem;
        line-height: 3.6rem;
        text-align: center;
        font-family: icons;
    }

    .view-dropdown input {
        box-shadow: var(--shadow)!important;
        outline: none!important;
        border-color: var(--primary-color)!important;
        background: #fff!important;
    }

    .search-location input {
        width: 25rem;
        background: #fff!important;
        padding-left: 3.5rem;
    }

    .view-dropdown .dropdown {
        height: 13rem;
        border: 1px solid #ccc;
        box-shadow: var(--shadow);
    }

    .view-dropdown .dropdown {
        height: 13rem;
        border: 1px solid #ccc;
        box-shadow: var(--shadow);
    }

    .dropdown .scrollbar {
        /* max-height: 16rem; */
        padding: 0!important;
    }




    .dropdown ul {
        width: 100%;
        margin: auto;
        padding: 0;
    }

    .dropdown ul li {
        width: 100%;
        position: relative;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.8rem;
        color: #333;
        border-bottom: 1px solid #ccc;
        padding: .7rem 2rem .7rem 1.2rem;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-transition: color .3s ease-in-out, background .3s ease-in-out;
        transition: color .3s ease-in-out, background .3s ease-in-out;
    }

    .dropdown ul li:after {
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: 50%;
        right: .5rem;
        -webkit-transform: translate(0,-50%) scale(0);
        transform: translate(0,-50%) scale(0);
        content: '\\ee68';
        font-size: .8rem;
        line-height: 1.5rem;
        text-align: center;
        font-family: icons;
        background: #5d2560;
        color: #fff;
        border-radius: 50%;
        -webkit-transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
    }

    .dropdown {
        width: 100%;
        height: 0;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 100;
        background: #fff;
        overflow: hidden;
        margin-top: 1px;
        -webkit-transition: height .3s ease-in-out, border .3s ease-in-out, box-shadow .3s ease-in-out;
        transition: height .3s ease-in-out, border .3s ease-in-out, box-shadow .3s ease-in-out;
        border: 0 solid transparent;
        box-shadow: none;
        border-radius: .3rem;
    }

`

const ViewMap = ({ element, answers_by_code, value = "" }) => {
	return (
		<div className="fieldset-row" style={{ pointerEvents: "all" }} css={cssFixes}>
			{element.title && <p>{synchronizeText(element.title, answers_by_code)}</p>}
			{element.text && <p>{synchronizeText(element.text, answers_by_code)}</p>}
			<div className="location-box">
				<div id="map">
					<MapComponent value={value} />
				</div>
			</div>
		</div>
	)
}

const defaultLocation = {
	lat: 45.9237859,
	lng: 22.7775086,
}

const MapComponent = ({ value }) => {

	return (
		<GoogleMapsLoader googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} loader={<Loading />}>
			<GoogleMap
				mapContainerStyle={{
					height: "100%",
					width: "100%",
				}}
				zoom={12}
				center={
					(value &&
						value.location &&
						value.location.coordinates && {
							lat: value.location.coordinates[1],
							lng: value.location.coordinates[0],
						}) ||
					defaultLocation
				}
				options={{
					mapTypeControl: false,
				}}
			>
				{value && (
					<Marker
						position={
							(value.location &&
								value.location.coordinates && {
									lat: value.location.coordinates[1],
									lng: value.location.coordinates[0],
								}) ||
							defaultLocation
						}
						icon={"alchemistLight/img/pin.png"}
					>
						<InfoWindow>
							<span>{value.address || ""}</span>
						</InfoWindow>
					</Marker>
				)}
			</GoogleMap>
		</GoogleMapsLoader>
	)
}

export default ViewMap
