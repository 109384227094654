import React from 'react'
import {useAppContext, getStore} from '../context'

const Lnotification = () => {
    const {notifications: [state]} = useAppContext()

    return(
        <>
            {state &&
                <div 
                    css={`
                        width: 100%;
                        position: absolute;
                        top: 7rem;
                        left: 0;
                        z-index: 99;
                        background: rgba(26,26,26,.9);
                        color: #fff;
                        padding: 2.5rem 2.4rem;
                        text-shadow: 0 1px 1px rgba(0,0,0,.3);
                        transition: transform .4s ease-out;
                        transform: ${state.open ? 'translate(0,0)' : 'translate(0,-101%)'};

                        p {
                            width: auto;
                            display: inline;
                            font-size: 1.3rem;
                            font-weight: 300;
                            line-height: 2rem;
                            margin: 0 1.5rem 0 0;
                        }

                        background: ${{
                            warning: 'rgba(235,43,43,.95)',
                            info: 'rgba(70,185,251,.95)',
                            success: 'rgba(60,186,43,.95)',
                            error: 'rgba(235,43,43,.95)'
                        }[state.type]};

                        .button {
                            height: 2.8rem;
                            line-height: 2.6rem;
                            padding: 0 1.3rem;
                            font-size: 1rem;
                            border-color: #fff;
                            color: #fff;
                            :hover {
                                border-color: var(--primary-color);
                            }
                        }
                    `}
                >
                    {state.message}
                </div>     
            }      
        </>
    )
}

Lnotification.notify = ({type="success", message="", duration=3000}) => {
    const {notifications: [state, setState]} = getStore()
    if(state.open) {
        state.cancel()
    }


    let timeoutId
    if(duration > 0) {
        const timeoutId = setTimeout(() => {
            setState(state => ({...state, open: false}))
        }, duration)
    }

    const cancel = () => {
        if(duration > 0) clearTimeout(timeoutId)
        setState(state => ({...state, open: false, message: ''}))
    }

    setState({message, type, open: true, cancel})
}

['info', 'warning', 'success', 'error'].forEach(type => Lnotification[type] = args => Lnotification.notify({...args, type}))

const notification = Object.entries(Lnotification).reduce((acc, [key, value]) => {acc[key] = value; return acc}, {})

notification.cancel = () => {
    const {
        notifications: [state],
    } = getStore()
    if (state.open) {
        state.cancel()
    }
}



export {notification}
export default Lnotification