import React from 'react'

function usePersistedState(defaultValue, key) {
  const [state, setState] = React.useState(
    JSON.parse(localStorage.getItem(key)) || defaultValue,
    key
  );
  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  const resetSate = () => setState(defaultValue)
  return [state, setState, resetSate]
}

const AppContext = React.createContext(null)
export default AppContext
export const useAppContext = () => React.useContext(AppContext)
export var store = {}
export const getStore = () => ({...store})
export const AppContextProvider =  ({ children }) => {
 
  const auth = usePersistedState(null, 'auth')

  const notifications = React.useState({})
  
  const storageNotifications = usePersistedState({
    configureOedrivePrompedOnce: false
  }, "storage-notifications")

  const setDefaultState = () => [auth, storageNotifications].forEach(storage => storage[2]())

  window.resetState = setDefaultState
  store = {
    auth,
    notifications,
    storageNotifications,
    setDefaultState
  }
  
  return <AppContext.Provider value={store}>{children}</AppContext.Provider>
}