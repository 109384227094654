import React from 'react';

import {SignaturePad, SignatureImage} from "../../components/SignaturePads";

const AgentSignature = ({
    element,
    answers_by_code,
    value = {
        name: '',
        signature: null
    }
}) => {

    if (value.signature === null || value.signature === "") {
        return (
            <div className="fieldset-row">
                {element.text &&
                    <p>{element.text}</p>
                }

                <p className="form-box-wrap-descrition">{value.name}</p>

                <div className="signaturePadContainerViewSession">
                    <SignaturePad/>
                </div>
            </div>
        );
    }

    return (
        <div className="fieldset-row">
            {element.text &&
                <p>{element.text}</p>
            }

            <p className="form-box-wrap-descrition">{value.name}</p>

            <div className="signaturePadContainerViewSession">
                <SignatureImage src={value.signature} />
            </div>
        </div>
    );
};

export default AgentSignature;