import React from 'react'

import LivePreview from './LivePreview.Vin'
import Editor from './Editor.Vin'
import CodeCopy from './CodeCopy.Vin'
import View from './View.Vin'
import {elements as ELEMENTS} from '../../constants/elements'
import Input from './Input.vin'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <Editor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,

    default: () => ({
        type: ELEMENTS.VIN,
        text: "VIN Title",
        placeholder: "VIN Placeholder",
        pinned: {
            status: false
        },
        validations: []
    }),
    
    availableValidations: ['required'],
    validate: () => false,
    group: "AUTOMOTIVE",
    icon: "icon-ia-chassis",
    name: "VIN",
    view: args => <View {...args}/>
}

