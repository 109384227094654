import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {useAppContext} from '../../context'
import Lnotification from '../Lnotification'

const Navbar = () => {

    const {auth:[auth, setAuth]} = useAppContext()
    const history = useHistory()
    const location = useLocation()

    return (
        <>
            <header className="header">
                <div className="logo" onClick={() => history.push('/')} >
                    <img className="white-logo" src="alchemistLight/img/Alchemist_Identity_Final-17.png" alt="" />
                </div>
                {auth &&
                <nav className="menu">
                    <a
                        href="#"
                        className={(location.pathname === '/forms') ? 'selected' : ''}
                        onClick={e => {
                            e.preventDefault()
                            history.push('/forms')
                        }}
                        title=""
                    ><strong><span>My<br/>forms</span></strong></a>
                    <a
                        href="#"
                        className={location.pathname === '/responses' ? 'selected' : ''}
                        onClick={e => {
                            e.preventDefault()
                            history.push('/responses')
                        }}
                        title=""
                    ><strong><span>Responses</span></strong></a>
                    <a
                        href="#"
                        className={location.pathname === '/profile' ? 'selected' : ''}
                        onClick={e => {
                            e.preventDefault()
                            history.push('/profile')
                        }}
                        title=""
                    ><strong><span>My<br/>Profile</span></strong></a>
                </nav>
                }
                {auth &&
                <div className="menu-right">
                    <a
                        href="#"
                        className="logout"
                        onClick={e => {
                            e.preventDefault()
                            setAuth(null)
                        }}
                    >
                        <div className="tooltip tooltip-bottom">Logout</div>
                    </a>
                </div>
                }
            </header>
            <Lnotification />
        </>
    )
}

Object.entries(Lnotification).forEach(([key, value]) => Navbar[key] = value)

export default Navbar