import React from 'react'
import ReactDOM from 'react-dom'
export default ({links = [], children, loader=null, loadStylesOnHead=false}) => {
    const [state, setState] = React.useState(links.map(() => false))

    const onLoad = index => setState(state => state.map((s, i) => i !== index ? s : true))

    const onError = (index, err) => {
        console.error(err)
        setState(state => state.map((s, i) => i !== index ? s : true))
    }

    return (
        <>
            {loadStylesOnHead &&
                ReactDOM.createPortal(
                        <>
                            {
                                links.map((link, index) => 
                                    <link 
                                        type="text/css" href={link} rel="stylesheet" 
                                        onLoad={() => onLoad(index)} 
                                        key={index}
                                        onError={(err) => onError(index, err)}
                                    />
                                )
                            }
                        </>
                    ,
                    document.head
                )
            }

            {!loadStylesOnHead &&
                links.map((link, index) => 
                    <link 
                        type="text/css" href={link} rel="stylesheet" 
                        onLoad={() => onLoad(index)} 
                        key={index}
                        onError={(err) => onError(index, err)}
                    />
                )
            }
          
            {state.reduce((acc, val) => val && acc,true) ? children : loader}
        </>
    )
}