import React from "react"
import { useParams } from "react-router-dom"
import { DragDropContext } from "react-beautiful-dnd"

import API from "../../api"
import Navbar from "../../components/Navbar/Navbar"
import ToolBox from "./FormDefinition.Toolbox"
import Editor from "./FormDefinition.Editor"
import Sidebar from "./FormDefinition.Sidebar"
import Loading from "../../components/Loading"
import supportedElements from "../../formComponents/supportedElements"
import { elements } from "../../constants/elements"
import LnavigationPrompt from "../../components/LnavigationPrompt"
import LpopUp from "../../components/LpopUp"
import { notification } from "../../components/Lnotification"

const neededElements = Object.keys(supportedElements)
	.filter(
		key =>
			![
				elements.AGENT_SIGNATURE,
				elements.VEHICLE_EVALUATION,
				elements.ID_CARD_EXTRACTOR,
				elements.PRIVACY_AGREEMENT,
				elements.CONTRACT_COMMUNICATION_OPTIONS,
			].includes(key)
	)
	.reduce((acc, key) => ({ ...acc, [key]: supportedElements[key] }), {})

const FormDefinition = () => {
	const [state, setState] = React.useState(
		{
			loadingForm: false,
			savingFormDefinition: false,
			form: null,
			unsavedChanges: false,
		},
		"form definition"
	)

	const params = useParams()

	let editorBoxRef = React.useRef(null)

	React.useEffect(() => {
		loadForm()
	}, [])

	const loadForm = async () => {
		try {
			setState(state => ({ ...state, loadingForm: true }))
			const {
				data: { form },
			} = await API.get(`forms/${params.id}`)

			setState(state => ({
				...state,
				form: {
					...form,
					elements: form.steps.length > 0 ? form.steps[0].elements : [],
					heading: form.steps.length > 0 ? form.steps[0].heading : "",
				},
				loadingForm: false,
			}))
		} catch (err) {
			setState(state => ({ ...state, loadingForm: false }))
		}
	}

	const setForm = callback =>
		setState(state => ({
			...state,
			form: callback(state.form),
			unsavedChanges: true,
		}))

	const onAddElement = element => {
		setForm(form => ({ ...form, elements: [...form.elements, element] }))
		editorBoxRef.scrollIntoView({ block: "end", behavior: "smooth" })
	}

	const onDragEnd = result => {
		if (!result.destination) return

		// reorder
		if (result.source.droppableId === result.destination.droppableId) {
			if (result.destination.index === result.source.index) return
			moveElement(result.source.index, result.destination.index)
			return
		}

		// add element on specific position
		if (
			result.source.droppableId !== "GENERAL_COMPONENT" &&
			result.source.droppableId !== "ADVANCED_COMPONENT" &&
			result.source.droppableId !== "AUTOMOTIVE_COMPONENT" &&
			result.source.droppableId !== "FINANCIAL_COMPONENT"
		)
			return

		// add element on specified position
		addElementOnPosition(result.destination.index, {
			...neededElements[
				Object.keys(neededElements)[result.source.index]
			].default(),
			code: Date.now().toString(),
		})
	}

	const addElementOnPosition = (position, element) => {
		function insert_into_position(orArr, index, element) {
			const arr = [...orArr]
			arr.splice(index, 0, element)
			return arr
		}

		setForm(form => ({
			...form,
			elements: insert_into_position(form.elements, position, element),
		}))
	}

	const moveElement = (fromIndex, toIndex) => {
		Array.prototype.move = function (from, to) {
			this.splice(to, 0, this.splice(from, 1)[0])
			return this
		}

		setForm(form => ({
			...form,
			elements: [...form.elements].move(fromIndex, toIndex),
		}))
	}

	return (
		<>
			<div className="wrapper new-form-page">
				<Navbar />
				{(state.loadingForm || state.savingFormDefinition) && <Loading />}
				{!state.loadingForm && !state.savingFormDefinition && state.form && (
					<>
						<div className="content">
							<DragDropContext onDragEnd={onDragEnd}>
								<ToolBox onClickElement={onAddElement} elements={neededElements} />
								<Editor
									state={state}
									setState={setState}
									setForm={setForm}
									ref={el => (editorBoxRef = el)}
								/>
							</DragDropContext>
						</div>
						<Sidebar state={state} />

						<LnavigationPrompt
							when={state.unsavedChanges}
							promptCallback={({ onConfirm, onCancel }) =>
								notification.warning({
									message: (
										<>
											<p>
												Are you sure you want to leave the page? You have unsaved changes.
											</p>
											<span
												className="button button-outline"
												onClick={() => {
													onConfirm()
													notification.cancel()
												}}
											>
												Leave
											</span>
											<span
												className="tbl-btn close-subheader"
												css={`
													&&&&&&&&{
														top: 25%;
														right: 1.8%;
														color: unset !important;
														opacity: 1 !important;
														position: absolute;
													}
												`}
												onClick={() => {
													onCancel()
													notification.cancel()
												}}
											>
												x
											</span>
										</>
									),
									duration: 0,
								})
							}
						/>
					</>
				)}
			</div>
		</>
	)
}

export default FormDefinition
