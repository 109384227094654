import React from 'react'
import {DatePicker} from 'antd'
import moment from 'moment'
import {synchronizeText} from "../../utils/synchronizeText"
import styled from "styled-components";

export default ({ 
    element,
    value = null,
    changeValue,
    errors = [],
    answers_by_code
}) => {

    const readonly = (
        element.validations.find(({validation}) => validation === 'no after session date') !== undefined && 
        element.validations.find(({validation}) => validation === 'no before session date') !== undefined
    );

    React.useEffect(() => {
        if(readonly) changeValue(moment(Date()).format("DD/MM/YYYY"))
    }, [readonly]);


    const setValue = (_, date) => {
        if (date === '')
            date = null;

        changeValue(date)
    };

    return (
        <div className="fieldset-row">
            <div className={`form-row ${(errors.length > 0) ? 'error' : '' }`}>
                {element.text &&  <label>{synchronizeText(element.text, answers_by_code)}</label>}

                <StyledDatePicker
                    disabled={readonly}
                    format={'DD/MM/YYYY'}
                    onChange={setValue}
                    value={value !== null ? moment(value, 'DD/MM/YYYY') : null}
                    placeholder={element.placeholder}
                />

                {renderErrors(errors, element)}
            </div>
        </div>
    )
}

const renderErrors = (errors, element) => {
    if (errors.length === 0)
        return null;

    return errors.map(error => {
        if (error === 'required') {
            return <span key={error} className="form-row-error-msg">This field is required</span>;
        } else if (error === 'no past date') {
            let date = element.validations.find(validation => validation.validation === "no past date").value;
            return <span key={error} className="form-row-error-msg">No date before {date}</span>;
        } else if (error === 'no future date') {
            let date = element.validations.find(validation => validation.validation === "no future date").value;
            return <span key={error} className="form-row-error-msg">No date past {date}</span>;
        } else if (error === 'no after session date') {
            return <span key={error} className="form-row-error-msg">No future dates</span>
        } else if (error === 'no before session date') {
            return <span key={error} className="form-row-error-msg">No past dates</span>
        }

        return null;
    })
};

const StyledDatePicker = styled(DatePicker)`
    .ant-calendar-picker-input{
    padding: 4px 30px 4px 11px
}
    `;