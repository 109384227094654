import React from 'react'
import {notification} from '../../components/Lnotification'

import {useAppContext} from '../../context'
import Loading from '../../components/Loading'
import API from '../../api'

const DeleteAccount = ({onCancel=f=>f}) => {
    const [loading, setLoading] = React.useState(false)
    const {auth: [,setAuth]} = useAppContext()

    const deleteAccount = async () => {
        try{
            setLoading(true)
            await API.delete('users')
            setLoading(false)
            onCancel()
            setAuth(null)
        }catch(err) {
            notification.warning({message: 'Something went wrong'})
            setLoading(false)
        }
    }

    return(
        <div className="delete-account">
            <div className="titleDesc">
                <h3>Are you sure you want to delete your account?</h3>
                <p>Deleting your account will remove all data, including forms and responses.</p>
            </div>
            <form 
                className="form"
                onSubmit={e => {
                    e.preventDefault()
                    deleteAccount()
                }}
            >
                {loading && <Loading color="#39193B"/>}
                {!loading &&
                    <div className="form-actions">
                        <button className="button with-icon-right close-box"><i className="icon-ia-trash-bold"></i>Delete</button>
                        <span onClick={onCancel} className="button button-link close-box">Cancel</span>
                    </div>
                }
            </form>
        </div>
    )
}

export default DeleteAccount