import React from 'react'

import LivePreview from './LivePreview.clientSignature'
import Editor from './Editor.clientSignature'
import CodeCopy from './CodeCopy.clientSignature'
import View from './View.clientSignature'
import Input from './Input.clientSignature'

export default {
  livePreview: args =>  <LivePreview {...args}/>,
  editor: args => <Editor {...args}/>,
  codeCopy: args => <CodeCopy {...args}/>,
  input: args => <Input {...args}/>,

  default: () => ({
    type: "signature",
    text: "Signature Pad Label",
    pinned: {
      status: false
    },
    validations: []
  }),
  
  availableValidations: ['required'],
  validate: () => false,
  group: "ADVANCED",
  icon: "icon-ia-signature",
  name: "Signature Pad",
  view: args => <View {...args}/>
}