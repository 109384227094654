import React from 'react'

import LivePreview from './LivePreview.agentSignature'
import Editor from './Editor.agentSignature'
import CodeCopy from './CodeCopy.agentSignature'
import View from './View.agentSignature'

export default {
  livePreview: args => <LivePreview {...args}/>,
  editor: args => <Editor {...args}/>,
  codeCopy: args => <CodeCopy {...args}/>,

  default: () => ({
    type: "agent signature",
    text: "Agent Signature Pad Label",
    pinned: {
      status: false
    },
    validations: []
  }),
  
  validate: () => false,
  group: "ADVANCED",
  icon: "icon-ia-agent-signature",
  name: "Agent Signature Pad",
  view: args => <View {...args} />
}