import React from 'react'
import {notification} from '../../components/Lnotification'
import LdeleteConfirm from '../../components/LdeleteConfirm';
import Validations from '../Validations'
import supportedElements from '../supportedElements'
import CodeCopy from '../../components/Editor.codeCopy'

export default ({
    element, 
    remove, 
    editElement, 
    changeElementEditorMode, 
    dragHandleProps = {}, 
    VisibilityAffected, 
    formElements,
    ConditionalValidation,
    editElementByCode,
    clearOppressedElements,
    clearOppressedAndShiftAffectedIndexes 
}) => {

    const [state, setState] = React.useState({
        duplicates: []
    })

    React.useEffect(() => {
        const duplicates = element.items.reduce((acc, item, itemIndex) => element.items.includes(item, itemIndex + 1) ? [...acc, item] : acc, [])
        if(state.duplicates !== duplicates) setState(state => ({...state, duplicates}))
    }, [element.items])

    const removeItem = index => {
        clearOppressedAndShiftAffectedIndexes(element.code, index)
        editElement({ ...element, items: element.items.filter((_, i) => i !== index) })
    };

    const editItem = (index, value) => {
        editElement({...element, items: element.items.map((el, i) => i !== index ? el : value)})
    };

    const onAddOptionClick = () => {
        editElement({...element, items: [...element.items, `Option`]})
    };

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        if (state.duplicates.length === 0) {
            changeElementEditorMode("DEFAULT")
        } else {
            notification.error({
                message: 'Some of the radiobox data is duplicated'
            })
        }
    }

    const onValidationsClick = () => {
        changeElementEditorMode("VALIDATIONS")
    };

    const onGridClick = () => {
        changeElementEditorMode("GRID")
    };

    const onGridMinus = () => {
        element.grid_columns > 1 &&
        editElement({
            ...element,
            grid_columns: element.grid_columns - 1
        })
    };

    const onGridPlus = () => {
        element.grid_columns < 3 &&
        editElement({
            ...element,
            grid_columns: element.grid_columns + 1
        })
    };

    const onValidationsChange = newValidations => {
        editElement({
            ...element,
            validations: newValidations
        })
    };

    const onPinnedChange = e => {
        editElement({
            ...element,
            pinned: {
                status: e.target.checked
            }
        })
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "GRID" && "view-box-grid"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center">
                <div className="form-box-wrap-center-content">
                    <p className="form-box-wrap-descrition">{element.text}</p>
                    <div className="form-grid" style={{gridTemplateColumns: `repeat(${element.grid_columns}, 1fr)`}}>
                        {element.items.map((item, itemIndex) => (
                            <div className="form-row-radio" key={itemIndex}>
                                <input id="radio81" type="radio" onChange={f => f} checked={false}/>
                                <label htmlFor="radio81">{item}</label>
                            </div>
                        ))}
                    </div>
                    <VisibilityAffected element={element} formElements={formElements}/>
                    <p className="form-row-validation-text">{element.validations.map(v => `[ ${v.validation} ]  `)}{element.pinned && element.pinned.status ? "[ show on top ]" : ""}</p>
                    <CodeCopy code={element.code}>
                        <span className="form-box-wrap-id"/>
                    </CodeCopy>
                </div>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onValidationsClick}><i className="icon-check trigger-validation-box"/>Validation</span>
                        <span onClick={onGridClick}><i className="icon-ia-grid trigger-grid-box"/>Grid</span>
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <LdeleteConfirm
                            onConfirm={() => {
                                clearOppressedElements(element.code)
                                remove()
                            }}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </LdeleteConfirm>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className="form-row">
                    <input id="" type="text" name="" placeholder="Radio Buttons Group Label" value={element.text}
                           onChange={e => editElement({...element, text: e.target.value})}/>
                </div>
                <div className="form-row multiple-radio-checkbox-list">
                    <label>Options List</label>
                    {element.items.map((item, itemIndex) => (
                        <div key={itemIndex} className={`mrl-row with-delete-btn ${state.duplicates.find(i => i === item) ? "error" : ""}`}>
                            <input type="text" value={item} onChange={e => editItem(itemIndex, e.target.value)}/>
                            <ConditionalValidation oppressorItemIndex={itemIndex} formElements={formElements} oppressorElement={element} editElementByCode={editElementByCode} />
                            {element.items.length > 1 &&
                            <LdeleteConfirm
                                onConfirm={() => removeItem(itemIndex)}
                                okText="Delete"
                                cancelText="Cancel"
                                title="Are you sure you want to delete this option? All data will be lost. "
                            >
                                <i className="icon-ia-trash-bold delete-form-row-btn"/>
                            </LdeleteConfirm>
                            }
                        </div>
                    ))}
                    {element.items.length <= 50 &&
                    <span className="add-block-input" onClick={onAddOptionClick}>Add Option</span>
                    }
                </div>
                <div className="frc-box">
                    <div className="form-row-checkbox">
                        <input id={`pinned-${element.code}`} type="checkbox"
                               checked={element.pinned && element.pinned.status} onChange={onPinnedChange}/>
                        <label htmlFor={`pinned-${element.code}`}>Show on top in saved response</label>
                    </div>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
            <div className="form-box-wrap-grid-edit">
                <div className="set-grid-box">
                    <label>Grid columns</label>
                    <div className="set-grid">
                        <i className="icon-ia-minus" onClick={onGridMinus}/>
                        <input type="text" name="" value={element.grid_columns} onChange={f => f}/>
                        <i className="icon-ia-plus" onClick={onGridPlus}/>
                    </div>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
            <div className="form-box-wrap-center-validation mco-box">
                <div className="mco-cell"><h4>Validations</h4></div>
                <Validations
                    availableValidations={supportedElements[element.type].availableValidations}
                    onChange={onValidationsChange}
                    value={element.validations}
                />
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}