import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {dataURLToBlob} from 'blob-util'
import {isEmpty} from "validator"
import isEmail from 'isemail'
import {ThemeProvider} from 'styled-components'
import {BlockPicker} from 'react-color'

import {readObjectUrl} from '../../utils/readObjectUrl'

import Navbar from '../../components/Navbar/Navbar'
import LivePreview from '../../components/LivePreview'

import API from '../../api'
import LdeleteConfirm from "../../components/LdeleteConfirm"
import Loading from '../../components/Loading'
import LnavigationPrompt from '../../components/LnavigationPrompt'
import LpopUp from '../../components/LpopUp'
import NotifyOthersList from '../NewForm/NotifyOthersList'
import {notification} from '../../components/Lnotification'

const defaultLookAndFeel = {
    formLogo: null,
    fontColor: "#5D2560",
    backgroundColor: "#F1F1F1",
    fontFace: 'Open Sans'
}

const FormProperties = () => {

    const [state, setState] = React.useState({
        form: null,
        saving: false,
        loadingForm: false,
        errors: {},
        unsavedChanges: false
    }, 'edit form properties');

    const params = useParams();
    const history = useHistory();

    React.useEffect(() => {
        loadForm()
    }, []);

    const setForm = callback => setState(state => ({...state, form: callback(state.form), unsavedChanges: true}))

    const loadForm = async () => {
        try {
            setState(state => ({...state, loadingForm: true}));

            const {data: {form}} = await API.get(`forms/${params.id}`);

            setState(state => ({
                ...state,
                form: {
                    name: form.name,
                    description: form.description,
                    notifyMe: form.notification_recipients.self,
                    sendFormToRecipient: form.notification_recipients.client,
                    notifyOthers: {
                        others: form.notification_recipients.others || [],
                        other: ''
                    },
                    backgroundColor: form.background_color,
                    fontFace: form.font_face,
                    fontColor: form.font_color,
                    formLogo: form.logo ? URL.createObjectURL(dataURLToBlob(form.logo)) : null
                }
            }))

            setState(state => ({...state, loadingForm: false}))
        } catch (err) {
            notification.warning({message: "Something went wrong"})
            setState(state => ({...state, loadingForm: false}))
        }
    };

    const onLogoChange = event => {
        const image = event.target.files[0];
        event.target.value = "";
        setForm(form => ({...form, formLogo: URL.createObjectURL(image)}))
    }

    const validate = () => {
        const errors = {};

        if (isEmpty(state.form.name)) errors.name = "Name is required";

        if (isEmpty(state.form.description)) errors.description = "this field is required";
        else if (state.form.description.length > 5000) errors.description = "Form description is too long.";

        if (isEmpty(state.form.fontColor)) errors.font_color = "Font color is required";

        if (isEmpty(state.form.backgroundColor)) errors.background_color = "Background color is required";

        if (isEmpty(state.form.fontFace)) errors.font_face = "Font face is required";

        if (state.form.notifyOthers.other.trim().length > 0 && !isEmail.validate(state.form.notifyOthers.other)) errors.notifyOthers = "Invalid Email"

        setState(state => ({...state, errors}));
        return Object.keys(errors).length === 0
    }

    const save = async () => {
        try {
            setState(state => ({...state, saving: true}));

            if (!validate()) {
                setState(state => ({...state, saving: false}));
                return
            }
            await API.put(`forms/${params.id}`, {
                name: state.form.name,
                description: state.form.description,
                font_color: state.form.fontColor,
                font_face: state.form.fontFace,
                background_color: state.form.backgroundColor,
                logo: state.form.formLogo ? await readObjectUrl(state.form.formLogo) : null,
                notification_recipients: {
                    self: state.form.notifyMe,
                    client: state.form.sendFormToRecipient,
                    others: [...state.form.notifyOthers.others, ...(state.form.notifyOthers.other.trim().length > 0) ? [state.form.notifyOthers.other] : []]
                }
            })

            setState(state => ({...state, saving: false, unsavedChanges: false}))
        } catch (err) {
            setState(state => ({...state, saving: false}))
            console.log(err)
            notification.warning({message: "Something went wrong"})
        }

    };

    const reset = () => setState(state => ({
        ...state,
        form: {
            ...state.form,
            ...defaultLookAndFeel 
        }
    }))

    return (
        <>
            <div className="wrapper new-form-page">
                <Navbar/>
                {state.loadingForm && <Loading />}
                {!state.loadingForm && state.form &&
                    <>
                        <div className="content">

                            <div className="toolbox toolbox-1">
                                <h3 className="tb-header-title">Look and feel</h3>
                                <div className="scrollbar">
                                    <h6>Form logo</h6>
                                    <div className="tb-upload-logo">
                                        <div className="tb-uploaded-img"><img
                                            src={state.form.formLogo || "/alchemistLight/img/Alchemist_Identity_Final-01.png"} alt=""/>
                                        </div>
                                        <input className="tb-upload-file" type="file" onChange={onLogoChange} accept="image/*"/>
                                    </div>
                                    <h6>Font color</h6>
                                    <div css={`margin: .5rem 0 1rem; >.block-picker>div:nth-child(2){border: 2px solid white;}`}>
                                        <BlockPicker
                                            triangle="hide"
                                            width="100%"  
                                            colors={[
                                                '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#000000',
                                                '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#7f8c8d',
                                                '#f1c40f', '#e67e22', '#fafbfe', '#fea540', '#95a5a6'
                                            ]}
                                            color={state.form.fontColor}
                                            onChange={color => setForm(form => ({...form, fontColor: color.hex}))}
                                        />
                                    </div>
                                    <h6>Background color</h6>
                                    <div css={`margin: .5rem 0 1rem; >.block-picker>div:nth-child(2){border: 2px solid white;}`}>
                                        <BlockPicker
                                            triangle="hide"
                                            width="100%" 
                                            colors={[
                                                '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#000000',
                                                '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#7f8c8d',
                                                '#f1c40f', '#e67e22', '#fafbfe', '#fea540', '#95a5a6'
                                            ]}
                                            color={state.form.backgroundColor}
                                            onChange={color => setForm(form => ({...form, backgroundColor: color.hex}))}
                                            
                                        />
                                    </div>
                                    <h6>Font face</h6>
                                    <div className="choose-font">
                                        <select
                                            value={state.form.fontFace}
                                            onChange={({target: {value}}) => setForm(form => ({...form, fontFace: value}))}
                                        >
                                            <option value="Open Sans">Open Sans</option>
                                            <option value="Sans Serif">Sans Serif</option>
                                            <option value="Calibri">Calibri</option>
                                        </select>
                                    </div>
                                    <span className="button button-outline" onClick={reset}>Reset</span>
                                </div>
                            </div>

                            <div className={`content-box ${Object.keys(state.errors).length !== 0 ? "error-anim" : ""}`}>
                                <form className="form form-step-1"
                                    onSubmit={e => {
                                        e.preventDefault();
                                        save()
                                    }}
                                >
                                    <div className="scrollbar">
                                        <div className="form-box-header">
                                            <h6 className="supTitle">New form</h6>
                                            <div className={`form-row ${state.errors.name ? "error" : ""}`}>
                                                <input
                                                    type="text"
                                                    placeholder="Form name"
                                                    value={state.form.name}
                                                    onChange={({target: {value}}) => setForm(form => ({...form, name: value }))}
                                                />
                                                {state.errors.name && <span className="form-row-error-msg">{state.errors.name}</span>}
                                            </div>
                                        </div>
                                        <div className="form-box-body">

                                            <fieldset>
                                                <div className={`form-row ${state.errors.description ? "error" : ""}`}>
                                                    <label htmlFor="form-description">Form description</label>
                                                    <textarea
                                                        value={state.form.description}
                                                        onChange={({target: {value}}) => setForm(form => ({ ...form, description: value }))}
                                                    />
                                                    {state.errors.description &&
                                                    <span className="form-row-error-msg">{state.errors.description}</span>}
                                                </div>
                                                <h4>Notification options</h4>
                                                <div className="notif-recipients-box">
                                                    <div className="form-row-checkbox">
                                                        <input
                                                            id="form-nr-agent"
                                                            type="checkbox"
                                                            checked={state.form.notifyMe}
                                                            onChange={({target: {checked}}) => setForm(form => ({...form, notifyMe: checked}))}
                                                        />
                                                        <label htmlFor="form-nr-agent">Notify me when a form is
                                                            filled-in</label>
                                                    </div>
                                                    <div className="form-row-checkbox">
                                                        <input
                                                            id="form-nr-others"
                                                            type="checkbox"
                                                            checked={state.form.sendFormToRecipient}
                                                            onChange={({target: {checked}}) => setForm(form => ({...form, sendFormToRecipient: checked}))}
                                                        />
                                                        <label htmlFor="form-nr-others">Send completed form to others:</label>
                                                    </div>
                                                    <NotifyOthersList 
                                                        value={state.form.notifyOthers}
                                                        changeValue={callback => setState(state => ({
                                                            ...state,
                                                            form: {
                                                                ...state.form,
                                                                notifyOthers: callback(state.form.notifyOthers)
                                                            }
                                                        }))}
                                                        error={state.errors.notifyOthers}
                                                    />
                                                </div>

                                            </fieldset>

                                            <div className="form-box-actions">
                                                <LdeleteConfirm
                                                    onConfirm={() => {
                                                        setForm(form => ({
                                                            ...form, 
                                                            name: '',
                                                            description: '',
                                                            notifyMe: false,
                                                            sendFormToRecipient: false,
                                                            notifyOthers: [],
                                                            fontColor: "#8F75DB",
                                                            backgroundColor: "#fafbfe",
                                                            fontFace: 'Open Sans',
                                                            formLogo: null
                                                        }))
                                                    }}
                                                    okText="Clear"
                                                    cancelText="Cancel"
                                                    title="Are you sure you want to clear all data?"
                                                >
                                                    <button className="button button-outline" type="button"><i className="icon-ia-trash"/><span>Clear All</span></button>
                                                </LdeleteConfirm>
                                                <span></span>
                                                <a className="button" href="#" onClick={async e => {
                                                    e.preventDefault();
                                                    await save()
                                                    if(history.location.state && history.location.state.newForm) {
                                                        history.push(`/forms/${params.id}/edit/definition`, {newForm: true})
                                                    } else {
                                                        history.push('/forms')
                                                    }
                                                }}><i className="icon-ia-checked-outline"></i><span>Save</span></a>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>

                        </div>

                        <div className="sidebar sidebar-preview-tablet">
                            <div className="sidebar-title">
                                <h3 className="sidebar-title sidebar-title-fixed">Preview</h3>
                            </div>
                            <div className="pts-top">

                                <div className="tablet-preview">
                                    <img src="alchemistLight/img/tablet.svg"/>
                                    <ThemeProvider theme={{ 
                                        mode: 'dark',
                                        backgroundColor: state.form.backgroundColor,
                                        fontColor: state.form.fontColor,
                                        fontFace: state.form.fontFace,
                                        defaultLook: {
                                            fontColor: "#5D2560",
                                            backgroundColor: "#F1F1F1",
                                            fontFace: 'Open Sans'
                                        }
                                    }}>
                                        <LivePreview
                                            name={state.form.name}
                                            logo={state.form.formLogo}
                                            description={state.form.description}
                                        />
                                    </ThemeProvider>
                                    <i className="icon-ia-edit-bold edit-slide-preview"></i>
                                </div>

                            </div>
                        </div>
                        <LnavigationPrompt when={state.unsavedChanges}>
                            {({onConfirm, onCancel}) => (
                                <LpopUp 
                                    visible={true}
                                    disableHeader
                                    onCancel={onCancel}
                                    onConfirm={onConfirm}
                                >
                                    <h3>Are you sure you want to leave the page? You have unsaved changes.</h3>
                                </LpopUp>
                            )}
                        </LnavigationPrompt>
                    </>
                }
            </div>
        </>
    )
};

export default FormProperties