import React from 'react'
import LdeleteConfirm from '../../components/LdeleteConfirm'
import supportedElements from '../supportedElements'
import Validations from '../Validations'
import CodeCopy from '../../components/Editor.codeCopy'

export default ({ element, remove, editElement, changeElementEditorMode, dragHandleProps = {}, VisibilityAffected, formElements }) => {

  const onOptionClick = () => {
    changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
  };

  const onEditClick = () => {
    changeElementEditorMode("EDIT")
  };

  const onValidationsClick = () => {
    changeElementEditorMode("VALIDATIONS")
  };

  const onSaveClick = () => {
    changeElementEditorMode("DEFAULT")
  };

  const onValidationsChange = newValidations => {
    editElement({
      ...element,
      validations: newValidations
    })
  };

  const onPinnedChange = e => {
    editElement({
      ...element,
      pinned: {
        status: e.target.checked
      }
    })
  };

  return(
    <div className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
      <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
      <div className="form-box-wrap-center">
        <div className="form-box-wrap-center-content">
          <h6 className="supTitle">Date Picker</h6>
          <p className="form-box-wrap-descrition">{element.text}</p>
          <p className="form-row-validation-text">{element.validations.map(v => `[ ${v.validation} ]  `)}{element.pinned && element.pinned.status ? "[ show on top ]" : ""}</p>
        </div>
        <VisibilityAffected element={element} formElements={formElements}/>
        <CodeCopy code={element.code}>
          <span className="form-box-wrap-id" />
        </CodeCopy>
        <div className="form-box-wrap-options">
          <div className="form-box-wrap-options-buttons">
            <span onClick={onValidationsClick}><i className="icon-check trigger-validation-box"/>Validation</span>
            <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
            <LdeleteConfirm
                onConfirm={remove}
                okText="Delete"
                cancelText="Cancel"
                title="Are you sure you want to delete this component? All data will be lost. "
            >
              <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
            </LdeleteConfirm>
          </div>
        </div>
      </div>
      <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
      <div className="form-box-wrap-center-edit">
        <div className="form-row mar2b">
          <input type="text" name="" placeholder="Date Picker Label" value={element.text} onChange={e => editElement({ ...element, text: e.target.value })}/>
        </div>
        <div className="form-row mar2b">
          <input type="text" name="" placeholder="Date Picker Placeholder" value={element.placeholder} onChange={e => editElement({ ...element, placeholder: e.target.value })}/>
        </div>
        <div className="frc-box">
          <div className="form-row-checkbox">
            <input id={`pinned-${element.code}`} type="checkbox"
                   checked={element.pinned && element.pinned.status} onChange={onPinnedChange}/>
            <label htmlFor={`pinned-${element.code}`}>Show on top in saved response</label>
          </div>
        </div>
        <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
      </div>
      <div className="form-box-wrap-center-validation mco-box">
        <div className="mco-cell"><h4>Validations</h4></div>
        <Validations 
          availableValidations={supportedElements[element.type].availableValidations}
          onChange={onValidationsChange}
          value={element.validations}
        />
        <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
      </div>
    </div>
  )
}