export const phoneValidator = (number) => {
    if (typeof number !== 'string') {
        return false;
    }

    if (!/^[0][0-9]{9}$/.test(number)) {
        return false;
    }
    
    return number;
};