import React from 'react'
import {useLocation} from 'react-router-dom'

import Navbar from '../../components/Navbar/Navbar'
import Portrait from '../../components/Portrait'

import ChangePassword from './ChangePassword'
import ChangeEmail from './ChangeEmail'
import DeleteAccount from './DeleteAccount'
import ChangeDetails from './ChangeDetails'
import ConfigureStorage from './ConfigureStorage'

import {useAppContext} from '../../context'

const Profile = () => {

    const {auth: [auth]} = useAppContext();
    const [tab, setTab] = React.useState(null)
    const toggleTab = t => setTab(tab === t ? null : t);
    const location = useLocation()

    React.useEffect(() => {
        if(location.state && location.state.onedrive) setTab('storage')
    }, [location])

    return (
        <>
            <div
                className={`wrapper profile-page ${tab === 'password' ? 'view-change-password' : ''} ${tab === 'email' ? 'view-edit-details' : ''} ${tab === 'delete-account' ? 'view-delete-account' : ''}`}>
                <Navbar/>
                <div className="content">
                    <div className="scrollbar">
                        <div className="profile-box">
                            <ChangeDetails/>
                            <div className="profile-action">
                                {(!auth.decodedToken.external || !auth.decodedToken.external.id) &&
                                    <>
                                        <span onClick={() => toggleTab('email')}
                                            className="button button-outline with-icon-right trigger-edit-details"><i
                                            className="icon-ia-edit-bold"/>Edit details</span>
                                        <span onClick={() => toggleTab('password')}
                                            className="button button-outline with-icon-right trigger-change-password"><i
                                            className="icon-lock"/>Change password</span>
                                    </>
                                }
                                {(auth.decodedToken.external && auth.decodedToken.external.system === 'microsoft') && 
                                    <span onClick={() => toggleTab('storage')} className="button button-outline with-icon-right">
                                        <i className="icon-ia-edit-bold"/>OneDrive
                                    </span>
                                }
                                <span onClick={() => toggleTab('delete-account')}
                                      className="button button-outline with-icon-right trigger-delete-account"><i
                                    className="icon-ia-trash-bold"/>Delete account</span>
                            </div>
                        </div>
                        {(auth.decodedToken.external && auth.decodedToken.external.system === 'microsoft') && 
                           <ConfigureStorage onCancel={() => setTab(null)} tab={tab}/>
                        }
                        {(!auth.decodedToken.external || !auth.decodedToken.external.id) &&
                            <>
                                <ChangeEmail onCancel={() => setTab(null)}/>
                                <ChangePassword onCancel={() => setTab(null)}/>
                            </>
                        }
                        <DeleteAccount onCancel={() => setTab(null)}/>
                        {/* <ChosePlan/> */}
                    </div>
                </div>
            </div>
            <Portrait/>
        </>
    )
};

export default Profile